import React from "react";

type DividerProps = {
  title?: string;
  header?: boolean;
};

export default function Divider({ title, header = false }: DividerProps) {
  return (
    <>
      {!header && <div className="h-[1px] bg-gray-200 w-full mb-4"></div>}
      {header && (
        <div className="py-2 border-y w-full mb-4 bg-gray-200">
          <div className="text-lg font-gilroy-medium px-4">{title}</div>
        </div>
      )}
    </>
  );
}
