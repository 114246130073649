import React from "react";

const Unauthorized = () => {
  return (
    <div className="flex justify-center items-center h-full">
      <p className="font-gilroy-bold text-2xl border-2 border-pot-yellow  ease-in-out transition rounded-md bg-pot-yellow text-white stroke-white hover:bg-pot-grey5 hover:border-pot-grey5 px-2.5 py-1.5 ">
        Not Authorized to access{" "}
      </p>
    </div>
  );
};

export default Unauthorized;
