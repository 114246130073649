import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateRangePicker.css";
import classNames from "classnames";
import DateIcon from "../../assets/svg-tsx/DateIcon";
import Button from "../Button";
import CrossIcon from "../../assets/CrossIcon";
import { motion } from "framer-motion";
import { formatDateToDDMMYYYY } from "../../utils/formatDateTime";
import { primaryInputStyle } from "../InputField";
import { DownwardArrowIcon } from "../../assets/ArrowIcons";

const dateRangeOptions = [
  {
    id: 1,
    dateSelection: "Today",
  },
  {
    id: 2,
    dateSelection: "Yesterday",
  },
  {
    id: 3,
    dateSelection: "This week",
  },
  {
    id: 4,
    dateSelection: "Last week",
  },
  {
    id: 5,
    dateSelection: "This month",
  },
  {
    id: 6,
    dateSelection: "Last month",
  },
  {
    id: 7,
    dateSelection: "This year",
  },
  {
    id: 8,
    dateSelection: "Last year",
  },
];

const dateRangeOptionsForDashboard = [
  {
    id: 1,
    dateSelection: "Today",
  },
  {
    id: 2,
    dateSelection: "Yesterday",
  },
  {
    id: 3,
    dateSelection: "This week",
  },
  {
    id: 4,
    dateSelection: "Last week",
  },
  {
    id: 5,
    dateSelection: "This month",
  },
  {
    id: 6,
    dateSelection: "Last month",
  },
  {
    id: 7,
    dateSelection: "This quarter",
  },
  {
    id: 8,
    dateSelection: "Last quarter",
  },
  {
    id: 9,
    dateSelection: "This year",
  },
  {
    id: 10,
    dateSelection: "Last year",
  },
];

const DateRangePicker = ({
  onApplyClick,
  onDateClear,
  calendarToShow = 2,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  buttonText = "Enquiry Date",
  customQuickSelectionRequired = false,
  extraCalendarStyles,
  extraButtonStyles,
}: any) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startInput, setStartInput] = useState("");
  const [endInput, setEndInput] = useState("");
  const calenderRef = useRef<any>();

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  function closeCalender() {
    setShowDatePicker(false);
  }
  function handleClickOutside(e: any) {
    if (calenderRef.current && !calenderRef.current.contains(e.target)) {
      closeCalender();
    }
  }

  useEffect(() => {
    setStartInput(startDate ? formatDateToDDMMYYYY(startDate) : "");
    setEndInput(endDate ? formatDateToDDMMYYYY(endDate) : "");
  }, [startDate, endDate]);

  useEffect(() => {
    if (showDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDatePicker]);

  const handleInputChange = (e: any, type: any) => {
    const value = e.target.value.trim();
    type === "start" ? setStartInput(value) : setEndInput(value);

    if (value.length < 7) {
      return;
    }
    if (dateValidationCheck(value)) {
      const [d, m, y] = value.split("/");
      const newValue = `${m}/${d}/${y}`;

      if (type === "start") {
        setStartDate(new Date(newValue));
      } else {
        setEndDate(new Date(newValue));
      }
    }
  };

  const dateValidationCheck = (e: any) => {
    if (!e) {
      return false;
    }
    const arr = e.split("/");
    if (arr.length !== 3) {
      return false;
    }
    if (+arr[0] > 31 || +arr[0] < 1) {
      return false;
    }
    if (+arr[1] > 12 || +arr[1] < 1) {
      return false;
    }
    if (+arr[2] < 1900 || +arr[2] > 2100) {
      return false;
    }
    return true;
  };

  const clearDates = (e: any) => {
    e.stopPropagation();
    setStartDate(null);
    setEndDate(null);
    onDateClear();
  };

  const onApplyHandler = () => {
    onApplyClick(startDate, endDate);
    closeCalender();
  };

  const handleOptionClick = (item: any) => {
    setSelectedOption(item.dateSelection);

    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    // Logic to set start and end dates based on the selected option
    if (item.dateSelection === "Today") {
      setStartDate(new Date());
      setEndDate(null);
    } else if (item.dateSelection === "Yesterday") {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      setStartDate(yesterday);
      setEndDate(null);
    } else if (item.dateSelection === "This week") {
      const today = new Date();
      const thisWeekStartDate = new Date(
        today.setDate(today.getDate() - today.getDay())
      );
      setStartDate(thisWeekStartDate);
      setEndDate(new Date());
    } else if (item.dateSelection === "Last week") {
      const lastWeekStartDate = new Date();
      lastWeekStartDate.setDate(
        lastWeekStartDate.getDate() - lastWeekStartDate.getDay() - 7
      );
      const lastWeekEndDate = new Date(
        lastWeekStartDate.getFullYear(),
        lastWeekStartDate.getMonth(),
        lastWeekStartDate.getDate() + 6
      );
      setStartDate(lastWeekStartDate);
      setEndDate(lastWeekEndDate);
    } else if (item.dateSelection === "This month") {
      const thisMonthStartDate = new Date();
      thisMonthStartDate.setDate(1);
      setStartDate(thisMonthStartDate);
      // setEndDate(new Date()); // Previous logic to set end date as today's date
      const thisMonthEndDate = new Date(thisMonthStartDate);
      thisMonthStartDate.setDate(1);
      thisMonthEndDate.setMonth(thisMonthStartDate.getMonth() + 1);
      thisMonthEndDate.setDate(0);
      setStartDate(thisMonthStartDate);
      setEndDate(thisMonthEndDate);
    } else if (item.dateSelection === "Last month") {
      const lastMonthStartDate = new Date();
      lastMonthStartDate.setMonth(lastMonthStartDate.getMonth() - 1, 1);
      const lastMonthEndDate = new Date(
        lastMonthStartDate.getFullYear(),
        lastMonthStartDate.getMonth() + 1,
        0
      );
      setStartDate(lastMonthStartDate);
      setEndDate(lastMonthEndDate);
    } else if (item.dateSelection === "This year") {
      const thisYearStartDate = new Date(new Date().getFullYear(), 0, 1);
      setStartDate(thisYearStartDate);
      setEndDate(new Date());
    } else if (item.dateSelection === "Last year") {
      const lastYearStartDate = new Date(new Date().getFullYear() - 1, 0, 1);
      const lastYearEndDate = new Date(new Date().getFullYear() - 1, 11, 31);
      setStartDate(lastYearStartDate);
      setEndDate(lastYearEndDate);
    } else if (item.dateSelection === "This quarter") {
      const quarterStartDate = new Date(
        currentYear,
        Math.floor(currentMonth / 3) * 3,
        1
      );
      setStartDate(quarterStartDate);
      setEndDate(new Date());
    } else if (item.dateSelection === "Last quarter") {
      const lastQuarterStartMonth = (Math.floor(currentMonth / 3) - 1) * 3;
      const lastQuarterStartDate = new Date(
        currentYear,
        lastQuarterStartMonth,
        1
      );
      const lastQuarterEndDate = new Date(
        currentYear,
        lastQuarterStartMonth + 2,
        new Date(currentYear, lastQuarterStartMonth + 2, 0).getDate()
      );
      setStartDate(lastQuarterStartDate);
      setEndDate(lastQuarterEndDate);
    }
    // Add more conditions as needed

    // Close the date picker after selecting an option
    // setShowDatePicker(false);
  };

  const customDayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  return (
    <>
      <div className="relative flex items-center" ref={calenderRef}>
        <button
          className={`min-w-[180px] justify-between h-[35px] text-xs flex rounded-md lg:text-sm font-normal leading-[14px] items-center px-3 gap-1 md:gap-3 ${primaryInputStyle} 
           ${
             showDatePicker
               ? "text-white bg-primary-black"
               : "text-primary-black bg-white"
           }
          )`}
          onClick={() => setShowDatePicker(!showDatePicker)}
        >
          {startDate || endDate ? (
            <p className="text-black">
              {formatDateToDDMMYYYY(startDate)}
              {endDate && ` - ${formatDateToDDMMYYYY(endDate)}`}
            </p>
          ) : (
            <span>{buttonText}</span>
          )}
          {!startDate && !endDate && (
            <span
              className={classNames(
                "transform duration-300",
                showDatePicker ? "rotate-180 transform" : "rotate-0"
              )}
            >
              <DownwardArrowIcon color="black" />
            </span>
          )}

          {startDate && (
            <span onClick={(e: any) => clearDates(e)}>
              <CrossIcon width="10" color="black" />
            </span>
          )}
        </button>

        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={
            showDatePicker
              ? { opacity: 1, scale: 1, display: "block" }
              : { opacity: 0, scale: 0.95, transitionEnd: { display: "none" } }
          }
          transition={{ duration: 0.1, ease: "easeOut" }}
          className={classNames(
            "outside-datepiker-container z-50",
            extraCalendarStyles
          )}
        >
          <div className=" flex items-center justify-between mb-2">
            <div className="p-2.5 flex gap-2.5 items-center text-black text-sm font-bold leading-[14px] rounded-md border border-gray-100">
              <span className="text-gray-600">
                <DateIcon />
              </span>
              <input
                type="text"
                value={startInput}
                onChange={(e) => handleInputChange(e, "start")}
                className="focus:outline-none w-[75px] mt-1 placeholder:text-sm"
                placeholder="DD/MM/YYYY"
              />
              <span>-</span>
              <input
                type="text"
                value={endInput}
                onChange={(e) => handleInputChange(e, "end")}
                className="mt-1 w-[100px] focus:outline-none placeholder:text-sm"
                placeholder="DD/MM/YYYY"
              />
            </div>
            <div className="flex gap-4 mr-4">
              <Button
                type="button"
                label="Cancel"
                variant="secondary-outline"
                onClick={() => setShowDatePicker(false)}
                disabled={!startDate}
              />
              <Button
                type="button"
                label="Apply"
                variant="primary"
                disabled={!startDate}
                onClick={onApplyHandler}
              />
            </div>
          </div>

          <div className="flex gap-4 flex-row h-max">
            {/* for desktop */}
            <div className="block xl:min-w-[91px]">
              {!customQuickSelectionRequired &&
                dateRangeOptions.map((item) => {
                  return (
                    <button
                      type="button"
                      key={item?.id}
                      className={classNames(
                        "w-full py-2.5 pl-2.5 text-left text-sm leading-[14px] font-normal transition-all duration-300",
                        item?.dateSelection === selectedOption
                          ? "text-yellow-600 bg-yellow-100 rounded-md"
                          : "text-brown-400 bg-white"
                      )}
                      onClick={() => handleOptionClick(item)}
                    >
                      {item?.dateSelection}
                    </button>
                  );
                })}

              {customQuickSelectionRequired &&
                dateRangeOptionsForDashboard.map((item) => {
                  return (
                    <button
                      type="button"
                      key={item?.id}
                      className={classNames(
                        "w-full py-2.5 pl-2.5 text-left text-sm leading-[14px] font-normal transition-all duration-300",
                        item?.dateSelection === selectedOption
                          ? "text-white bg-pot-yellow rounded-md"
                          : "text-brown-400 bg-white"
                      )}
                      onClick={() => handleOptionClick(item)}
                    >
                      {item?.dateSelection}
                    </button>
                  );
                })}
            </div>

            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
              monthsShown={calendarToShow}
              dateFormat="dd/MM/yyyy"
              useWeekdaysShort
              weekDayClassName={(date) =>
                `custom-day-${customDayNames[date.getDay()]}`
              }
              calendarClassName="range-picker-calender"
              monthClassName={() =>
                `mt-0 border-2 border-red-800 [&>div]:bg-green-800`
              }
            />

            {/* <div className="flex justify-end xl:hidden">
              <div className="flex gap-3.5 items-center xl:hidden">
                <button
                  className="w-20 h-[35px] rounded-md bg-[#666666]/25 text-primary-black text-sm font-normal leading-[14px]"
                  type="button"
                  onClick={() => setShowDatePicker(false)}
                >
                  Cancel
                </button>
                <Button
                  type="button"
                  label="Apply"
                  variant="primary"
                  // extraStyles="!w-[87px] !h-[35px] !rounded-md"
                  disabled={!startDate}
                  onClick={onApplyHandler}
                />
              </div>
            </div> */}
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default DateRangePicker;
