import React, { useEffect, useMemo, useState } from "react";
import EditIcon from "../../../assets/svg/pen.svg";
import DeleteIcon from "../../../assets/svg/trash.svg";
import ActiveStatusIcon from "../../../assets/svg/activestatus.svg";
import DeactiveStatusIcon from "../../../assets/svg/deactivatestatus.svg";
import { useTable, usePagination, useSortBy } from "react-table";
import { useSticky } from "react-table-sticky";
import InputField from "../../../components/InputField";
import SearchIcon from "../../../assets/SearchIcon";
import { Link } from "react-router-dom";
import ManagementTableLayout from "../../../components/MangementTable";
import Button from "../../../components/Button";
import PlusIcon from "../../../assets/PlusIcon";
import { toast } from "react-toastify";
import {
  deleteVariants,
  getVariants,
  updateVariants,
} from "../../../api/variants";
import { useRecoilState } from "recoil";
import { variantsDataCount } from "../../../atom";
import Pagination2 from "../../../components/Pagination";
import Modal from "../../../components/common/Modal";
import _debounce from "lodash/debounce";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import { checkPermissions } from "../../../utils/helper";

const VariantTable = () => {
  const [totalCount, setTotalCount] = useState<number>(100);
  const [page, setPage] = useState(1);
  const [pageLength, setPageLength] = useState<number>(25);
  const [loading, setLoading] = useState(true);
  const [inputVal, setInputVal] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [variantData, setVariantData] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<any>("");
  const [filteredData, setFilteredData] = useState<
    { name: string; totalSpend: string }[]
  >([]);

  const [variantsCount, setVariantsCount] = useRecoilState(variantsDataCount);
  const [startIndex, setStartIndex] = useState(0);

  //Permissions
  const hasCreatePermission = checkPermissions("create_product");
  const hasEditPermission = checkPermissions("edit_product");
  const hasDeletePermission = checkPermissions("delete_product");

  // const downloadReport = async () => {
  //   const response = await fetch("your-api-url", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/xml", // Set the content type to match your API
  //     },
  //   });
  //   const blob = await response.blob();
  //   const url = window.URL.createObjectURL(new Blob([blob]));
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", "report.xml");
  //   document.body.appendChild(link);
  //   link.click();
  // };

  const handleDelete = async (id: string) => {
    try {
      await protectedAxiosInstance.delete(
        `/admin/products/variant-template/${id}`
      );
      let settablePage: any;
      if ((totalCount - 1) % pageLength === 0) {
        settablePage = page - 1;
      } else {
        settablePage = page;
      }
      setPage(settablePage === 0 ? 1 : settablePage);
      setDeleteModal("");
      fetchVariants();
      toast.success("Variant deleted successfully");
    } catch (error) {
      console.error(error);
      toast.error("Error deleting variant");
    }
  };

  const fetchVariants = async () => {
    setLoading(true);
    await getVariants({ page: page, length: pageLength, search: inputVal })
      .then((res) => {
        const { variant_template, pagination } = res.data;
        setVariantData(variant_template);
        setVariantsCount(pagination.total_count);
        setTotalCount(pagination.total_count);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const [time, setTime] = useState(0);
  const debouncedFetchVariantData = _debounce(fetchVariants, time);
  useEffect(() => {
    setTime(500);
    setPage(1);
    const fetchData = async () => {
      await debouncedFetchVariantData();
    };
    fetchData();
    return () => {
      debouncedFetchVariantData.cancel();
    };
  }, [inputVal, pageLength]);

  useEffect(() => {
    setTime(0);
    const fetchData = async () => {
      await debouncedFetchVariantData();
    };
    fetchData();
    return () => {
      debouncedFetchVariantData.cancel();
    };
  }, [page]);

  const handleToggleStatus = (id: any, data: any) => {
    const updatedData = { ...data, is_active: !data.is_active };

    updateVariants(id, { is_active: updatedData.is_active })
      .then(() => {
        const updatedVariantData = variantData.map((variant: any) =>
          variant.id === id ? updatedData : variant
        );
        setVariantData(updatedVariantData);
        toast.success("Variant status updated successfully");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to update variant status");
      });
  };

  const COLUMN = [
    {
      id: "sr",
      Header: () => <div>Sr. No</div>,
      width: "4.5%",
      accessor: "sr",
      Cell: ({ row }: { row: any }) => <div>{startIndex + row.index + 1}</div>,
    },
    {
      id: "variantName",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center ">Variants</div>
      ),
      accessor: "name",

      Cell: ({ value }: { value: any }) => <div>{value}</div>,
    },
    {
      id: "options",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center ">Total options</div>
      ),
      accessor: "options",
      Cell: ({ row }: { row: any }) => <div>{row.original.options.length}</div>,
    },
    ...(hasEditPermission
      ? [
          {
            id: "action",
            width: "15%",
            Header: ({ column }: { column: any }) => (
              <div className="flex justify-start items-center ">Actions</div>
            ),
            accessor: "",
            Cell: ({ row }: any) => (
              <div className="flex gap-x-5 px-2 items-center">
                {hasEditPermission && (
                  <Link to={`/products/variant-templates/${row.original.id}`}>
                    <img src={EditIcon} alt="EditIcon" />
                  </Link>
                )}
                {hasDeletePermission && (
                  <button onClick={() => setDeleteModal(row.original.id)}>
                    <img src={DeleteIcon} alt="DeleteIcon" />
                  </button>
                )}
                {hasEditPermission && (
                  <div
                    onClick={() => {
                      setVariantData((prevData: any) =>
                        prevData.map((product: any) => {
                          if (product.id === row.original.id) {
                            return {
                              ...product,
                              is_active: !row.original.is_active,
                            };
                          }
                          return product;
                        })
                      );
                      handleToggleStatus(row.original.id, row.original);
                    }}
                    className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white h-8 w-12"
                  >
                    {row.original.is_active ? (
                      <img
                        src={ActiveStatusIcon}
                        alt="status-icon"
                        className="w-full object-contain h-full"
                      />
                    ) : (
                      <img
                        src={DeactiveStatusIcon}
                        alt="status-icon"
                        className="w-full object-contain h-full"
                      />
                    )}
                  </div>
                )}
              </div>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    const lastIndex = (page - 1) * pageLength;
    setStartIndex(lastIndex);
  }, [page, pageLength, totalCount]);

  const columns = useMemo(() => COLUMN, [variantData]);
  const data = useMemo(() => variantData || [], [variantData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
      useSticky,
    },
    useSortBy,
    usePagination
  );
  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };

  return (
    <div className="h-full">
      <div className="flex flex-row justify-between items-center py-2 w-full px-3 h-[3rem] ">
        <div className="flex gap-4 w-[max-content]">
          <div className=" w-[235px]">
            <InputField
              onChange={(e: string) => setInputVal(e)}
              placeholder="Enter Something"
              value={inputVal}
              rightIcon={<SearchIcon />}
            />
          </div>

          {hasCreatePermission && (
            <div className="w-fit">
              <Link to={"/products/variant-templates/create"}>
                <Button
                  label="Create new variant template"
                  leftIcon={<PlusIcon />}
                />
              </Link>
            </div>
          )}
        </div>

        {/* <div className=" flex flex-row justify-end items-center gap-2 w-[65%]">
          <div className="inline-flex  gap-2">
            <div
              className="flex items-center gap-1 text-xs"
              onClick={downloadReport}
            >
              <DownloadIcon />
              <p className=" underline text-pot-yellow">Download</p>
            </div>
          </div>
        </div> */}
      </div>

      <div className="h-[calc(100%-5.5rem)] relative -z-0">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={loading}
        />
      </div>

      {loading ? (
        ""
      ) : (
        <div className="w-full">
          <Pagination2
            currentPage={page}
            pageSize={pageLength}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}

      {deleteModal && (
        <Modal
          top
          header="Delete Variant"
          footer={
            <div className="flex gap-x-3">
              <Button
                variant="primary"
                label="Delete"
                onClick={() => handleDelete(deleteModal)}
              />
              <Button
                variant="secondary"
                label="Cancel"
                onClick={() => {
                  setDeleteModal("");
                }}
              />
            </div>
          }
          handleCancel={() => setDeleteModal("")}
        >
          <p className="text-left">
            Are you sure you want to delete the variant?
          </p>
        </Modal>
      )}
    </div>
  );
};

export default VariantTable;
