import React, { use, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OrderDetails from "./OrderDetails";
import ConsultantFiles from "./ConsultantFiles";
import UserFiles from "./UserFiles";
import Timeline from "./Timeline";
import DetailSection from "./DetailSection";
import RejectionReason from "./RejectionReason";
import OrderDetailsTwo from "./OrderDetailsTwo";
import AssignConsultant from "./AssignConsultant";
import {
  getLightingConsultantDetails,
  getPaymentLogsLightingConsultant,
} from "../../api/lightingConsultant";
import loadingSpinner from "../../assets/images/LoadingSpinner.gif";
import { checkPermissions } from "../../utils/helper";
import { toast } from "react-toastify";
import { protectedAxiosInstance } from "../../api/axiosManagement";

const ConsultantDetail = () => {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [consultantFiles, setConsultantFiles] = useState<any>([]);
  const [quotationFiles, setQuotationFiles] = useState<any>([]);
  const [paymentLogs, setPaymentLogs] = useState<any>([]);
  const navigate = useNavigate();

  //Permissions
  const hasViewPermission = checkPermissions("view_lc_order", true, toast);
  const hasEditPermission = checkPermissions("edit_lc_order", true, toast);


  const [activityLog, setActivityLog] =
  useState<any>([]);  

const fetchActivityLogDetails = () => {
  return protectedAxiosInstance
    .get(`/admin/lc/order/${id}/activity-log`)
    .then((res) => setActivityLog(res.data.data))
    .catch((err) => {
      console.error(err, "Error in getting activity log");
      throw err;
    });
};


useEffect(() => {
  fetchActivityLogDetails();
}, []);

  const fetchSingleOrderDetails = async () => {
    try {
      setLoading(true);
      const response = await getLightingConsultantDetails(id);

      setOrderDetails(response?.data?.order);
      setConsultantFiles(response?.data?.order?.consultant_files);
      setQuotationFiles(response?.data?.order?.quotation_files);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPaymentLogs = async () => {
    try {
      const response = await getPaymentLogsLightingConsultant(id);
      setPaymentLogs(response?.data?.payments);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!id) return;
    fetchSingleOrderDetails();
    fetchPaymentLogs();
  }, [id]);

  if (loading || !orderDetails) {
    return (
      <div className="min-h-[90vh] flex items-center justify-center">
        <img src={loadingSpinner} alt="loading" className="h-auto w-[10vh]" />
      </div>
    );
  }

  if (!hasViewPermission || !hasEditPermission) {
    navigate("/unauthorized");
  }

  return (
    <div className="flex flex-row">
      {/* First Column  */}
      <div className="w-[80%] border-r-2 px-5 py-6">
        {/* <OrderDetails /> */}
        <OrderDetailsTwo
          orderDetails={orderDetails}
          paymentLogs={paymentLogs}
          fetchPaymentLogs={fetchPaymentLogs}
          fetchSingleOrderDetails={fetchSingleOrderDetails}
          orderId={id}
        />
        <ConsultantFiles
        orderDetails={orderDetails}
          consultantFiles={consultantFiles}
          quotationFiles={quotationFiles}
          setConsultantFiles={setConsultantFiles}
          setQuotationFiles={setQuotationFiles}
          orderId={id}
          fetchSingleOrderDetails={fetchSingleOrderDetails}
        />
        <UserFiles orderDetails={orderDetails} />
        <Timeline activityLog={activityLog}/>
      </div>
      <div className="w-[20%]">
        <DetailSection orderDetails={orderDetails} />
        <AssignConsultant
          orderId={id}
          fetchSingleOrderDetails={fetchSingleOrderDetails}
          orderDetails={orderDetails}
        />
        <RejectionReason orderDetails={orderDetails} />
      </div>
    </div>
  );
};

export default ConsultantDetail;
