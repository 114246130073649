import React, { useState } from "react";
import tickIcon from "../../assets/svg/tick.svg";
import crossIcon from "../../assets/svg/cross.svg";
import { CustomDropdown } from "../ProfessionalDetail/DropDown";
import { formatDateTime } from "../../utils/helper";

export default function Timeline({ activityLog }: { activityLog: any }) {
  return (
    <>
      <CustomDropdown label="Activity Logs">
        <div className="px-4 py-3 h-[50vh] overflow-scroll no-scrollbar">
          {activityLog?.map((el: any, i: any) => {
            return (
              <div
                key={i}
                className="flex justify-start items-start gap-4 w-[55%] text-start"
              >
                <div>
                  <img src={tickIcon} alt="tick-icon" />
                  <div
                    className={`${
                      i !== activityLog.length - 1
                        ? `h-[50px] border-2 w-0 ml-2`
                        : ""
                    } `}
                  ></div>
                </div>

                <div>
                  <div className="flex space-x-4 text-[12px] leading-5 ">
                    <p className="font-medium ">{el.action_type}</p>
                    <p className="text-[#AEAEAE]">
                      {formatDateTime(el.action_at)}
                    </p>
                  </div>

                  <p className="text-[12px] leading-5 ">
                    {el.description}
                    {el.action_by && (
                      <span className="text-[#AEAEAE]"> by {el.action_by}</span>
                    )}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </CustomDropdown>
    </>
  );
}
