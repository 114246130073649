import { useState, useEffect } from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/common/Modal";
import InputField from "../../../components/InputField";
import TrashIcon from "../../../assets/TrashIcon";
import PlusIcon from "../../../assets/PlusIcon";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import { toast } from "react-toastify";

const EditFloorAndAreasModal = ({
  openFloorAreaModal,
  setOpenFloorAreaModal,
  singleProjectData,
  getSingleProjectData,
  quotationId,
}: {
  openFloorAreaModal: boolean;
  setOpenFloorAreaModal: (value: boolean) => void;
  singleProjectData: any;
  getSingleProjectData: any;
  quotationId: any;
}) => {
  const projectId = singleProjectData?.project?.id;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<{
    floors: Array<{
      id?: number;
      name: string;
      to_delete?: boolean;
      comingFormApi?: boolean;
      areas: Array<{
        id?: number;
        name: string;
        to_delete?: boolean;
        comingFormApi?: boolean;
      }>;
    }>;
  }>({
    floors: [],
  });

  // Initialize form with existing project floors when modal opens
  useEffect(() => {
    if (openFloorAreaModal && singleProjectData?.project?.floors) {
      setForm({
        floors: singleProjectData.project.floors.map((floor: any) => ({
          id: floor.id,
          name: floor.name,
          comingFormApi: true,
          to_delete: false,
          areas: floor.areas.map((area: any) => ({
            id: area.id,
            name: area.name,
            comingFormApi: true,
            to_delete: false,
          })),
        })),
      });
    }
  }, [openFloorAreaModal, singleProjectData]);

  const handleSubmit = async (e: any) => {
    if (form.floors.some((floor) => floor.name === "")) {
      toast.error("Please enter floor name");
      return;
    }
    if (
      form.floors.some((floor) => floor.areas.some((area) => area.name === ""))
    ) {
      toast.error("Please enter area name");
      return;
    }

    e?.preventDefault();
    setLoading(true);
    setErrors({});

    try {
      // Prepare the payload, removing any extra spaces
      const payload = {
        floors: form.floors.map((floor) => {
          // If floor is marked for deletion or has no name, include to_delete
          const floorPayload: any = {
            ...(floor.id && { id: floor.id }),
            ...(floor.to_delete && { to_delete: true }),
            name: floor.name.trim(),
            areas: floor.areas.map((area) => ({
              ...(area.id && { id: area.id }),
              ...(area.to_delete && { to_delete: true }),
              name: area.name.trim(),
            })),
          };
          return floorPayload;
        }),
      };

      const res = await protectedAxiosInstance.put(
        `/admin/projects/${projectId}/update-floor-area`,
        payload
      );

      if (res?.data) {
        toast.success("Floor and area details updated successfully");
        setOpenFloorAreaModal(false);
        getSingleProjectData(quotationId);
      }
    } catch (e: any) {
      if (e?.response?.data?.errors) {
        const backendErrors = e.response.data.errors;

        // Schema-level errors
        if (backendErrors._schema?.length) {
          const schemaErrors = backendErrors._schema;
          if (schemaErrors.length === 1) {
            toast.error(schemaErrors[0]);
          } else {
            toast.error(
              <ul className="list-disc pl-4">
                {schemaErrors.map((errorMessage: string, i: number) => (
                  <li key={i}>{errorMessage}</li>
                ))}
              </ul>
            );
          }
        }

        // Field-specific errors
        const fieldErrors: Record<string, string> = {};
        Object.keys(backendErrors).forEach((key) => {
          if (key !== "_schema" && Array.isArray(backendErrors[key])) {
            fieldErrors[key] = backendErrors[key][0];
          }
        });

        setErrors(fieldErrors);
      } else {
        toast.error("Failed to update floor and area details");
      }
    } finally {
      setLoading(false);
    }
  };

  const addArea = (floorIndex: number) => {
    const updatedFloors = [...form.floors];
    updatedFloors[floorIndex].areas.push({ name: "" });
    setForm({ ...form, floors: updatedFloors });
  };

  const deleteArea = (floorIndex: number, areaIndex: number) => {
    const updatedFloors = [...form.floors];
    const area = updatedFloors[floorIndex].areas[areaIndex];

    // If area came from API and has an ID, mark for deletion
    if (area.comingFormApi && area.id) {
      area.to_delete = true;
    } else {
      // If it's a new area without ID, remove it completely
      updatedFloors[floorIndex].areas.splice(areaIndex, 1);
    }

    setForm({ ...form, floors: updatedFloors });
  };

  const updateFloorName = (floorIndex: number, value: string) => {
    const updatedFloors = [...form.floors];
    updatedFloors[floorIndex].name = value;
    setForm({ ...form, floors: updatedFloors });
  };

  const updateAreaName = (
    floorIndex: number,
    areaIndex: number,
    value: string
  ) => {
    const updatedFloors = [...form.floors];
    updatedFloors[floorIndex].areas[areaIndex].name = value;
    setForm({ ...form, floors: updatedFloors });
  };

  const addNewFloor = () => {
    setForm({
      ...form,
      floors: [...form.floors, { name: "", areas: [] }],
    });
  };

  const deleteFloor = (floorIndex: number) => {
    const updatedFloors = [...form.floors];
    const floor = updatedFloors[floorIndex];

    // If floor came from API and has an ID, mark for deletion
    if (floor.comingFormApi && floor.id) {
      floor.to_delete = true;
    } else {
      // If it's a new floor without ID, remove it completely
      updatedFloors.splice(floorIndex, 1);
    }

    setForm({ ...form, floors: updatedFloors });
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        {openFloorAreaModal && (
          <Modal
            clickOutsideToClose
            allowScroll
            header="Edit floor and areas"
            footer={
              <Button
                variant="secondary"
                onClick={handleSubmit}
                label="Save changes"
                loading={loading}
                disabled={loading}
              />
            }
            handleCancel={() => setOpenFloorAreaModal(false)}
            minHeight="!w-[65vh]"
          >
            <div className="w-full flex flex-col gap-4">
              {form.floors.map((floor, floorIndex) => {
                if (floor.to_delete) {
                  return;
                }
                return (
                  <div key={floorIndex} className="relative">
                    <div className="flex items-center gap-2">
                      <InputField
                        label="Floor name"
                        placeholder="Floor name"
                        value={floor.name}
                        onChange={(value) => updateFloorName(floorIndex, value)}
                      />
                      {form.floors.length > 1 && (
                        <div
                          className="h-8 w-8 flex justify-center items-center cursor-pointer"
                          onClick={() => deleteFloor(floorIndex)}
                        >
                          <TrashIcon width="16" height="16" color="#4E4E4E" />
                        </div>
                      )}
                    </div>

                    <div className="flex flex-row justify-between items-center mt-2">
                      <p className="text-xs text-black font-medium">
                        Area name
                      </p>
                      <Button
                        variant="secondary-text"
                        label="Add Area"
                        leftIcon={<PlusIcon />}
                        onClick={() => addArea(floorIndex)}
                      />
                    </div>

                    {floor.areas.map((area, areaIndex) => {
                      if (area.to_delete) {
                        return null;
                      }
                      return (
                        <div key={areaIndex} className="flex gap-1">
                          <div className="w-full mb-2">
                            <InputField
                              placeholder="Area name"
                              value={area.name}
                              onChange={(value) =>
                                updateAreaName(floorIndex, areaIndex, value)
                              }
                            />
                          </div>

                          <div
                            className="h-8 w-8 flex md:gap-x-[1vw] justify-center items-center cursor-pointer"
                            onClick={() => deleteArea(floorIndex, areaIndex)}
                          >
                            <TrashIcon width="16" height="16" color="#4E4E4E" />
                          </div>
                        </div>
                      );
                    })}
                    <div className="h-[1.5px] w-full bg-gray-200 my-2"></div>
                  </div>
                );
              })}

              <div className="col-span-1 mb-2">
                <Button
                  variant="secondary"
                  label="Add new Floor"
                  leftIcon={<PlusIcon color="#fff" />}
                  onClick={addNewFloor}
                />
              </div>
            </div>
          </Modal>
        )}
      </form>
    </div>
  );
};

export default EditFloorAndAreasModal;
