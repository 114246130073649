import * as yup from "yup";

export const designConsultantDefaultValues = {
  customer: "",
  address_billing: "",
  customer_files: [],
  additional_information: "",
  assign_consultant: "",
  coupon_code: "",
};

export const createEditConsultantSchema = yup.object().shape({
  customer: yup.string().required("Customer is required"),
  address_billing: yup.mixed().required("Billing address is required"),
  customer_files: yup
    .array()
    .of(
      yup.mixed().test("is-file", "Invalid file format", (value) => {
        if (!value) return false;
        // Allow both string URLs and file objects
        return typeof value === "string" || typeof value === "object";
      })
    )
    .min(1, "At least one file is required")
    .required("Please upload files"),
  additional_information: yup.string().trim(),
  assign_consultant: yup.string().trim().required("Please select a consultant"),
  coupon_code: yup.string().trim(),
});

export type CreateEditConsultantSchema = yup.InferType<
  typeof createEditConsultantSchema
>;
