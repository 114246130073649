import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import LoadingSpinner from "../../assets/images/LoadingSpinner.gif";
import { protectedAxiosInstance } from "../../api/axiosManagement";
import UploadIcon from "../../assets/UploadIcon";

type FileType = {
  file: string;
  filename: string;
  kind?: string;
  added?: boolean;
  download_url?: string;
};

type FileUploaderProps = {
  kind: string;
  uploadedFiles: FileType[];
  setUploadedFiles: (files: FileType[]) => void;
  acceptTypes?: string[];
  maxSize?: number;
  maxFiles?: number;
  disabled?: boolean;
  buttonText?: string;
  submitKind?: string;
};

const FileUploader = ({
  kind,
  uploadedFiles,
  setUploadedFiles,
  acceptTypes = [".pdf", ".jpg", ".jpeg", ".png", ".cad", ".cdr"],
  maxSize = 20,
  maxFiles = Infinity,
  disabled = false,
  buttonText = "Upload Files",
  submitKind,
}: FileUploaderProps) => {
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<any>(null);

  const get_signed = async (extension: string) => {
    const payload = {
      extension,
      kind,
    };
    const response = await protectedAxiosInstance
      .post(`/admin/orders/generate-upload-url`, payload)
      .then((res: any) => res?.data?.data)
      .catch((res: any) => {
        return res?.data?.errors;
      });
    return response;
  };

  const signed_to_s3 = async (acceptedFile: File) => {
    const extension = acceptedFile.name.split(".").at(-1)?.toLowerCase();
    const { file_path, upload_url } = await get_signed(extension as string);

    let contentType: string;

    if (extension === "cdr") {
      contentType = "application/octet-stream";
    } else if (extension === "pdf") {
      contentType = "application/pdf";
    } else if (extension === "dwg" || extension === "cad") {
      contentType = "image/vnd.dwg";
    } else if (extension === "jpg" || extension === "jpeg") {
      contentType = "image/jpeg";
    } else if (extension === "png") {
      contentType = "image/png";
    } else {
      contentType = "";
    }

    const response = await fetch(upload_url, {
      method: "PUT",
      body: acceptedFile,
      headers: {
        "Content-Type": contentType,
      },
    });

    return { response, file_path, download_url: upload_url };
  };

  const onDrop = async (acceptedFiles: File[], rejectedFiles: any) => {
    // Filter files by extension manually
    const validFiles = acceptedFiles.filter((file) => {
      const extension = "." + file.name.split(".").pop()?.toLowerCase();
      return acceptTypes.includes(extension);
    });

    const invalidFiles = acceptedFiles.filter(
      (file) => !validFiles.includes(file)
    );

    if (invalidFiles.length > 0) {
      toast.error(
        `Some files were rejected. Only ${acceptTypes.join(", ")} are accepted.`
      );
      // Add rejected files to the rejectedFiles array
      rejectedFiles = [
        ...rejectedFiles,
        ...invalidFiles.map((file) => ({
          file,
          errors: [{ code: "file-invalid-type", message: "Invalid file type" }],
        })),
      ];
    }

    if (
      rejectedFiles.some(
        (el: any) =>
          el.errors &&
          el.errors.some((error: any) => error.code === "file-too-large")
      )
    ) {
      toast.error(`File size exceeded. Maximum file size is ${maxSize}MB.`);
    }
    if (
      rejectedFiles.some(
        (el: any) =>
          el.errors &&
          el.errors.some((error: any) => error.code === "file-invalid-type")
      )
    ) {
      toast.error(
        `Invalid file format selected. Only ${acceptTypes.join(
          ", "
        )} are accepted.`
      );
    }

    if (acceptedFiles.length === 0) return;

    // Check if adding these files would exceed the max files limit
    if (uploadedFiles.length + acceptedFiles.length > maxFiles) {
      toast.error(`You can only upload a maximum of ${maxFiles} files.`);
      return;
    }

    setUploading(true);

    try {
      const newFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          const { file_path, response, download_url } = await signed_to_s3(
            file
          );
          return {
            file: file_path,
            filename: file.name,
            kind: submitKind,
            added: true,
            download_url: download_url,
            // added: true,
          };
        })
      );

      setUploadedFiles([...uploadedFiles, ...newFiles]);
      toast.success("Files uploaded successfully");
    } catch (error) {
      toast.error("Error uploading files");
    } finally {
      setUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    }
  };

  const getAcceptObject = (types: string[]) => {
    return types.reduce((acc: { [key: string]: string[] }, type) => {
      acc[type] = [];
      return acc;
    }, {});
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: getAcceptObject(acceptTypes),
    onDrop,
    maxSize: maxSize * 1024 * 1024,
    disabled: disabled || uploading || uploadedFiles.length >= maxFiles,
  });

  const isUploadDisabled =
    disabled || uploading || uploadedFiles.length >= maxFiles;

  return (
    <div {...getRootProps()} className="w-fit">
      <input {...getInputProps()} ref={fileInputRef} />
      <button
        className={`w-fit flex gap-x-2 ${
          isUploadDisabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
        }`}
        type="button"
        disabled={isUploadDisabled}
      >
        {uploading ? (
          <img alt="" width={24} height={24} src={LoadingSpinner} />
        ) : (
          <p className="text-pot-yellow font-gilroy-medium font-normal">
            <UploadIcon />
          </p>
        )}
        <p className="text-pot-yellow text-sm whitespace-nowrap font-gilroy-medium font-normal">
          {buttonText}
        </p>
      </button>
    </div>
  );
};

export default FileUploader;
