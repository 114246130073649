import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import EditIcon from "../../../assets/EditIcon";
import { checkPermissions } from "../../../utils/helper";

const DetailsHeader = ({ data }: any) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { id: customerID } = useParams();
  const [editURL, setEditURL] = useState("");

  //Permissions
  const hasApprovePermission = checkPermissions("approve_professional");
  const hasEditPermission = checkPermissions("edit_customer");

  const detailsHeading = [
    {
      title: "Customer Profile",
      url: "/customers/",
    },
    {
      title: "Employee Profile",
      url: "/customers/professionals/employee/",
    },
    {
      title: "Company Profile",
      url: "/customers/professionals/company/",
    },
  ];

  const isOnlyCustomers =
    currentPath.includes("/customers/") &&
    !currentPath.includes("/professionals/");
  const isProfessionals = currentPath.includes("/customers/professionals/");

  useEffect(() => {
    const newEditURL = isOnlyCustomers
      ? `/customers/${customerID}/edit`
      : isProfessionals
      ? `/customers/professionals/${customerID}/edit`
      : "";

    setEditURL(newEditURL);
  }, [currentPath, customerID]);

  const isProfessional = data?.customer?.is_professional;

  const isCompany = isProfessional === true && data?.p_company !== null;
  const isEmployee = isProfessional === true && data?.p_individual !== null;

  return (
    <div className="flex justify-between items-center border-b w-full">
      <div className="flex flex-row justify-start items-center  min-h-[2.9rem] ">
        {detailsHeading?.map((item, index) => {
          // Check if the item is "Company Profile" and both isProfessional and isDataEmpty are true
          if (item.title === "Company Profile" && !isCompany) {
            return null;
          }
          if (item.title === "Employee Profile" && !isEmployee) {
            return null;
          }

          return (
            <Link key={index} to={`${item?.url}${customerID}`}>
              <p
                key={index}
                className={`h-[2.9rem] flex items-center w-auto text-sm px-5 text-center cursor-pointer font-gilroy-medium border-r ${
                  currentPath === item?.url + customerID
                    ? "bg-black text-white"
                    : ""
                }`}
              >
                {item?.title}
              </p>
            </Link>
          );
        })}
      </div>

      <div className="flex gap-4 pr-4">
        {editURL && hasEditPermission ? (
          <Link to={editURL}>
            <Button
              label="Edit"
              leftIcon={<EditIcon width="14" />}
              variant="secondary"
            />
          </Link>
        ) : (
          <Button
            label="Edit"
            leftIcon={<EditIcon width="14" />}
            variant="secondary"
            disabled={true}
            showNoPermissionTooltip={true}
          />
        )}

        {isOnlyCustomers &&
          (hasApprovePermission ? (
            <Link to={`/customers/professionals/${customerID}/edit`}>
              <Button
                label="Open professional form"
                leftIcon={<EditIcon width="14" />}
                variant="secondary"
              />
            </Link>
          ) : (
            <Button
              label="Open professional form"
              leftIcon={<EditIcon width="14" />}
              variant="secondary"
              disabled={true}
              showNoPermissionTooltip={true}
            />
          ))}
      </div>
    </div>
  );
};

export default DetailsHeader;
