import Modal from "../../../components/common/Modal";
import PaymentStatusTag from "../../../components/PaymentStatusTag";
import { formatIndianRupeesForDecimals } from "../../../utils/formatToIndianRupees";

const formatDate = (dateString: string) => {
  if (!dateString) return "-";
  return dateString.split("T")[0].split("-").reverse().join("-");
};

const PaymentDetails = ({ setPaymentModal, paymentLogs }: any) => {
  return (
    <Modal
      header="Payment History"
      clickOutsideToClose
      handleCancel={() => setPaymentModal(false)}
      widthClass="!max-w-[60vw]"
      minHeight="min-h-[45vh]"
    >
      <div className="my-3 w-full">
        {paymentLogs?.length > 0 ? (
          <div className="w-full overflow-y-auto custom-scrollbar h-[33vh]">
            <table className="w-full">
              <thead className="bg-[#ECECEC80] text-[#242424] border-[#ECECEC80] border-l-[0.3px] border-y-0 border-r-0 font-gilroy-semi-bold font-normal text-sm leading-4">
                <tr>
                  <th className="w-[11rem] py-3 px-4 text-left">Type</th>
                  <th className="w-[8rem] py-3 px-4 text-left">Date</th>
                  <th className="w-[7rem] py-3 px-2 text-right">Amount</th>
                  <th className="w-[11rem] py-3 px-4 text-left">
                    Mode of Payment
                  </th>
                  <th className="w-[11rem] py-3 px-4 text-left">Comment</th>
                  <th className="w-[5rem] py-3 px-4 text-left">Status</th>
                </tr>
              </thead>
              <tbody className="font-gilroy-medium font-normal text-[#242424] text-sm leading-4">
                {paymentLogs?.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border-[0.3px] border-t-0 border-[#ECECEC]">
                      {`${item.amount < 0 ? "Refund" : "Payment"}${
                        item?.is_reconciled ? " (Reconcile)" : ""
                      }`}
                    </td>
                    <td className="py-2 px-4 border-[0.3px] border-t-0 min-w-[150px] border-[#ECECEC]">
                      {formatDate(item?.pay_date)}
                    </td>
                    <td className="py-2 pr-2 text-right border-[0.3px] border-t-0 border-[#ECECEC]">
                      {formatIndianRupeesForDecimals(item?.amount)}
                    </td>
                    <td className="py-2 px-4 border-[0.3px] border-t-0 border-[#ECECEC]">
                      {item?.mode?.split("_").join(" ")}
                    </td>
                    <td
                      className="py-2 max-w-[250px] px-4 border-[0.3px] border-t-0 border-[#ECECEC] truncate"
                      title={item?.comment}
                    >
                      {item?.comment || "-"}
                    </td>
                    <td className="py-2 px-4 border-[0.3px] border-t-0 border-[#ECECEC]">
                      <PaymentStatusTag status={item?.status} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="flex justify-center items-center py-6">
            No Payment History Available
          </p>
        )}
      </div>
    </Modal>
  );
};

export default PaymentDetails;
