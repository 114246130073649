import React from "react";
import { Link, useNavigate } from "react-router-dom";
import FacebookIcon from "../../../../assets/svg-tsx/FacebookIcon";
import InstagramIcon from "../../../../assets/svg-tsx/InstagramIcon";
import LinkedInIcon from "../../../../assets/svg-tsx/LinkedInIcon";
import TwitterIcon from "../../../../assets/svg-tsx/TwitterIcon";
import WebIcon from "../../../../assets/svg-tsx/WebIcon";

const DetailSection = ({ data, loading }: any) => {
  // Function to render value or dash if value is null or undefined
  const renderValue = (value: any) => {
    return value ?? "-";
  };

  return (
    <div className="text-start px-4 pt-2 py-4 border-b">
      <div className="w-full flex justify-between">
        <h1 className="text-pot-yellow font-medium text-lg">
          Company Profile:{" "}
        </h1>
      </div>
      <div
        className={`flex flex-col ${
          loading ? "gap-3" : "space-y-1 "
        } text-start mt-4 text-[12px] leading-6`}
      >
        <div className="flex flex-col gap-2">
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">Logo:</p>
          )}
          <div className="h-14 w-14 rounded-full">
            {loading ? (
              <div className="w-full h-full object-cover rounded-full bg-skeleton-text"></div>
            ) : (
              <img
                src={`${process.env.REACT_APP_BUCKET}/${data?.p_company?.profile_picture}`} //eslint-disable-line
                alt="profile-picture"
                className="w-full h-full object-cover rounded-full"
              />
            )}
          </div>
        </div>

        <div>
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">Name of Company:</p>
          )}
          {loading ? (
            <div className="w-[60%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : (
            <p className="leading-[20px] text-[12px]">
              {renderValue(data?.p_company?.name)}
            </p>
          )}
        </div>

        <div className="w-full">
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">About:</p>
          )}
          {loading ? (
            <div className="w-[100%] h-32 bg-skeleton-text mt-1.5"></div>
          ) : (
            <p className=" break-words">
              {renderValue(data?.p_company?.about)}
            </p>
          )}
        </div>

        <div>
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">Category:</p>
          )}

          {loading ? (
            <div className="w-[65%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : (
            <p>{renderValue(data?.p_company?.category?.name)}</p>
          )}
        </div>

        <div>
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">Practice Areas:</p>
          )}

          {loading ? (
            // Render loading state for practice areas
            data?.p_company?.practice_areas.map(
              (area: string, index: number) => (
                <div
                  key={index}
                  className="w-[50%] h-5 bg-skeleton-text mt-1.5"
                ></div>
              )
            )
          ) : (
            // Render practice areas if not loading
            <ul className="list-disc pl-6">
              {data?.p_company?.practice_areas?.map(
                (area: string, index: number) => (
                  <li key={index}>{area}</li>
                )
              )}
            </ul>
          )}
        </div>

        <div>
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">Location:</p>
          )}

          {loading ? (
            <div className="w-[60%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : (
            <p>
              {renderValue(data?.p_company?.practice_location_city)},{" "}
              {renderValue(data?.p_company?.practice_location_state)}
            </p>
          )}
        </div>

        <div>
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">Years of establishment:</p>
          )}
          {loading ? (
            <div className="w-[60%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : (
            <p>{renderValue(data?.p_company?.establishment_year)}</p>
          )}
        </div>

        <div className="flex flex-col gap-1">
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">Social Links:</p>
          )}

          {loading
            ? ""
            : !(
                data?.p_company?.social_fb ||
                data?.p_company?.social_insta ||
                data?.p_company?.social_x ||
                data?.p_company?.social_linkedin ||
                data?.p_company?.social_website
              ) && <p>-</p>}

          <div className="flex flex-row gap-4">
            {loading ? (
              <div className="w-6 h-6 bg-skeleton-text mt-1"></div>
            ) : (
              data?.p_company?.social_fb && (
                <a
                  href={data?.p_company?.social_fb}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon color={"#D4A15E"} />
                </a>
              )
            )}
            {loading ? (
              <div className="w-6 h-6 bg-skeleton-text mt-1"></div>
            ) : (
              data?.p_company?.social_insta && (
                <a
                  href={data?.p_company?.social_insta}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon color={"#D4A15E"} />
                </a>
              )
            )}
            {loading ? (
              <div className="w-6 h-6 bg-skeleton-text mt-1"></div>
            ) : (
              data?.p_company?.social_x && (
                <a
                  href={data?.p_company?.social_x}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon color={"#D4A15E"} />
                </a>
              )
            )}
            {loading ? (
              <div className="w-6 h-6 bg-skeleton-text mt-1"></div>
            ) : (
              data?.p_company?.social_linkedin && (
                <a
                  href={data?.p_company?.social_linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon color={"#D4A15E"} />
                </a>
              )
            )}
            {loading ? (
              <div className="w-6 h-6 bg-skeleton-text mt-1"></div>
            ) : (
              data?.p_company?.social_website && (
                <a
                  href={data?.p_company?.social_website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WebIcon />
                </a>
              )
            )}
          </div>
        </div>

        <div className="font-gilroy-medium">
          <div className="flex justify-start items-center flex-row gap-4">
            {loading ? (
              <div className="w-24 h-4 bg-skeleton-text"></div>
            ) : (
              <p className="text-[#AEAEAE] leading-[20px] text-[12px]">
                Social Email ID:
              </p>
            )}
          </div>

          {loading ? (
            <div className="w-[90%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : data?.p_company?.social_email ? (
            <p className="text-pot-yellow leading-[20px] text-[12px] underline">
              <Link to={`mailto:${data?.p_company?.social_email}`}>
                {data?.p_company?.social_email}
              </Link>
            </p>
          ) : (
            "-"
          )}
        </div>

        <div className="font-gilroy-medium">
          <div className="flex justify-start items-center flex-row gap-4">
            {loading ? (
              <div className="w-24 h-4 bg-skeleton-text"></div>
            ) : (
              <p className="text-[#AEAEAE] leading-[20px] text-[12px]">
                Social Phone Number:
              </p>
            )}
          </div>
          {loading ? (
            <div className="w-[50%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : data?.p_company?.social_phone ? (
            <p className="leading-[20px] text-[12px]">
              {data?.p_company?.social_phone}
            </p>
          ) : (
            "-"
          )}
        </div>

        <div>
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">Profile Link:</p>
          )}

          {loading ? (
            <div className="w-[42%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : data?.p_company?.slug ? (
            <p
              className="leading-[20px] text-[12px] text-pot-yellow underline cursor-pointer"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_DOMAIN}/c/${data?.p_company?.slug}`,
                  "_blank"
                )
              }
            >
              Open public profile
            </p>
          ) : (
            <p>{"-"}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailSection;
