import React from "react";

const HistoryClockIcon = ({
  color,
  width,
  height,
}: {
  color?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width || "15"}
      height={height || "14"}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14560_46579)">
        <path
          d="M7.70781 4.83104H6.83997V8.73629H7.70781V4.83104Z"
          fill={color || "white"}
        />
        <path
          d="M8.57564 0.925781H5.97214V1.79362H8.57564V0.925781Z"
          fill={color || "white"}
        />
        <path
          d="M12.4809 3.9632L11.8647 3.35138L10.8884 4.32769C10.0892 3.40476 8.96518 2.82522 7.74982 2.70949C6.53446 2.59375 5.32123 2.95071 4.36221 3.70621C3.40319 4.46171 2.77212 5.55765 2.6001 6.76633C2.42809 7.97501 2.72837 9.2035 3.43855 10.1965C4.14873 11.1896 5.2142 11.8708 6.4136 12.0988C7.613 12.3267 8.85409 12.0837 9.87903 11.4204C10.904 10.7571 11.6339 9.72436 11.9173 8.53683C12.2006 7.3493 12.0155 6.09827 11.4004 5.04366L12.4809 3.9632ZM7.27389 11.3398C6.5015 11.3398 5.74646 11.1108 5.10424 10.6816C4.46203 10.2525 3.96148 9.64261 3.6659 8.92902C3.37032 8.21542 3.29299 7.43021 3.44367 6.67266C3.59436 5.91512 3.9663 5.21927 4.51246 4.67311C5.05862 4.12695 5.75447 3.75501 6.51201 3.60432C7.26956 3.45364 8.05477 3.53097 8.76836 3.82655C9.48196 4.12213 10.0919 4.62268 10.521 5.2649C10.9501 5.90711 11.1791 6.66215 11.1791 7.43454C11.1791 8.47028 10.7677 9.46359 10.0353 10.196C9.30294 10.9283 8.30963 11.3398 7.27389 11.3398Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14560_46579">
          <rect
            width="13.8854"
            height="13.8854"
            fill={color || "white"}
            transform="translate(0.332031 0.0585938)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HistoryClockIcon;
