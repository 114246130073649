import { useEffect, useMemo, useState } from "react";
import SelectField2 from "../../../components/SelectedField2";
import InputField from "../../../components/InputField";
import {
  validatePrice,
  validateTextNumber,
  validateTextNumberSpaceHyphen,
  validateTextSpaceDot,
} from "../../../utils/validations";
import { getCountryCallingCode } from "libphonenumber-js";
import { isSupportedCountry } from "libphonenumber-js";
import AuthInputField from "../../../components/AuthInputField";
import Button from "../../../components/Button";
import { stateAtom } from "../../../atom";
import { useRecoilState } from "recoil";
import ImageUpload from "../../../components/ImageUpload";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getSingleChannlePartner,
  postChannelPartner,
  updateChannelPartner,
} from "../../../api/channelPartner";
import ErrorMessage from "../../../components/ErrorMessage";
import UploadFile from "../../../components/UploadFile";
import AssignedLocations from "./Components/AssignedLocations";
import { createEditChannelPartnerSchema } from "../../../validators/CreateEditChannelPartnerSchema";
import { checkPermissions } from "../../../utils/helper";

const CreateEditChannelPartner = () => {
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      assigned_zipcodes: [],
      assigned_cities: [],
    },
    resolver: yupResolver(createEditChannelPartnerSchema),
  });

  const { id: partnerId } = useParams();
  const [stateData] = useRecoilState(stateAtom);
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState<any>();
  const [loading, setLoading] = useState(false);
  const logo = watch("logo");
  const addrState = watch("addr_state");
  const imageList = useMemo(() => (logo ? [logo] : []), [logo]);

  // Permissions

  // Permissions
  const hasEditPermission = partnerId
    ? checkPermissions("edit_channel_partner", true, toast)
    : false;
  const hasCreatePermission =
    !partnerId && checkPermissions("create_channel_partner", true, toast);
  const hasViewPermission = partnerId ? checkPermissions("view_channel_partner") : true;

  // Permission-based navigation
  useEffect(() => {
    // For create page
    if (!partnerId && !hasCreatePermission) {
      navigate("/channel-partners");
      return;
    }

    // For edit/view page
    if (partnerId) {
      // If trying to access edit URL without edit permission, redirect
      if (!hasEditPermission|| !hasViewPermission) {
        navigate("/channel-partners");
        return;
      }
    }
  }, [partnerId, hasCreatePermission, hasEditPermission, hasViewPermission]);

  useEffect(() => {
    if (partnerId) {
      const fetchPartnerData = async () => {
        try {
          setLoading(true);
          const response = await getSingleChannlePartner(partnerId);
          const { channel_partner } = response.data;

          // Destructure id and spread the rest of the properties
          const { id, ...partnerDataWithoutId } = channel_partner;

          // Reset form with all values except id
          reset({
            ...partnerDataWithoutId,
            assigned_cities: channel_partner.assigned_cities || [],
            assigned_zipcodes: channel_partner.assigned_zipcodes || [],
          });

          // Set country code for phone number
          if (channel_partner.phone) {
            const countryCode = channel_partner.phone.substring(0, 2);
            setCountryCode(countryCode);
          }
        } catch (error) {
          console.error("Error fetching partner data:", error);
          toast.error("Failed to fetch partner data");
        } finally {
          setLoading(false);
        }
      };

      fetchPartnerData();
    }
  }, [partnerId, reset]);

  const extractPath = (url: string) => {
    const urlWithoutQuery = url.split("?")[0]; // Remove query parameters
    const urlParts = urlWithoutQuery.split("/");
    return urlParts.slice(-2).join("/");
  };

  const onSubmit = async (data: any) => {
    const payload = {
      ...data,
      gst_doc: data.gst_doc ? extractPath(data.gst_doc) : null,
      pan_doc: data.pan_doc ? extractPath(data.pan_doc) : null,
      partner_pan_doc: data.partner_pan_doc
        ? extractPath(data.partner_pan_doc)
        : null,
      partner_aadhaar_doc: data.partner_aadhaar_doc
        ? extractPath(data.partner_aadhaar_doc)
        : null,
      quotation_front_cover: data.quotation_front_cover
        ? extractPath(data.quotation_front_cover)
        : null,
      quotation_back_cover: data.quotation_back_cover
        ? extractPath(data.quotation_back_cover)
        : null,
      bank_cancel_chq_doc: data.bank_cancel_chq_doc
        ? extractPath(data.bank_cancel_chq_doc)
        : null,
      assigned_zipcodes: data.assigned_zipcodes,
    };

    try {
      setLoading(true);
      if (partnerId) {
        await updateChannelPartner(partnerId, payload);
        toast.success("Channel partner updated successfully");
        navigate("/channel-partners");
      } else {
        await postChannelPartner(payload);
        toast.success("Channel partner created successfully");
        navigate("/channel-partners");
      }
    } catch (error: any) {
      // Handle field-specific validation errors
      if (error?.data?.errors) {
        const backendErrors = error?.data?.errors;
        Object.entries(backendErrors).forEach(([field, messages]) => {
          const message = Array.isArray(messages) ? messages[0] : messages;
          setError(field as any, {
            type: "manual",
            message: message as string,
          });
        });
      } else {
        // Handle general API errors
        const errorMessage =
          error?.response?.data?.message ||
          "Failed to process your request. Please try again.";
        toast.error(errorMessage);
      }
      console.error("Channel Partner Error:", error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className=" h-[calc(100vh-2.5rem)] no-scrollbar w-full flex flex-col justify-start items-start">
      <div className="z-50 top-0 bg-white border-b border-[#ECECEC] w-full pt-4 pb-4  px-4">
        <p className="flex font-gilroy-medium text-[14px] leading-[20p[x]]">
          Create Channel Partner
        </p>
      </div>
      <div className="flex w-full justify-between flex-1 overflow-y-auto custom-scrollbar">
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className="w-full flex"
        >
          {/* Left side */}
          <div className="w-[60%] no-scrollbar overflow-y-auto custom-scrollbar pr-[150px] mb-4">
            {/* Channel Partner Information */}
            <div className="mx-4 flex flex-col gap-4">
              <p className="text-base mt-4 uppercase font-gilroy-semi-bold text-[#D1A56A]">
                Channel Partner Information
              </p>
              <Controller
                name="name"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      required
                      label="Company Name"
                      placeholder="Enter company name"
                      value={field.value}
                      onChange={field.onChange}
                      hint={errors?.name?.message}
                      validate={validateTextNumberSpaceHyphen}
                    />
                  );
                }}
              />
              <div
                className={`w-[60%] flex items-start justify-items-start ${
                  logo ? "h-[120px]" : "h-[90px]"
                }`}
              >
                <Controller
                  name="logo"
                  control={control}
                  render={({ field }) => {
                    return (
                      <ImageUpload
                        label="Logo"
                        imageList={imageList}
                        setter={(value: any) => {
                          setValue("logo", value);
                          clearErrors("logo");
                        }}
                        kind="CP-LOGO"
                        isSelectTypeButton
                        required
                        disabled={loading}
                      />
                    );
                  }}
                />
              </div>
              <div className="-mt-3">
                <ErrorMessage error={errors?.logo?.message} />
              </div>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => {
                  return (
                    <AuthInputField
                      value={field.value}
                      onChange={field.onChange}
                      country_code_value={
                        isSupportedCountry(countryCode)
                          ? `+${getCountryCallingCode(countryCode)}`
                          : `+${getCountryCallingCode("IN")}`
                      }
                      setCode={(e: any) => {
                        setCountryCode(e);
                      }}
                      placeholder="XXXXXXXXXX"
                      heading="Phone number"
                      hint={errors?.phone?.message}
                      type="telephone"
                      maxLength={10}
                      validate={validatePrice}
                      required
                    />
                  );
                }}
              />
              <Controller
                name="gst_no"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      required
                      label="GST Number"
                      placeholder="Enter GST number"
                      value={field.value}
                      onChange={field.onChange}
                      hint={errors?.gst_no?.message}
                    />
                  );
                }}
              />
              <Controller
                name="gst_doc"
                control={control}
                render={({ field }) => {
                  return (
                    <UploadFile
                      label="GST Certificate Upload"
                      required
                      kind="CP-GSTC"
                      inputID="file-upload-GST"
                      value={field?.value}
                      setter={(value: any) => {
                        setValue("gst_doc", value);
                        clearErrors("gst_doc");
                      }}
                      error={errors?.gst_doc?.message}
                      disabled={loading}
                      setErrors={(message: any) =>
                        setError("gst_doc", { message })
                      }
                    />
                  );
                }}
              />
              <Controller
                name="pan_no"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      required
                      label="PAN number"
                      placeholder="Enter PAN number"
                      value={field.value?.toUpperCase()}
                      onChange={(e) => {
                        const value = e.slice(0, 10);
                        field.onChange(value);
                      }}
                      hint={errors?.pan_no?.message}
                      validate={validateTextNumber}
                    />
                  );
                }}
              />
              <Controller
                name="pan_doc"
                control={control}
                render={({ field }) => {
                  return (
                    <UploadFile
                      label="PAN card Upload"
                      required
                      kind="CP-GSTC"
                      inputID="file-upload-PAN"
                      value={field?.value}
                      setter={(value: any) => {
                        setValue("pan_doc", value);
                        clearErrors("pan_doc");
                      }}
                      error={errors?.pan_doc?.message}
                      disabled={loading}
                      setErrors={(message: any) =>
                        setError("pan_doc", { message })
                      }
                    />
                  );
                }}
              />
              <Controller
                name="emergency_contact_name"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      required
                      label="Emergency Contact Name"
                      placeholder="Enter emergency contact name"
                      value={field.value}
                      onChange={field.onChange}
                      hint={errors?.emergency_contact_name?.message}
                      validate={validateTextSpaceDot}
                    />
                  );
                }}
              />
              <Controller
                name="emergency_contact_phone"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      required
                      label="Emergency Contact Phone"
                      placeholder="Enter emergency contact phone"
                      value={field.value}
                      onChange={field.onChange}
                      hint={errors?.emergency_contact_phone?.message}
                      validate={validatePrice}
                    />
                  );
                }}
              />
            </div>

            {/* Partner Details */}
            <div className="mx-4 flex flex-col gap-4">
              <p className="text-base mt-10 uppercase font-gilroy-semi-bold text-[#D1A56A]">
                Authorized Person Details
              </p>

              <Controller
                name="partner_pan_no"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      required
                      label="Authorized Person PAN Number"
                      placeholder="Enter PAN number"
                      value={field.value?.toUpperCase()}
                      onChange={(e) => {
                        const value = e.slice(0, 10);
                        field.onChange(value);
                      }}
                      hint={errors?.partner_pan_no?.message}
                      validate={validateTextNumber}
                    />
                  );
                }}
              />
              <Controller
                name="partner_pan_doc"
                control={control}
                render={({ field }) => {
                  return (
                    <UploadFile
                      label="Authorized Person PAN Card Upload"
                      required
                      kind="CP-GSTC"
                      inputID="file-upload-Auth-PAN"
                      value={field?.value}
                      setter={(value: any) => {
                        setValue("partner_pan_doc", value);
                        clearErrors("partner_pan_doc");
                      }}
                      error={errors?.partner_pan_doc?.message}
                      disabled={loading}
                      setErrors={(message: any) =>
                        setError("partner_pan_doc", { message })
                      }
                    />
                  );
                }}
              />
              <Controller
                name="partner_aadhaar_no"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      required
                      label="Authorized Person Aadhaar Number"
                      placeholder="Enter partner Aadhaar number"
                      value={field.value}
                      onChange={(e) => {
                        const value = e.slice(0, 12);
                        field.onChange(value);
                      }}
                      hint={errors?.partner_aadhaar_no?.message}
                      validate={validatePrice}
                    />
                  );
                }}
              />
              <Controller
                name="partner_aadhaar_doc"
                control={control}
                render={({ field }) => {
                  return (
                    <UploadFile
                      label="Authorized Person Adhar Card Upload"
                      required
                      kind="CP-GSTC"
                      inputID="file-upload-Auth-Adhar"
                      value={field?.value}
                      setter={(value: any) => {
                        setValue("partner_aadhaar_doc", value);
                        clearErrors("partner_aadhaar_doc");
                      }}
                      error={errors?.partner_aadhaar_doc?.message}
                      disabled={loading}
                      setErrors={(message: any) =>
                        setError("partner_aadhaar_doc", { message })
                      }
                    />
                  );
                }}
              />
            </div>

            {/* Bank Details */}
            <div className="mx-4 flex flex-col gap-4">
              <p className="text-base mt-8 uppercase font-gilroy-semi-bold text-[#D1A56A]">
                Bank Details
              </p>
              <Controller
                name="bank_beneficiary"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      required
                      label="Bank Beneficiary"
                      placeholder="Enter bank beneficiary name"
                      value={field.value}
                      onChange={field.onChange}
                      hint={errors?.bank_beneficiary?.message}
                    />
                  );
                }}
              />

              <Controller
                name="bank_ifsc"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      required
                      label="Bank IFSC"
                      placeholder="Enter IFSC code"
                      value={field.value?.toUpperCase()}
                      onChange={field.onChange}
                      hint={errors?.bank_ifsc?.message}
                      validate={validateTextNumber}
                    />
                  );
                }}
              />

              <Controller
                name="bank_acc_no"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      required
                      label="Bank Account Number"
                      placeholder="Enter bank account number"
                      value={field.value}
                      onChange={field.onChange}
                      hint={errors?.bank_acc_no?.message}
                      validate={validatePrice}
                    />
                  );
                }}
              />
              <Controller
                name="bank_cancel_chq_doc"
                control={control}
                render={({ field }) => {
                  return (
                    <UploadFile
                      label="Bank Cancel Cheque"
                      required
                      kind="CP-CCHEQUE"
                      inputID="file-upload-cc"
                      value={field?.value}
                      setter={(value: any) => {
                        setValue("bank_cancel_chq_doc", value);
                        clearErrors("bank_cancel_chq_doc");
                      }}
                      error={errors?.bank_cancel_chq_doc?.message}
                      disabled={loading}
                      setErrors={(message: any) =>
                        setError("bank_cancel_chq_doc", { message })
                      }
                    />
                  );
                }}
              />
            </div>

            {/* Office Address */}
            <div className="mx-4 flex flex-col gap-4">
              <p className="text-base mt-8 uppercase font-gilroy-semi-bold text-[#D1A56A]">
                Office Address
              </p>
              <Controller
                name="addr_street1"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      required
                      label="Address Line 1"
                      placeholder="Enter address line 1"
                      value={field.value}
                      onChange={field.onChange}
                      hint={errors?.addr_street1?.message}
                    />
                  );
                }}
              />
              <Controller
                name="addr_street2"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      label="Address Line 2 (Optional)"
                      placeholder="Enter address line 2 "
                      value={field.value || ""}
                      onChange={field.onChange}
                    />
                  );
                }}
              />
              <div className="flex flex-row w-full gap-4">
                <Controller
                  name="addr_city"
                  control={control}
                  render={({ field }) => {
                    return (
                      <SelectField2
                        placeholder="Select City"
                        label="City"
                        options={(
                          stateData.find(
                            (el: any) => el.state_code === watch("addr_state")
                          ) || { cities: [] }
                        ).cities.map((el: any) => ({
                          value: el,
                          label: el,
                        }))}
                        value={field.value}
                        onChange={(e: string) => {
                          field.onChange(e);
                          setValue("addr_city", e);
                        }}
                        selectCSS="xl:w-[17.3rem] md:w-full"
                        hint={errors?.addr_city?.message}
                        disabled={!addrState}
                        required
                        searchPlaceHolder="Search City"
                      />
                    );
                  }}
                />
                <Controller
                  name="addr_state"
                  control={control}
                  render={({ field }) => {
                    return (
                      <SelectField2
                        label="State"
                        options={stateData.map((el: any) => ({
                          value: el.state_code,
                          label: el.name,
                        }))}
                        value={field.value}
                        onChange={(e: string) => {
                          field.onChange(e);
                          setValue("addr_state", e);
                          setValue("addr_city", "");
                        }}
                        placeholder="Select state"
                        autoComplete="off"
                        searchPlaceHolder="Search State"
                        selectCSS="xl:w-[17.3rem] md:w-full"
                        hint={errors?.addr_state?.message}
                        required
                      />
                    );
                  }}
                />
              </div>
              <Controller
                name="addr_zipcode"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      required
                      label="Pincode"
                      placeholder="Enter pincode"
                      value={field.value}
                      onChange={field.onChange}
                      hint={errors?.addr_zipcode?.message}
                      validate={validatePrice}
                      maxLength={6}
                    />
                  );
                }}
              />
              <Controller
                name="office_phone"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      label="Office Number"
                      placeholder="Enter Office Number"
                      value={field.value}
                      onChange={field.onChange}
                      hint={errors?.office_phone?.message}
                      validate={validatePrice}
                      required
                    />
                  );
                }}
              />
            </div>

            {/* Quotation */}
            <div className="mx-4 flex flex-col gap-4">
              <p className="text-base mt-8 uppercase font-gilroy-semi-bold text-[#D1A56A]">
                Quotation
              </p>
              <Controller
                name="quotation_front_cover"
                control={control}
                render={({ field }) => {
                  return (
                    <UploadFile
                      label="Upload quotation front cover"
                      required
                      kind="CP-PHOTO"
                      inputID="file-upload-QFC"
                      value={field?.value}
                      setter={(value: any) => {
                        setValue("quotation_front_cover", value);
                        clearErrors("quotation_front_cover");
                      }}
                      error={errors?.quotation_front_cover?.message}
                      disabled={loading}
                      setErrors={(message: any) =>
                        setError("quotation_front_cover", { message })
                      }
                    />
                  );
                }}
              />
              <Controller
                name="quotation_back_cover"
                control={control}
                render={({ field }) => {
                  return (
                    <UploadFile
                      label="Upload quotation back cover"
                      required
                      kind="CP-PHOTO"
                      inputID="file-upload-QBC"
                      value={field?.value}
                      setter={(value: any) => {
                        setValue("quotation_back_cover", value);
                        clearErrors("quotation_back_cover");
                      }}
                      error={errors?.quotation_back_cover?.message}
                      disabled={loading}
                      setErrors={(message: any) =>
                        setError("quotation_back_cover", { message })
                      }
                    />
                  );
                }}
              />
            </div>
          </div>

          {/* Right side */}
          {/* Assigned cities and addr_zipcode */}
          <div className="w-[550px] flex flex-row gap-4 mr-6">
            <div>
              <div className="w-[1px] h-full mb-4 bg-[#ECECEC]"></div>
            </div>
            <div className="w-full flex flex-col gap-4">
              <span className="font-gilroy-medium text-base leading-5 mt-4">
                Assigned Cities/Pincodes{" "}
              </span>
              <AssignedLocations
                watch={watch}
                setValue={setValue}
                clearErrors={clearErrors}
                errors={errors}
                control={control}
              />
            </div>
          </div>
        </form>
      </div>
      {/* footer */}
      <div className="bg-white bo flex gap-x-4 w-[100%] py-3 border-t  border-[#ECECEC] px-4">
        <Button
          label={partnerId ? "Update" : "Create"}
          variant="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
        />
        <Button
          label="Cancel "
          variant="secondary-outline"
          onClick={() => navigate("/channel-partners")}
        />
      </div>
    </div>
  );
};

export default CreateEditChannelPartner;
