import React, { useRef, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { motion } from "framer-motion";
import CancleIcon from "../../assets/svg/close.svg";

type ModalPropType = {
  handleCancel?: () => void;
  header?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  top?: boolean;
  allowScroll?: boolean;
  clickOutsideToClose?: boolean;
  bigText?: boolean;
  fullHeight?: boolean;
  minHeight?: string;
  maxHeight?: string;
  hideCondition?: boolean;
  widthClass?: string;
};

const Modal = ({
  handleCancel = () => {},
  header,
  children,
  footer,
  top = false,
  allowScroll,
  clickOutsideToClose = false,
  bigText = false,
  fullHeight = false,
  minHeight,
  maxHeight = "max-h-[80vh]",
  hideCondition = false,
  widthClass = "",
}: ModalPropType) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.getElementById("modal");
    setMounted(true);
  }, []);

  if (!(mounted && ref.current)) {
    return <></>;
  }

  return createPortal(
    <>
      <div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0 }}
          className="w-screen h-screen fixed top-0 left-0 z-[48]"
        ></motion.div>
        <div
          className="w-screen h-screen fixed top-0 left-0 z-[49] backdrop-blur-[1px] bg-gray-900/30"
          onClick={() => {
            clickOutsideToClose && handleCancel(); // Close the popup only when the props is clickOutsideToClose passed
          }}
        ></div>
      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className={`pointer-events-none ${hideCondition ? "hidden" : ""} ${
          top
            ? "flex justify-center py-20"
            : "grid px-2 py-2 place-items-center"
        } w-screen z-[50] h-screen fixed top-0 left-0`}
      >
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className={`select-none z-[50] pointer-events-auto w-[80vw] max-w-2xl h-fit ${minHeight} ${
            allowScroll && `${maxHeight} overflow-y-auto no-scrollbar`
          } flex flex-col  bg-white shadow-2xl py-3 ${
            fullHeight && "h-full"
          } ${widthClass}`}
        >
          {header && (
            <div className="flex justify-between sticky top-0 px-6 pb-3 bg-white z-50">
              <h3
                className={`${
                  bigText ? "text-3xl font-mangoGrotesque-black" : "text-lg"
                } font-gilroy-bold`}
              >
                {header}
              </h3>
              <div onClick={handleCancel} className="cursor-pointer p-2">
                <img src={CancleIcon} alt="CancelIcon" onClick={handleCancel} />
              </div>
            </div>
          )}
          <div
            className={`flex px-6  z-50 ${fullHeight && "h-full"} ${
              allowScroll && `${maxHeight} overflow-y-auto custom-scrollbar`
            }`}
          >
            {/* <div className='flex px-6 h-[41rem]'> */}
            {children}
          </div>
          {footer && (
            <div className="flex justify-start sticky bottom-0 px-6 py-3 bg-white z-40">
              {footer}
            </div>
          )}
        </div>
      </div>
    </>,
    ref.current!
  );
};

export default Modal;
