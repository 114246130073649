import { useState } from "react";
import EditIcon from "../../../assets/EditIcon";
import PlusIcon from "../../../assets/PlusIcon";
import AddProfessional from "./AddProfessional";
import RedirectingIcon from "../../../assets/svg-tsx/RedirectingIcon";

function TotalProfessionals({ data, orderDetails, setOrderDetails }: any) {
  const [openModal, setOpenModal] = useState(false);
  const [openModalEditProfessional, setOpenModalEditProfessional] =
    useState("");

  const handleAddProfessional = () => {
    setOpenModal(true);
  };
  const handleEditProfessional = (id: any) => {
    setOpenModalEditProfessional(id);
    setOpenModal(true);
  };

  const kind = orderDetails?.professional?.kind === "p_individual" ? "i" : "c";
  return (
    <div className="text-start px-3 pt-4 py-4  h-full">
      <div className="w-full flex justify-between items-center">
        <h1 className="font-gilroy-medium text-sm text-[#AEAEAE]">
          Professional Referred
        </h1>

        {orderDetails?.professional ? (
          <div
            onClick={() =>
              handleEditProfessional(orderDetails?.professional?.id)
            }
            className="cursor-pointer"
          >
            <EditIcon />
          </div>
        ) : (
          <div onClick={handleAddProfessional} className="cursor-pointer">
            <PlusIcon color="black" />
          </div>
        )}
      </div>
      <div className="text-start mt-3 text-[12px] leading-5">
        {orderDetails?.professional ? (
          <>
            <div className="flex flex-col gap-1 text-start text-[12px] leading-5">
              <a
                href={`${process.env.REACT_APP_DOMAIN}/${kind}/${orderDetails?.professional?.slug}`}
                target="_blank"
                rel="noreferrer"
                className="underline-offset-2 underline flex flex-row gap-1 font-gilroy-medium font-normal leading-5 text-sm"
              >
                <p className="font-gilroy-medium font-normal leading-5 text-sm">
                  {orderDetails?.professional?.name}
                </p>
                <span className="mt-[6px] ml-1">
                  <RedirectingIcon width="10" height="12" />
                </span>
              </a>
              <div className="flex flex-row text-[#1C1C1C] text-sm font-gilroy-regular font-normal">
                <p>{orderDetails?.professional?.profession?.name}</p>
                <ul>
                  <li className="list-disc ml-6 ">
                    {orderDetails?.professional?.practice_location_state && (
                      <span>
                        {orderDetails?.professional?.practice_location_state},{" "}
                      </span>
                    )}
                    <span>
                      {orderDetails?.professional?.practice_location_city ||
                        "-"}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          <p className="flex justify-center items-center mt-2 ">
            No Professional added
          </p>
        )}
      </div>
      {openModal && (
        <AddProfessional
          setOpenModal={setOpenModal}
          orderDetails={orderDetails}
          setOrderDetails={setOrderDetails}
          editProfessionalId={openModalEditProfessional}
        />
      )}
    </div>
  );
}

export default TotalProfessionals;
