import React from "react";

const ReadyForDeliverIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14378_27345)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.48 1.12379L13.98 4.13317C14.1373 4.21926 14.2686 4.34609 14.3601 4.50036C14.4516 4.65464 14.4999 4.83068 14.5 5.01004V8.87609C14.1992 8.63497 13.8628 8.43641 13.5 8.28954V5.62692L11.5 6.72129V8.02973C11.1507 8.07329 10.8153 8.16189 10.5 8.28954V7.26879L8.5 8.36317V10.0608C8.18142 10.6349 8 11.2957 8 11.9988C8 12.9949 8.36413 13.906 8.96655 14.6062L8.48 14.8725C8.33305 14.9537 8.16789 14.9963 8 14.9963C7.83211 14.9963 7.66695 14.9537 7.52 14.8725L2.02 11.8619C1.86293 11.776 1.73181 11.6494 1.64034 11.4955C1.54888 11.3416 1.50041 11.166 1.5 10.9869V5.00942C1.50041 4.83037 1.54888 4.65472 1.64034 4.50081C1.73181 4.34689 1.86293 4.22036 2.02 4.13442L7.52 1.12379C7.66695 1.04259 7.83211 1 8 1C8.16789 1 8.33305 1.04259 8.48 1.12379ZM13.0219 4.74879L8 1.99879L6.13875 3.01692L11.1606 5.76692L13.0219 4.74879ZM2.97813 4.74879L8 7.49879L10.1194 6.33817L5.0975 3.58817L2.97813 4.74879Z"
          fill="black"
        />
        <g clipPath="url(#clip1_14378_27345)">
          <path
            d="M14.2523 11.2759L11.7445 13.7837C11.6859 13.8423 11.6064 13.8752 11.5236 13.8752C11.4407 13.8752 11.3613 13.8423 11.3027 13.7837L9.90386 12.3775C9.84536 12.3189 9.8125 12.2395 9.8125 12.1567C9.8125 12.0739 9.84536 11.9945 9.90386 11.9359L10.2945 11.5453C10.3531 11.4869 10.4323 11.4542 10.515 11.4542C10.5976 11.4542 10.6769 11.4869 10.7355 11.5453L11.5314 12.3171L13.4212 10.4505C13.4798 10.3922 13.5591 10.3594 13.6419 10.3594C13.7246 10.3594 13.8039 10.3922 13.8625 10.4505L14.2521 10.8322C14.2814 10.8612 14.3047 10.8958 14.3206 10.9338C14.3364 10.9719 14.3446 11.0128 14.3446 11.054C14.3447 11.0953 14.3365 11.1361 14.3207 11.1742C14.3048 11.2123 14.2816 11.2469 14.2523 11.2759Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_14378_27345">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_14378_27345">
          <rect
            width="5"
            height="5"
            fill="white"
            transform="translate(9.5 9.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReadyForDeliverIcon;
