import React from "react";
import { CustomDropdown } from "../ProfessionalDetail/DropDown";

type RejectionReasonProps = {
  orderDetails: any;
};
const RejectionReason = ({ orderDetails }: RejectionReasonProps) => {
  const rejectionReason = orderDetails?.rejection_reasons?.reverse();
  return (
    <CustomDropdown
      sideType
      label="Rejection Reason"
      headerClassName="border-l-0"
    >
      {rejectionReason?.length > 0 &&
        rejectionReason?.map((reason: any, idx: number) => (
          <div key={idx} className="flex text-xs text-left gap-2 px-3 py-2">
            <p>{idx + 1}.</p>
            <p>{reason}</p>
          </div>
        ))}
      {!orderDetails?.rejection_reasons && (
        <div className="flex text-xs text-left gap-2 px-3 py-2">
          <p>No rejection reason found</p>
        </div>
      )}
    </CustomDropdown>
  );
};

export default RejectionReason;
