import React, { useEffect, useState } from "react";
import AuthInputField from "../../components/AuthInputField";
import Button from "../../components/Button";
import HipchatChevronRightIcon2 from "../../assets/HipchatChevronRightIcon2";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../components/InputField";
import * as yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email address is required"),
});

const StepOne = ({
  setCurrentStep,
  setOtpKey,
  email,
  token,
  setEmail,
}: any) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const BASE_URL = process.env.REACT_APP_API_DOMAIN;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (email) {
      setValue("email", email);
    }
  }, [email, setValue]);

  const onSubmit = async (data: any) => {
    const payload = {
      email: data?.email || null,
    };
    
    setEmail(data?.email);

    try {
      setLoading(true);
      const response = await axios.post(
        `${BASE_URL}/accounts/otp/send`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setOtpKey(response?.data?.data?.otp_key);
      toast.success("OTP sent successfully");
      setCurrentStep(2);
    } catch (error: any) {
      console.error("Reset Password Error:", error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full min-w-[450px]">
      <h1 className="text-5xl mx-auto w-max font-mangoGrotesque-black my-4 sm:my-10">
        Reset Password
      </h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
        noValidate
        className="w-full"
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <InputField
              label="Enter Email Address"
              placeholder="Email address"
              value={field.value}
              onChange={(e: string) => {
                field.onChange(e);
                setValue("email", e);
              }}
              hint={errors?.email?.message || ""}
              required
            />
          )}
        />

        <div className="w-full my-8">
          <Button
            variant="secondary"
            onClick={handleSubmit(onSubmit)}
            type="button"
            label="Submit"
            rightIcon={<HipchatChevronRightIcon2 />}
            fullWidth
            loading={loading}
            disabled={loading}
          />
        </div>
      </form>
    </div>
  );
};

export default StepOne;
