import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import BottomBar from "./QuotationDetailsComponent/BottomBar";
import AddProductModal from "./QuotationDetailsComponent/AddProductModal";
import DefaultQuotationTable from "./QuotationDetailsComponent/DefaultQuotationTable";
import LegendList from "./components/LegenList";
import Button from "../../components/Button";
import FileDownloadIcon from "../../assets/svg-tsx/FileDownloadIcon";
import ProjectQuotationTable from "./QuotationDetailsComponent/ProjectQuotationTable";
import EditFloorAreaModal from "./QuotationDetailsComponent/EditFloorAreaModal";
import {
  getSingleQuotationDetails,
  getSingleProjectDetails,
  getSingleQuotationDetailsItems,
} from "../../api/quotation";
import loadingSpinner from "../../assets/images/LoadingSpinner.gif";
import { checkPermissions, formatDate1 } from "../../utils/helper";
import AddDiscountModal from "./QuotationDetailsComponent/AddDiscountModal";
import { protectedAxiosInstance } from "../../api/axiosManagement";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DateIcon from "../../assets/svg-tsx/DateIcon";
import { formatDateTimeOnlyMonth } from "../../utils/formatDateTimeOnlyMonth";
import InputField from "../../components/InputField";
import { useRecoilValue } from "recoil";
import { userInfoAtom } from "../../atom";
import { validateNumber, validateTextSpaceDot } from "../../utils/validations";
import CreateQuotationOrder from "./QuotationDetailsComponent/CreateQuotationOrder";

const QuotationDetails = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openFloorAreaModal, setOpenFloorAreaModal] = useState(false);
  const [showProfessionalModal, setShowProfessionalModal] = useState(false);
  const [singleQuotationLoading, setSingleQuotationLoading] = useState(false);
  const [singleQuotationData, setSingleQuotationData] = useState<any>([]);
  const [singleProjectData, setSingleProjectData] = useState<any>([]);
  const [cost, setCost] = useState<any>(0);
  const [costLoading, setCostLoading] = useState(false);
  const [cartCheckoutData, setCartCheckoutData] = useState<any>([]);
  const [downloading, setDownLoading] = useState(false);
  const [discount, setDiscount] = useState("");
  const [name, setName] = useState("");
  const [updateDiscountLoading, setUpdateDiscountLoading] = useState(false);
  const [cartItemsLoading, setCartItemsLoading] = useState(false);

  // default cart states
  const [pageLoading, setPageLoading] = useState(false);
  const [cartSpecificData, setCartSpecificData] = useState<any>([]);
  const [showBanner, setShowBanner] = useState(false);
  const [checkout, enableCheckout] = useState(false);
  const [projectSelectionIndex, setProjectSelectionIndex] = useState(null);
  const isAuthenticated = true;
  const [bottomBarHeight, setBottomBarHeight] = useState(0); // State to track bottom bar height
  const [cartSpecificProjectData, setCartSpecificProjectData] = useState<any>(
    []
  );
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const { id: quotationId } = useParams();
  const [selectedProfessional, setSelectedProfessional] = useState<any>(null);

  // Permission
  const hasViewPermission = checkPermissions("view_quotation", true, toast);
  const hasEditPermission = checkPermissions("edit_quotation");

  const userInfo = useRecoilValue(userInfoAtom);

  //Customer info states
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");

  async function getSingleQuotation(id: any) {
    setSingleQuotationLoading(true);
    return getSingleQuotationDetails(id)
      .then((res: any) => {
        setSingleQuotationData(res?.data);
        setSelectedProfessional(res?.data?.professional);
        setDiscount(res?.data?.discount_percentage);
        setName(res?.data?.name);
        setCustomerName(res?.data?.customer_name || "");
        setCustomerPhone(res?.data?.customer_phone || "");
      })
      .catch((error) => {
        if (error.status === 404) {
          toast.error(error?.data?.errors);
          navigate("/quotations");
        }
        console.error("Error fetching quotation details: ", error);
      })
      .finally(() => {
        setSingleQuotationLoading(false);
      });
  }

  async function getSingleProjectData(id: any) {
    return getSingleProjectDetails(id)
      .then((res: any) => {
        setSingleProjectData(res?.data);
      })
      .catch((error: any) => {
        console.error("Error fetching project details: ", error);
      })
      .finally(() => {});
  }

  useEffect(() => {
    if (singleQuotationData?.kind === "PROJECT") {
      getSingleProjectData(quotationId);
    }
  }, [quotationId, singleQuotationData]);

  async function getSingleQuotationCartItems(id: any) {
    setCartItemsLoading(true);
    return getSingleQuotationDetailsItems(id)
      .then((res: any) => {
        if (!res?.data?.floors?.[0]?.id) {
          setCartSpecificData(res.data?.floors?.[0]?.areas?.[0]?.items);
          setCartCheckoutData({
            line_items: res?.data?.floors?.[0]?.areas?.[0]?.items?.map(
              (el: any) => el.id
            ),
          });
          calculateCost(res?.data?.floors?.[0]?.areas?.[0]?.items);
        }

        if (res?.data?.floors?.[0]?.id) {
          const allItemsFlat: any = [];
          const allItems = res?.data?.floors?.map((floor: any, index: any) => {
            const allFloors = floor?.areas?.map((area: any, index: any) => {
              area?.items?.map((item: any, index: any) => {
                allItemsFlat.push(item);
              });
            });
          });
          setCartCheckoutData({
            line_items: allItemsFlat.map((el: any) => el.id),
          });
          setCartSpecificProjectData(res?.data?.floors);
          calculateCost(allItemsFlat);
        }

        if (res?.data?.floors.length === 0) {
          setCartSpecificProjectData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching quotation items: ", error);
      })
      .finally(() => {
        setCartItemsLoading(false);
      });
  }

  useEffect(() => {
    if (quotationId) {
      getSingleQuotation(quotationId);
    }
  }, [quotationId]);

  useEffect(() => {
    if (singleQuotationData && quotationId) {
      getSingleQuotationCartItems(quotationId);
    }
  }, [quotationId, singleQuotationData]);

  function AddProductHandler() {
    const fullUrl = `${process.env.REACT_APP_DOMAIN}/shop/categories?num=${
      singleQuotationData?.num
    }&quotationId=${singleQuotationData?.id}&project=${
      singleQuotationData?.kind === "PROJECT"
    }&quotationName=${singleQuotationData?.name}`;
    // Ensure URL is defined and valid
    if (fullUrl) {
      window.location.href = fullUrl;
    }
  }

  // default cart functions
  function calculateCost(currentData: any = null) {
    setCostLoading(true);
    const payload: any = {};

    if (currentData) {
      payload.items = currentData.map((el: any) => (el.id ? el.id : el));
    } else if (
      cartCheckoutData?.line_items &&
      cartCheckoutData.line_items.length !== 0
    ) {
      payload.items = cartCheckoutData.line_items;
    } else {
      setCost(null);
      setCostLoading(false);
      return;
    }

    if (selectedProfessional) {
      payload.professional_id = selectedProfessional?.id;
      payload.professional_kind = selectedProfessional?.kind;
    }

    if (payload?.items?.length !== 0) {
      protectedAxiosInstance
        .post(`/admin/quotation/${quotationId}/calculate-cost`, payload)
        .then((res: any) => {
          setCost(res?.data?.data);
          setCostLoading(false);
        })
        .catch((err) => {
          setCost(null);
          setCostLoading(false);
        });
    } else {
      setCost(null);
      setCostLoading(false);
    }
  }

  useEffect(() => {
    calculateCost();
  }, [selectedProfessional]);

  function handleDeleteProfessional() {
    setSelectedProfessional(null);
    protectedAxiosInstance
      .put(`/admin/quotation/${quotationId}`, {
        professional_id: null,
        professional: null,
      })
      .then((res: any) => {
        getSingleQuotation(quotationId);
        setShowProfessionalModal(false);
      })
      .catch((error) => {
        toast.error(error?.data?.errors?.professional_id?.[0]);
        setShowProfessionalModal(false);
      });
  }

  function downloadQuotation(theme: string) {
    setDownLoading(true);
    const parameters: any = {
      fileType: "pdf",
      theme: theme,
    };

    protectedAxiosInstance
      .get(`/admin/quotation/${quotationId}/download`, {
        responseType: "blob",
        params: parameters,
      })
      .then((res: any) => {
        const href = URL.createObjectURL(res?.data);
        const link = document.createElement("a");
        link.href = href;
        link.target = "_blank";
        // const projectName = projectsList.find(
        //   (project: any) => project.owner_id === ownerId
        // )?.name;
        // const fileName =
        //   ownerId && owner !== "ANON"
        //     ? "Arcz-" + (projectName && " - " + projectName) + "Quotation"
        //     : "Arcz-Quotation";
        const fileName = "Quotation-" + singleQuotationData?.num + " Quotation";
        link.setAttribute("download", `${fileName}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        setDownLoading(false);
      })
      .catch((error: any) => {
        setDownLoading(false);
      });
  }

  function updateDiscount() {
    setUpdateDiscountLoading(true);
    protectedAxiosInstance
      .put(`/admin/quotation/${quotationId}`, {
        discount_percentage: discount ? discount : null,
        name: name || null,
        customer_name: customerName || null,
        customer_phone: customerPhone || null,
      })
      .then(() => {
        getSingleQuotation(quotationId);
        getSingleQuotationCartItems(quotationId);
      })
      .catch((error) => {
        toast.error("Failed to update quotation");
      })
      .finally(() => {
        setUpdateDiscountLoading(false);
      });
  }

  if (singleQuotationLoading) {
    return (
      <div className="h-[70vh] flex justify-center items-center">
        <div className="text-md font-medium text-gray-900 bg-white rounded-lg inline-flex items-center gap-3">
          <img src={loadingSpinner} alt="spinner" className="h-auto w-[10vh]" />
        </div>
      </div>
    );
  }
  // total areas
  const totalAreas = singleProjectData?.project?.floors?.reduce(
    (sum: any, floor: any) => sum + floor?.areas?.length,
    0
  );

  const disableButton =
    downloading ||
    ((cartSpecificData === undefined || cartSpecificData?.length === 0) &&
      (cartSpecificProjectData === undefined ||
        cartSpecificProjectData?.length === 0));

  if (!hasViewPermission) {
    navigate("/quotations");
  }

  return (
    <div className="h-[calc(100dvh-40px)] flex flex-col justify-between items-start relative ">
      <div className="h-full w-full">
        <div className="flex flex-col">
          {/* Quotation Number and Date */}
          <div className="w-full flex justify-between px-6 items-center border-b border-gray-200">
            <div className="flex gap-6 items-center py-3">
              <div className="flex items-center gap-2">
                <span className="font-gilroy-semi-bold text-sm">
                  Name of Quotation :
                </span>
                <span>{singleQuotationData?.name}</span>
              </div>
              <div className="flex justify-center items-center gap-3 text-[#AEAEAE] font-gilroy-semi-bold">
                <DateIcon />
                <span className="text-[13px]">
                  {formatDateTimeOnlyMonth(singleQuotationData?.created_at)}
                </span>
              </div>
            </div>
            <div>
              <CreateQuotationOrder
                kind={singleQuotationData?.kind}
                quotationId={quotationId}
                projectData={singleProjectData}
                disabled={disableButton}
              />
            </div>
          </div>

          {/* Project Details Section */}

          <div className="border-b border-gray-200 px-6 text-[#1c1c1c] grid grid-cols-2">
            {singleQuotationData?.kind === "PROJECT" && (
              <div className="border-r border-gray-200 py-2.5">
                <h2 className="text-black font-gilroy-bold text-xs leading-5">
                  Project Details
                </h2>

                <p className="text-[32px] font-mangoGrotesque-black mt-1 mb-2">
                  {singleProjectData?.project?.name}
                </p>

                <div className="flex gap-[155px] text-xs text-[#1C1C1C] leading-5">
                  <div className="flex flex-col gap-3">
                    <div className="flex items-center">
                      <p>Date created : </p>
                      <p className="font-gilroy-medium pl-2">
                        {formatDate1(singleProjectData?.project?.created_at) ||
                          "-"}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <p>Location : </p>
                      <p className="font-gilroy-medium pl-2">
                        {`${singleProjectData?.project?.address_city || ""}${
                          singleProjectData?.project?.address_city &&
                          singleProjectData?.project?.address_state
                            ? ", "
                            : ""
                        }${singleProjectData?.project?.address_state || "-"}`}
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col gap-3">
                    <div className="flex items-center">
                      <p>Total floors : </p>
                      <p className="font-gilroy-medium pl-2">
                        {singleProjectData?.project?.floors?.length || "-"}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <p>Total areas : </p>
                      <p className="font-gilroy-medium pl-2">
                        {totalAreas || "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              className={`py-2.5 px-5`}
              style={{
                padding: `10px ${
                  singleQuotationData?.kind === "REGULAR" && "0px"
                }`,
              }}
            >
              <div className="w-full flex flex-row gap-6">
                <div>
                  <p className="text-xs text-black leading-5 font-gilroy-semi-bold">
                    Other Options
                  </p>
                  <div className="w-[210px]">
                    <div className="flex gap-2.5 items-center mt-3">
                      <p className="text-xs text-[#1C1C1C] leading-5">Name:</p>
                      <InputField
                        placeholder="Enter quotation name"
                        value={name}
                        onChange={(value) => setName(value)}
                        disabled={!hasEditPermission}
                      />
                    </div>
                  </div>
                  <div className="flex gap-2.5 items-center mt-3">
                    <p className="text-xs text-[#1C1C1C] leading-5 w-[80px]">
                      Discount (%):
                    </p>
                    <div className="w-[121px]">
                      <InputField
                        placeholder="discount"
                        value={discount}
                        disabled={!hasEditPermission}
                        onChange={(e) => {
                          const validInput = /^(\d{0,3})(\.\d{0,2})?$/.test(e);

                          // Convert input to number and check if it is <= 100
                          const numericValue = parseFloat(e);
                          if (
                            (validInput && (!e || numericValue <= 100)) ||
                            e === ""
                          ) {
                            setDiscount(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <p className="text-xs text-black leading-5 font-gilroy-semi-bold">
                    Customer info
                  </p>
                  <div className="flex gap-2.5 items-center mt-3">
                    <p className="text-xs text-[#1C1C1C] leading-5 w-[153px]">
                      Customer Name:
                    </p>
                    <InputField
                      placeholder="Enter customer name"
                      value={customerName}
                      onChange={(value) => setCustomerName(value)}
                      disabled={!hasEditPermission}
                      inputContainerStyles={` ${
                        singleQuotationData?.kind === "REGULAR"
                          ? "!w-[185px]"
                          : "!w-[165px]"
                      }`}
                      validate={validateTextSpaceDot}
                    />
                  </div>

                  <div className="flex gap-2.5 items-center mt-3">
                    <div className="flex flex-row">
                      <p
                        className={`${
                          singleQuotationData?.kind === "REGULAR"
                            ? "w-[250px]"
                            : "w-[260px]"
                        } text-xs  text-[#1C1C1C] leading-5 mt-1`}
                      >
                        Customer Phone:
                      </p>
                      <InputField
                        placeholder="Enter customer phone"
                        value={customerPhone}
                        onChange={(value) => setCustomerPhone(value)}
                        disabled={!hasEditPermission}
                        validate={validateNumber}
                        maxLength={10}
                      />
                      <div className="ml-8">
                        <Button
                          variant="secondary"
                          label="Save"
                          onClick={updateDiscount}
                          disabled={updateDiscountLoading || !hasEditPermission}
                          showNoPermissionTooltip={!hasEditPermission}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex items-center justify-between flex-row gap-4 h-[52px] px-4">
          {/* <div className="text-black font-gilroy-medium text-base leading-5">
            Items
          </div> */}
          <LegendList />

          {userInfo?.channel_partner?.id ? (
            <Button
              variant="secondary"
              label="Download"
              leftIcon={<FileDownloadIcon />}
              onClick={() => {
                downloadQuotation("CHANNEL_PARTNER");
              }}
              disabled={disableButton}
              className="!h-8"
            />
          ) : (
            <div className="flex flex-row gap-4 items-center justify-center">
              <Button
                variant="secondary"
                label="Arcz"
                leftIcon={<FileDownloadIcon color="#FFFFFF" />}
                onClick={() => {
                  downloadQuotation("arcz");
                }}
                disabled={disableButton}
                className="!h-8"
              />
              <Button
                variant="secondary"
                leftIcon={<FileDownloadIcon color="#FFFFFF" />}
                label="Luminarist"
                onClick={() => {
                  downloadQuotation("luminarist");
                }}
                disabled={disableButton}
                className="!h-8"
              />
              <Button
                variant="secondary"
                leftIcon={<FileDownloadIcon color="#FFFFFF" />}
                label="Perfect Lights"
                onClick={() => {
                  downloadQuotation("pl");
                }}
                disabled={disableButton}
                className="!h-8"
              />
            </div>
          )}
        </div>

        <div className="overflow-x-auto">
          {singleQuotationData?.kind === "REGULAR" && quotationId && (
            <DefaultQuotationTable
              loading={
                singleQuotationLoading ||
                (cartItemsLoading && !cartSpecificData?.length)
              }
              data={cartSpecificData}
              calculateCost={calculateCost}
              showBanner={showBanner}
              enableCheckout={enableCheckout}
              projectSelectionIndex={projectSelectionIndex}
              setProjectSelectionIndex={setProjectSelectionIndex}
              cartSpecificData={cartSpecificData}
              setCartSpecificData={setCartSpecificData}
              isAuthenticated={isAuthenticated}
              bottomBarHeight={bottomBarHeight}
              quotationId={quotationId}
              getSingleQuotationCartItems={getSingleQuotationCartItems}
            />
          )}

          {singleQuotationData?.kind === "PROJECT" && quotationId && (
            <ProjectQuotationTable
              loading={
                singleQuotationLoading ||
                (cartItemsLoading && !cartSpecificProjectData?.length)
              }
              data={cartSpecificProjectData}
              cartSpecificProjectData={cartSpecificProjectData}
              setCartSpecificProjectData={setCartSpecificProjectData}
              calculateCost={calculateCost}
              selectedProject={selectedProject}
              showBanner={showBanner}
              enableCheckout={enableCheckout}
              projectSelectionIndex={projectSelectionIndex}
              setProjectSelectionIndex={setProjectSelectionIndex}
              bottomBarHeight={bottomBarHeight}
              quotationId={quotationId}
              getSingleQuotationCartItems={getSingleQuotationCartItems}
              projectId={singleProjectData?.project?.id}
            />
          )}
        </div>
      </div>

      <BottomBar
        cost={cost}
        costLoading={costLoading}
        openModal={openModal}
        setOpenModal={setOpenModal}
        openFloorAreaModal={openFloorAreaModal}
        setOpenFloorAreaModal={setOpenFloorAreaModal}
        showProfessionalModal={showProfessionalModal}
        setShowProfessionalModal={setShowProfessionalModal}
        AddProductHandler={AddProductHandler}
        singleQuotationData={singleQuotationData}
        professional={selectedProfessional}
        handleDeleteProfessional={handleDeleteProfessional}
      />
      <AddProductModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        quotationId={quotationId}
        singleProjectData={singleProjectData}
        singleQuotationData={singleQuotationData}
        getSingleQuotationCartItems={getSingleQuotationCartItems}
      />
      <EditFloorAreaModal
        openFloorAreaModal={openFloorAreaModal}
        setOpenFloorAreaModal={setOpenFloorAreaModal}
        singleProjectData={singleProjectData}
        getSingleProjectData={getSingleProjectData}
        quotationId={quotationId}
      />
      <AddDiscountModal
        setShowProfessionalModal={setShowProfessionalModal}
        showProfessionalModal={showProfessionalModal}
        quotationId={quotationId}
        getSingleQuotation={getSingleQuotation}
      />
    </div>
  );
};

export default QuotationDetails;
