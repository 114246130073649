import React, { useState } from "react";
import { motion } from "framer-motion";
import HipchatChevronDownIcon from "../../assets/HipchatChevronDownIcon";
import PlusIcon from "../../assets/PlusIcon";
import UploadIcon from "../../assets/UploadIcon";
interface CustomDropdownProps {
  children: React.ReactNode;
  label: string;
  createButton?: string;
  sideType?: boolean;
  upload?: boolean;
  headerClassName?: string;
  containerClassName?: string;
  date?: any;
  additionalButtons?: any;
  onClickCreateButton?: any;
}
export const CustomDropdown: React.FC<CustomDropdownProps> = ({
  children,
  label,
  createButton = "",
  sideType = false,
  upload = false,
  headerClassName,
  date,
  additionalButtons,
  containerClassName,
  onClickCreateButton,
}: CustomDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div>
      <div
        className={`w-full ${
          !sideType && "border-2 mt-4"
        } h-auto ${containerClassName}`}
      >
        <div
          className={`flex justify-between items-center ${
            sideType ? "border px-4" : "bg-[#FBFBFB] px-5"
          } h-[48px] py-2 ${headerClassName}`}
        >
          <div className="flex items-center gap-[33px]">
            <p className="font-medium text-[14px] leading-5">{label}</p>
            {date && <div>{date}</div>}
          </div>
          <div className="flex flex-1 flex-row items-end justify-end ">
            {additionalButtons && <>{additionalButtons}</>}
          </div>

          <motion.div
            className="w-fit flex gap-x-5 items-center justify-center"
            onClick={handleDropdownClick}
          >
            {createButton && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!createButton) {
                    handleDropdownClick();
                  }
                  if (onClickCreateButton) {
                    onClickCreateButton();
                  }
                }}
                className="w-fit flex gap-x-2 items-center"
              >
                <p
                  className={upload ? ` text-pot-yellow` : "stroke-pot-yellow"}
                >
                  {upload ? <UploadIcon /> : <PlusIcon />}
                </p>
                <p className="text-pot-yellow text-xs whitespace-nowrap">
                  {createButton}
                </p>
              </button>
            )}

            <motion.button animate={{ rotate: isDropdownOpen ? 180 : 0 }}>
              <HipchatChevronDownIcon color="black" width="22" />
            </motion.button>
          </motion.div>
        </div>

        {isDropdownOpen && (
          <motion.div
            layout
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{
              opacity: 0,
              y: -30,
              transition: { duration: 0.2 },
            }}
            transition={{ delay: 0.1, duration: 0.2 }}
          >
            {children}
          </motion.div>
        )}
      </div>
    </div>
  );
};
