import Modal from "../../../components/common/Modal";
import { formatIndianRupees, formatIndianRupeesForDecimals } from "../../../utils/formatToIndianRupees";
import { formatDateToDDMMYYYY } from "../../../utils/formatDateTime";

const ProductDetailsPopup = ({ setOpenProductDetailsPopup, data }: any) => {
  const renderDetailRow = (label: string, value: any) => (
    <div className="flex  py-2">
      <span className="text-[#6B6B6B] font-gilroy-medium leading-5 font-normal min-w-[220px]">
        {label}
      </span>
      <span className="text-[#1C1C1C] font-gilroy-semi-bold font-normal min-w-[50px]">
        -
      </span>
      <span className="text-[#1C1C1C] font-gilroy-semi-bold font-normal">
        {value || "-"}
      </span>
    </div>
  );

  const formatLabel = (key: string) => {
    return key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const renderProductDetails = (item: any) => {
    const excludedKeys = [
      "id",
      "product",
      "item_properties",
      "product_id",
      "floor_id",
      "area_id",
      "order_id",
      "selected_variants",
      "part_list",
      "created_at",
      "track_svg",
    ];

    const specialHandling = {
      sub_total: (value: any) =>
        formatIndianRupeesForDecimals(parseFloat(value)),
      unit_price: (value: any) =>
        formatIndianRupeesForDecimals(parseFloat(value)),
      grand_total: (value: any) =>
        formatIndianRupeesForDecimals(parseFloat(value)),
      tax_igst: (value: any) =>
        formatIndianRupeesForDecimals(parseFloat(value)),
      est_delivery_at: (value: any) => formatDateToDDMMYYYY(value) || "-",
      selected_variants: (variants: any[]) =>
        variants.map((v) => `${v.variant_name}: ${v.option_name}`).join(", "),
      part_list: (parts: any[]) => (Array.isArray(parts) ? parts.length : "0"),
    };

    return Object.entries(item)
      .filter(([key]) => !excludedKeys.includes(key))
      .map(([key, value]) => {
        if (specialHandling[key as keyof typeof specialHandling]) {
          return renderDetailRow(
            formatLabel(key),
            specialHandling[key as keyof typeof specialHandling](value as any)
          );
        }
        return renderDetailRow(formatLabel(key), value);
      });
  };

  const renderItemProperties = (properties: any) => {
    if (!properties) return null;

    const excludedProperties = ["id"];

    return Object.entries(properties)
      .filter(
        ([key, value]) => value !== null && !excludedProperties.includes(key)
      )
      .map(([key, value]) => {
        const label =
          key === "track_driver_quantity"
            ? "Track Driver Quantity"
            : key === "track_shape"
            ? "Track Shape"
            : key === "track_dimension"
            ? "Track Dimension"
            : formatLabel(key);

        // Format track shape value by replacing underscore with space
        const formattedValue =
          key === "track_shape" && typeof value === "string"
            ? value.replace("_", " ")
            : value;

        return renderDetailRow(label, formattedValue);
      });
  };

  const renderProductInfo = (product: any) => {
    if (!product) return null;

    const excludedProperties = ["id", "primary_image"];

    return Object.entries(product)
      .filter(
        ([key, value]) => value !== null && !excludedProperties.includes(key)
      )
      .map(([key, value]) => {
        // Special handling for brand object
        if (key === "brand" && value) {
          return renderDetailRow("Brand", (value as any).name);
        }
        // Add special handling for description with hover tooltip
        if (key === "description") {
          return renderDetailRow(
            formatLabel(key),
            <div className="group relative">
              <div className="line-clamp-3">{value as any}</div>
              <div className="invisible group-hover:visible absolute z-10 bg-white text-gray-400 p-2 rounded-md text-xs max-w-[400px] left-0 mt-1 shadow-2xl">
                {value as any}
              </div>
            </div>
          );
        }
        return renderDetailRow(formatLabel(key), value);
      });
  };

  const formattedPrice = (price: number) => {
    return formatIndianRupeesForDecimals(price);
  };

  return (
    <div>
      <Modal
        clickOutsideToClose
        header="Product Details"
        handleCancel={() => setOpenProductDetailsPopup(false)}
      >
        <div className="w-[95%] space-y-2">
          {data?.map((item: any, index: number) => (
            <div key={index}>
              <div className="flex gap-4 mb-4 flex-row justify-center items-center">
                <img
                  src={`${process.env.REACT_APP_BUCKET}/${item?.product?.primary_image}`}
                  alt="product"
                  className="w-[130px] h-[130px] object-cover"
                />
                {item?.track_svg && (
                  <div
                    className="w-auto h-[130px] overflow-hidden"
                    dangerouslySetInnerHTML={{
                      __html: item.track_svg.replace(
                        'width="979.2"',
                        'width="100%" viewBox="0 0 979.2 85"'
                      ),
                    }}
                  />
                )}
              </div>
              <div
                className={`${
                  item?.part_list?.length > 0 ? "h-[30dvh]" : "h-[50dvh]"
                } px-2 overflow-y-auto custom-scrollbar`}
              >
                {renderProductInfo(item?.product)}
                {renderItemProperties(item?.item_properties)}
                {renderProductDetails(item)}
              </div>

              {/* Add Parts List Table */}
              {item?.part_list?.length > 0 && (
                <div className="mt-4 overflow-x-auto custom-scrollbar">
                  <table className="w-full border border-c-gray-4 border-collapse text-[0.7rem] text-center table-auto">
                    <thead>
                      <tr className="bg-c-gray-1 border border-c-gray-4 [&>th]:px-2 [&>th]:py-1 font-gilroy-semi-bold [&>th]:border [&>th]:border-c-gray-4 [&>th]:whitespace-nowrap [&>th]:bg-c-gray-3">
                        <th>No</th>
                        <th>Code</th>
                        <th>Product Name</th>
                        <th>Image</th>
                        <th>Wattage</th>
                        <th>Control Category</th>
                        <th>Track Shape</th>
                        <th>Track Dimension</th>
                        <th>Light Color</th>
                        <th>Body Color</th>
                        <th>Reflector Color</th>
                        <th>Unit Price</th>
                        <th>Quantity</th>
                        <th>Total Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.part_list.map((part: any, partIndex: number) => (
                        <tr
                          key={partIndex}
                          className="border border-c-gray-4 [&>td]:border [&>td]:border-c-gray-4 [&>td]:whitespace-nowrap [&>td]:px-2 [&>td]:py-1"
                        >
                          <td>{partIndex + 1}</td>
                          <td>{part?.cart_properties?.code || "-"}</td>
                          <td>{part?.product?.name || "-"}</td>
                          <td>
                            <div className="h-8 w-8 mx-auto">
                              {part?.product?.primary_image ? (
                                <img
                                  src={`${process.env.REACT_APP_BUCKET}/${part.product.primary_image}`}
                                  alt=""
                                  className="h-full w-full object-contain"
                                />
                              ) : (
                                "-"
                              )}
                            </div>
                          </td>
                          <td>{part?.cart_properties?.wattage || "-"}</td>
                          <td>
                            {part?.cart_properties?.lighting_control_category ||
                              "-"}
                          </td>
                          <td>{part?.cart_properties?.track_shape || "-"}</td>
                          <td>
                            {part?.cart_properties?.track_dimension || "-"}
                          </td>
                          <td>{part?.cart_properties?.light_color || "-"}</td>
                          <td>{part?.cart_properties?.body_color || "-"}</td>
                          <td>
                            {part?.cart_properties?.reflector_color || "-"}
                          </td>
                          <td>{formatIndianRupees(part?.price_unit)}</td>
                          <td>{part?.quantity}</td>
                          <td>{formatIndianRupees(part?.price_total)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default ProductDetailsPopup;
