import { useEffect, useState } from "react";
import { protectedAxiosInstance } from "../../../../api/axiosManagement";
import { useRecoilValue } from "recoil";
import { stateAtom } from "../../../../atom";
import ScaleLoading from "../../../../components/ScaleLoading";
import Button from "../../../../components/Button";
import PlusIcon from "../../../../assets/PlusIcon";
import TrashIcon from "../../../../assets/TrashIcon";
import { toast } from "react-toastify";
import AddressForm from "../../../../components/AddressForm";
import OrderAddressDelete from "../../../OrderManagment/components/OrderAddressDelete";
import close from "../../../../assets/svg/close.svg";
import editPen from "../../../../assets/svg/pen.svg";

const AddressCardPopup = ({
  customerId,
  setShowAddressPopup,
  setSelectedAddress,
  selectedAddress,
}: any) => {
  const [address, setAddress] = useState([]);
  const [openInputAddress, setOpenInputAddress] = useState(false);
  const [openDeleteAddress, setOpenDeleteAddress] = useState(false);
  const [targetAddress, setTargetAddress] = useState(null); // Initialize with null
  const [loading, setLoading] = useState(true);

  const getAddressList = () => {
    protectedAxiosInstance
      .get(`/admin/accounts/customers/${customerId}/address`)
      .then((res) => {
        setAddress(res.data.data.address);
        setLoading(false); // Move setLoading(false) inside the .then block
      })
      .catch((error) => {
        console.error("Error fetching addresses:", error);
        setLoading(false); // Handle errors and set loading to false
      });
  };

  useEffect(() => {
    setLoading(true);

    if (customerId && customerId) {
      getAddressList();
    } else {
      setLoading(false);
    }
  }, [customerId]);

  const stateData = useRecoilValue(stateAtom);

  const handleSubmit = (el: any) => {
    setSelectedAddress(el);
    setShowAddressPopup(false);
  };

  const handleDeleteAddress = (id: any) => {
    protectedAxiosInstance
      .delete(`/admin/accounts/customers/${customerId}/address/${id}`)
      .then((res) => {
        toast.success("Successfully deleted address");
        getAddressList();
      });
  };

  return (
    <div
      className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center backdrop-blur-xs bg-black/20 z-50"
      onClick={() => {
        if (openInputAddress) {
          setOpenInputAddress(false);
          setTargetAddress(null);
        } else if (openDeleteAddress) {
          setOpenDeleteAddress(false);
          setTargetAddress(null);
        } else {
          setShowAddressPopup(false);
        }
      }}
    >
      {!openInputAddress && !openDeleteAddress && (
        <>
          {loading ? (
            <div
              className="flex flex-col justify-center items-center gap-4 relative min-w-[80vw] bg-white h-[80vh]"
              onClick={(e) => e.stopPropagation()}
            >
              <ScaleLoading />
            </div>
          ) : (
            <div
              className="flex flex-col justify-center items-center gap-4 relative min-w-[60vw] w-1/2 h-[80vh] "
              onClick={(e) => e.stopPropagation()}
            >
              <div className="bg-white  overflow-y-auto no-scrollbar overflow-x-hidden  w-full">
                <div className="sticky top-0 bg-white py-2 px-5 flex justify-between items-center border-b border-c-white-3 overflow-hidden">
                  <p className="text-2xl font-gilroy-bold">Address</p>
                  <button
                    className="p-4 bg-white transform scale-150"
                    onClick={() => setShowAddressPopup(false)}
                  >
                    <img src={close} alt="close" />
                  </button>
                </div>
                <div className="px-5 py-3">
                  <div className="flex items-center justify-start gap-5">
                    <p className="text-[18px] leading-[20px] font-gilroy-semi-bold">
                      Billing Addresses
                    </p>
                    <Button
                      variant="secondary"
                      leftIcon={<PlusIcon />}
                      label="Add new"
                      onClick={() => setOpenInputAddress(true)}
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3  gap-6 my-5">
                    {address.filter((el: any) => el?.kind === "BILLING")
                      .length === 0 && (
                      <p className="min-h-[25vh] min-w-[80vw]">
                        No address added
                      </p>
                    )}
                    {address
                      .filter((el: any) => el?.kind === "BILLING")
                      .map((el: any) => {
                        console.log(el, "address");
                        console.log(selectedAddress, "selectedAddress");

                        return (
                          <div
                            key={el?.id}
                            onClick={() => handleSubmit(el)}
                            className={`border ${
                              selectedAddress?.postal_code ===
                                el?.postal_code &&
                              selectedAddress?.full_name === el?.full_name &&
                              selectedAddress?.kind === el?.kind &&
                              selectedAddress?.phone === el?.phone &&
                              selectedAddress?.street_1 === el?.street_1 &&
                              selectedAddress?.street_2 === el?.street_2
                                ? "border-c-yellow-5"
                                : "border-c-white-3"
                            } p-4 cursor-pointer`}
                          >
                            <div className="flex justify-between mb-5">
                              <p className="bg-[#EDEDED] p-1 rounded-lg text-sm capitalize leading-[20px] font-gilroy-bold">
                                {el.tag.toLowerCase()}
                              </p>
                              <div
                                onClick={(e) => e.stopPropagation()}
                                className="flex gap-2"
                              >
                                <button
                                  onClick={() => {
                                    setTargetAddress(el);
                                    setOpenInputAddress(true);
                                  }}
                                >
                                  <img src={editPen} alt="edit-pen" />
                                </button>
                                <button
                                  onClick={() => {
                                    setOpenDeleteAddress(true);
                                    setTargetAddress(el);
                                  }}
                                >
                                  <TrashIcon />
                                </button>
                              </div>
                            </div>
                            <div>
                              <p className=" text-[15px] leading-[20px] font-gilroy-medium">
                                {el?.full_name}
                              </p>
                              <p className="text-[12px] leading-[20px] font-gilroy-medium">
                                {el?.street_1},
                              </p>
                              {el?.street_2 && (
                                <p className="text-[12px] leading-[20px] font-gilroy-medium">
                                  {el?.street_2 && `${el?.street_2}`},
                                </p>
                              )}

                              <p className="text-[12px] leading-[20px] font-gilroy-medium">
                                {el?.city} - {el?.postal_code},
                              </p>
                              <p className="text-[12px] leading-[20px] font-gilroy-medium">
                                {stateData &&
                                  stateData?.find(
                                    (state: any) =>
                                      state?.state_code == el?.state
                                  )?.name}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {openInputAddress && (
        <AddressForm
          addressData={targetAddress}
          setOpenInputAddress={setOpenInputAddress}
          setTargetAddress={setTargetAddress}
          customerId={customerId}
          getAddressList={getAddressList}
          showShipping={false}
        />
      )}
      {openDeleteAddress && (
        <OrderAddressDelete
          targetAddress={targetAddress}
          setOpenDeleteAddress={setOpenDeleteAddress}
          handleDeleteAddress={handleDeleteAddress}
          setTargetAddress={setTargetAddress}
        />
      )}
    </div>
  );
};

export default AddressCardPopup;
