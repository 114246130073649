import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import arczLogo from "../../assets/images/Arcz_Black.png";
import Button from "../../components/Button";
import LeftArrowIcon from "../../assets/svg-tsx/LeftArrowIcon";
import StepTwo from "./StepTwo";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import axios from "axios";

const ResetPassword = () => {
  const BASE_URL = process.env.REACT_APP_API_DOMAIN; //eslint-disable-line

  const utcDate = new Date();
  const istOffset = 5.5 * 60 * 60 * 1000;
  const istDate = new Date(utcDate.getTime() + istOffset);
  const currentYearInIST = istDate.getUTCFullYear();
  const navigate = useNavigate();
  const [buttonHovered, setButtonHovered] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [otpKey, setOtpKey] = useState("");

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const [token, setToken] = useState<any>("");

  async function getAnonymousToken() {
    const tokenResponse = await axios.get(`${BASE_URL}/accounts/anon-token`);
    const token = tokenResponse.data.data.token;
    setToken(token);
  }

  useEffect(() => {
    getAnonymousToken();
  }, []);

  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <div className="relative h-[90svh] w-full m-auto flex flex-col justify-center items-center">
        <div className="absolute top-0 w-[max-content] mx-auto flex flex-col">
          <img
            src={arczLogo}
            alt=""
            srcSet=""
            className="w-24 h-10 object-contain m-auto"
          />
        </div>
        <div className="w-[80%] sm:w-[400px] flex flex-col justify-center items-center">
          {currentStep === 1 && (
            <StepOne
              setCurrentStep={setCurrentStep}
              email={email}
              setEmail={setEmail}
              setOtpKey={setOtpKey}
              token={token}
            />
          )}
          {currentStep === 2 && (
            <StepTwo
              setCurrentStep={setCurrentStep}
              email={email}
              setEmail={setEmail}
              otpKey={otpKey}
              token={token}
              setOtpKey={setOtpKey}
            />
          )}
          {currentStep === 3 && <StepThree token={token} otpKey={otpKey} />}
          <div>
            <Button
              variant="secondary-text"
              label="Go Back to Login"
              className="!text-black font-gilroy-semi-bold text-base"
              leftIcon={
                <LeftArrowIcon  />
              }
              fullWidth
              loading={loading}
              onClick={() => navigate("/login")}
            />
          </div>
        </div>
        <div className="absolute bottom-0 text-center w-[94.2%] xl:w-full lg:w-full  flex flex-col justify-center items-center">
          <div className="flex justify-center items-center gap-1 mx-auto">
            <div className="text-[12px] font-gilroy-bold flex justify-center items-center gap-1">
              Copyright &copy; {currentYearInIST} ARCZ. All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
