import * as yup from "yup";

export const deliveryDetailsDefaultValues = {
  courier_name: "",
  awb_number: "",
  tracking_link: "",
};

export const deliveryDetailsFormSchema = yup.object().shape({
  courier_name: yup.string().trim().required("Courier name is required"),
  awb_number: yup.string().trim().required("AWB number is required"),
  tracking_link: yup
    .string()
    .trim()
    .matches(
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
      "Please enter a valid URL"
    )
    .required("Tracking URL is required"),
});

export type DeliveryDetailsFormSchema = yup.InferType<
  typeof deliveryDetailsFormSchema
>;
