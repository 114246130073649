import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import OrderDetails from "../CustomerDetailsComponent/sections/Order";
import ProjectDetails from "../CustomerDetailsComponent/sections/ProjectDetails";
import Address from "../CustomerDetailsComponent/sections/Address";
import Timeline from "../CustomerDetailsComponent/sections/Timeline";
import DetailsHeader from "../components/DetailsHeader";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import DetailSection from "../CustomerDetailsComponent/sections/Details/DetailSection";
import Notfound from "../../404/components/Notfound";
import { getFilteredOrderList, getOrderStats } from "../../../api/customers";
import { formatIndianRupeesForDecimals } from "../../../utils/formatToIndianRupees";

function CustomerDetails() {
  const navigate = useNavigate();
  const { id: customerID } = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [details, setDetails] = useState<any>();
  const [notFound, setNotFound] = useState(false);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageLength, setPageLength] = useState<number>(10);
  const [page, setPage] = useState<any>(1);
  const [loading, setLoading] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const [orderStatsData, setOrderStatsData] = useState<any>({});

  const { total_orders, total_spend, average_order_value } =
    orderStatsData || {};

  useEffect(() => {
    setLoading(true);

    getFilteredOrderList(customerID, { page: page, length: pageLength })
      .then((res) => {
        const orders = res.data.order;
        setOrderList(orders);
        setTotalCount(res.data.pagination.total_count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [page, pageLength]);

  useEffect(() => {
    setLoading(true);

    getOrderStats(customerID)
      .then((res) => {
        const ordersStats = res?.data;
        setOrderStatsData(ordersStats);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const fetchDetails = () => {
    return protectedAxiosInstance
      .get(`/admin/accounts/professional/${customerID}`)
      .then((res) => res.data.data)
      .catch((err) => {
        console.error(err, "Error in getting professional details");
        throw err;
      });
  };

  useEffect(() => {
    setPageLoading(true);

    Promise.all([fetchDetails()])
      .then(([details]) => {
        setDetails(details);
      })
      .catch((err) => {
        if (err.status === 404) {
          setNotFound(true);
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  }, []);

  if (notFound) {
    return <Notfound />;
  }

  return (
    <>
      {/* Navigation */}
      <DetailsHeader data={details} />
      <div className={`flex flex-row ${pageLoading ? "animate-pulse" : ""}`}>
        {/* First Column  */}
        <div className="w-[75%] border-r-2 px-5 py-6">
          <div className="flex flex-row justify-between items-start ">
            <div className="flex flex-row gap-4">
              <div className=" w-[150px] h-16 flex flex-col items-start pl-4 justify-center border-l-2 border-l-pot-yellow bg-[#F9FAFB]">
                <p className="font-medium text-[14px]">
                  {total_spend && formatIndianRupeesForDecimals(total_spend)}
                </p>
                <p className="text-[12px] text-[#AEAEAE]">Total spend</p>
              </div>
              <div className=" w-[150px] h-16 flex flex-col items-start pl-4 justify-center border-l-2 border-l-pot-yellow bg-[#F9FAFB]">
                <p className="font-medium text-[14px]">
                  {total_orders && total_orders}
                </p>
                <p className="text-[12px] text-[#AEAEAE]">Total orders</p>
              </div>
              <div className=" w-[150px] h-16 flex flex-col items-start pl-4 justify-center border-l-2 border-l-pot-yellow bg-[#F9FAFB]">
                <p className="font-medium text-[14px]">
                  {average_order_value &&
                    formatIndianRupeesForDecimals(average_order_value)}
                </p>
                <p className="text-[12px] text-[#AEAEAE]">
                  Average order value
                </p>
              </div>
            </div>
          </div>

          <OrderDetails
            orderList={orderList}
            loading={loading}
            totalCount={totalCount}
            pageLength={pageLength}
            page={page}
            setPage={setPage}
            setPageLength={setPageLength}

          />

          {/* Project Details */}
          <ProjectDetails />

          {/* Address */}
          <Address />

          {/* Timeline */}
          {/* <Timeline /> */}
        </div>

        {/* Second Column */}

        <DetailSection data={details} loading={pageLoading} />
      </div>
    </>
  );
}

export default CustomerDetails;
