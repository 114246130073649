import DesignConsultantTable from "./components/DesignConsultantTable";

function ConsultancyList() {
  return (
    <div>
      <DesignConsultantTable />
    </div>
  );
}

export default ConsultancyList;
