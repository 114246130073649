import React, { useState } from "react";
import { CustomDropdown } from "../ProfessionalDetail/DropDown";
import FileIcon from "../../assets/FileIcon";
import PdfIcon from "../../assets/PdfIcon";
import imag from "../../assets/images/image67.png";
import UploadedFileView from "./UploadedFileView";
import Button from "../../components/Button";
import UploadIcon from "../../assets/UploadIcon";
import FileUploader from "./FileUploader";
import { toast } from "react-toastify";
import { submitConsultantFiles } from "../../api/lightingConsultant";
import { checkPermissions } from "../../utils/helper";

type ConsultantFilesProps = {
  consultantFiles: any;
  quotationFiles: any;
  orderDetails: any;
  setConsultantFiles: (files: any) => void;
  setQuotationFiles: (files: any) => void;
  orderId: any;
  fetchSingleOrderDetails: () => void;
};

const extractFilePathFromUrl = (url: string): string => {
  // Check if the URL is a Google Cloud Storage URL with query parameters
  if (url.includes("storage.googleapis.com") && url.includes("?")) {
    // Extract the path between the domain and the query parameters
    const match = url.match(/storage\.googleapis\.com\/[^/]+\/([^?]+)/);
    if (match && match[1]) {
      return match[1]; // Returns "user-lc-order-docs/uuid.extension"
    }
  }

  // If it's already in the simple format or doesn't match the pattern, return as is
  return url;
};

const ConsultantFiles = ({
  consultantFiles,
  quotationFiles,
  setConsultantFiles,
  setQuotationFiles,
  orderId,
  fetchSingleOrderDetails,
  orderDetails,
}: ConsultantFilesProps) => {
  const [hideButtons, setHideButtons] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  //Permissions
  const hasEditLCPermission = checkPermissions("edit_lc_order");
  console.log("CONSULTANT FILES", consultantFiles);

  const handleRemoveFileConsultantFiles = (index: number) => {
    const newFiles = [...consultantFiles];
    newFiles.splice(index, 1);
    setConsultantFiles(newFiles);
  };

  const handleRemoveFileQuotationFiles = (index: number) => {
    const newFiles = [...quotationFiles];
    newFiles.splice(index, 1);
    setQuotationFiles(newFiles);
  };

  const handleSubmitFiles = async () => {
    if (consultantFiles.length === 0 && quotationFiles.length === 0) {
      toast.error("There are no files to submit");
    }

    let lc_files: any[] = [];

    if (consultantFiles.length > 0) {
      const consultantFilesData = consultantFiles.map((file: any) => ({
        file: extractFilePathFromUrl(file?.file),
        filename: file?.filename,
        kind: file?.kind || "C_FILES",
      }));
      lc_files = [...lc_files, ...consultantFilesData];
    }

    if (quotationFiles.length > 0) {
      const quotationFilesData = quotationFiles.map((file: any) => ({
        file: extractFilePathFromUrl(file?.file),
        filename: file?.filename,
        kind: file?.kind || "C_QUOTATION",
      }));
      lc_files = [...lc_files, ...quotationFilesData];
    }

    const payload = {
      lc_files,
    };

    try {
      setSubmitLoading(true);
      const response = await submitConsultantFiles(orderId, payload);
      toast.success("Files submitted successfully");
      fetchSingleOrderDetails();
    } catch (error: any) {
      console.log(error);
      if (error?.data?.errors?._schema?.length > 0) {
        toast.error(error?.data?.errors?._schema?.[0]);
        return;
      }
      toast.error("Error submitting files");
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <CustomDropdown
      label="Submission Files"
      additionalButtons={
        <div className="mr-4 flex flex-row gap-3">
          {hideButtons ? (
            <Button
              variant="secondary"
              label={"Edit"}
              className="h-8"
              onClick={() => setHideButtons(false)}
              disabled={!orderDetails?.allowed_actions?.can_upload_lc_files}
              showNoPermissionTooltip={
                !orderDetails?.allowed_actions?.can_upload_lc_files
              }
            />
          ) : (
            <>
              <Button
                variant="secondary-outline"
                label={"Cancel Edit"}
                className="h-8"
                onClick={() => {
                  setHideButtons(true);
                }}
              />
              <Button
                variant="secondary"
                label={"Submit"}
                className="h-8"
                loading={submitLoading}
                onClick={() => {
                  handleSubmitFiles();
                  setHideButtons(true);
                }}
              />
            </>
          )}
        </div>
      }
    >
      <div className="flex flex-1 w-full flex-row justify-between pt-3 pr-[8%] pl-4">
        <div>
          <p className="font-gilroy-semi-bold font-normal text-sm leading-5">
            Consultant Files
          </p>
        </div>
        {!hideButtons && (
          <FileUploader
            kind="LC_USER_FILES"
            uploadedFiles={consultantFiles}
            setUploadedFiles={setConsultantFiles}
            acceptTypes={[".pdf", ".jpg", ".jpeg", ".png", ".cad", ".cdr"]}
            maxSize={20}
            maxFiles={10}
            submitKind="C_FILES"
          />
        )}
      </div>
      <div className="px-4 pb-3 pt-2 max-h-[50vh] overflow-scroll no-scrollbar flex flex-wrap gap-x-5">
        <UploadedFileView
          files={consultantFiles}
          handleRemoveFile={handleRemoveFileConsultantFiles}
          showDelete={orderDetails?.allowed_actions?.can_upload_lc_files}
        />
      </div>

      <div className=" ml-5 mr-16 mt-3 mb-3 bg-[#DEDEDE] h-[1px]"></div>

      {/* Quotation Files */}
      <div className="flex flex-1 w-full flex-row justify-between pt-3 pr-[8%] pl-4">
        <div>
          <p className="font-gilroy-semi-bold font-normal text-sm leading-5">
            Quotation Files
          </p>
        </div>
        {!hideButtons && (
          <FileUploader
            kind="LC_USER_FILES"
            uploadedFiles={quotationFiles}
            setUploadedFiles={setQuotationFiles}
            acceptTypes={[".pdf", ".jpg", ".jpeg", ".png", ".cad", ".cdr"]}
            maxSize={20}
            maxFiles={10}
            submitKind="C_QUOTATION"
          />
        )}
      </div>
      <div className="px-4 pb-3 pt-2 max-h-[50vh] overflow-scroll no-scrollbar flex flex-wrap gap-x-5">
        <UploadedFileView
          files={quotationFiles}
          handleRemoveFile={handleRemoveFileQuotationFiles}
          showDelete={orderDetails?.allowed_actions?.can_upload_lc_files}
        />
      </div>
    </CustomDropdown>
  );
};

export default ConsultantFiles;
