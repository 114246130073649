import React from "react";

const TickIcon2 = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14378_27340)">
        <path
          d="M15.2074 5.68298L7.18235 13.708C6.99484 13.8954 6.74058 14.0006 6.47548 14.0006C6.21038 14.0006 5.95612 13.8954 5.7686 13.708L1.29235 9.20798C1.10515 9.02049 1 8.76637 1 8.50142C1 8.23647 1.10515 7.98235 1.29235 7.79486L2.54235 6.54485C2.72977 6.35822 2.98348 6.25344 3.24798 6.25344C3.51247 6.25344 3.76619 6.35822 3.9536 6.54485L6.50048 9.01486L12.548 3.04173C12.7354 2.8549 12.9893 2.75 13.2539 2.75C13.5186 2.75 13.7724 2.8549 13.9599 3.04173L15.2067 4.26298C15.3005 4.3559 15.3749 4.46647 15.4258 4.5883C15.4766 4.71014 15.5028 4.84084 15.5028 4.97285C15.5029 5.10486 15.4768 5.23558 15.4261 5.35746C15.3754 5.47934 15.301 5.58997 15.2074 5.68298Z"
          fill="#B1B1B1"
        />
      </g>
      <defs>
        <clipPath id="clip0_14378_27340">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TickIcon2;
