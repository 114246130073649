import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import ManagementTableLayout from "../../../components/MangementTable";
import InputField from "../../../components/InputField";
import SearchIcon from "../../../assets/SearchIcon";
import Button from "../../../components/Button";
import PlusIcon from "../../../assets/PlusIcon";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import {
  getLightingConsultant,
  getListForLightingConsultant,
} from "../../../api/lightingConsultant";
import SelectField2 from "../../../components/SelectedField2";
import { ordeAppConfigAtom } from "../../../atom";
import { useRecoilValue } from "recoil";
import FilterIcon from "../../../assets/svg-tsx/FilterIcon";
import PaymentStatusTag from "../../../components/PaymentStatusTag";
import OrderStatusTag from "../../../components/OrderStatusTag";
import { formatIndianRupeesForDecimals } from "../../../utils/formatToIndianRupees";
import _debounce from "lodash/debounce";
import AssignConsultantPopup from "../../ConsultantDetail/AssignConsultantPopup";
import { checkPermissions } from "../../../utils/helper";
import { toast } from "react-toastify";
import useDebounce from "../../../hooks/useDebounce";

function DesignConsultantTable() {
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState<number>(100);
  const [pageLength, setPageLength] = useState<number>(25);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initial, setInitial] = useState(true);
  const [consultantData, setConsultantData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const [openChangeConsultant, setOpenChangeConsultant] = useState(false);

  const orderAppConfig = useRecoilValue(ordeAppConfigAtom);

  const ORDER_STATUS = orderAppConfig?.lc_order_status?.map((status: any) => ({
    label: status,
    value: status,
  }));
  const PAYMENT_STATUS = orderAppConfig?.lc_payment_status?.map(
    (status: any) => ({
      label: status,
      value: status,
    })
  );

  // Filter states
  const [filteredOrderStatus, setFilteredOrderStatus] = useState("");
  const [filteredPaymentStatus, setFilteredPaymentStatus] = useState("");

  // Consultant dropdown states
  const [consultantLoading, setConsultantLoading] = useState(true);
  const [consultants, setConsultants] = useState<any[]>([]);
  const [selectedConsultants, setSelectedConsultants] = useState<any>();

  const [consultantSearchText, setConsultantSearchText] = useState("");
  const [consultantPage, setConsultantPage] = useState(1);
  const [consultantTotalPage, setConsultantTotalPage] = useState(0);
  const [orderId, setOrderId] = useState<any>();

  //Permissions
  const hasViewPermission = checkPermissions("view_lc_order", true, toast);

  const hasReassignConsultantPermission = checkPermissions(
    "reassign_lc_consultant"
  );

  const getConsultantData = async () => {
    setLoading(true);
    const params = {
      page: page,
      length: pageLength,
      search: debouncedSearchValue,
      status: filteredOrderStatus,
      payment_status: filteredPaymentStatus,
      consultant_id: selectedConsultants,
    };
    getListForLightingConsultant(params)
      .then((res) => {
        setConsultantData(res?.data?.orders);
        setTotalCount(res?.data.pagination.total_count);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getConsultantData();
  }, [
    totalCount,
    setTotalCount,
    pageLength,
    setPageLength,
    page,
    setPage,
    debouncedSearchValue,
    filteredOrderStatus,
    filteredPaymentStatus,
    selectedConsultants,
  ]);

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };

  const handleOpenChangeConsultant = (orderId: any) => {
    setOpenChangeConsultant(true);
    setOrderId(orderId);
  };

  const COLUMN = [
    {
      id: "order_no",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Order ID</div>
      ),
      accessor: "order_no",
      Cell: ({ value, row }: { value: any; row: any }) => (
        <p className="p-2">
          <Link to={`/consultancy-list/${row.original.id}`}>#{value}</Link>
        </p>
      ),
      width: "70px",
    },
    {
      id: "created_at",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2">
          <p>Date</p>
        </div>
      ),
      accessor: "created_at",
      Cell: ({ value }: { value: any }) => (
        <p>{new Date(value).toLocaleDateString()}</p>
      ),
      width: "90px",
    },
    {
      id: "customer_name",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Name</div>
      ),
      accessor: (row: any) => row.ordered_for.name,
      Cell: ({ row }: { row: any }) => (
        <Link to={`/consultancy-list/${row.original.id}`}>
          <p>{row.original.ordered_for.name}</p>
        </Link>
      ),
      width: "100px",
    },
    {
      id: "phone",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Phone Number</div>
      ),
      accessor: (row: any) => row.ordered_for.phone,
      Cell: ({ value }: { value: any }) => (
        <div className="font-semibold">{value}</div>
      ),
      width: "100px",
    },
    {
      id: "location",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Location</div>
      ),
      accessor: (row: any) => row.billing_city,
      Cell: ({ value, row }: { value: any; row: any }) => (
        <div className="">
          {row.original.billing_city || row.original.billing_state ? (
            <p>
              {row.original.billing_city}, {row.original.billing_state}
            </p>
          ) : (
            "-"
          )}
        </div>
      ),
      width: "100px",
    },
    {
      id: "end_date",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2">
          <p>Close Date</p>
        </div>
      ),
      accessor: "end_date",
      Cell: ({ value }: { value: any }) => (
        <p>{value ? new Date(value).toLocaleDateString() : "-"}</p>
      ),
    },
    {
      id: "amount",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2">Amount</div>
      ),
      accessor: "grand_total",
      Cell: ({ value }: { value: any }) => (
        <p>{value ? formatIndianRupeesForDecimals(value) : "-"}</p>
      ),
      width: "90px",
    },
    {
      id: "consultant",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 px-2">
          Consultant
        </div>
      ),
      accessor: (row: any) => row.consultant?.lc_name,
      Cell: ({ value, row }: { value: any; row: any }) => (
        <div className="relative">
          {value ? (
            <p>{value}</p>
          ) : (
            <>
              <Button
                className="h-6 font-thin"
                label="Assign"
                variant="secondary"
                onClick={() => handleOpenChangeConsultant(row.original.id)}
                disabled={!hasReassignConsultantPermission}
                showNoPermissionTooltip={!hasReassignConsultantPermission}
              />
            </>
          )}
        </div>
      ),
    },
    {
      id: "status",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 px-2">
          Status
        </div>
      ),
      accessor: "status",
      Cell: ({ value }: { value: any }) => (
        <p className="px-1.5 py-0.5 text-black font-medium">
          {value ? <OrderStatusTag status={value} /> : "-"}
        </p>
      ),
    },
    {
      id: "payment_status",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 px-2">
          Payment
        </div>
      ),
      accessor: "payment_status",
      Cell: ({ value }: { value: any }) => (
        <p className="px-1.5 py-0.5 text-black font-medium">
          <PaymentStatusTag status={value} />
        </p>
      ),
      width: "100px",
    },
  ];
  const columns = useMemo(() => COLUMN, [consultantData]);
  const data = useMemo(() => consultantData || [], [consultantData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page - 1, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (initial) {
      setInitial(false);
    }
  }, [initial]);

  // Update the fetchConsultants function
  const fetchConsultants = async () => {
    try {
      setConsultantLoading(true);
      const response = await getLightingConsultant({
        length: 50,
        page: consultantPage,
        search: consultantSearchText,
      });

      const consultantList = response.data.lighting_consultants.map(
        (user: any) => ({
          label: user.lc_name,
          value: user.id,
        })
      );

      setConsultants((prevConsultants) =>
        consultantPage === 1
          ? consultantList
          : [...prevConsultants, ...consultantList]
      );
      setConsultantTotalPage(response.data.pagination.total_pages);
    } catch (error) {
      console.error("Error fetching consultants:", error);
    } finally {
      setConsultantLoading(false);
    }
  };

  const debouncedFetchConsultants = _debounce(fetchConsultants, 500);

  // Add useEffect for consultant search
  useEffect(() => {
    setConsultantPage(1);
    setConsultants([]);
    setConsultantLoading(true);
    const fetchData = async () => {
      await debouncedFetchConsultants();
    };
    fetchData();
    return () => {
      debouncedFetchConsultants.cancel();
    };
  }, [consultantSearchText]);

  if (!hasViewPermission) {
    navigate("/unauthorized");
  }

  return (
    <>
      {openChangeConsultant && (
        <AssignConsultantPopup
          onClose={() => setOpenChangeConsultant(false)}
          orderId={orderId}
          getConsultantData={getConsultantData}
        />
      )}
      <div className="flex flex-row justify-between items-center py-2">
        <div className="flex gap-4 w-[max-content] px-3 py-4">
          <div className=" w-[235px]">
            <InputField
              onChange={(e: string) => setSearchValue(e)}
              placeholder="Search Order ID"
              value={searchValue}
              rightIcon={<SearchIcon />}
            />
          </div>
          <div className="w-fit">
            <Link to={"/create-consultant"}>
              <Button label="Create Consultant Order" leftIcon={<PlusIcon />} />
            </Link>
          </div>
        </div>

        <div className="w-full flex flex-row justify-end items-center">
          <div className="mx-2 mb-4">
            <SelectField2
              disabled={loading}
              label="Payment Status"
              placeholder="Select payment status"
              value={filteredPaymentStatus}
              onChange={(e) => {
                if (e === filteredPaymentStatus) {
                  setFilteredPaymentStatus("");
                } else {
                  setFilteredPaymentStatus(e);
                }
              }}
              removeOption={() => {
                setFilteredPaymentStatus("");
              }}
              options={PAYMENT_STATUS?.map((el: any) => ({
                value: el.value,
                label: el.label.split("_").join(" "),
              }))}
              leftIcon={<FilterIcon />}
            />
          </div>
          <div className="mx-2 mb-4">
            <SelectField2
              disabled={loading}
              label="Order Status"
              placeholder="Select order status"
              value={filteredOrderStatus}
              onChange={(e) => {
                if (e === filteredOrderStatus) {
                  setFilteredOrderStatus("");
                } else {
                  setFilteredOrderStatus(e);
                }
              }}
              removeOption={() => {
                setFilteredOrderStatus("");
              }}
              options={ORDER_STATUS?.map((el: any) => ({
                value: el.value,
                label: el.label.split("_").join(" "),
              }))}
              leftIcon={<FilterIcon />}
            />
          </div>
          <div className="mx-2 mb-4">
            <SelectField2
              label="Select Consultant"
              options={consultants}
              value={selectedConsultants}
              onChange={(e: any) => {
                if (e === selectedConsultants) {
                  setSelectedConsultants("");
                } else {
                  setSelectedConsultants(e);
                }
              }}
              removeOption={() => {
                setSelectedConsultants("");
              }}
              placeholder="Select Consultant"
              disabled={loading}
              loading={consultantLoading}
              totalPage={consultantTotalPage}
              page={consultantPage}
              setPage={setConsultantPage}
              searchPlaceHolder="Search consultant"
              openListCss="overflow-y-scroll h-[140px] z-[999]"
              leftIcon={<FilterIcon />}
            />
          </div>
        </div>
      </div>

      <div className="h-[78dvh] relative -z-0">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={loading}
        />
      </div>

      <div className="w-full flex gap-4 px-3">
        <Pagination
          currentPage={page}
          pageSize={pageLength}
          totalCount={totalCount}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </>
  );
}

export default DesignConsultantTable;
