import { useRef, useState } from "react";
import { protectedAxiosInstance } from "../api/axiosManagement";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import useWindowWidth from "../hooks/useWindowWidth";
import ErrorMessage from "../components/ErrorMessage";
import { Link } from "react-router-dom";
import { truncateFilename } from "../utils/helper";
import CrossIcon from "../assets/CrossIcon";
import UploadIcon from "../assets/UploadIcon";
import LoadingSpinner from "../assets/images/Spinner4.gif";

type ImageUploadPropType = {
  isMulti?: boolean;
  kind: "LC_USER_FILES";
  setter: (values: any[]) => void;
  imageList: string[];
  errors?: any;
  removeError?: () => void;
  disabled?: boolean;
  required?: boolean;
  label?: string | undefined;
  targetRef?: any;
  setMediaLoading?: any;
};

/**
 *
 * for imageList in single upload memoize it with an array because it only accept as an array
 * ie. const imageList = useMemo(() => [image], [image])
 */
const ConsultantUpload = ({
  isMulti = false,
  kind,
  setter,
  imageList,
  errors = [],
  removeError = () => {},
  disabled = false,
  required = false,
  label,
  targetRef,
  setMediaLoading,
}: ImageUploadPropType) => {
  const [mouseOnImage, setMouseOnImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<any>(null);

  const get_signed = async (extension: string) => {
    const payload = {
      extension,
      kind,
    };
    const response = protectedAxiosInstance
      .post(`/admin/orders/generate-upload-url`, payload)
      .then((res: any) => res?.data?.data)
      .catch((res: any) => {
        return res?.data?.errors;
      });
    return response;
  };

  const signed_to_s3 = async (acceptedFile: File) => {
    const extension = acceptedFile.name.split(".").at(-1)?.toLowerCase();
    const { file_path, upload_url } = await get_signed(extension as string);

    const file = new FormData();
    file.append("file", acceptedFile);

    let contentType: string;

    if (extension === "cdr") {
      contentType = "application/octet-stream";
    } else if (extension === "pdf") {
      contentType = "application/pdf";
    } else if (extension === "jpeg" || extension === "jpg") {
      contentType = "image/jpeg";
    } else {
      contentType = "";
    }

    const response = await fetch(upload_url, {
      method: "PUT",
      body: acceptedFile,
      headers: {
        "Content-Type": contentType,
      },
    });

    return { response, file_path };
  };

  const onDrop = async (acceptedFiles: File[], rejectedFiles: any) => {
    removeError();
    if (
      rejectedFiles.some(
        (el: any) =>
          el.errors &&
          el.errors.some((error: any) => error.code === "file-too-large")
      )
    ) {
      toast.error("File size exceeded.");
    }
    if (
      rejectedFiles.some(
        (el: any) =>
          el.errors &&
          el.errors.some((error: any) => error.code === "file-invalid-type")
      )
    ) {
      toast.error(
        "Invalid file format selected. Only JPEG, PDF, CDR are accepted."
      );
    }

    if (acceptedFiles.length === 0) {
      return;
    }
    setUploading(true);
    setMediaLoading(true);
    if (!acceptedFiles.length) return;

    const pathList = await Promise.all(
      acceptedFiles.map(async (acceptedFile) => {
        const { response, file_path } = await signed_to_s3(acceptedFile);
        return file_path;
      })
    );

    const insertIndex = imageList.findIndex(
      (item: any) => item?.to_delete === true
    );

    const transformedPathList = pathList.map((file, index) => ({
      file: file,
      filename: acceptedFiles[index]?.name,
      added: true,
    }));

    let updatedFiles;
    if (insertIndex !== -1) {
      // Insert the new items before the first 'to_delete' item
      updatedFiles = [
        ...imageList.slice(0, insertIndex),
        ...transformedPathList,
        ...imageList.slice(insertIndex),
      ];
    } else {
      // If no 'to_delete' item is found, simply append the new items
      updatedFiles = [...(imageList || []), ...transformedPathList];
    }

    setter(updatedFiles);
    setUploading(false);
    setMediaLoading(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleRemove = (each: any, index: number) => {
    if (each?.added === true) {
      const updatedFiles = imageList.filter(
        (el: any) => el?.file !== each?.file
      );

      setter(updatedFiles);
    } else {
      // Update the 'to_delete' property to true
      const updatedFiles = imageList
        .map((el: any, idx) => {
          if (el?.file === each?.file) {
            return {
              ...el,
              to_delete: true,
            };
          }
          return el;
        })
        .filter((el: any) => el?.file !== each?.file); // Filter out the element to be moved

      // Append the element to the end
      updatedFiles.push({
        ...each,
        to_delete: true,
      });

      setter(updatedFiles);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      ".cdr": [],
      ".pdf": [],
      ".jpeg": [],
      ".jpg": [],
    },
    onDrop,
    maxSize: 20 * 1024 * 1024,
  });

  const windowWidth = useWindowWidth();

  return (
    <div ref={targetRef} className="min-h-[140px]">
      {label && (
        <div className="flex items-center justify-between text-center pb-1 gap-2">
          <h4 className="text-xs font-bold font-gilroy-regular">
            {label}
            {required && <span className="text-red-500 text-xs ml-1">*</span>}
          </h4>
        </div>
      )}

      <div
        {...getRootProps()}
        className={`flex items-center justify-center w-full mb-2 ${
          disabled || uploading ? " opacity-50" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <label
          className={`flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed  bg-white  ${
            disabled || uploading
              ? "cursor-not-allowed"
              : "cursor-pointer hover:bg-gray-100"
          }`}
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <UploadIcon color="#D4A15E" />
            <p className="mb-2 text-sm text-gray-500">
              Drag & Drop or{" "}
              <span className="font-gilroy-bold text-pot-yellow">Choose</span>{" "}
              file to upload{" "}
            </p>
            <p className="text-xs text-gray-500 text-center">
              Upload JPEG, PDF, CDR file (Max file size: 20 MB)
            </p>
          </div>
          <input
            {...getInputProps({ disabled: disabled || uploading })}
            ref={fileInputRef}
          />
        </label>
      </div>

      <ErrorMessage error={errors} />

      {(imageList?.length > 0 || uploading) && (
        <div className="py-2 my-2 ">
          <div className="space-y-2">
            {imageList
              ?.filter((each: any) => !each?.to_delete)
              ?.map((each: any, index: number) => {
                return (
                  <div
                    key={each?.file + each?.index}
                    className={`flex flex-col relative border-2 border-c-gray-2 px-2 rounded-md `}
                    onMouseEnter={() => setMouseOnImage(each?.file)}
                    onMouseLeave={() => setMouseOnImage("")}
                  >
                    <div className="flex space-x-2 items-center py-2 justify-between w-full">
                      {each?.download_url ? (
                        <a
                          href={`${each?.download_url}`}
                          download
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <p
                            className={`text-xs text-pot-black font-gilroy-regular ${
                              each?.download_url
                                ? "hover:underline cursor-pointer"
                                : ""
                            }`}
                            title={each?.filename || "-"}
                          >
                            {each?.filename
                              ? truncateFilename(
                                  each?.filename,
                                  windowWidth < 200 ? 40 : 50
                                )
                              : "-"}
                          </p>
                        </a>
                      ) : (
                        <p
                          className={`text-xs text-pot-black font-gilroy-regular `}
                          title={each?.filename || "-"}
                        >
                          {each?.filename
                            ? truncateFilename(
                                each?.filename,
                                windowWidth < 200 ? 40 : 50
                              )
                            : "-"}
                        </p>
                      )}

                      {/* </ToolTip> */}
                      <button onClick={() => handleRemove(each, index)}>
                        <CrossIcon color={`gray`} />
                      </button>
                    </div>
                  </div>
                );
              })}

            {uploading && (
              <div className="flex justify-center items-center border-2 border-x-c-gray-2 rounded-md">
                <img alt="" width={32} height={32} src={LoadingSpinner} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConsultantUpload;
