import { useState } from "react";
import Button from "../../components/Button";
import Modal from "../../components/common/Modal";
import {
  uploadInvoiceDefaultValues,
  UploadInvoiceFormSchema,
  uploadInvoiceFormSchema,
} from "../../validators/upload-invoice-form";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../components/InputField";
import ImageUpload from "../../components/ImageUpload";
import { uploadInvoice } from "../../api/orderManagement";
import { toast } from "react-toastify";
import OrderUpload from "../OrderManagment/components/OrderUpload";
import { uploadInvoiceLightingConsultant } from "../../api/lightingConsultant";

type UploadInvoiceFormProps = {
  orderId: any;
  fetchOrderDetails: () => void;
  orderDetails: any;
};

export default function UploadInvoiceForm({
  orderId,
  fetchOrderDetails,
  orderDetails,
}: UploadInvoiceFormProps) {
  const [openUploadInvoice, setOpenUploadInvoice] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    setError,
    reset,
    setValue,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<UploadInvoiceFormSchema>({
    defaultValues: uploadInvoiceDefaultValues,
    resolver: yupResolver(uploadInvoiceFormSchema),
  });

  async function onSubmit(data: UploadInvoiceFormSchema) {
    console.log(data, "form data");
    const payload = {
      invoice_document: data.document,
      invoice_num: data.num,
    };
    try {
      setLoading(true);
      await uploadInvoiceLightingConsultant(orderId, payload);
      fetchOrderDetails();
      setOpenUploadInvoice(false);
      reset();
      toast.success("Invoice uploaded successfully");
    } catch (error: any) {
      toast.error(error?.data?.errors);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function handleCancel() {
    setOpenUploadInvoice(false);
    reset();
    setLoading(false);
  }

  return (
    <div>
      <Button
        className="!font-gilroy-medium h-[30px]"
        label="Upload Invoice"
        variant="secondary"
        onClick={() => setOpenUploadInvoice(true)}
        disabled={!orderDetails?.allowed_actions?.can_upload_invoice}
        showNoPermissionTooltip={
          !orderDetails?.allowed_actions?.can_upload_invoice
        }
      />

      {openUploadInvoice && (
        <Modal
          handleCancel={handleCancel}
          header="Upload Invoice"
          clickOutsideToClose={true}
          widthClass="!w-[564px]"
        >
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="mt-7 w-full flex flex-col gap-4">
              <Controller
                name="num"
                control={control}
                render={({ field }) => (
                  <InputField
                    required
                    label="Invoice Number"
                    placeholder="Invoice Number"
                    value={field.value}
                    maxLength={15}
                    onChange={(e: any) => {
                      const value = e.replace(/\s/g, "");
                      field.onChange(value);
                      clearErrors("num");
                    }}
                    hint={errors?.num?.message}
                  />
                )}
              />
              <Controller
                name="document"
                control={control}
                render={({ field }) => (
                  <OrderUpload
                    label="Upload Invoice:"
                    disabled={loading}
                    required
                    kind="LC_ORDER_INVOICE"
                    acceptTypes={[".pdf"]}
                    setter={(fileData) => {
                      if (!fileData) {
                        setValue("document", "");
                        return;
                      }
                      setValue("document", fileData.file);
                      clearErrors("document");
                    }}
                    file={
                      field.value
                        ? {
                            file: field.value,
                            filename: field.value.split("/").pop() || "",
                            added: true,
                          }
                        : null
                    }
                    errors={
                      errors?.document?.message ? [errors.document.message] : []
                    }
                    removeError={() => clearErrors("document")}
                    setMediaLoading={() => {}}
                  />
                )}
              />
            </div>

            <div className="mt-7 w-full flex gap-7">
              <Button
                label="Upload"
                variant="primary"
                type="submit"
                loading={isSubmitting}
                onClick={handleSubmit(onSubmit)}
                className="min-w-[72px]"
              />
              <Button
                label="Cancel"
                variant="secondary-outline-dark"
                onClick={handleCancel}
              />
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
}
