import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button";
import InputField from "../../components/InputField";
import Checkbox from "../../components/Checkbox";
import {
  getAdminUserAvailablePermissionRoles,
  getSingleUserRole,
} from "../../api/user";
import { protectedAxiosInstance } from "../../api/axiosManagement";
import { toast } from "react-toastify";
import { titleAtom } from "../../atom";
import { useSetRecoilState } from "recoil";
import { checkPermissions } from "../../utils/helper";

interface Permission {
  id: string;
  name: string;
  selected: boolean;
}

interface ModulePermissions {
  isSelected: boolean;
  permissions: Permission[];
  checkValue: any;
}

interface PermissionGroups {
  [key: string]: ModulePermissions;
}

interface CreateRolePayload {
  name: string;
  permissions: string[];
}

function CreateRole() {
  const navigate = useNavigate();
  const { id: roleID } = useParams();
  const setTitle = useSetRecoilState(titleAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [permissionGroups, setPermissionGroups] = useState<PermissionGroups>(
    {}
  );
 
  // Permissions
  const hasEditPermission = roleID ? checkPermissions("edit_role", true, toast) : false;
  const hasCreatePermission = !roleID && checkPermissions("create_role", true, toast);
  const hasViewPermission = roleID ? checkPermissions("view_role") : true;

  // Permission-based navigation
  useEffect(() => {
    // For create page
    if (!roleID && !hasCreatePermission) {
      navigate("/users/roles");
      return;
    }

    // For edit/view page
    if (roleID) {
      // If user doesn't have view permission or edit permission, redirect
      if (!hasViewPermission || !hasEditPermission) {
        navigate("/users/roles");
        return;
      }
    }
  }, [roleID, hasCreatePermission, hasEditPermission, hasViewPermission]);

  useEffect(() => {
    getAdminUserAvailablePermissionRoles()
      .then((res) => {
        const formattedGroups: PermissionGroups = {};
        Object.entries(res?.data?.permissions)?.forEach(
          ([groupName, permissions]: any) => {
            formattedGroups[groupName] = {
              isSelected: false,
              checkValue: 0,
              permissions: permissions?.map(([id, name]: any) => ({
                id,
                name: name as string,
                selected: false,
              })),
            };
          }
        );

        // If we have a roleID, fetch the role data
        if (roleID) {
          getSingleUserRole(roleID)
            .then((roleRes) => {
              setRoleName(roleRes.data.role.name);

              // Update permissions based on role data
              Object.keys(formattedGroups).forEach((groupName) => {
                formattedGroups[groupName].permissions = formattedGroups[
                  groupName
                ].permissions.map((perm) => ({
                  ...perm,
                  selected: roleRes.data.role.permissions.includes(perm.id),
                }));

                // Calculate module selection state
                const selectedPermissions = formattedGroups[
                  groupName
                ].permissions.filter((perm) => perm.selected);

                const totalPermissions =
                  formattedGroups[groupName].permissions.length;
                const selectedCount = selectedPermissions.length;

                // Update module selection state and checkValue
                if (selectedCount === 0) {
                  formattedGroups[groupName].isSelected = false;
                  formattedGroups[groupName].checkValue = 0;
                } else if (selectedCount === totalPermissions) {
                  formattedGroups[groupName].isSelected = true;
                  formattedGroups[groupName].checkValue = 1;
                } else {
                  formattedGroups[groupName].isSelected = false;
                  formattedGroups[groupName].checkValue = 2;
                }
              });
            })
            .catch((error) => {
              console.error("Error fetching role:", error);
              if (error.status === 404) {
                navigate("/users/roles");
                toast.error("Role not found");
              }
            });
        }

        setPermissionGroups(formattedGroups);
      })
      .catch((e) => {
        console.error("Error fetching permissions:", e);
        toast.error("Failed to fetch permissions");
      });
  }, [roleID]);

  const handleModuleSelect = (moduleName: string) => {
    setPermissionGroups((prev) => {
      const currentModuleSelected = prev[moduleName].permissions.some(
        (perm) => perm.selected
      );
      const newSelectedState = !currentModuleSelected;

      return {
        ...prev,
        [moduleName]: {
          ...prev[moduleName],
          isSelected: newSelectedState,
          permissions: prev[moduleName].permissions?.map((perm) => ({
            ...perm,
            selected: newSelectedState,
          })),
        },
      };
    });
  };

  const handlePermissionChange = (moduleName: string, permissionId: string) => {
    setPermissionGroups((prev) => {
      const updatedPermissions = prev[moduleName].permissions.map((perm) =>
        perm.id === permissionId ? { ...perm, selected: !perm.selected } : perm
      );

      // Check if any permissions are selected to determine the module's checkbox state
      const hasSelectedPermissions = updatedPermissions.some(
        (perm) => perm.selected
      );
      const areAllPermissionsSelected = updatedPermissions.every(
        (perm) => perm.selected
      );

      return {
        ...prev,
        [moduleName]: {
          ...prev[moduleName],
          // Set isSelected based on permission states:
          // - If all permissions are selected: true (1)
          // - If some permissions are selected: partially selected (2)
          // - If no permissions are selected: false (0)
          isSelected: areAllPermissionsSelected
            ? true
            : hasSelectedPermissions
            ? false
            : false,
          checkValue: areAllPermissionsSelected
            ? 1
            : hasSelectedPermissions
            ? 2
            : 0,
          permissions: updatedPermissions,
        },
      };
    });
  };

  const getSelectedPermissions = (): string[] => {
    const selectedPermissions: string[] = [];
    Object.values(permissionGroups).forEach((moduleData) => {
      moduleData.permissions?.forEach((permission) => {
        if (permission.selected) {
          selectedPermissions.push(permission.id);
        }
      });
    });
    return selectedPermissions;
  };

  // Function to check if at least one permission is selected in all modules
  const hasAtLeastOneSelected = (modules: any) => {
    return Object.entries(modules).some(([moduleName, moduleData]: any) => {
      return moduleData.permissions.some(
        (permission: any) => permission.selected
      );
    });
  };

  const handleSubmit = async () => {
    if (!roleName) {
      toast.error("Please enter role name");
      return;
    }

    if (!hasAtLeastOneSelected(permissionGroups)) {
      toast.error("Please select at least one permission");
      return;
    }

    const payload: CreateRolePayload = {
      name: roleName.trim(),
      permissions: getSelectedPermissions(),
    };

    try {
      setIsLoading(true);
      if (roleID) {
        await protectedAxiosInstance.put(
          `/admin/accounts/role/${roleID}`,
          payload
        );
        toast.success("Role updated successfully");
      } else {
        await protectedAxiosInstance.post("/admin/accounts/role", payload);
        toast.success("Role created successfully");
      }
      navigate("/users/roles");
    } catch (error) {
      console.error("Error saving role:", error);
      toast.error("Failed to save role. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

 
  return (
    <>
      <div className="h-[calc(100vh-100px)] no-scrollbar w-full flex flex-col justify-start items-start">
        <div className="bg-white border-b border-[#ECECEC] w-full pt-4 pb-4 px-4">
          <p className="flex font-gilroy-medium text-[14px] leading-[20px]">
            {roleID ? "Edit Role" : "Create new Role"}
          </p>
        </div>

        <div className="w-full mt-4 px-4">
          <div className="mb-6 max-w-lg">
            <InputField
              label="Role Name"
              placeholder="Role Name"
              value={roleName}
              onChange={setRoleName}
              required
              disabled={isLoading}
            />
          </div>
          <div className="w-full h-[1px] mb-4 bg-[#DEDEDE]"></div>
          <div>
            <h2 className="text-[16px] mt-5 mb-4 font-gilroy-medium text-[#000000]">
              Permissions
            </h2>

            <div className="h-[56dvh] overflow-y-scroll custom-scrollbar">
              {Object.entries(permissionGroups)?.map(
                ([moduleName, moduleData]: any) => (
                  <div key={moduleName} className="py-2 mb-[12px]">
                    <div className="flex items-start flex-col gap-4">
                      <div className="flex items-center">
                        <Checkbox
                          checkValue={
                            moduleData.checkValue ||
                            (moduleData.isSelected ? 1 : 0)
                          }
                          onClick={() => handleModuleSelect(moduleName)}
                          borderType="square"
                        />
                        <span className="text-sm font-gilroy-bold text-black">
                          {moduleName}
                        </span>
                      </div>

                      <div className="flex items-start">
                        {moduleData.permissions?.map((permission: any) => (
                          <div
                            key={permission.id}
                            className="flex items-center space-x-2 mr-4"
                          >
                            <Checkbox
                              checkValue={permission.selected ? 1 : 0}
                              onClick={() =>
                                handlePermissionChange(
                                  moduleName,
                                  permission.id
                                )
                              }
                              borderType="square"
                              label={permission.name}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-x-4 w-[100%] py-3 border-t border-[#ECECEC] px-4">
        <Button
          label={roleID ? "Update" : "Create"}
          variant="primary"
          onClick={handleSubmit}
          disabled={isLoading}
        />
        <Button
          label="Cancel"
          variant="secondary-outline"
          onClick={() => navigate("/users/roles")}
          disabled={isLoading}
        />
      </div>
    </>
  );
}

export default CreateRole;
