import React from "react";
import editPen from "../../assets/svg/pen.svg";
import { useNavigate } from "react-router-dom";
import EyeIcon from "../../assets/svg-tsx/EyeIcon";
import { Tooltip } from "antd";
import { checkPermissions } from "../../utils/helper";

type Props = {
  orderDetails: any;
};

const DetailSection = ({ orderDetails }: Props) => {
  const navigate = useNavigate();
  // Permission
  const hasViewPermission = checkPermissions("view_customer");

  return (
    <div className="text-start px-4 pt-2 py-4 border-b">
      <div className="w-full flex justify-between">
        <h1 className="text-pot-yellow font-medium text-lg">
          Customer Details:{" "}
        </h1>

        <Tooltip
          title={
            !hasViewPermission &&
            "You don't have permission to perform this action"
          }
          className={`${
            !hasViewPermission
              ? " opacity-30 cursor-not-allowed"
              : "cursor-pointer"
          }`}
        >
          <button
            className="mr-2 flex justify-center items-center "
            onClick={() =>
              navigate(`/customers/${orderDetails?.ordered_for?.id}`)
            }
          >
            <EyeIcon size="16" />
          </button>
        </Tooltip>
      </div>
      <div className="flex flex-col space-y-1  text-start mt-4 text-[12px] leading-6">
        <div>
          <p className="text-[#AEAEAE]">Name:</p>
          <p>{orderDetails?.ordered_for?.name || "-"}</p>
        </div>

        <div>
          <p className="text-[#AEAEAE]">Contact</p>
          <p>{orderDetails?.ordered_for?.phone || "-"}</p>
        </div>
        <div>
          <p className="text-[#AEAEAE]">Email id:</p>
          <p>{orderDetails?.ordered_for?.email || "-"}</p>
        </div>
      </div>
    </div>
  );
};

export default DetailSection;
