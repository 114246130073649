import { useState } from "react";
import Button from "../../components/Button";
import AssignConsultantPopup from "./AssignConsultantPopup";
import { toast } from "react-toastify";
import { checkPermissions } from "../../utils/helper";

const AssignConsultant = ({
  orderId,
  orderDetails,
  getSingleOrderApi,
  isOrderCancelled,
  fetchSingleOrderDetails,
}: any) => {
  const [openChangeConsultant, setOpenChangeConsultant] = useState(false);

  // Permissions
  const hasReassignConsultantPermission = checkPermissions(
    "reassign_lc_consultant"
  );

  const buttonLabel = orderDetails?.consultant?.name ? "Change" : "Assign";

  return (
    <div className="text-start px-4 py-4 h-fit border border-t-0 border-l-0 border-b">
      <div className="w-full flex justify-between">
        <div className="flex flex-col gap-2">
          <p className="text-[#AEAEAE] text-[12px]">Assigned Consultant</p>
          <p
            title={orderDetails?.consultant?.lc_name}
            className="font-gilroy-medium text-[12px] leading-5 truncate w-36"
          >
            {orderDetails?.consultant?.lc_name || "-"}
          </p>
        </div>

        <Button
          className="h-7 font-thin"
          label={buttonLabel}
          variant="secondary"
          onClick={() => setOpenChangeConsultant(true)}
          disabled={!hasReassignConsultantPermission}
          showNoPermissionTooltip={!hasReassignConsultantPermission}
        />

        {openChangeConsultant && (
          <AssignConsultantPopup
            onClose={() => setOpenChangeConsultant(false)}
            orderId={orderId}
            orderDetails={orderDetails}
            getSingleOrderApi={getSingleOrderApi}
            fetchSingleOrderDetails={fetchSingleOrderDetails}
          />
        )}
      </div>
    </div>
  );
};

export default AssignConsultant;
