import { useEffect, SetStateAction, Dispatch, useState } from "react";
import {
  assignedConsultant,
  getLightingConsultant,
  getListForLightingConsultant,
} from "../../api/lightingConsultant";
import _debounce from "lodash/debounce";
import { toast } from "react-toastify";
import SelectField2 from "../../components/SelectedField2";
import Button from "../../components/Button";
import Modal from "../../components/common/Modal";
import React from "react";

const AssignConsultantPopup = ({
  onClose,
  orderId,
  getConsultantData,
  orderDetails,
  fetchSingleOrderDetails,
}: any) => {
  const [consultantList, setConsultantList] = useState([]);
  const [consultant, setConsultant] = useState<any>(null);
  const [externalConsultant, setExternalConsultant] = useState<any>(null);
  const [totalPage, setTotalPage] = useState(1);
  const [consultantPage, setConsultantPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [consultantLoading, setConsultantLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [time, setTime] = useState(500);
  const debouncedFetchConsultants = _debounce(geConsultantList, time);

  async function geConsultantList() {
    setConsultantLoading(true);
    const params = {
      page: consultantPage,
      length: 10,
      search: searchValue,
    };
    try {
      const res = await getLightingConsultant(params);
      const newConsultantList = res?.data?.lighting_consultants.map(
        (consultant: any) => ({
          value: consultant.id,
          label: consultant.lc_name,
        })
      );

      // Update list based on page number
      setConsultantList((prev) =>
        consultantPage === 1
          ? newConsultantList
          : [...prev, ...newConsultantList]
      );

      setTotalPage(res?.data?.pagination?.total_pages);
    } catch (error) {
      console.error("Error fetching channel partner list:", error);
    } finally {
      setConsultantLoading(false);
    }
  }

  // Handle pagination/infinite scroll
  useEffect(() => {
    if (consultantPage <= totalPage) {
      const fetchData = async () => {
        await debouncedFetchConsultants();
      };
      fetchData();
    }
    return () => {
      debouncedFetchConsultants.cancel();
    };
  }, [consultantPage]);

  // Handle search
  useEffect(() => {
    setTime(500);
    setConsultantPage(1);
    setConsultantList([]);
    setConsultantLoading(true);
    const fetchData = async () => {
      await debouncedFetchConsultants();
    };
    fetchData();
    return () => {
      debouncedFetchConsultants.cancel();
    };
  }, [searchValue]);

  useEffect(() => {
    if (orderDetails?.consultant) {
      setExternalConsultant({
        id: orderDetails?.consultant?.id,
        value: orderDetails?.consultant?.lc_name,
      });
      setConsultant(orderDetails?.consultant?.id);
    }
  }, [orderDetails]);

  const handleSubmit = async () => {
    const payload = {
      consultant_id: consultant,
    };

    try {
      setLoading(true);
      await assignedConsultant(orderId, payload);
      toast.success("Consultant assigned successfully");
      if (getConsultantData) {
        await getConsultantData();
      }
      if (fetchSingleOrderDetails) {
        await fetchSingleOrderDetails();
      }
      onClose();
    } catch (e: any) {
      toast.error(e?.data?.errors);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal handleCancel={onClose} header="Change Consultant">
      <div className="w-full text-black py-2">
        <div className="mt-2">
          <SelectField2
            options={consultantList}
            value={consultantLoading ? null : consultant}
            onChange={(value: any) => {
              setConsultant(value);
            }}
            loading={consultantLoading}
            totalPage={totalPage}
            page={consultantPage}
            setPage={setConsultantPage}
            externalSearch={searchValue}
            externalSetSearch={setSearchValue}
            placeholder="Select Consultant"
            searchPlaceHolder="Select Consultant"
            label="Consultant"
            openListCss="h-[180px]"
            removeOption={() => {
              setConsultant(null);
            }}
            externalValue={externalConsultant}
            setExternalValue={setExternalConsultant}
          />
        </div>
        <div className="mt-9 flex gap-4">
          <Button label="Apply" disabled={loading} onClick={handleSubmit} />
          <Button
            label="Cancel"
            onClick={onClose}
            variant="secondary-outline"
          />
        </div>
      </div>
    </Modal>
  );
};

export default AssignConsultantPopup;
