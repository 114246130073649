import React, { useState } from "react";
import UploadInvoiceForm from "./UploadInvoiceForm";
import FileDownloadIcon from "../../assets/svg-tsx/FileDownloadIcon";
import TrashIcon from "../../assets/TrashIcon";
import { toast } from "react-toastify";
import { deleteInvoiceLightingConsultant } from "../../api/lightingConsultant";
import ConfirmationModal from "../../components/common/ConfirmationModal";

type DownloadInvoicesSectionProps = {
  orderId: any;
  orderDetails: any;
  fetchOrderDetails: () => void;
};

export default function DownloadInvoicesSection({
  orderId,
  orderDetails,
  fetchOrderDetails,
}: DownloadInvoicesSectionProps) {
  const [downloadingId, setDownloadingId] = useState<string | null>(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const handleDownload = async (invoice: any) => {
    setDownloadingId("1");
    try {
      // Create temporary link
      const link = document.createElement("a");
      link.href = invoice.document;
      link.download = `Invoice_${invoice.num}.pdf`; // Force the filename

      // Programmatically click the link
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading invoice");
    } finally {
      setDownloadingId(null);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteInvoiceLightingConsultant(orderId);
      fetchOrderDetails();
      toast.success("Invoice deleted successfully");
    } catch (error) {
      console.error("Error deleting invoice:", error);
      toast.error("Error deleting invoice");
    }
  };

  return (
    <div className="px-4 py-3">
      <div className="flex justify-between items-center mb-1">
        <h2 className="text-sm font-gilroy-medium">Invoices</h2>
        <UploadInvoiceForm
          orderId={orderId}
          fetchOrderDetails={fetchOrderDetails}
          orderDetails={orderDetails}
        />
      </div>
      {orderDetails?.invoice_document ? (
        <>
          <ul className="space-y-2 list-disc pl-5 text-[14px] h-[25vh] overflow-y-auto custom-scrollbar">
            <li>
              <div className="flex justify-between text-sm px-2 py-1">
                <span>{orderDetails?.invoice_num}</span>
                <div className="flex gap-2">
                  <button
                    className="hover:opacity-75 transition-opacity"
                    onClick={() =>
                      handleDownload({
                        document: orderDetails?.invoice_document,
                        num: orderDetails?.invoice_num,
                      })
                    }
                    disabled={downloadingId === "1"}
                  >
                    {downloadingId === "1" ? (
                      <span>Downloading...</span>
                    ) : (
                      <FileDownloadIcon />
                    )}
                  </button>
                  <button
                    className="hover:opacity-75 transition-opacity"
                    onClick={() => setOpenConfirmationModal(true)}
                  >
                    <TrashIcon width="15" height="15" />
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </>
      ) : (
        <div className="h-[32vh] flex flex-col justify-center items-center gap-1">
          <p className="font-gilroy-semi-bold font-semibold">
            No Invoices Found
          </p>
          <p className="text-[#AEAEAE] font-gilroy-medium font-normal">
            Invoices will appear here once created.
          </p>
        </div>
      )}

      {openConfirmationModal && (
        <ConfirmationModal
          header="Delete Invoice"
          description="Are you sure you want to delete this invoice?"
          onConfirm={() => {
            handleDelete();
            setOpenConfirmationModal(false);
          }}
          onCancel={() => setOpenConfirmationModal(false)}
        />
      )}
    </div>
  );
}
