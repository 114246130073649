import { useState, useEffect } from "react";
import SelectField2 from "../../../components/SelectedField2";
import { getListForChannelPartner } from "../../../api/channelPartner";
import _debounce from "lodash/debounce";
import { useRecoilValue } from "recoil";
import { userInfoAtom } from "../../../atom";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";

export default function Filters({
  channelPartner,
  setChannelPartner,
  showChannlePartnerDropdowon = false,
  onApplyClick,
  onDateClear,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: any) {
  const [channelPartnerList, setChannelPartnerList] = useState([]);

  const [externalChannelPartner, setExternalChannelPartner] =
    useState<any>(null);
  const [totalPage, setTotalPage] = useState(1);
  const [channlePartnerPage, setChannlePartnerPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [channelPartnerLoading, setChannelPartnerLoading] = useState(false);

  const [time, setTime] = useState(500);
  const debouncedFetchChannelPartners = _debounce(geChannelPartnerList, time);

  const userInfo = useRecoilValue(userInfoAtom);

  async function geChannelPartnerList() {
    setChannelPartnerLoading(true);
    const params = {
      page: channlePartnerPage,
      length: 10,
      search: searchValue,
    };
    try {
      const res = await getListForChannelPartner(params);
      const newChannelPartnerList = res?.data?.channel_partners.map(
        (channelPartner: any) => ({
          value: channelPartner.id,
          label: channelPartner.name,
        })
      );

      // Update list based on page number
      setChannelPartnerList((prev: any) =>
        channlePartnerPage === 1
          ? newChannelPartnerList
          : [...prev, ...newChannelPartnerList]
      );

      setTotalPage(res?.data?.pagination?.total_pages);
    } catch (error) {
      console.error("Error fetching channel partner list:", error);
    } finally {
      setChannelPartnerLoading(false);
    }
  }

  // Handle pagination/infinite scroll
  useEffect(() => {
    if (channlePartnerPage <= totalPage) {
      const fetchData = async () => {
        await debouncedFetchChannelPartners();
      };
      fetchData();
    }
    return () => {
      debouncedFetchChannelPartners.cancel();
    };
  }, [channlePartnerPage]);

  // Handle search
  useEffect(() => {
    setTime(500);
    setChannlePartnerPage(1);
    setChannelPartnerList([]);
    setChannelPartnerLoading(true);
    const fetchData = async () => {
      await debouncedFetchChannelPartners();
    };
    fetchData();
    return () => {
      debouncedFetchChannelPartners.cancel();
    };
  }, [searchValue]);

  useEffect(() => {
    if (userInfo?.channel_partner) {
      setChannelPartner(userInfo?.channel_partner?.id);
      setExternalChannelPartner({
        value: userInfo?.channel_partner?.name,
      });
    }
  }, []);

  const disableChannelPartnerDropdown = userInfo?.channel_partner
    ? true
    : false;

  return (
    <div className="flex items-center justify-end py-2 border-b px-6 gap-4">
      {showChannlePartnerDropdowon && (
        <SelectField2
          options={channelPartnerList}
          value={channelPartnerLoading ? null : channelPartner}
          onChange={(value: any) => {
            setChannelPartner(value);
          }}
          loading={channelPartnerLoading}
          totalPage={totalPage}
          page={channlePartnerPage}
          setPage={setChannlePartnerPage}
          externalSearch={searchValue}
          externalSetSearch={setSearchValue}
          placeholder="Select Channel Partner"
          searchPlaceHolder="Select Channel Partner"
          openListCss="h-[180px]"
          disabled={disableChannelPartnerDropdown}
          removeOption={() => {
            setChannelPartner(null);
          }}
          externalValue={externalChannelPartner}
          setExternalValue={setExternalChannelPartner}
        />
      )}
      <DateRangePicker
        onApplyClick={onApplyClick}
        onDateClear={onDateClear}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        buttonText="Select Date"
        customQuickSelectionRequired={true}
      />
    </div>
  );
}
