import React from "react";

const EyeIcon2 = ({
  size = "14",
  color = "",
}: {
  size?: string;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9499 4.275C10.7383 1.63688 8.45914 0 6 0C3.54086 0 1.26165 1.63688 0.0500734 4.275C0.0170469 4.34597 0 4.42257 0 4.5C0 4.57743 0.0170469 4.65403 0.0500734 4.725C1.26165 7.36312 3.54086 9 6 9C8.45914 9 10.7383 7.36312 11.9499 4.725C11.983 4.65403 12 4.57743 12 4.5C12 4.42257 11.983 4.34597 11.9499 4.275ZM6 7.875C4.09266 7.875 2.29929 6.58687 1.26165 4.5C2.29929 2.41313 4.09266 1.125 6 1.125C7.90734 1.125 9.70071 2.41313 10.7383 4.5C9.70071 6.58687 7.90734 7.875 6 7.875ZM6 2.25C5.52549 2.25 5.06164 2.38196 4.6671 2.62919C4.27256 2.87643 3.96505 3.22783 3.78346 3.63896C3.60187 4.0501 3.55436 4.5025 3.64693 4.93895C3.73951 5.37541 3.96801 5.77632 4.30353 6.09099C4.63906 6.40566 5.06655 6.61995 5.53195 6.70677C5.99734 6.79358 6.47973 6.74903 6.91812 6.57873C7.35651 6.40843 7.73121 6.12004 7.99483 5.75003C8.25846 5.38002 8.39916 4.94501 8.39916 4.5C8.39916 3.90326 8.1464 3.33097 7.69647 2.90901C7.24653 2.48705 6.6363 2.25 6 2.25ZM6 5.625C5.76274 5.625 5.53082 5.55902 5.33355 5.4354C5.13628 5.31179 4.98252 5.13609 4.89173 4.93052C4.80094 4.72495 4.77718 4.49875 4.82347 4.28052C4.86975 4.06229 4.984 3.86184 5.15177 3.7045C5.31953 3.54717 5.53328 3.44002 5.76597 3.39662C5.99867 3.35321 6.23986 3.37549 6.45906 3.46064C6.67826 3.54578 6.8656 3.68998 6.99742 3.87498C7.12923 4.05999 7.19958 4.2775 7.19958 4.5C7.19958 4.79837 7.0732 5.08452 6.84823 5.2955C6.62327 5.50647 6.31815 5.625 6 5.625Z"
        fill="white"
      />
    </svg>
  );
};

export default EyeIcon2;
