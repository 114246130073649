import React, { useState } from "react";
import { motion } from "framer-motion";
import HipchatChevronDownIcon from "../../../../assets/HipchatChevronDownIcon";


type OrderDetailsDropDownProps = {
  label?: string;
  staticData?: React.ReactNode;
  dropDown?: React.ReactNode;
  heading?: string;
  onClick?: () => void;
};

export default function OrderDetailsDropDown({
  label,
  staticData,
  dropDown,
  heading,
  onClick,
}: OrderDetailsDropDownProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <div>
      <div className="w-full border-2 h-auto mt-4">
        <div className="flex justify-between items-center bg-[#F6F6F6] h-[40px] px-5 py-2">
          <p className="font-medium text-[14px] leading-5">{label}</p>
          <div className="flex gap-3 justify-center items-center">
            <div
              onClick={onClick}
              className="flex gap-2 items-center justify-center cursor-pointer text-pot-yellow text-sm"
            >
              {" "}
              {heading}{" "}
            </div>
            <motion.div
              animate={{ rotate: isDropdownOpen ? 180 : 0 }}
              className="w-[38px]  h-[38px] flex items-center justify-center cursor-pointer"
              onClick={handleDropdownClick}
            >
              <HipchatChevronDownIcon color="black" width="22" />
            </motion.div>
          </div>
        </div>

        <motion.div
          layout
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{
            opacity: 0,
            y: -30,
            transition: { duration: 0.2 },
          }}
          transition={{ delay: 0.1, duration: 0.2 }}
        >
          {staticData}
        </motion.div>

        {isDropdownOpen && (
          <>
            <motion.div
              layout
              initial={{ opacity: 0, y: -30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{
                opacity: 0,
                y: -30,
                transition: { duration: 0.2 },
              }}
              transition={{ delay: 0.1, duration: 0.2 }}
            >
              {dropDown}
            </motion.div>
          </>
        )}
      </div>
    </div>
  );
}
