import React from "react";
import { CustomDropdown } from "../../DropDown";
import editPen from "../../../../assets/svg/pen.svg";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../../../utils/helper";

export default function ProjectDetails({ projectsData }: any) {
  const navigate = useNavigate();
  return (
    <>
      <CustomDropdown
        createButton="Add project"
        onClickCreateButton={() => navigate("/projects/create")}
        label="Project Details"
      >
        <div className="grid grid-cols-3">
          {projectsData && projectsData.length > 0 ? (
            projectsData?.map((el: any, i: number) => (
              <div key={i} className="border-x border-y px-5 py-5">
                <div className="flex justify-between h-[40px]">
                  <Link to={`/projects/${el.id}`}>
                    <p className="underline text-[14px] font-medium">
                      {el?.name}
                    </p>
                  </Link>
                  <Link to={`/projects/${el.id}/edit`}>
                    <img src={editPen} alt="edit-pen" />
                  </Link>
                </div>

                <div className="flex flex-col text-[11px] leading-5 tracking-[-0.08px] text-start">
                  <div className="flex space-x-6">
                    <p className="flex justify-between flex-row w-[90px]">
                      <span>Created on</span>
                      <span>:</span>
                    </p>
                    <span>{formatDate(el.created_at)}</span>
                  </div>
                  <div className="flex space-x-6">
                    <p className="flex justify-between flex-row w-[90px]">
                      Area Count
                      <span>:</span>
                    </p>
                    <span>{el.area_count}</span>
                  </div>
                  <div className="flex space-x-6">
                    <p className="flex justify-between flex-row w-[90px]">
                      Floor Count
                      <span>:</span>
                    </p>
                    <span>{el.floor_count}</span>
                  </div>
                  <div className="flex space-x-6">
                    <p className="flex justify-between flex-row w-[90px]">
                      Location
                      <span>:</span>
                    </p>
                    {el?.address_state || el?.address_city ? (
                      <span>
                        {el?.address_state && el?.address_state},{" "}
                        {el?.address_city && el?.address_city}
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                  <div className="flex space-x-6">
                    <p className="flex justify-between flex-row w-[90px]">
                      Total Orders
                      <span>:</span>
                    </p>
                    <span>{el.order_count}</span>
                  </div>
                  <div className="flex space-x-6">
                    <p className="flex justify-between flex-row w-[90px]">
                      Professionals
                      <span>:</span>
                    </p>
                    <span>{el.member_count}</span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="col-span-3 text-center py-4">No projects available</p>
          )}
        </div>
      </CustomDropdown>
    </>
  );
}
