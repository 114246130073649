import React, { useState } from "react";
import { motion } from "framer-motion";
import HipchatChevronDownIcon from "../assets/HipchatChevronDownIcon";
import { Tooltip } from "antd";
interface CustomDropdownProps {
  children: React.ReactNode;
  label?: string;
  heading?: any;
  onClick?: () => void;
  footer?: any;
  disable?: any;
  isPermission?: boolean;
}
export const Accordian: React.FC<CustomDropdownProps> = ({
  children,
  label,
  heading,
  onClick,
  footer,
  disable,
  isPermission,
}: CustomDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div>
      <div className="w-full border h-auto mt-4">
        <div className="flex justify-between items-center bg-[#FBFBFB] h-[48px] px-5 py-2">
          <p className="font-medium text-[14px] leading-5">{label}</p>

          <div className="flex gap-3 justify-center items-center">
            <Tooltip
              title={
                isPermission &&
                "You don't have permission to perform this action"
              }
            >
              <button
                disabled={disable}
                onClick={onClick}
                className={`flex gap-2 items-center justify-center cursor-pointer text-pot-yellow text-sm ${
                  disable && "opacity-40"
                }`}
              >
                {" "}
                {heading}{" "}
              </button>
            </Tooltip>
            <motion.div
              animate={{ rotate: isDropdownOpen ? 180 : 0 }}
              className="w-[38px]  h-[38px] flex items-center justify-center cursor-pointer"
              onClick={handleDropdownClick}
            >
              <HipchatChevronDownIcon color="black" width="22" />
            </motion.div>
          </div>
        </div>

        {isDropdownOpen && (
          <motion.div
            layout
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{
              opacity: 0,
              y: -30,
              transition: { duration: 0.2 },
            }}
            transition={{ delay: 0.1, duration: 0.2 }}
          >
            {children}
          </motion.div>
        )}

        {footer && (
          <div className="h-[40px] px-5 py-2 border border-c-white-3 grid-item flex justify-center items-center cursor-pointer text-[14px] leading-[20px] text-pot-yellow font-gilroy-medium">
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};
