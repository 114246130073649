import React from "react";
import Modal from "./Modal";
import Button from "../Button";

type ConfirmationModalProps = {
  header: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  description?: string;
};

export default function ConfirmationModal({
  header = "",
  onConfirm,
  onCancel,
  confirmButtonLabel = "Yes",
  cancelButtonLabel = "No",
  description = "",
}: ConfirmationModalProps) {
  const footerContent = (
    <div className="flex gap-x-3">
      <Button
        variant="primary"
        label={confirmButtonLabel}
        onClick={onConfirm}
      />
      <Button
        variant="secondary"
        label={cancelButtonLabel}
        onClick={onCancel}
      />
    </div>
  );
  return (
    <Modal
      top
      header={header}
      footer={footerContent}
      handleCancel={onCancel}
      clickOutsideToClose
    >
      <p className="text-left">{description}</p>
    </Modal>
  );
}
