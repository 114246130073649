import React, { useEffect, useRef, useState } from "react";
import HipchatChevronRightIcon2 from "../../assets/HipchatChevronRightIcon2";
import InputField from "../../components/InputField";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../../components/Button";
import { validateNumber } from "../../utils/validations";
import EditIcon from "../../assets/EditIcon";
import { BASE_URL } from "../../api/axiosManagement";
import { toast } from "react-toastify";
import axios from "axios";
import SquareEditIcon from "../../assets/svg-tsx/SquareEditIcon";

const schema = yup.object().shape({
  otp: yup.string(),
});

const StepTwo = ({
  setCurrentStep,
  email,
  otpKey,
  token,
  setEmail,
  setOtpKey,
}: any) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [number, setNumber] = useState<string[]>([]);
  const [error, setError] = useState<any>({});
  const [expiryTime, setExpiryTime] = useState(300);
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const inputRefs = Array.from(
    { length: 6 },
    () => useRef<HTMLInputElement>(null) //eslint-disable-line
  );

  // Check all otp are filled or not
  const isAllFieldsFilled =
    number.length === 6 &&
    number.every((value) => value !== "" && value !== undefined);

  const optValue = number.join("");

  const onSubmit = async (data: any) => {
    const payload = {
      otp: optValue,
      otp_key: otpKey,
    };

    try {
      setLoading(true);

      const response = await axios.post(
        `${BASE_URL}/accounts/otp/verify`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.data?.is_verified) {
        toast.success("OTP verified successfully");
        setCurrentStep(3);
      }

      if (response?.data?.data?.is_verified === false) {
        toast.error("Invalid OTP");
      }
    } catch (error: any) {
      console.error(":", error);
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResendOTP = async () => {
    if (expiryTime > 0) return; // Don't allow resend if timer is still running

    setError({});
    setNumber([]);
    const payload = {
      email: email,
    };

    try {
      setResendLoading(true);
      const response = await axios.post(
        `${BASE_URL}/accounts/otp/send`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setOtpKey(response?.data?.data?.otp_key);
      setExpiryTime(300); // Reset timer to 5 minutes
      toast.success("OTP sent successfully");
    } catch (error: any) {
      console.error("Resend OTP Error:", error);
      toast.error(error?.response?.data?.message);
    } finally {
      setResendLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setExpiryTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(interval);
  }, [number]);

  const handleInput = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (error?.otp) {
      delete error?.otp;
      setError(error);
    }
    const value = event.currentTarget.value;
    if (validateNumber(value) === false) {
      return;
    }
    const newNumber = [...number];
    newNumber[index] = value;
    setNumber(newNumber);

    setValue("otp", newNumber.join(""));

    if (value.length === 1 && index < 5) {
      inputRefs[index + 1].current?.focus();
    } else if (value.length === 1 && index === 5) {
      inputRefs[index].current?.blur();
    }
  };

  const handleBackspace = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && !event.currentTarget.value) {
      if (error?.otp) {
        delete error.otp;
        setError(error);
      }
      event.preventDefault();
      const newNumber = [...number];
      newNumber[index - 1] = "";
      setNumber(newNumber);
      inputRefs[index - 1].current?.focus();
    }
  };
  return (
    <div className="w-full min-w-[450px]">
      <h1 className="text-5xl mx-auto w-max font-mangoGrotesque-black my-4 ">
        Enter OTP
      </h1>
      <div className="flex flex-row my-8 items-center justify-center">
        <div className="text-[#111111] text-xs font-gilroy-medium">
          <span>OTP has been sent to your email address </span>
          <span className="text-pot-yellow">
            {email.replace(/(.{3})(.*)(@.*)/, "$1****$3")}
          </span>
        </div>
        <div
          onClick={() => {
            setCurrentStep(1);
            setEmail(email);
          }}
          className="cursor-pointer mx-2"
        >
          <SquareEditIcon width={16} />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate className="w-full">
        <Controller
          name="otp"
          control={control}
          render={({ field }) => (
            <>
              <div className="relative mb-[3vh]">
                <label className="text-start leading-6 text-[#111111] text-xs font-gilroy-semi-bold">
                  Enter OTP
                </label>
                <div className="flex justify-between items-center gap-2 mt-2">
                  {inputRefs.map((ref, index) => (
                    <input
                      key={index}
                      type="text"
                      inputMode="numeric"
                      maxLength={1}
                      className="w-full rounded-md h-10 lg:w-full xl:w-full lg:h-10  border border-[#4D4D4D]  text-center  outline-none text-md  text-black font-normal  hover:border-2 hover:border-[#A2A2A2] hover:border-opacity-50 focus:border-[#4D4D4D] placeholder:text-[#A2A2A2] placeholder:text-sm active:border-[#A2A2A2] active:border-2"
                      ref={ref}
                      value={number[index] || ""}
                      onChange={(event: any) => {
                        handleInput(index, event);
                        field.onChange(number.join(""));
                      }}
                      onKeyDown={(
                        event: React.KeyboardEvent<HTMLInputElement>
                      ) => handleBackspace(index, event)}
                    />
                  ))}
                </div>
              </div>

              <div className="flex justify-between mt-3 font-gilroy-medium">
                <p className="text-black text-sm ">
                  Didn’t receive?
                  <span
                    onClick={handleResendOTP}
                    className={` text-sm mx-1  ${
                      !expiryTime
                        ? " text-pot-yellow cursor-pointer"
                        : "cursor-not-allowed text-gray-400"
                    }`}
                  >
                    Resend OTP
                  </span>
                </p>
                <p className="text-pot-yellow">
                  {Math.floor(expiryTime / 60)}:
                  {expiryTime % 60 <= 9
                    ? "0" + (expiryTime % 60)
                    : expiryTime % 60}
                </p>
              </div>
            </>
          )}
        />

        <div className="w-full my-8">
          <Button
            variant="secondary"
            type="submit"
            label="Submit OTP"
            rightIcon={<HipchatChevronRightIcon2 />}
            fullWidth
            loading={loading || resendLoading}
            disabled={!isAllFieldsFilled || resendLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default StepTwo;
