import React from "react";
import LinkIcon from "../../../assets/svg-tsx/LinkIcon";
import { Link } from "react-router-dom";
import PlusIcon from "../../../assets/PlusIcon";
import { checkPermissions } from "../../../utils/helper";
import { Tooltip } from "antd";

const ProfessionalInfo = ({ data, setShowInviteModal }: any) => {
  // Permissions
  const hasEditPermission = checkPermissions("edit_project");

  return (
    <div className="w-full border-b">
      <p className="font-gilroy-semi-bold text-[12px] p-4">
        Professional Details
      </p>

      <div className="px-4 pb-4 flex flex-col gap-0">
        <p className="text-[#AEAEAE] font-gilroy-medium text-[12px] leading-[20px]">
          Primary Company
        </p>

        {data?.primary_company === null ? (
          <div className="flex flex-col gap-0 text-[12px]">
            <p className="rounded-md bg-[#EAEAEA] py-1 my-2 px-3 w-fit font-gilroy-regular">
              No Professional is added{" "}
            </p>
            <Tooltip
              title={
                !hasEditPermission &&
                "You don't have permission to perform this action"
              }
              className={`${!hasEditPermission && " opacity-30 "}`}
            >
              <button
                className="text-pot-yellow flex flex-row gap-2 items-center font-gilroy-medium cursor-pointer hover:underline"
                onClick={() => setShowInviteModal(true)}
                disabled={!hasEditPermission}
              >
                <span className="flex flex-row gap-2">
                  <PlusIcon width="16" />
                  Add Professional
                </span>
              </button>
            </Tooltip>
          </div>
        ) : (
          // <div className="flex flex-row items-center gap-2">
          <Link
            to={`/customers/professionals/company/${data?.primary_company?.customer_id}`}
          >
            <p className="underline text-pot-yellow font-gilroy-medium text-[12px] leading-[20px]">
              {data?.primary_company?.name}
            </p>
          </Link>
          // </div>
        )}
      </div>
    </div>
  );
};

export default ProfessionalInfo;
