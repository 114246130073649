import OrderDetailsDropDown from "../../components/OrderDetailsDropDown";
import React, { useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import UpandDownArrow from "../../../../../assets/svg/dateIcon.svg";
import calenderIcon from "../../../../../assets/svg/clenderIcon.svg";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import ProgressBar from "../../../../../components/Progressbar";
import Pagination from "../../../../../components/Pagination";
import { formatIndianRupeesForDecimals } from "../../../../../utils/formatToIndianRupees";
import OrderStatusTag from "../../../../../components/OrderStatusTag";
import PaymentStatusTag from "../../../../../components/PaymentStatusTag";

const orderData = [
  {
    orderId: "#123",
    date: "25/05/2023",
    numberOfItem: 700,
    totalAmount: 1000000,
    status: "Ongoing",
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  },
  {
    orderId: "#123",
    date: "25/05/2023",
    numberOfItem: 700,
    totalAmount: 1000000,
    status: "Complete",
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  },
  {
    orderId: "#123",
    date: "25/05/2023",
    numberOfItem: 700,
    totalAmount: 1000000,
    status: "Shipped",
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  },
  {
    orderId: "#123",
    date: "25/05/2023",
    numberOfItem: 500,
    totalAmount: 1500000,
    status: "Ongoing",
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  },
  {
    orderId: "#123",
    date: "25/05/2023",
    numberOfItem: 700,
    totalAmount: 1000000,
    status: "Cancel",
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  },
  {
    orderId: "#123",
    date: "25/05/2023",
    numberOfItem: 700,
    totalAmount: 1000000,
    status: "Return",
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  },
  {
    orderId: "#123",
    date: "25/05/2023",
    numberOfItem: 700,
    totalAmount: 1000000,
    status: "Pending",
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  },
];

const outgoingStepProcess = [
  {
    id: 0,
    status: true,
    step: "Disabled Step",
  },
  {
    id: 1,
    status: true,
    step: "Visited Step",
  },
  {
    id: 2,
    status: true,
    step: "Current Step",
  },
  {
    id: 3,
    status: false,
    step: "Unvisited Step",
  },
  {
    id: 4,
    status: false,
    step: "Unvisited Step",
  },
];

export default function OrderDetails({
  orderList,
  totalCount,
  pageLength,
  page,
  setPage,
  setPageLength,
}: any) {
  const handleAddOrder = () => {
    console.log("handleAddOrder");
  };
  return (
    <>
      <OrderDetailsDropDown
        label="Order Details"
        onClick={handleAddOrder}
        dropDown={
          <OrderSecondRow
            orderList={orderList}
            totalCount={totalCount}
            pageLength={pageLength}
            page={page}
            setPage={setPage}
            setPageLength={setPageLength}
          />
        }
      />
    </>
  );
}

const ongoingOrders = orderData.filter((order) => order.status === "Ongoing");
const OrderFirstRow = () => {
  return (
    <>
      <div>
        {ongoingOrders
          .filter((el, i) => i < 1)
          .map((el, i) => {
            return (
              <div
                key={i}
                className={`py-4 px-4 flex flex-col space-y-2 mb-10`}
              >
                <div className="flex justify-between ">
                  <div className="flex gap-7  font-medium leading-5 tracking-tighter">
                    <p className="text-[14px]">Order id: {el.orderId}</p>
                    <div className="flex items-center gap-3">
                      <img src={calenderIcon} alt="calender-icon" />
                      <p className="text-[#AEAEAE] text-[12px]">{el.time}</p>
                    </div>
                  </div>

                  <p className=" bg-[#CFF9FF] w-[80px] py-0.5  rounded-md text-[12px] font-medium leading-5 tracking-tighter">
                    {el.status}
                  </p>
                </div>

                <div className="flex flex-col space-y-2">
                  <div className="flex gap-4">
                    <p className="text-[#AEAEAE] text-[12px]">No. of items: </p>
                    <span className="text-[12px]">{el.numberOfItem}</span>
                  </div>
                  <div className="flex justify-between items-center ">
                    <div className="flex gap-2">
                      <p className="text-[#AEAEAE] text-[12px]">
                        Total amount:{" "}
                      </p>
                      <span className="text-[12px]">{el.totalAmount}</span>
                    </div>

                    <div className={`w-7/12 flex m-auto `}>
                      <ProgressBar data={outgoingStepProcess} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

// Second row Data
const OrderSecondRow = ({
  orderList,
  totalCount,
  pageLength,
  page,
  setPage,
  setPageLength,
}: any) => {
  const columns = useMemo(
    () => [
      {
        Header: () => (
          <>
            <span>Order id</span>
          </>
        ),
        accessor: "order_no",
        width: 100,
        Cell: ({ value, row }: any) => (
          <Link to={`/orders/${row.original.id}`}>
            <p className="underline">{row.original.order_no}</p>
          </Link>
        ),
      },
      {
        Header: () => (
          <div className="flex justify-start items-center gap-2 ">
            <p>Type</p>
          </div>
        ),
        accessor: "type",
        width: 90,
      },
      {
        Header: () => (
          <>
            <span>Partner</span>
          </>
        ),
        accessor: "partner",
        width: 110,
      },
      {
        Header: () => (
          <>
            <span>Date</span>
          </>
        ),
        accessor: "date",
        width: 110,
      },
      {
        Header: () => (
          <>
            <span>Total Items</span>
          </>
        ),
        accessor: "totalItems",
        width: 100,
        Cell: ({ value }: { value: any }) => {
          return <span>{value}</span>;
        },
      },
      {
        Header: () => (
          <>
            <span>Total Amount</span>
          </>
        ),
        accessor: "totalAmount",
        width: 120,
        Cell: ({ value }: { value: any }) => {
          return <span>{value}</span>;
        },
      },
      {
        Header: () => (
          <>
            <span>Payment Status</span>
          </>
        ),
        accessor: "paymentStatus",
        width: 120,
        Cell: ({ value }: { value: any }) => {
          return (
            <span>
              <PaymentStatusTag status={value} />
            </span>
          );
        },
      },
      {
        Header: () => (
          <>
            <span>Order Status</span>
          </>
        ),
        accessor: "orderStatus",
        width: 150,
        Cell: ({ value }: { value: any }) => {
          return (
            <span>
              <OrderStatusTag status={value} />
            </span>
          );
        },
      },
    ],
    []
  );

  const tableData = useMemo(() => {
    if (!orderList?.length) return [];

    return orderList.map((order: any) => ({
      id: order.id,
      order_no: order.order_no,
      type: order.owner.kind,
      partner: order.assigned_channel_partner?.name || "-",
      date: new Date(order.order_date).toLocaleDateString(),
      totalItems: order.line_item_count,
      totalAmount: formatIndianRupeesForDecimals(order.grand_total),
      paymentStatus: order.payment_status,
      orderStatus: order.status,
    }));
  }, [orderList]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: tableData,
        initialState: { pageIndex: page - 1, pageSize: pageLength },
        manualPagination: true,
        pageCount: Math.ceil(totalCount / pageLength),
      },
      useSortBy,
      usePagination
    );

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageLength(size);
  };
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ stiffness: 60, duration: 0.2 }}
        exit={{ opacity: 0 }}
        className="overflow-auto custom-scrollbar"
      >
        <table
          {...getTableProps()}
          className="w-full border-x border-y overflow-x-auto"
        >
          <thead>
            {headerGroups.map((headerGroup: any, headerIndex: number) => (
              <tr
                key={headerIndex}
                {...headerGroup.getHeaderGroupProps()}
                className="bg-[#F9FAFB] border-y"
              >
                {headerGroup.headers.map((column: any, columnIndex: number) => (
                  <th
                    key={columnIndex}
                    {...column.getHeaderProps()}
                    style={{ width: column.width }}
                    className="py-2 border-x px-4 text-left text-sm font-medium text-gray-900"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="bg-white">
            {rows.map((row: any, rowIndex: number) => {
              prepareRow(row);
              return (
                <tr
                  key={rowIndex}
                  {...row.getRowProps()}
                  className="border-y border hover:bg-gray-50"
                >
                  {row.cells.map((cell: any, cellIndex: number) => (
                    <td
                      key={cellIndex}
                      {...cell.getCellProps()}
                      className="border-x py-2 px-4 text-sm text-gray-900"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </motion.div>
      {orderList?.length !== 0 && (
        <div className="flex justify-end w-full gap-4 px-3 py-2">
          <Pagination
            currentPage={page}
            pageSize={pageLength}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}
    </>
  );
};
