import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { protectedAxiosInstance } from "../../api/axiosManagement";
import { useRecoilValue } from "recoil";
import { stateAtom } from "../../atom";
import { toast } from "react-toastify";
import Modal from "../../components/common/Modal";
import Button from "../../components/Button";
import InputField from "../../components/InputField";
import Label from "../../components/Label";
import TrashIcon from "../../assets/TrashIcon";
import PlusIcon from "../../assets/PlusIcon";
import SelectField2 from "../../components/SelectedField2";
import TextArea from "../../components/TextArea";
import ProjectFileUpload from "./components/ProjectFileUpload";
import ProjectMediaUpload from "./components/ProjectMediaUpload";
import { checkPermissions } from "../../utils/helper";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CreateEditProject = () => {
  const { id: projectId } = useParams();
  const query = useQuery();
  const addOtherFiles = query.get("add-other-files");
  const addMedia = query.get("add-media");
  const media_files_ref = useRef<any>(null);
  const document_files_ref = useRef<any>(null);

  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [pageLoading, setPageLoading] = useState(false);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const stateList = useRecoilValue<any>(stateAtom);

  const [openFloorModal, setOpenFloorModal] = useState<any>(null);
  const [pendingFloorData, setPendingFloorData] = useState<any>(null);
  const location = useLocation();

  // Permissions
  const hasEditPermission = projectId
    ? checkPermissions("edit_project", true, toast)
    : false;
  const hasCreatePermission =
    !projectId && checkPermissions("create_project", true, toast);
  const hasViewPermission = projectId ? checkPermissions("view_project") : true;

  // Permission-based navigation
  useEffect(() => {
    // For create page
    if (!projectId && !hasCreatePermission) {
      navigate("/projects");
      return;
    }

    // For edit/view page
    if (projectId) {
      // If trying to access edit URL without edit permission, redirect
      if (!hasEditPermission || !hasViewPermission) {
        navigate("/projects");
        return;
      }
    }
  }, [projectId, hasCreatePermission, hasEditPermission, hasViewPermission]);

  const handleGoBack = () => {
    if (projectId) {
      // navigate(`/projects/${projectId}`);
      navigate(-1);
    } else {
      navigate("/projects");
    }
  };

  const fetchProjectData = async () => {
    try {
      setPageLoading(true);
      const response: any = await protectedAxiosInstance.get(
        `/admin/projects/${projectId}`
      );
      const data = response?.data?.data?.project;

      const {
        created_at,
        customer_id,
        customer_invite_phone,
        customer_invite_at,
        customer_invite_sent_on,
        customer_invite_status,
        customer_name,
        is_public,
        id,
        members,
        modified_at,
        role_features,
        primary_company,
        ...filteredData
      } = data;

      filteredData.media_files.sort((a: any, b: any) => {
        // Handle cases where order might be null or undefined
        if (a.order === null || a.order === undefined) return 1;
        if (b.order === null || b.order === undefined) return -1;
        return a.order - b.order;
      });

      filteredData.address_state = stateList.find(
        (el: any) => el.state_code === data?.address_state
      )?.name;

      setFormData(filteredData);
    } catch (error: any) {
      console.error("Error fetching project data:", error);
      if (error?.status === 404) {
        toast.error(`The project you are trying to access does not exist.`);
        navigate(`/projects`);
      }
      // Handle the error, such as displaying a message to the user
    } finally {
      setPageLoading(false);
    }
  };
  useEffect(() => {
    if (projectId && stateList) {
      fetchProjectData();
    }
  }, [projectId, stateList]);

  // project location

  const [locationList, setLocationList] = useState<any>([]);
  const [locationPage, setLocationPage] = useState(1);
  const [locationTotalPage, setLocationTotalPage] = useState(0);
  const [locationSearch, setLocationSearch] = useState("");

  // Effect for handling locationSearch changes
  const itemsPerPage = 10;

  useEffect(() => {
    setLocationPage(1);
  }, [locationSearch]);

  useEffect(() => {
    const allCities =
      stateList
        ?.map((state: any) => {
          return state?.cities?.map((city: any) => ({
            value: `${city}, ${state.name}, ${state.state_code}`,
            label: `${city}, ${state.name}`,
          }));
        })
        .flat(1) || [];

    const filteredCities = allCities.filter((city: any) =>
      `${city.value} ${city.label}`
        .toLowerCase()
        .includes(locationSearch.toLowerCase())
    );

    const totalPage = Math.ceil(filteredCities.length / itemsPerPage);
    setLocationTotalPage(totalPage);

    if (locationPage === 1) {
      // Reset locationList if page is 1
      setLocationList(filteredCities.slice(0, itemsPerPage));
    } else {
      // Append to locationList if page increases
      const startIndex = (locationPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedList = [
        ...(locationList || []), // Preserve existing items
        ...filteredCities.slice(startIndex, endIndex),
      ];
      setLocationList(paginatedList);
    }
  }, [stateList, locationPage, locationSearch]);

  // to add an area input box in add an area in floor model
  const handleAddArea = () => {
    const newArea = { name: "", added: true };
    const toDeleteIndex = pendingFloorData.areas.findIndex(
      (area: any) => area.to_delete === true
    );
    const newIndex =
      toDeleteIndex !== -1
        ? toDeleteIndex
        : pendingFloorData.areas?.length || 0;

    const updatedAreas = [
      ...pendingFloorData.areas.slice(0, newIndex), // Elements before the insert index
      newArea, // New area inserted at the insert index
      ...pendingFloorData.areas.slice(newIndex), // Elements after the insert index
    ];

    const updatedData = {
      ...pendingFloorData,
      areas: updatedAreas,
    };

    setPendingFloorData(updatedData);
  };

  useEffect(() => {
    if (pendingFloorData?.areas?.length === 0) {
      setPendingFloorData((prev: any) => ({
        ...prev,
        areas: [{ name: "" }],
      }));
    }
  }, [pendingFloorData]);

  const handleSave = async () => {
    setErrors({});

    // Filter out channel_partner from formData if it exists
    const { channel_partners, ...restFormData } = formData;

    const updatedFormData = {
      ...restFormData,
      media_files: formData.media_files?.map(
        ({ file_size, added, created_at, download_url, ...rest }: any) => rest
      ),
      document_files: formData.document_files?.map(
        ({ added, file_size, created_at, download_url, ...rest }: any) => rest
      ),
      floors: formData?.floors?.map(
        ({ areas, added, ...floorRest }: any, index: any) => ({
          ...floorRest,
          sequence: index + 1,
          areas: areas.map(({ added, ...areaRest }: any, index: any) => ({
            ...areaRest,
            sequence: index + 1,
          })),
        })
      ),
      address_state: stateList.find(
        (state: any) => state.name === formData?.address_state
      )?.state_code,
    };

    setPageLoading(true);
    if (projectId) {
      try {
        const response: any = await protectedAxiosInstance.put(
          `/admin/projects/${projectId}`,
          updatedFormData
        );
        // await fetchProjectData();
        toast.success("Project updated successfully.");
        navigate(`/projects/${response?.data?.data?.project?.id}`);
      } catch (error: any) {
        setErrors(error?.data?.errors);
        toast.error("Project update failed.");
      } finally {
        setPageLoading(false);
      }
    } else {
      try {
        const response: any = await protectedAxiosInstance.post(
          "/admin/projects",
          updatedFormData
        );

        toast.success("Project created successfully.");

        // Check if this is a popup window
        const isPopup = query.get("window_popup");
        if (isPopup) {
          // Notify parent window and close
          window.opener?.postMessage(
            {
              type: "PROJECT_CREATED",
              projectId: response?.data?.data?.project?.id,
            },
            "*"
          );
        } else {
          navigate(`/projects/${response?.data?.data?.project?.id}`);
        }
      } catch (error: any) {
        setErrors(error?.data?.errors);
        toast.error("Project creation failed.");
      } finally {
        setPageLoading(false);
      }
    }
  };

  useEffect(() => {
    const areaCount = pendingFloorData?.areas?.filter(
      (el: any) => !el.to_delete
    )?.length;
    if (areaCount === 0) {
      handleAddArea();
    }
  }, [pendingFloorData]);

  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (Object.keys(formData)?.length > 2 && initialRender) {
      if (addMedia || addMedia === "true") {
        media_files_ref.current.scrollIntoView({ behavior: "smooth" });
      } else if (addOtherFiles || addOtherFiles === "true") {
        document_files_ref.current.scrollIntoView({
          behavior: "smooth",
        });
      }
      setInitialRender(false);
    }
  }, [addMedia, addOtherFiles, formData, initialRender]);

  return (
    <div className="h-full overflow-y-auto  custom-scrollbar">
      {/* new floor modal */}
      {typeof openFloorModal === "number" && !pageLoading && (
        <Modal
          header={openFloorModal >= 0 ? "Edit Floor:" : "Add New Floor:"}
          handleCancel={() => {
            setOpenFloorModal(null);
            setPendingFloorData(null);
          }}
          allowScroll
          footer={
            <div className="flex gap-4">
              <Button
                label="Save"
                variant="primary"
                onClick={() => {
                  const updatedFloorData = { ...pendingFloorData };

                  if (!updatedFloorData?.name) {
                    toast.error("Floor name cannot be blank.");
                    return;
                  }
                  updatedFloorData.areas = updatedFloorData.areas
                    .map((el: any) => {
                      if (!el.name && !el.added) {
                        el.to_delete = true;
                        el.name = "#DELETED";
                        return el;
                      }
                      return el;
                    })
                    .filter((el: any) => {
                      if (el.added && !el.name) {
                        // If added is true but name is empty, remove it
                        return false; // Filter out
                      }
                      return el;
                    });

                  // Move areas marked for deletion to the end of the areas array
                  const deletedAreas = updatedFloorData.areas.filter(
                    (el: any) => el.to_delete
                  );
                  const remainingAreas = updatedFloorData.areas.filter(
                    (el: any) => !el.to_delete
                  );
                  updatedFloorData.areas = [...remainingAreas, ...deletedAreas];

                  setPendingFloorData(updatedFloorData);

                  if (openFloorModal >= 0) {
                    setFormData((prev: any) => ({
                      ...prev,
                      floors: prev.floors.map((floor: any, index: number) =>
                        index === openFloorModal ? updatedFloorData : floor
                      ),
                    }));
                  } else {
                    const insertIndex = formData?.floors?.findIndex(
                      (floor: any) => floor?.to_delete === true
                    );
                    const newIndex =
                      insertIndex !== -1
                        ? insertIndex
                        : formData.floors?.length || 0;

                    setFormData((prev: any) => {
                      const floors = prev?.floors ?? []; // Use an empty array if prev.floors is null or undefined
                      return {
                        ...prev,
                        floors: [
                          ...floors.slice(0, newIndex), // Elements before the insert index
                          updatedFloorData, // Pending floor data inserted at the insert index
                          ...floors.slice(newIndex), // Elements after the insert index
                        ],
                      };
                    });
                  }
                  setOpenFloorModal(null);
                  setPendingFloorData(null);
                }}
              />
              <Button
                label="Cancel"
                variant="secondary-outline"
                onClick={() => {
                  setOpenFloorModal(null);
                  setPendingFloorData(null);
                }}
              />
            </div>
          }
        >
          <div className="space-y-4 mb-4 w-full">
            <InputField
              onChange={(e) => {
                setPendingFloorData((prev: any) => ({
                  ...prev,
                  name: e,
                }));
              }}
              label="Enter Floor Name"
              placeholder=""
              value={pendingFloorData?.name}
            />

            <div>
              <Label label="Enter Area Name" />
              <div className="flex flex-col gap-2">
                {pendingFloorData?.areas
                  ?.filter((el: any) => !el?.to_delete)
                  ?.map((el: any, index: any) => (
                    <div key={index} className="flex ">
                      <InputField
                        value={el?.name}
                        onChange={(value) => {
                          const updatedData = { ...pendingFloorData };
                          if (!updatedData.areas) {
                            updatedData.areas = [];
                          }
                          updatedData.areas[index] = {
                            ...updatedData.areas[index],
                            name: value,
                          };
                          setPendingFloorData(updatedData);
                        }}
                        placeholder=""
                      />
                      <button
                        onClick={() => {
                          if (el?.added) {
                            setPendingFloorData((prev: any) => ({
                              ...prev,
                              areas: prev.areas.filter(
                                (_: any, i: any) => i !== index
                              ),
                            }));
                          } else {
                            const removedArea = pendingFloorData.areas[index]; // Save the area to be removed

                            setPendingFloorData((prev: any) => ({
                              ...prev,
                              areas: [
                                ...prev.areas.filter(
                                  (_: any, i: any) => i !== index
                                ), // Remove the area from its current index
                                {
                                  ...removedArea,
                                  to_delete: true,
                                },
                              ],
                            }));
                          }
                        }}
                        className="px-2"
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  ))}
              </div>
              <Button
                // className="my-2"
                label="Add an area"
                variant="secondary-text"
                leftIcon={<PlusIcon />}
                onClick={handleAddArea}
              />
            </div>
          </div>
        </Modal>
      )}

      {/* Heading */}

      <div className="bg-white border-b border-[#ECECEC] w-full pt-4 pb-4  pl-4">
        <h2 className="font-semibold">
          {projectId ? "Edit" : "Create new"} Project
        </h2>
      </div>

      <div className="overflow-y-auto custom-scrollbar h-[calc(100%-150px)] pl-4">
        <div className="flex flex-col divide-y-2 gap-4   pr-5 md:pr-14 lg:pr-0 lg:w-[70%] lg:max-w-[50vw] ">
          {/* Project details */}
          <div className="space-y-4  pt-4">
            <InputField
              label="Project Name"
              placeholder="Project Name"
              onChange={(e) => {
                setFormData((prev: any) => ({ ...prev, name: e }));
                setErrors((prev: any) => ({ ...prev, name: null }));
              }}
              required
              value={formData?.name}
              maxLength={70}
              hint={errors?.name}
              disabled={pageLoading}
            />

            <SelectField2
              searchPlaceHolder="Search city or state here"
              // valueFont={"text-[12px] md:text-[13px]"}
              disabled={pageLoading}
              totalPage={locationTotalPage}
              page={locationPage || 1}
              setPage={setLocationPage}
              options={locationList}
              externalSearch={locationSearch}
              externalSetSearch={setLocationSearch}
              value={
                formData?.address_city && formData?.address_state
                  ? `${formData?.address_city}, ${formData?.address_state}`
                  : ""
              }
              label="Project Location"
              onChange={(e: any) => {
                const string = e?.split(", ");
                setFormData((prev: any) => ({
                  ...prev,
                  address_city: string?.[0],
                  address_state: string?.[1],
                }));
                setErrors((prev: any) => ({
                  ...prev,
                  address_city: null,
                  address_state: null,
                }));
              }}
              removeOption={() => {
                setFormData((prev: any) => ({
                  ...prev,
                  address_city: null,
                  address_state: null,
                }));
                setErrors((prev: any) => ({
                  ...prev,
                  address_city: null,
                  address_state: null,
                }));
              }}
              hint={errors?.address_city || errors?.address_state}
            />

            <TextArea
              label="Project Description"
              value={formData?.description || ""}
              disabled={pageLoading}
              placeholder="Write something about project"
              onChange={(e) => {
                setFormData((prev: any) => ({
                  ...prev,
                  description: e,
                }));
                setErrors((prev: any) => ({
                  ...prev,
                  description: null,
                }));
              }}
              rows={4}
              hint={errors?.description}
            />

            <div>
              <Label label="Add Floors & Areas" />
              <div className="flex flex-col md:flex-row gap-2 md:gap-4 w-[200px]">
                <Button
                  label="Add a Floor"
                  disabled={pageLoading}
                  variant="secondary"
                  fullWidth
                  onClick={() => {
                    setOpenFloorModal(-1);
                    setPendingFloorData({
                      name: "",
                      added: true,
                      areas: [
                        {
                          name: "",
                          added: true,
                        },
                      ],
                    });
                  }}
                />
              </div>

              <div className="space-y-4 pt-4 text-[#1C1C1C]">
                {formData?.floors
                  ?.filter((floor: any) => !floor.to_delete)
                  ?.map((floor: any, floorIndex: any) => (
                    <div
                      key={floorIndex}
                      className="border-2 border-c-gray-2 p-4"
                    >
                      <div>
                        <div className="flex justify-between">
                          <p className="font-gilroy-semi-bold font-bold text-sm">
                            {floorIndex + 1}. {floor?.name?.toUpperCase()}
                          </p>
                          <div className="space-x-3 flex items-center ">
                            <button
                              disabled={pageLoading}
                              onClick={() => {
                                setOpenFloorModal(floorIndex);
                                setPendingFloorData(floor);
                              }}
                            >
                              {/* <PenIcon /> */}
                            </button>
                            <button
                              disabled={pageLoading}
                              onClick={() => {
                                if (floor?.added) {
                                  setFormData((prev: any) => ({
                                    ...prev,
                                    floors: prev.floors.filter(
                                      (_: any, i: any) => i !== floorIndex
                                    ),
                                  }));
                                } else {
                                  const removedFloor =
                                    formData.floors[floorIndex]; // Save the floor to be removed

                                  setFormData((prev: any) => ({
                                    ...prev,
                                    floors: [
                                      ...prev.floors.filter(
                                        (_: any, i: any) => i !== floorIndex
                                      ), // Remove the floor from its current index
                                      {
                                        ...removedFloor,
                                        to_delete: true,
                                        areas: removedFloor.areas.map(
                                          (area: any) => ({
                                            ...area,
                                            to_delete: true,
                                          })
                                        ),
                                      },
                                    ],
                                  }));
                                }
                              }}
                            >
                              <TrashIcon />
                            </button>
                          </div>
                        </div>
                        <div className="flex flex-wrap pt-4 gap-4">
                          {floor?.areas
                            ?.filter((el: any) => !el?.to_delete)
                            ?.map((area: any, areaIndex: any) => (
                              <div
                                key={areaIndex}
                                className="font-gilroy-medium text-xs border-2 border-c-gray-2 p-3"
                              >
                                {area?.name}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/* Project Files */}
          <div className="">
            <p className=" text-base xl:text-lg  my-4 font-gilroy-bold">
              Project Files
            </p>

            <div className="space-y-4">
              <ProjectMediaUpload
                targetRef={media_files_ref}
                label="Upload Photos, Videos"
                disabled={pageLoading}
                isMulti
                kind="PROJECT"
                setter={(values: any) => {
                  setFormData((prev: any) => ({
                    ...prev,
                    media_files: values,
                  }));
                }}
                imageList={formData?.media_files || []}
                errors={errors?.media_files}
                removeError={() => {
                  if (errors.media_files) delete errors?.media_files;
                  setErrors(structuredClone(errors));
                }}
                setMediaLoading={setMediaLoading}
              />

              <ProjectFileUpload
                targetRef={document_files_ref}
                label="Upload Internal Files"
                disabled={pageLoading}
                isMulti
                kind="PROJECT"
                setter={(values: any) => {
                  setFormData((prev: any) => ({
                    ...prev,
                    document_files: values,
                  }));
                }}
                imageList={formData?.document_files || []}
                errors={errors?.document_files}
                removeError={() => {
                  if (errors.document_files) delete errors?.document_files;
                  setErrors(structuredClone(errors));
                }}
                setMediaLoading={setFileLoading}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-x-4 pt-4 pl-4 border-t  border-[#ECECEC]">
        <Button
          label={`${projectId ? "Update" : "Create"} Project`}
          variant="primary"
          onClick={handleSave}
          disabled={pageLoading || mediaLoading || fileLoading}
        />
        <Button
          label={"Cancel"}
          variant="secondary-outline"
          onClick={() => handleGoBack()}
          disabled={pageLoading || mediaLoading || fileLoading}
        />
      </div>
    </div>
  );
};

export default CreateEditProject;
