import React from "react";
import PdfIcon from "../../assets/PdfIcon";
import FileIcon from "../../assets/FileIcon";
import EyeIcon from "../../assets/svg-tsx/EyeIcon";
import DownloadIcon from "../../assets/DownloadIcon";
import EyeIcon2 from "../../assets/svg-tsx/EyeIcon2";
import TrashIcon from "../../assets/TrashIcon";
import { toast } from "react-toastify";

const formatDateString = (dateString: string): string => {
  const date = new Date(dateString);

  // Get day with suffix (1st, 2nd, 3rd, 4th, etc.)
  const day = date.getDate();
  const suffix = getDaySuffix(day);

  // Get month name and year
  const month = date.toLocaleString("en-US", { month: "long" });
  const year = date.getFullYear().toString().slice(-2);

  return `${day}${suffix} ${month} ${year}`;
};

// Helper function to get day suffix
const getDaySuffix = (day: number): string => {
  if (day >= 11 && day <= 13) return "th";

  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const formatFileSize = (sizeInKB: number = 0): string => {
  if (sizeInKB >= 1024) {
    return `${(sizeInKB / 1024).toFixed(2)} MB`;
  }
  return `${sizeInKB} KB`;
};

type UploadedFileViewProps = {
  files: any;
  showDelete?: boolean;
  handleRemoveFile?: (file: any) => void;
};

const UploadedFileView = ({
  files,
  showDelete = true,
  handleRemoveFile,
}: UploadedFileViewProps) => {
  const handleDownload = async (
    fileUrl: string,
    filename: string,
    fileId: string
  ) => {
    try {
      // Create temporary link
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = filename;

      // Programmatically click the link
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading file");
    }
  };

  const handleView = (file: any) => {
    try {
      const fileUrl = file?.added ? file?.download_url : file?.file;
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error viewing file:", error);
      toast.error("Error viewing file");
    }
  };

  // const handleView = (file: any) => {
  //   try {
  //     const fileUrl =
  //       "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
  //     // const fileUrl =
  //     //   "https://plus.unsplash.com/premium_photo-1691938884993-cd87199d807b?q=80&w=2028&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
  //     window.open(fileUrl, "_blank");
  //   } catch {
  //     toast.error("Error viewing file");
  //   }
  // };

  return (
    <div className="flex flex-row gap-7">
      {files?.length === 0 && (
        <div className="text-gray-500 text-sm">No files uploaded</div>
      )}
      {files.map((each: any, docIndex: number) => (
        <div
          key={docIndex}
          className="h-[19vh] w-[10rem] flex flex-col gap-y-2 pb-2 overflow-hidden border"
        >
          {each?.filename.endsWith(".pdf") || each?.added ? (
            <>
              <div className="w-full h-full bg-pot-grey4/70 flex justify-center items-center pt-[3vh] relative">
                {each.filename.endsWith(".pdf") && <PdfIcon />}
                <div className="absolute top-3 right-3 z-10 w-[30%] h-fit">
                  <div className="flex flex-row items-center justify-center gap-2">
                    {showDelete && (
                      <button
                        type="button"
                        onClick={() => {
                          handleRemoveFile?.(docIndex);
                        }}
                      >
                        <TrashIcon color="white" width="11" height="12" />
                      </button>
                    )}
                    {!each?.added && (
                      <button
                        type="button"
                        onClick={() =>
                          handleDownload(
                            each?.added ? each?.download_url : each?.file,
                            each?.filename,
                            each?.id
                          )
                        }
                      >
                        <DownloadIcon color="white" width="13" height="14" />
                      </button>
                    )}

                    {!each?.added && (
                      <button type="button" onClick={() => handleView(each)}>
                        <EyeIcon2 />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="w-[10rem] relative overflow-hidden h-[19vh]">
              <img
                src={each?.added ? each?.download_url : each?.file}
                alt="image"
                className="w-fit h-fit object-cover bg-blend-overlay hover:bg-blend-darken"
              />
              <div className=" absolute top-0 left-0 z-10 bg-pot-grey4/70 hover:bg-pot-grey4/20 transition ease-in-out w-full h-full"></div>
              <div className="absolute top-3 right-3 z-10  w-[30%] h-fit">
                <div className="flex flex-row items-center justify-center gap-2">
                  {showDelete && (
                    <button
                      type="button"
                      onClick={() => {
                        handleRemoveFile?.(docIndex);
                      }}
                    >
                      <TrashIcon color="white" width="11" height="12" />
                    </button>
                  )}
                  {!each?.added && (
                    <button
                      type="button"
                      onClick={() =>
                        handleDownload(
                          each?.added ? each?.download_url : each?.file,
                          each?.filename,
                          each?.id
                        )
                      }
                    >
                      <DownloadIcon color="white" width="13" height="14" />
                    </button>
                  )}

                  {!each?.added && (
                    <button type="button" onClick={() => handleView(each)}>
                      <EyeIcon2 />
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="flex gap-x-1 px-2">
            <FileIcon />
            <div className="overflow-hidden">
              <p className="text-xs font-gilroy-medium font-normal leading-4 text-left truncate">
                {each?.filename}
              </p>

              <p className="text-[8px] font-gilroy-regular leading-2 font-normal text-left">
                {each?.file_size && formatFileSize(each?.file_size)}{" "}
                {each?.file_size && each?.created_at && "|"}{" "}
                {each?.created_at && formatDateString(each?.created_at)}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UploadedFileView;
