import React, { useEffect, useState } from "react";
import Filters from "./components/Filters";
import WidgetCard from "./components/WidgetCard";
import Divider from "./components/Divider";
import ChartWrapper from "./components/ChartWrapper";
import LineChartComponent from "./components/LineChartComponent";
import HorizontalBarChartComponent from "./components/HorizontalBarChartComponent";
import PieChartComponent from "./components/PieChartComponent";
import VerticalBarChartComponent from "./components/VerticalBarChartComponent";
import DonutChartComponent from "./components/DonutChartComponent";
import { postCharts } from "../../api/dashboard";
import {
  formatDateToYYYYMMDD,
  getCurrentMonthDateRange,
} from "../../utils/helper";

const firstTimeVsReturningCustomersData = {
  name: "First-time vs Returning Customers",
  values: [
    {
      name: "First-time",
      value: 1000,
    },
    {
      name: "Returning",
      value: 2000,
    },
  ],
};

export default function OrderAnalytics() {
  const [channelPartner, setChannelPartner] = useState<any>(null);

  //  State for date range
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [appliedStartDate, setAppliedStartDate] = useState<any>(null);
  const [appliedEndDate, setAppliedEndDate] = useState<any>(null);

  const formattedStartDate = appliedStartDate
    ? formatDateToYYYYMMDD(appliedStartDate)
    : "";
  const formattedEndDate = appliedEndDate
    ? formatDateToYYYYMMDD(appliedEndDate)
    : "";

  // Add handlers for DateRangePicker
  const handleDateApply = (start: Date | null, end: Date | null) => {
    setAppliedStartDate(start);
    setAppliedEndDate(end);
  };

  const handleDateClear = () => {
    setStartDate(null);
    setEndDate(null);
    setAppliedStartDate(null);
    setAppliedEndDate(null);
  };

  //Order Overview states
  const [orderOverviewData, setOrderOverviewData] = useState([]);
  const [orderOverviewLoading, setOrderOverviewLoading] = useState(false);

  //States for Sales by Location
  const [salesByLocationData, setSalesByLocationData] = useState<any>([]);
  const [salesByLocationLoading, setSalesByLocationLoading] = useState(false);

  //States for Order Type
  const [orderTypeData, setOrderTypeData] = useState<any>([]);
  const [orderTypeLoading, setOrderTypeLoading] = useState(false);

  //States for Quotation Conversion Count Over Time
  const [
    quotationConversionCountOverTimeData,
    setQuotationConversionCountOverTimeData,
  ] = useState<any>([]);
  const [
    quotationConversionCountOverTimeLoading,
    setQuotationConversionCountOverTimeLoading,
  ] = useState(false);

  //States for Quotation Count Over Time
  const [quotationCountOverTimeData, setQuotationCountOverTimeData] =
    useState<any>([]);
  const [quotationCountOverTimeLoading, setQuotationCountOverTimeLoading] =
    useState(false);

  //States for Most Quoted Products
  const [mostQuotedProductsData, setMostQuotedProductsData] = useState<any>([]);
  const [mostQuotedProductsLoading, setMostQuotedProductsLoading] =
    useState(false);

  //States for Most Ordered Products
  const [mostOrderProductsData, setMostOrderProductsData] = useState<any>([]);
  const [mostOrderProductsLoading, setMostOrderProductsLoading] =
    useState(false);

  //States for conversion by type
  const [conversionByTypeData, setConversionByTypeData] = useState<any>([]);
  const [conversionByTypeLoading, setConversionByTypeLoading] = useState(false);

  //Total Sales states
  const [totalSalesData, setTotalSalesData] = useState([]);
  const [totalSalesLoading, setTotalSalesLoading] = useState(false);

  // Helper function to get value from orderOverviewData
  const getValueFromOrderOverview = (name: any) => {
    const data: any = orderOverviewData?.find(
      (item: any) => item.name === name
    );
    return data?.values[0]?.value || 0;
  };

  // Order By Status
  const [orderByStatusData, setOrderByStatusData] = useState<any>([]);
  const [orderByStatusLoading, setOrderByStatusLoading] =
    useState<boolean>(false);

  useEffect(() => {
    getOrderTypeData();
    getTotalSalesData();
    getOrderOverviewData();
    getOrderByStatusData();
    getSalesByLocationData();
    getConversionByTypeData();
    getMostOrderProductsData();
    getMostQuotedProductsData();
    getQuotationCountOverTimeData();
    getQuotationConversionCountOverTimeData();
  }, [channelPartner, appliedStartDate, appliedEndDate]);

  const formattedDateRange =
    formattedStartDate && formattedEndDate
      ? `${formattedStartDate} : ${formattedEndDate}`
      : getCurrentMonthDateRange();

  // Function to get order by status data
  const getOrderByStatusData = async () => {
    const payload = {
      chart_name: "order_by_status",
      filter: {
        date_range: formattedDateRange,
        channel_partner_id: channelPartner,
      },
    };

    try {
      setOrderByStatusLoading(true);
      const res = await postCharts(payload);
      setOrderByStatusData(res?.data?.chart_data?.[0]?.values);
      setOrderByStatusLoading(false);
    } catch (e: any) {
      console.log(e);
      setOrderByStatusLoading(false);
    } finally {
      setOrderByStatusLoading(false);
    }
  };

  // Function to get order overview data
  const getOrderOverviewData = async () => {
    const payload = {
      chart_name: "order_overview",
      filter: {
        date_range: formattedDateRange,
        channel_partner_id: channelPartner,
      },
    };

    try {
      setOrderOverviewLoading(true);
      const res = await postCharts(payload);
      setOrderOverviewData(res?.data?.chart_data);
      setOrderOverviewLoading(false);
    } catch (e: any) {
      console.log(e);
      setOrderOverviewLoading(false);
    } finally {
      setOrderOverviewLoading(false);
    }
  };

  // Function to get total sales data
  const getTotalSalesData = async () => {
    const payload = {
      chart_name: "order_value_trend",
      filter: {
        date_range: formattedDateRange,
        channel_partner_id: channelPartner,
      },
    };

    try {
      setTotalSalesLoading(true);
      const res = await postCharts(payload);
      setTotalSalesData(res?.data?.chart_data);
      setTotalSalesLoading(false);
    } catch (e: any) {
      console.log(e);
      setTotalSalesLoading(false);
    } finally {
      setTotalSalesLoading(false);
    }
  };

  // Function to get data for sales by location
  const getSalesByLocationData = async () => {
    const payload = {
      chart_name: "order_value_by_location",
      filter: {
        date_range: formattedDateRange,
        channel_partner_id: channelPartner,
      },
    };

    try {
      setSalesByLocationLoading(true);
      const res = await postCharts(payload);
      setSalesByLocationData(res?.data?.chart_data?.[0]?.values);
      setSalesByLocationLoading(false);
    } catch (e: any) {
      console.log(e);
      setSalesByLocationLoading(false);
    } finally {
      setSalesByLocationLoading(false);
    }
  };

  // Function to get data by order type
  const getOrderTypeData = async () => {
    const payload = {
      chart_name: "order_owner",
      filter: {
        date_range: formattedDateRange,
        channel_partner_id: channelPartner,
      },
    };

    try {
      setOrderTypeLoading(true);
      const res = await postCharts(payload);
      setOrderTypeData(res?.data?.chart_data?.[0]?.values);
      setOrderTypeLoading(false);
    } catch (e: any) {
      console.log(e);
      setOrderTypeLoading(false);
    } finally {
      setOrderTypeLoading(false);
    }
  };

  // Function to get data by quotation count over time
  const getQuotationConversionCountOverTimeData = async () => {
    const payload = {
      chart_name: "quotation_conversion_trend",
      filter: {
        date_range: formattedDateRange,
        channel_partner_id: channelPartner,
      },
    };

    try {
      setQuotationConversionCountOverTimeLoading(true);
      const res = await postCharts(payload);
      setQuotationConversionCountOverTimeData(res?.data?.chart_data);
      setQuotationConversionCountOverTimeLoading(false);
    } catch (e: any) {
      console.log(e);
      setQuotationConversionCountOverTimeLoading(false);
    } finally {
      setQuotationConversionCountOverTimeLoading(false);
    }
  };

  // Function to get data by quotation count over time
  const getQuotationCountOverTimeData = async () => {
    const payload = {
      chart_name: "quotation_count_trend",
      filter: {
        date_range: formattedDateRange,
        channel_partner_id: channelPartner,
      },
    };

    try {
      setQuotationCountOverTimeLoading(true);
      const res = await postCharts(payload);
      setQuotationCountOverTimeData(res?.data?.chart_data);
      setQuotationCountOverTimeLoading(false);
    } catch (e: any) {
      console.log(e);
      setQuotationCountOverTimeLoading(false);
    } finally {
      setQuotationCountOverTimeLoading(false);
    }
  };

  // Function to get Most Quoted Products
  const getMostQuotedProductsData = async () => {
    const payload = {
      chart_name: "product_quote_count",
      filter: {
        date_range: formattedDateRange,
      },
    };

    try {
      setMostQuotedProductsLoading(true);
      const res = await postCharts(payload);
      setMostQuotedProductsData(res?.data?.chart_data[0]?.values);
      setMostQuotedProductsLoading(false);
    } catch (e: any) {
      console.log(e);
      setMostQuotedProductsLoading(false);
    } finally {
      setMostQuotedProductsLoading(false);
    }
  };

  // Function to get Most Quoted Products
  const getMostOrderProductsData = async () => {
    const payload = {
      chart_name: "product_sale_count",
      filter: {
        date_range: formattedDateRange,
      },
    };

    try {
      setMostOrderProductsLoading(true);
      const res = await postCharts(payload);
      setMostOrderProductsData(res?.data?.chart_data[0]?.values);
      setMostOrderProductsLoading(false);
    } catch (e: any) {
      console.log(e);
      setMostOrderProductsLoading(false);
    } finally {
      setMostOrderProductsLoading(false);
    }
  };

  // Function to get conversion by type data
  const getConversionByTypeData = async () => {
    const payload = {
      chart_name: "quotation_conversion_by_type",
      filter: {
        date_range: formattedDateRange,
      },
    };

    try {
      setConversionByTypeLoading(true);
      const res = await postCharts(payload);
      setConversionByTypeData(res?.data?.chart_data[0]?.values);
      setConversionByTypeLoading(false);
    } catch (e: any) {
      console.log(e);
      setConversionByTypeLoading(false);
    } finally {
      setConversionByTypeLoading(false);
    }
  };

  return (
    <div className="w-full">
      <Filters
        setChannelPartner={setChannelPartner}
        channelPartner={channelPartner}
        showChannlePartnerDropdowon={true}
        onApplyClick={handleDateApply}
        onDateClear={handleDateClear}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <div className="px-4 py-4">
        <div className="grid grid-cols-3 gap-4">
          <WidgetCard
            title="Total Orders"
            value={getValueFromOrderOverview("Total Orders")}
          />
          <WidgetCard
            title="No of Active Orders"
            value={getValueFromOrderOverview("Active Orders")}
          />
          <WidgetCard
            title="Total Orders completed"
            value={getValueFromOrderOverview("Completed Orders")}
          />
          <WidgetCard
            title="Total Sales"
            value={getValueFromOrderOverview("Total Sales")}
            showRupees={true}
          />
          <WidgetCard
            title="AOV"
            value={getValueFromOrderOverview("Average Order Value")}
            showRupees={true}
          />
        </div>
      </div>
      <Divider />
      <div className="px-4 mb-4 grid grid-cols-2 gap-4 w-full">
        {/* Total Sales */}
        <ChartWrapper
          title="Total Sales"
          chartData={totalSalesData}
          loading={totalSalesLoading}
        >
          <LineChartComponent chartData={totalSalesData} showRupees={true} />
        </ChartWrapper>
        {/* Order Status */}
        <ChartWrapper
          title="Orders By Status"
          chartData={orderByStatusData}
          loading={orderByStatusLoading}
        >
          <DonutChartComponent
            chartData={orderByStatusData}
            name={orderByStatusData?.name}
            showRupees={false}
            legendsAtBottom={true}
          />
        </ChartWrapper>
        {/* Sales by Location */}
        <ChartWrapper
          title="Sales by Location"
          chartData={salesByLocationData}
          loading={salesByLocationLoading}
          // extraStyles="col-span-2"
        >
          <HorizontalBarChartComponent
            chartData={salesByLocationData}
            name={salesByLocationData?.name}
            showRupees={true}
            // height="800px"
          />
        </ChartWrapper>
        {/* First-time vs Returning Customers */}
        <ChartWrapper
          title="First-time vs Returning Customers"
          chartData={firstTimeVsReturningCustomersData?.values}
        >
          <PieChartComponent
            chartData={firstTimeVsReturningCustomersData?.values}
            name={firstTimeVsReturningCustomersData?.name}
            showRupees={false}
            legendsAtBottom={true}
          />
        </ChartWrapper>
        {/* Order Type */}
        <ChartWrapper
          title="Order Type"
          chartData={orderTypeData}
          loading={orderTypeLoading}
        >
          <PieChartComponent
            chartData={orderTypeData}
            name={orderTypeData?.name}
            showRupees={false}
            legendsAtBottom={true}
          />
        </ChartWrapper>
        {/* Most Ordered Products */}
        <ChartWrapper
          title="Most Ordered Products"
          chartData={mostOrderProductsData}
          loading={mostOrderProductsLoading}
        >
          <VerticalBarChartComponent
            chartData={mostOrderProductsData}
            // name={mostOrderProductsData}
            height="450px"
            rotate={true}
            showRupees={true}
            showCount={true} // to show order count
            showLegend={true}
          />
        </ChartWrapper>
      </div>
      <Divider title="Quotations" header={true} />
      <div className="px-4 mb-4 grid grid-cols-2 gap-4 w-full">
        <ChartWrapper
          title="Conversion Count Over Time"
          chartData={quotationConversionCountOverTimeData}
          loading={quotationConversionCountOverTimeLoading}
        >
          <LineChartComponent
            chartData={quotationConversionCountOverTimeData}
            showRupees={true}
          />
        </ChartWrapper>
        <ChartWrapper
          title="Conversion by Type"
          chartData={conversionByTypeData}
          loading={conversionByTypeLoading}
        >
          <DonutChartComponent
            chartData={conversionByTypeData}
            name={conversionByTypeData?.name}
            showRupees={false}
            legendsAtBottom={true}
          />
        </ChartWrapper>
        <ChartWrapper
          title="Quotation Count Over Time"
          chartData={quotationCountOverTimeData}
          loading={quotationCountOverTimeLoading}
        >
          <LineChartComponent
            chartData={quotationCountOverTimeData}
            showRupees={false}
            rotate={true}
          />
        </ChartWrapper>
        <ChartWrapper
          title="Most Quoted Products"
          chartData={mostQuotedProductsData}
          loading={mostQuotedProductsLoading}
        >
          <VerticalBarChartComponent
            chartData={mostQuotedProductsData}
            name={mostQuotedProductsData?.name}
            height="450px"
            rotate={true}
            showRupees={false}
            showCount={true} // to show order count
          />
        </ChartWrapper>
      </div>
    </div>
  );
}
