import React from "react";

interface PaymentStatusTagProps {
  status: string;
  className?: string;
}

const getStatusColor = (status: string): string => {
  switch (status.toUpperCase()) {
    case "UNPAID":
      return "bg-[#FFCECE]"; // Light red
    case "PROCESSING":
      return "bg-[#A1C8FF]"; // Light blue
    case "PARTIALLY_PAID":
      return "bg-[#FFCB83]"; // Light orange
    case "PAID":
      return "bg-[#CEFF97]"; // Light green
    case "REFUND":
      return "bg-[#A789FF]"; // Light purple
    case "OFFLINE_MODE":
      return "bg-[#F1F8E9]";
    case "FAILED":
      return "bg-[#FFCB83]";
    case "SUCCESS":
      return "bg-[#CEFF97]";
    case "SUCCESSFUL":
      return "bg-[#CEFF97]";
    case "AWAITING_TIMELINE":
      return "bg-[#F1F8E9]";

    default:
      return "bg-[#EDEDED]"; // Default grey
  }
};

const formatStatus = (status: string): string => {
  // Replace underscores with spaces and convert to title case
  return status
    .split("_")
    .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
    .join(" ");
};

const PaymentStatusTag: React.FC<PaymentStatusTagProps> = ({
  status,
  className = "",
}) => {
  const backgroundColor = getStatusColor(status);
  const formattedStatus = formatStatus(status);

  return (
    <div
      className={`inline-flex items-center px-2 py-1 rounded-md ${backgroundColor} ${className}`}
    >
      <span className="text-sm leading-5 font-normal font-gilroy-regular">
        {formattedStatus}
      </span>
    </div>
  );
};

export default PaymentStatusTag;
