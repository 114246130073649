import React from "react";

interface RecordIconProps {
  size?: string;
  color?: string;
  className?: string;
}

const RecordIcon = ({
  size = "14",
  color = "black",
  className,
}: RecordIconProps) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      className={`stroke-current ${className}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14382_29017)">
        <path
          d="M5.20703 7.10742H8.45703"
          strokeWidth="0.8125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.20703 8.73242H8.45703"
          strokeWidth="0.8125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.76953 2.63867H10.8945V10.7637C10.8945 11.0869 10.7661 11.3969 10.5376 11.6255C10.309 11.854 9.99901 11.9824 9.67578 11.9824H3.98828C3.66505 11.9824 3.35505 11.854 3.12649 11.6255C2.89793 11.3969 2.76953 11.0869 2.76953 10.7637V2.63867Z"
          strokeWidth="0.8125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.39453 1.82617V3.45117"
          strokeWidth="0.8125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.83203 1.82617V3.45117"
          strokeWidth="0.8125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.26953 1.82617V3.45117"
          strokeWidth="0.8125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_14382_29017">
          <rect
            width="13"
            height="13"
            fill="white"
            transform="translate(0.332031 0.607422)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RecordIcon;
