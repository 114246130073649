import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import TrashIcon from "../../../assets/TrashIcon";
import React, { useEffect, useMemo, useRef, useState } from "react";
import EditIcon from "../../../assets/EditIcon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import {
  getOrderItems,
  getSingleOrderDetails,
  getSingleOrderItem,
  updateOrderItem,
  updateTimelineBulk,
} from "../../../api/orderManagement";
import AddProductPopup from "./AddProductPopup";
import { formatIndianRupeesForDecimals } from "../../../utils/formatToIndianRupees";
import Modal from "../../../components/common/Modal";
import Button from "../../../components/Button";
import { checkPermissions } from "../../../utils/helper";
import { Tooltip } from "antd";
import ProductDetailsPopup from "./ProductDetailsPopup";
import Checkbox from "../../../components/Checkbox";
import OrderStatusTag from "../../../components/OrderStatusTag";
import InfoIcon from "../../../assets/svg-tsx/InfoIcon";
import { toast } from "react-toastify";

function OrderTimelineTable({
  data,
  columnVisibility,
  setTimelineData,
  handleDelete,
  handleQuantity,
  setTableData,
  projectDetails,
  orderDetails,
  setOrderDetails,
  SetTableWidth,
  checkedRows,
  onCheckboxChange,
  isOrderCancelled,
}: any) {
  const { id } = useParams();
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [openAddProductModal, setOpenAddProductModal] =
    useState<boolean>(false);
  const [currentProject, setCurrentProject] = useState<any>("");

  const [deleteModal, setDeleteModal] = useState<any>("");
  const [openProductDetailsPopup, setOpenProductDetailsPopup] =
    useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [getSingleItemLoading, setGetSingleItemLoading] =
    useState<boolean>(false);

  const tableRef = useRef<HTMLTableElement>(null);

  // Permission
  const hasEditPermission = checkPermissions("edit_order");

  // To set the width for table heading (floor name)
  useEffect(() => {
    const tableElement = tableRef.current;

    if (tableElement) {
      // Initialize ResizeObserver to watch for changes in height
      const resizeObserver = new ResizeObserver(() => {
        const newHeight = tableElement.offsetWidth;
        SetTableWidth(newHeight);
      });

      // Observe the bottomBar element
      resizeObserver.observe(tableElement);

      // Cleanup the observer on unmount
      return () => resizeObserver.disconnect();
    }
  }, []);

  // Function to format the date coming from the response into DD/MM/YY format
  function parseDate(formattedDate: any) {
    if (formattedDate === null || formattedDate === undefined) return null;
    const [year, month, day] = formattedDate?.split("-")?.map(Number); // eslint-disable-line
    return new Date(year, month - 1, day); // Subtract 1 from month since it's 0-based
  }

  // Function to format the date into YYYY-MM-DD format to pass as response body
  function formatDate(inputDate: any) {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  useEffect(() => {
    if (selectedDate) {
      const { productId, date } = selectedDate;
      const payload = {
        items: [productId],
        est_delivery_at: date,
      };
      updateTimelineBulk(id, payload)
        .then((res: any) => {
          toast.success("Timeline updated successfully");
          getOrderItems(id).then((res: any) => {
            setTableData(res.data);
          });
        })
        .catch((error) => {
          console.error("Error : ", error);
          toast.error(error?.data?.errors?.est_delivery_at[0]);
        });
    }
  }, [selectedDate]);

  // Get header checkbox state (keep this helper function)
  const getHeaderCheckboxValue = () => {
    const checkedValues = Object.values(checkedRows);
    if (checkedValues.length === 0) return 0;

    const allChecked = data.every((row: any) => checkedRows[row.id]);
    if (allChecked) return 1;

    const someChecked = data.some((row: any) => checkedRows[row.id]);
    if (someChecked) return 2;

    return 0;
  };

  const onClickHandler = (itemId: string) => {
    setGetSingleItemLoading(true);
    // Get single order item details
    getSingleOrderItem(id, itemId)
      .then((response: any) => {
        setSelectedProduct([response.data]); // Wrap in array since popup expects array
        setOpenProductDetailsPopup(true);
      })
      .catch((error) => {
        console.error("Error fetching order item details:", error);
      })
      .finally(() => {
        setGetSingleItemLoading(false);
      });
  };

  //Show column conditionally
  let showTrackShape = false;
  let showTrackDimension = false;
  let showControllerQuantity = false;

  data?.map((item: any) => {
    if (item?.item_properties?.track_shape) {
      showTrackShape = true;
    }
    if (item?.item_properties?.track_dimension) {
      showTrackDimension = true;
    }
    if (item?.item_properties?.track_driver_quantity) {
      showControllerQuantity = true;
    }
  });

  let columns = useMemo(
    () => [
      {
        header: () => (
          <Checkbox
            checkValue={getHeaderCheckboxValue()}
            onClick={(value) => {
              // Set all rows in this table to the same value
              data.forEach((row: any) => {
                onCheckboxChange(row.id, value === 1);
              });
            }}
            borderType="square"
          />
        ),
        accessorKey: "selection",
        cell: ({ row }: any) => {
          return (
            <Checkbox
              checkValue={checkedRows[row.original.id] ? 1 : 0}
              onClick={(value) =>
                onCheckboxChange(row.original.id, value === 1)
              }
              borderType="square"
            />
          );
        },
      },
      {
        header: "No",
        accessorKey: "",
        cell: ({ row }: any) => {
          return <p className="min-w-[30px]">{+row.id + 1}</p>;
        },
      },
      {
        header: "Code",
        accessorKey: "product.product_code",
        cell: ({ row }: any) => {
          return (
            <p className="min-w-[80px]">
              {row?.original?.item_properties?.code || "-"}
            </p>
          );
        },
      },
      {
        header: "Product Name",
        accessorKey: "product.name",
        cell: ({ row, getValue }: any) => {
          return <p className="min-w-[150px]">{getValue()}</p>;
        },
      },
      {
        header: "Image",
        accessorKey: "product.primary_image",
        cell: ({ row }: any) => {
          return (
            <p className="min-w-[40px]">
              <img
                src={`${process.env.REACT_APP_BUCKET}/${row?.original?.product?.primary_image}`}
                alt="product-image"
                className="h-8 w-8"
              />
            </p>
          );
        },
      },
      {
        header: "Wattage",
        accessorKey: "product.Wattage",
        cell: ({ row }: any) => {
          return (
            <p className="min-w-[60px]">
              {row?.original?.item_properties?.wattage || "-"}
            </p>
          );
        },
      },
      {
        header: "Control Type",
        accessorKey: "product.control_type",
        cell: ({ row }: any) => {
          return (
            <p className="min-w-[100px]">
              {row?.original?.item_properties?.lighting_control || "-"}
            </p>
          );
        },
      },
      ...(columnVisibility?.showTrackShape
        ? [
            {
              header: "Profile Shape",
              accessorKey: "product.profile_shape",
              cell: ({ row }: any) => {
                return (
                  <p className="min-w-[12 0px]">
                    {row?.original?.item_properties?.track_shape || "-"}
                  </p>
                );
              },
            },
          ]
        : []),

      ...(columnVisibility?.showTrackDimension
        ? [
            {
              header: "Profile Dimension",
              accessorKey: "product.profile_dimention",
              cell: ({ row }: any) => {
                return (
                  <p className="min-w-[100px]">
                    {row?.original?.item_properties?.track_dimension || "-"}
                  </p>
                );
              },
            },
          ]
        : []),

      ...(columnVisibility?.showControllerQuantity
        ? [
            {
              header: "Controller Quantity",
              accessorKey: "product.quantity",
              cell: ({ row }: any) => {
                return (
                  <p className="min-w-[100px]">
                    {row?.original?.item_properties?.track_driver_quantity}
                  </p>
                );
              },
            },
          ]
        : []),
      {
        header: "Light colour",
        accessorKey: "product_variants",
        cell: ({ row }: any) => {
          return (
            <p className="min-w-[70px]">
              {row?.original?.item_properties?.light_color || "-"}
            </p>
          );
        },
      },
      {
        header: "Body colour",
        accessorKey: "product_variants",
        cell: ({ row }: any) => {
          return (
            <p className="min-w-[70px]">
              {row?.original?.item_properties?.body_color || "-"}
            </p>
          );
        },
      },
      {
        header: "Reflector colour",
        accessorKey: "product_variants",
        cell: ({ row }: any) => {
          return (
            <p className="min-w-[90px]">
              {row?.original?.item_properties?.reflector_color || "-"}
            </p>
          );
        },
      },
      {
        header: "Unit Price",
        accessorKey: "unit_price",
        cell: ({ getValue }: any) => {
          let price = formatIndianRupeesForDecimals(parseFloat(getValue()));
          return (
            <div className="flex gap-2 justify-center min-w-[80px]">
              <p>{price}</p>
            </div>
          );
        },
      },
      {
        header: "Quantity",
        accessorKey: "quantity",
        cell: ({ row, getValue }: any) => {
          return (
            <p className="w-8 flex justify-center items-center  min-w-[60px]">
              {getValue()}
            </p>
          );
        },
      },
      {
        header: "Total Price",
        accessorKey: "grand_total",
        cell: ({ getValue }: any) => {
          let price = formatIndianRupeesForDecimals(parseFloat(getValue()));
          return (
            <div className="flex gap-2 justify-center min-w-[80px]">
              <p>{price}</p>
            </div>
          );
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: ({ row, getValue }: any) => {
          return (
            <p className="min-w-[100px]">
              {" "}
              <OrderStatusTag
                status={row?.original?.delivery_status}
                tagStyles="!text-[10px] font-gilroy-medium font-bold"
                tagCircleStyles="!w-2 !h-2"
              />
            </p>
          );
        },
      },
      {
        header: "Timeline",
        accessorKey: "timeline",
        cell: ({ row, getValue }: any) => {
          const est_date: any = parseDate(row?.original?.est_delivery_at);
          return (
            <>
              <Tooltip
                title={
                  !hasEditPermission &&
                  "You don't have permission to perform this action"
                }
              >
                <DatePicker
                  disabled={!hasEditPermission || isOrderCancelled}
                  selected={est_date}
                  minDate={new Date()}
                  onChange={(currentDate: any) => {
                    const formattedDate: any = formatDate(currentDate);
                    setSelectedDate({
                      date: formattedDate,
                      productId: row.original.id,
                    });
                  }}
                  className="text-center w-[85px] z-[100]"
                  dateFormat={"dd/MM/yy"}
                  placeholderText="DD/MM/YY"
                  popperClassName="!z-[9999]"
                  dayClassName={(date) =>
                    est_date && date.toDateString() === est_date?.toDateString()
                      ? "bg-pot-yellow text-[#000]"
                      : null
                  }
                />
              </Tooltip>
            </>
          );
        },
      },
      ...(hasEditPermission
        ? [
            {
              header: "Actions",
              accessorKey: "actions",
              cell: ({ row }: any) => {
                return (
                  <div className="flex gap-2">
                    {orderDetails?.allowed_actions?.can_add_item && (
                      <div
                        className="flex justify-center items-center mx-1 cursor-pointer"
                        onClick={() => {
                          setCurrentProject(row.original);
                          setOpenAddProductModal(true);
                        }}
                      >
                        <EditIcon width="14" />
                      </div>
                    )}
                    {orderDetails?.allowed_actions?.can_add_item && (
                      <div
                        className="flex justify-center items-center mx-1 cursor-pointer"
                        onClick={() => {
                          setDeleteModal(row?.original?.id);
                        }}
                      >
                        <TrashIcon width="14" />
                      </div>
                    )}
                    <button
                      className="flex justify-center disabled:opacity-50 disabled:cursor-not-allowed items-center cursor-pointer"
                      onClick={() => {
                        !getSingleItemLoading &&
                          onClickHandler(row?.original?.id);
                      }}
                      disabled={getSingleItemLoading}
                    >
                      <InfoIcon />
                    </button>
                  </div>
                );
              },
            },
          ]
        : []),
    ],
    [checkedRows, data, onCheckboxChange, columnVisibility]
  );

  if (orderDetails?.owner === "PROJECT") {
    columns.splice(1, 0, {
      header: "Area",
      accessorKey: "area_id",
      cell: ({ row, getValue }: any) => {
        return (
          <p className="min-w-[100px]">{row?.original?.area_name || "-"}</p>
        );
      },
    });
  }

  const tableData = useMemo(() => data || [], [data]);

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection: checkedRows,
    },
  });

  return (
    <>
      {openProductDetailsPopup && (
        <ProductDetailsPopup
          setOpenProductDetailsPopup={setOpenProductDetailsPopup}
          data={selectedProduct}
        />
      )}
      <table
        className="mb-[1rem] w-full border border-c-gray-4 border-collapse text-[0.7rem] text-center table-auto"
        ref={tableRef}
      >
        <thead className="bg-[#ECECEC80] h-[36px]">
          {table.getHeaderGroups().map((headerGroup: any) => (
            <tr
              key={headerGroup.id}
              className="bg-c-gray-1 border border-c-gray-4 [&>th]:px-2 [&>th]:py-1 font-gilroy-semi-bold [&>th]:border [&>th]:border-c-gray-4 [&>th]:whitespace-nowrap [&>th]:bg-c-gray-3"
            >
              {headerGroup.headers.map((header: any, index: any) => {
                return (
                  <th
                    key={index}
                    colSpan={header.colSpan}
                    className={`!font-gilroy-semi-bold text-xs font-normal whitespace-nowrap border ${
                      index === 0
                        ? "sticky left-0 z-[1] bg-pot-grey3"
                        : index === 1
                        ? "sticky left-[43px] z-[1] bg-pot-grey3 "
                        : ""
                    }`}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                      // className={`"w-full flex justify-center text-base py-2`}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table?.getRowModel().rows.map((row: any) => {
            return (
              <tr
                key={row.id}
                className={`${
                  row.getCanExpand()
                    ? row.original.paymentStatus
                      ? "bg-[#F2F6FF]"
                      : "bg-[#FFFCF1]"
                    : "bg-white"
                }`}
              >
                {row.getVisibleCells().map((cell: any, index: any) => {
                  return (
                    <td
                      key={index}
                      className={` border p-2  ${
                        index === 0
                          ? "sticky left-0 z-[1] bg-white "
                          : index === 1
                          ? "sticky left-[43px] z-[1] bg-white border"
                          : ""
                      }`}
                    >
                      <div className="w-full flex justify-center items-center">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}

          {deleteModal && (
            <Modal
              top
              header="Delete Item"
              footer={
                <div className="flex gap-x-3">
                  <Button
                    variant="primary"
                    label="Delete"
                    onClick={() => {
                      handleDelete(id, deleteModal);
                      setDeleteModal("");
                      setTimeout(() => {
                        getSingleOrderDetails(orderDetails?.id)
                          .then((res) => {
                            setOrderDetails(res?.data?.order);
                            setDeleteModal("");
                          })
                          .catch((err) => {
                            console.log(err, "err");
                            setDeleteModal("");
                          });
                      }, 500);
                    }}
                  />
                  <Button
                    variant="secondary"
                    label="Cancel"
                    onClick={() => {
                      setDeleteModal("");
                    }}
                  />
                </div>
              }
              handleCancel={() => setDeleteModal("")}
            >
              <p className="text-left">
                Are you sure you want to delete this Item?
              </p>
            </Modal>
          )}

          {openAddProductModal && (
            <AddProductPopup
              handleCancel={() => {
                setOpenAddProductModal(false);
                setCurrentProject({});
              }}
              projectDetails={projectDetails}
              orderDetails={orderDetails}
              currentProject={currentProject}
              setTableData={setTableData}
              setOrderDetails={setOrderDetails}
            />
          )}
        </tbody>
      </table>
    </>
  );
}

export default OrderTimelineTable;
