import React, { useState, useRef, useEffect } from "react";
import OrderDetailsCard from "./components/OrderDetailsCard";
import calenderIcon from "../../assets/svg/clenderIcon.svg";
import TotalProfessionals from "./components/TotalProfessionals";
import OrderStatusCard from "./components/OrderStatusCard";
import OrderAddressCard from "./components/OrderAddressCard";
import OrderProjectDetails from "./components/OrderProjectDetails";
import ProjectDetailsTimeline from "../ProjectManagment/projectdetails/ProjectDetailsTimline";
import OrderTimelineRequested from "./components/OrderTimelineRequested";
import { useNavigate, useParams } from "react-router-dom";
import {
  cancelOrder,
  confirmOrder,
  createDispatch,
  getDispatchList,
  getOrderItems,
  getOrderTimeline,
  getPaymentLogs,
  getSingleOrderDetails,
  submitTimeline,
  placeOrder,
} from "../../api/orderManagement";
import { useRecoilState, useRecoilValue } from "recoil";
import { ordeAppConfigAtom, titleAtom } from "../../atom";
import { getSingleProject } from "../../api/project";
import useSeparateOrdersByFloor from "../../hooks/useSeparateOrdersByFloor";
import { getSingleCustomer } from "../../api/customers";
import OrderCreateUserDetails from "./components/OrderCreateUserDetails";
import PaymentDetails from "./components/PaymentDetails";
import TimerIcon from "../../assets/svg-tsx/TimerIcon";
import loadingSpinner from "../../assets/images/LoadingSpinner.gif";
import AssignPartner from "./components/AssignPartner";
import { checkPermissions } from "../../utils/helper";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import OrderStatusTag from "../../components/OrderStatusTag";
import PaymentStatusTag from "../../components/PaymentStatusTag";
import DeliveredProductsTable from "./components/DeliveredProductsTable";
import DownloadInvicesSection from "./components/DownloadInvicesSection";
import UploadInvoiceForm from "./components/UploadInvoiceForm";
import UpdateTimelineForm from "./components/UpdateTimelineForm";
import RecordPaymentForm from "./components/RecordPaymentForm";

import HistoryClockIcon from "../../assets/svg-tsx/HistoryClockIcon";
import TickIcon from "../../assets/TickIcon";

export const getStatusBgColor = (Status: string): string => {
  switch (Status) {
    case "PARTIALLY_PAID":
      return "bg-[#EBFFF2]"; // Light green background for PARTIALLY PAID
    case "PAID":
      return "bg-[#42B86D]"; // Green background for PAID
    case "FAILED":
      return "bg-[#FFB4A1]"; // Red background for FAILED
    case "DRAFT":
      return "bg-[#EDEDED]"; // Grey background for DRAFT
    case "PENDING":
      return "bg-[#FFD194]"; // Yellow background for PENDING
    case "AWAITING_TIMELINE":
    case "AWAITING_TIMELINE_CONFIRMATION":
    case "OUT_FOR_DELIVERY":
      return "bg-[#DBD6F6]"; // Purple background for AWAITING statuses and OUT FOR DELIVERY
    case "PROCESSING":
      return "bg-[#FFD194]"; // Yellow background for PROCESSING
    case "PARTIALLY_DELIVERED":
    case "DELIVERED":
    case "REFUNDED":
      return "bg-[#42B86D]"; // Green background for PARTIALLY DELIVERED, DELIVERED, and REFUNDED
    case "CANCELLED":
      return "bg-[#FFB4A1]"; // Purple background for REFUNDED
    default:
      return "bg-[#EDEDED]"; // Default grey background
  }
};

function SingleOrderDetails() {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState<any>();
  const [projectDetails, setProjectDetails] = useState<any>();
  const [orderTimeline, setOrderTimeline] = useState<any>();
  const [selectedValue, setSelectedValue] = useState("");
  const [inputVal, setInputVal] = useState("");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [tableData, setTableData] = useState<any>();
  const [timelineData, setTimelineData, handleDelete, handleQuantity, trigger] =
    useSeparateOrdersByFloor(tableData);
  const [userDetails, setUserDetails] = useState<any>();
  const [professionalDetails, setProfessionalDetails] = useState<any>();
  const [paymentModal, setPaymentModal] = useState(false);
  const [loading, setLoading] = useState(true);

  // payment logs state
  const [paymentLogs, setPaymentLogs] = useState<any>();

  // dispatch list state
  const [dispatchList, setDispatchList] = useState([]);

  const navigate = useNavigate();
  //Permissions
  const hasManageOrderPaymentsPermission = checkPermissions(
    "manage_order_payments"
  );
  const hasViewPermission = checkPermissions("view_order", true, toast);

  useEffect(() => {
    if (tableData) {
      trigger();
    }
  }, [tableData]);

  const getSingleOrderApi = async () => {
    setLoading(true);
    const queryParams = { search: inputVal };
    getSingleOrderDetails(id, queryParams)
      .then((res) => {
        setOrderDetails(res?.data?.order);
        setSelectedOrderStatus(res?.data?.order?.status);
      })
      .catch((err) => {
        console.error(err, "err");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPaymentLogsApi = async () => {
    getPaymentLogs(id)
      .then((res: any) => {
        setPaymentLogs(res.data?.payments);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const getDispatchListApi = async () => {
    getDispatchList(id)
      .then((res: any) => {
        setDispatchList(res.data);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getSingleOrderApi();
    getPaymentLogsApi();
    getDispatchListApi();
  }, [id]);

  const fetchOrderItems = async () => {
    getOrderItems(id).then((res: any) => {
      setTableData(res.data);
    });
  };

  // Useeffect to get the project details
  useEffect(() => {
    if (orderDetails?.owner === "PROJECT" && orderDetails?.owner_id) {
      getSingleProject(orderDetails?.owner_id).then((res: any) => {
        // set project details into projectDetails state
        setProjectDetails(res?.data?.project);
      });
    }
    getOrderItems(id).then((res: any) => {
      setTableData(res.data);
    });
  }, [orderDetails?.id]);

  const handleOrderStatusChange = (value: string) => {
    setSelectedOrderStatus(value);
  };

  const orderAppConfig = useRecoilValue(ordeAppConfigAtom);
  const orderStatusOptions = orderAppConfig?.order_status?.map(
    (status: any) => ({
      label: status,
      value: status,
    })
  );

  const paymentStatusOptions = orderAppConfig?.payment_status?.map(
    (status: any) => ({
      label: status,
      value: status,
    })
  );

  useEffect(() => {
    // const queryParams = { search: inputVal };
    getOrderTimeline(id)
      .then((res) => {
        setOrderTimeline(res.data);
      })
      .catch((err) => {
        console.error(err, "err");
      });
  }, []);

  function getDaySuffix(day: any) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  function formatDate(dateString: any, format: string = "default") {
    const date = new Date(dateString);

    // Add IST offset (5 hours and 30 minutes)
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);

    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();

    // Extract the day and add 'st', 'nd', or 'rd' as appropriate
    const daySuffix = getDaySuffix(day);

    const hour = date.getHours();
    const minute = date.getMinutes();
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 === 0 ? "12" : (hour % 12).toString();
    const formattedMinute = minute < 10 ? `0${minute}` : minute.toString();

    const formattedDate = `${day}${daySuffix} ${month} ${year}`;
    const formattedTime = `${formattedHour}:${formattedMinute} ${ampm}`;

    if (format === "date") {
      return formattedDate;
    } else if (format === "time") {
      return formattedTime;
    } else {
      return { date: formattedDate, time: formattedTime };
    }
  }

  const orderFormattedDate: any = orderDetails?.order_date
    ? formatDate(orderDetails?.order_date, "date")
    : "-";
  const orderFormattedTime: any = orderDetails?.order_date
    ? formatDate(orderDetails?.order_date, "time")
    : "-"; // Get only the time

  // Get user details
  useEffect(() => {
    if (orderDetails?.ordered_for) {
      getSingleCustomer(orderDetails?.ordered_for)
        .then((res) => {
          setUserDetails(res?.data?.customer);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [orderDetails]);

  // Get professional details
  useEffect(() => {
    if (orderDetails?.professional_id) {
      getSingleCustomer(orderDetails?.professional_id)
        .then((res) => {
          setProfessionalDetails(res?.data?.customer);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [orderDetails?.id]);

  const handlePaymentDetailsModal = () => {
    setPaymentModal(true);
  };

  useEffect(() => {
    if (orderDetails?.status === "DRAFT") {
      document.title = `Orders - ${orderDetails?.status}`;
    } else if (orderDetails?.order_no) {
      document.title = `Orders - ${orderDetails?.order_no}`;
    } else {
      document.title = `Orders - ...`;
    }
  }, [orderDetails?.status, orderDetails?.order_no]);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const updateTimeline = () => {
    let itemsWithEstDates: any = [];
    let totalItems: any = [];
    timelineData?.map((singleData: any) => {
      singleData.items?.map((item: any) => {
        totalItems.push(item);
        if (item.est_delivery_at) {
          itemsWithEstDates.push(item);
        }
      });
    });
    submitTimeline(id)
      .then((res: any) => {
        toast.success("Timeline Submitted");
        getSingleOrderApi();
        fetchOrderItems();
      })
      .catch((error: any) => {
        const errorMessage = error?.data?.errors?.general[0];
        toast.error(errorMessage);
      });
  };

  const handleCreateDispatch = async (selectedItems: any) => {
    const payload = {
      items: selectedItems,
    };
    try {
      const res = await createDispatch(id, payload);
      getDispatchListApi();
      getSingleOrderApi();
      fetchOrderItems();
      toast.success("Dispatch created successfully");
    } catch (error) {
      toast.error(
        "Some of the items are already dispatched or are ready to be dispatched"
      );
    }
  };

  // API call for cancel order
  const handleCancelOrder = () => {
    cancelOrder(id)
      .then((response) => {
        toast.success("Order cancelled successfully");
        getSingleOrderApi();
      })
      .catch((error) => {
        console.log(error?.data?.errors);
        toast.error(error?.data?.errors?.general[0]);
      });
  };

  const handleConfirmOrder = () => {
    confirmOrder(id)
      .then((response) => {
        toast.success("Order confirm successfully");
        getSingleOrderApi();
      })
      .catch((error) => {
        console.log(error?.data?.errors);
        toast.error(error?.data?.errors?.general[0]);
      });
  };

  // Add new handler function
  const handlePlaceOrder = () => {
    placeOrder(id)
      .then((response) => {
        toast.success("Order placed successfully");
        getSingleOrderApi();
      })
      .catch((error) => {
        console.log(error?.data?.errors);
        toast.error(error?.data?.errors?.general[0]);
      });
  };

  if (!hasViewPermission) {
    navigate("/orders");
  }

  const isOrderCancelled = orderDetails?.status === "CANCELLED";

  return (
    <>
      {loading ? (
        <div className=" min-h-[90vh] flex items-center justify-center">
          <img src={loadingSpinner} alt="loading" className="h-auto w-[10vh]" />
        </div>
      ) : (
        <div className="w-full flex flex-col h-auto">
          <div className="row w-full flex flex-row h-full">
            <div className="w-[88%] h-auto flex flex-col ">
              {/* First row header */}
              <div className="h-[60px] w-full pl-5 flex flex-row justify-between">
                <div className="flex gap-7 font-medium leading-5 items-center ">
                  <p className="text-[14px] font-gilroy-medium">
                    Order ID:{" "}
                    <span className="ml-1">
                      #{orderDetails?.order_no || "-"}
                    </span>
                  </p>
                  <div
                    className="flex gap-3"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="relative h-full justify-center items-center">
                      <button className="flex items-center">
                        <img src={calenderIcon} alt="calender-icon" />
                      </button>

                      {isHovered && (
                        <div className="absolute bottom-full top-[130%] left-[45%] transform -translate-x-1/2 flex justify-center items-center gap-3 px-2 py-2 h-8 text-center w-36 bg-black text-white text-[14px] font-gilroy-bold shadow-lg rounded-md ">
                          <p className="text-[12px]">
                            Order placed at this date
                          </p>
                          <div className="tooltip-arrow"></div>
                        </div>
                      )}
                    </div>
                    <div className="flex items-center gap-3">
                      <p className="text-[#AEAEAE] font-gilroy-semi-bold text-[12px] tracking-normal">
                        {orderFormattedDate}
                      </p>
                      <p className="text-[#AEAEAE] font-gilroy-semi-bold text-[12px] tracking-normal">
                        {orderFormattedTime}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mr-6 flex items-center gap-4">
                  {orderDetails?.allowed_actions?.can_place_order ? (
                    <Button
                      label="Place Order"
                      variant="secondary"
                      className="h-[30px] text-[12px] font-gilroy-medium"
                      onClick={handlePlaceOrder}
                    />
                  ) : orderDetails?.allowed_actions?.can_confirm_order &&
                    !isOrderCancelled ? (
                    <Button
                      label="Confirm Order"
                      variant="secondary"
                      className="h-[30px] text-[12px] font-gilroy-medium"
                      onClick={handleConfirmOrder}
                    />
                  ) : null}

                  {orderDetails?.is_order_confirmed && !isOrderCancelled && (
                    <div className="flex flex-row items-start justify-center">
                      <span className="mr-2 mt-1">
                        <TickIcon width="14" color="#A3A3A3" />
                      </span>
                      <span className="text-[#A3A3A3] font-gilroy-medium font-normal">
                        Order Confirmed
                      </span>
                    </div>
                  )}

                  {orderDetails?.allowed_actions?.can_cancel_order && (
                    <Button
                      label="Cancel Order"
                      variant="secondary-text"
                      className="!text-[#FF5B5B] text-[12px] font-normal font-gilroy-medium"
                      onClick={handleCancelOrder}
                    />
                  )}

                  {orderDetails?.status === "CANCELLED" && (
                    <p className="text-[#FF5B5B] text-sm font-normal font-gilroy-medium">
                      Order Cancelled
                    </p>
                  )}
                </div>
              </div>
              {/* Second row header */}
              <div className="h-[65px] border-y border-[#DEDEDE] w-full flex flex-row justify-between">
                {/* Left Section */}
                <div className="flex flex-row gap-3">
                  <div className="flex flex-col gap-1 ml-5 my-2">
                    <p className="text-[#1C1C1C] font-gilroy-semi-bold text-xs">
                      Order Status
                    </p>
                    <p className="text-black font-gilroy-semi-bold text-xs">
                      <OrderStatusTag status={orderDetails?.status} />
                    </p>
                  </div>
                  <div className="h-full w-[1px] bg-[#DEDEDE] mx-5"></div>
                  <div className="flex flex-col gap-1 my-2">
                    <p className="text-[#1C1C1C] font-gilroy-semi-bold text-xs">
                      Payment Status
                    </p>
                    <p className="text-black font-gilroy-semi-bold text-xs">
                      <PaymentStatusTag status={orderDetails?.payment_status} />
                    </p>
                  </div>
                </div>
                {/* Right Section */}
                <div className="flex flex-row justify-center items-center mx-4">
                  {orderDetails?.allowed_actions?.can_submit_timeline && (
                    <Button
                      label={"Submit Timeline"}
                      variant={"secondary"}
                      className="h-[30px] text-[12px] font-gilroy-medium "
                      onClick={updateTimeline}
                    />
                  )}
                </div>
              </div>

              {/* Payment info section  and address section */}
              <div className="w-full h-[270px] flex flex-row border-b">
                <div className="text-left flex flex-col w-[790px] max-w-[790px] h-auto justify-between px-5 py-4 border-r">
                  <div className="flex justify-between items-center text-start mb-3">
                    <p className="text-black font-gilroy-semi-bold text-xs">
                      Payment Info
                    </p>
                    {orderDetails?.requested_offline_payment && (
                      <div className="mx-2">
                        <PaymentStatusTag status="OFFLINE_MODE" />
                      </div>
                    )}
                    <div className="flex flex-row gap-2 ml-2">
                      <div>
                        <RecordPaymentForm
                          orderId={id}
                          getPaymentLogsApi={getPaymentLogsApi}
                          getSingleOrderApi={getSingleOrderApi}
                          getDispatchListApi={getDispatchListApi}
                          orderDetails={orderDetails}
                        />
                      </div>
                      <div>
                        <Button
                          label="History"
                          leftIcon={
                            <HistoryClockIcon
                              color="white"
                              width="22"
                              height="22"
                            />
                          }
                          variant="secondary"
                          onClick={handlePaymentDetailsModal}
                          className="h-[30px] !font-gilroy-medium"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between items-center text-start">
                    <p className="text-[#AEAEAE] w-[40%] text-[12px] leading-[12px] font-gilroy-medium ">
                      Base Price
                    </p>
                    <div className="flex justify-start gap-8 items-center w-[70%]">
                      <p className="text-[#1C1C1C] font-gilroy-semi-bold text-center">
                        -
                      </p>
                      <p className="text-[12px] leading-[12px] font-gilroy-semi-bold">
                        ₹
                        {(orderDetails?.sub_total / 100).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between  items-center text-start">
                    <p className="text-[#AEAEAE] text-[12px] leading-[12px] font-gilroy-medium  w-[40%]">
                      Discount (-)
                    </p>
                    <div className="flex justify-start gap-8 items-center w-[70%]">
                      <p className="text-[#1C1C1C] font-gilroy-semi-bold text-center">
                        -
                      </p>
                      <p className="text-[12px] leading-[12px] font-gilroy-semi-bold">
                        ₹
                        {(
                          orderDetails?.discount_professional_referral / 100
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="flex  justify-between items-center text-start">
                    <p className="text-[#AEAEAE]  text-[12px] leading-[12px] font-gilroy-medium  w-[40%]">
                      Total Tax
                    </p>
                    <div className="flex justify-start gap-8 items-center w-[70%] text-left">
                      <p className="text-[#1C1C1C] font-gilroy-semi-bold">-</p>
                      <p className="text-[12px] leading-[12px] font-gilroy-semi-bold">
                        ₹
                        {(
                          (orderDetails?.tax_cgst_total +
                            orderDetails?.tax_igst_total +
                            orderDetails?.tax_sgst_total) /
                          100
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center text-start">
                    <p className="text-[#AEAEAE]  text-[12px] leading-[12px] font-gilroy-medium  w-[40%]">
                      Grand Total
                    </p>
                    <div className="flex justify-start gap-8 items-center w-[70%]">
                      <p className="text-[#1C1C1C] font-gilroy-semi-bold text-center">
                        -
                      </p>
                      <p className="text-[12px] leading-[12px] font-gilroy-semi-bold">
                        ₹
                        {(orderDetails?.grand_total / 100).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center text-start">
                    <p className="text-[#AEAEAE]  text-[12px] leading-[12px] font-gilroy-medium  w-[40%]">
                      Total Amount Paid
                    </p>

                    <div className="flex justify-start gap-8 items-center w-[70%]">
                      <p className="text-[#1C1C1C] font-gilroy-semi-bold">-</p>
                      <p className="text-[12px] leading-[12px] font-gilroy-semi-bold">
                        ₹
                        {(orderDetails?.amount_paid / 100).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full flex gap-5 ">
                  <div className="w-full flex justify-between">
                    <div className="flex-1 border-r">
                      <OrderAddressCard
                        orderDetails={orderDetails}
                        setOrderDetails={setOrderDetails}
                        title="Shipping Address"
                        heading={orderDetails?.address_delivery?.full_name}
                        content={[
                          { line1: orderDetails?.address_delivery?.street_1 },
                          { line2: orderDetails?.address_billing?.street_2 },
                          { city: orderDetails?.address_delivery?.city },
                          {
                            pincode:
                              orderDetails?.address_delivery?.postal_code,
                          },
                          { state: orderDetails?.address_delivery?.state },
                          { country: orderDetails?.address_billing?.country },
                        ]}
                        editDisabled={isOrderCancelled}
                      />
                    </div>
                    <div className="flex-1">
                      <OrderAddressCard
                        orderDetails={orderDetails}
                        setOrderDetails={setOrderDetails}
                        title="Billing Address"
                        heading={orderDetails?.address_billing?.full_name}
                        content={[
                          { line1: orderDetails?.address_billing?.street_1 },
                          { line2: orderDetails?.address_billing?.street_2 },
                          { city: orderDetails?.address_billing?.city },
                          {
                            pincode: orderDetails?.address_billing?.postal_code,
                          },
                          { state: orderDetails?.address_billing?.state },
                          { country: orderDetails?.address_billing?.country },
                        ]}
                        editDisabled={isOrderCancelled}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Dispatch and invoice table */}
              <div className="flex flex-row border-b h-[310px]">
                <div className="w-full flex flex-row">
                  <div className="w-full h-auto flex flex-row">
                    <div className="w-[65%] px-2 flex flex-col border-r">
                      <DeliveredProductsTable
                        orderId={id}
                        dispatchList={dispatchList}
                        getDispatchListApi={getDispatchListApi}
                        fetchOrderItems={fetchOrderItems}
                        delivery_opt_timeline={
                          orderDetails?.delivery_opt_timeline
                        }
                        getSingleOrderApi={getSingleOrderApi}
                        isOrderCancelled={isOrderCancelled}
                      />
                    </div>
                    <div className="w-[50%] overflow-hidden">
                      <DownloadInvicesSection
                        orderId={id}
                        orderDetails={orderDetails}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right section (user details ,total professionals ,assign partner) */}
            <div className="w-[22%] h-[705px] border-l-2 flex flex-col">
              <div className="h-[40%] border-b">
                <OrderCreateUserDetails
                  userData={userDetails}
                  orderDetails={orderDetails}
                  formatDate={formatDate}
                />
              </div>
              <div className="h-[25%] border-b">
                <TotalProfessionals
                  data={professionalDetails}
                  orderDetails={orderDetails}
                  setOrderDetails={setOrderDetails}
                />
              </div>
              <div className="h-[15%] border-b">
                <AssignPartner
                  orderId={id}
                  orderDetails={orderDetails}
                  getSingleOrderApi={getSingleOrderApi}
                  isOrderCancelled={isOrderCancelled}
                />
              </div>
              <div className="h-[316px] border-b">
                <OrderProjectDetails projectDetails={projectDetails} />
              </div>
            </div>
          </div>

          {/* Activity Log section and timeline section */}
          <div>
            <div className="m-4">
              <OrderTimelineRequested
                data={timelineData}
                orderId={id}
                setTimelineData={setTimelineData}
                handleDelete={handleDelete}
                handleQuantity={handleQuantity}
                setTableData={setTableData}
                setOrderDetails={setOrderDetails}
                orderDetails={orderDetails}
                projectDetails={projectDetails}
                handleCreateDispatch={handleCreateDispatch}
                isOrderCancelled={isOrderCancelled}
              />
            </div>
            <div className="m-4">
              <ProjectDetailsTimeline
                orderTimeline={orderTimeline}
                formatDate={formatDate}
              />
            </div>
          </div>
          {paymentModal && (
            <PaymentDetails
              setPaymentModal={setPaymentModal}
              paymentLogs={paymentLogs}
            />
          )}
        </div>
      )}
    </>
  );
}

export default SingleOrderDetails;
