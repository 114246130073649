import React, { useEffect, useState } from "react";
import FileDownloadIcon from "../../../assets/svg-tsx/FileDownloadIcon";
import UploadInvoiceForm from "./UploadInvoiceForm";
import { getInvoice } from "../../../api/orderManagement";
import { toast } from "react-toastify";

type DownloadInvicesSectionProps = {
  orderId: any;
  orderDetails?: any;
};

const DownloadInvicesSection = ({
  orderId,
  orderDetails,
}: DownloadInvicesSectionProps) => {
  const [allInvoices, setAllInvoices] = useState([]);
  const [downloadingId, setDownloadingId] = useState<string | null>(null);

  async function getAllInvoices() {
    getInvoice(orderId).then((res) => {
      setAllInvoices(res?.data);
    });
  }

  const handleDownload = async (invoice: any) => {
    setDownloadingId(invoice.id);
    try {
      // Create temporary link
      const link = document.createElement("a");
      link.href = invoice.document;
      link.download = `Invoice_${invoice.num}.pdf`; // Force the filename

      // Programmatically click the link
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading invoice");
    } finally {
      setDownloadingId(null);
    }
  };

  useEffect(() => {
    getAllInvoices();
  }, [orderId]);

  return (
    <div className="px-4 py-3">
      <div className="flex justify-between items-center mb-1">
        <h2 className="text-sm font-gilroy-medium">Invoices</h2>
        <UploadInvoiceForm
          orderId={orderId}
          getAllInvoices={getAllInvoices}
          orderDetails={orderDetails}
        />
      </div>
      {allInvoices.length !== 0 ? (
        <>
          <ul className="space-y-2 list-disc pl-5 text-[14px] h-[25vh] overflow-y-auto custom-scrollbar">
            {allInvoices.map((invoice: any) => (
              <li key={invoice?.id}>
                <div className="flex justify-between text-sm px-2 py-1">
                  <span>{invoice?.num}</span>
                  <button
                    className="hover:opacity-75 transition-opacity"
                    onClick={() => handleDownload(invoice)}
                    disabled={downloadingId === invoice.id}
                  >
                    {downloadingId === invoice.id ? (
                      <span>Downloading...</span>
                    ) : (
                      <FileDownloadIcon />
                    )}
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className="h-[32vh] flex flex-col justify-center items-center gap-1">
          <p className="font-gilroy-semi-bold font-semibold">
            No Invoices Found
          </p>
          <p className="text-[#AEAEAE] font-gilroy-medium font-normal">
            Invoices will appear here once created.
          </p>
        </div>
      )}
    </div>
  );
};

export default DownloadInvicesSection;
