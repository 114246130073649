import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import InputField from "../../../components/InputField";
import SearchIcon from "../../../assets/SearchIcon";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import ManagementTableLayout from "../../../components/MangementTable";
import { useSticky } from "react-table-sticky";
import PlusIcon from "../../../assets/PlusIcon";
import EditIcon from "../../../assets/EditIcon";
import TrashIcon from "../../../assets/TrashIcon";
import activeStatus from "../../../assets/svg/activestatus.svg";
import DeActiveStatus from "../../../assets/svg/deactivatestatus.svg";
import { useRecoilValue } from "recoil";
import { MainAppSideBarExpandAtom } from "../../../atom";
import { postAssignedData } from "../../../api/product_series";
import { toast } from "react-toastify";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import Modal from "../../../components/common/Modal";
import _debounce from "lodash/debounce";
import CopyIcon from "../../../assets/svg/copyIcon.svg";
import Pagination from "../../../components/Pagination";
import {
  checkPermissions,
  formattedPrice,
  removeExtraSpaces,
} from "../../../utils/helper";
import SelectField2 from "../../../components/SelectedField2";
import FilterIcon from "../../../assets/svg-tsx/FilterIcon";

const Table = ({ setCount, categoryList }: any) => {
  const [totalCount, setTotalCount] = useState<number>(100);
  const [page, setPage] = useState<any>(1);
  const [pageLength, setPageLength] = useState<number>(25);
  const [loading, setLoading] = useState(true);
  const [inputVal, setInputVal] = useState("");

  const [settableData, setSettableData] = useState<any>([]);
  // const [called, setCallIncrement] = useState(0);
  const [deleteModal, setDeleteModal] = useState<any>("");
  const [startIndex, setStartIndex] = useState(0);
  const [isTogglingStatus, setIsTogglingStatus] = useState("");
  const [rowLoading, setRowLoading] = useState(false);
  const [filteredCategory, setFilteredCategory] = useState("");

  const sidebarExpand = useRecoilValue(MainAppSideBarExpandAtom);
  const navigate = useNavigate();

  //Permissions
  const hasViewPermission = checkPermissions("view_product", true, toast);
  const hasCreatePermission = checkPermissions("create_product");
  const hasEditPermission = checkPermissions("edit_product");
  const hasDeletePermission = checkPermissions("delete_product");

  const hideActionColumn = hasDeletePermission || hasEditPermission;

  const handleStatusToggle = async (productId: any, currentStatus: any) => {
    const payload = {
      changes: {
        is_active: !currentStatus,
      },
      products: [productId],
    };
    try {
      await postAssignedData(payload);
      toast.success("Status updated successfully");
      // console.log(settableData, "settableData");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update status");
    }
  };

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const { data: response } = await protectedAxiosInstance.get(
        `/admin/products`,
        {
          params: {
            page: page,
            length: pageLength,
            search: inputVal,
            category_id: filteredCategory,
          },
        }
      );

      if (response?.data) {
        const { product, pagination } = response.data;
        setSettableData(product);
        setTotalCount(pagination?.total_count);
        setCount(pagination?.total_count);

        if (isTogglingStatus) {
          setIsTogglingStatus("");
        }
        setLoading(false);
      }
    } catch (err) {
      console.error(err, "Hi");
      setSettableData([]);
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await protectedAxiosInstance.delete(`/admin/products/${id}`);
      let settablePage: any;
      if ((totalCount - 1) % pageLength === 0) {
        settablePage = page - 1;
      } else {
        settablePage = page;
      }
      setPage(settablePage === 0 ? 1 : settablePage);
      setDeleteModal("");
      toast.success("Product deleted successfully");
      fetchProducts();
    } catch (error: any) {
      console.error(error);
      toast.error(error?.data?.errors?.general);
    }
  };

  // if (status === 200 || status === 204) {
  //   setDeleteModal("");
  //   fetchProducts();
  // }

  const [time, setTime] = useState(0);
  const debouncedFetchProducts = _debounce(fetchProducts, time);
  useEffect(() => {
    setPage(1);
    setTime(500);
    const fetchData = async () => {
      await debouncedFetchProducts();
    };
    fetchData();
    return () => {
      debouncedFetchProducts.cancel();
    };
  }, [inputVal, pageLength]);

  useEffect(() => {
    setTime(0);
    const fetchData = async () => {
      await debouncedFetchProducts();
    };
    fetchData();
    return () => {
      debouncedFetchProducts.cancel();
    };
  }, [page, filteredCategory]);

  // const downloadReport = async () => {
  //   const response = await fetch("your-api-url", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/xml", // Set the content type to match your API
  //     },
  //   });

  //   const blob = await response.blob();

  //   const url = window.URL.createObjectURL(new Blob([blob]));
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", "report.xml");
  //   document.body.appendChild(link);
  //   link.click();
  // };

  // console.log(seriesList, "seriesList");

  const handleCopy = async (id: string) => {
    if (rowLoading) {
      return;
    }

    setRowLoading(true);

    protectedAxiosInstance
      .post(`/admin/products/${id}/copy`)
      .then((res) => {
        toast.success("Product copied successfully!");
        navigate(`/products/${res.data.data.product.id}`);
      })
      .catch((err) => {
        toast.error("Error copying product.");
      })
      .finally(() => {
        setRowLoading(false);
      });
  };

  const COLUMN = [
    {
      id: "sr",
      Header: () => <p className="whitespace-nowrap">Sr. No.</p>,
      width: "60px",
      accessor: "sr",
      Cell: ({ row }: { row: any }) => <p>{startIndex + row.index + 1}</p>,
    },
    {
      id: "Code",
      Header: () => <p>Code</p>,
      accessor: "code",
      width: "120px",
      Cell: ({ value }: { value: any }) => {
        return (
          <p className="line-clamp-1" title={removeExtraSpaces(value)}>
            {removeExtraSpaces(value) || "-"}
          </p>
        );
      },
    },
    {
      id: "Position",
      Header: () => <p>Position</p>,
      accessor: "position",
      width: "140px",
      Cell: ({ value }: { value: any }) => {
        return (
          <p className="line-clamp-1" title={removeExtraSpaces(value)}>
            {removeExtraSpaces(value) || "-"}
          </p>
        );
      },
    },
    {
      id: "Name",
      Header: () => <p className="whitespace-nowrap">Product Name</p>,
      accessor: "name",
      width: "150px",
      Cell: ({ value }: { value: any }) => (
        <p className="line-clamp-1" title={removeExtraSpaces(value)}>
          {removeExtraSpaces(value) || "-"}
        </p>
      ),
    },
    {
      id: "Description",
      Header: () => <p>Description</p>,
      width: "130px",
      accessor: "description",
      Cell: ({ value }: { value: any }) => (
        <p className="line-clamp-1" title={removeExtraSpaces(value)}>
          {removeExtraSpaces(value) || "-"}
        </p>
      ),
    },
    {
      id: "Picture",
      width: "80px",
      Header: () => <p>Picture</p>,
      accessor: "primary_image",
      Cell: ({ value }: { value: any }) => (
        <img
          src={`${process.env.REACT_APP_BUCKET}/${value}`} //eslint-disable-line
          alt="picture"
          className="w-8 h-8 object-contain m-auto my-1"
        />
      ),
    },
    {
      id: "brand",
      width: "80px",
      Header: () => <p>Brand</p>,
      accessor: "brand",
      Cell: ({ value }: any) => {
        const brand: any = value?.name;
        return (
          <p className="line-clamp-1" title={removeExtraSpaces(brand)}>
            {removeExtraSpaces(brand) || "-"}
          </p>
        );
      },
    },
    {
      id: "series",
      width: "90px",
      Header: () => <p>Series</p>,
      accessor: "series",
      Cell: ({ value }: any) => {
        const series: any = value?.full_name;
        return (
          <p className="line-clamp-1" title={removeExtraSpaces(series)}>
            {removeExtraSpaces(series) || "-"}
          </p>
        );
      },
    },
    {
      id: "category",
      width: "100px",
      Header: () => <p>Category</p>,
      accessor: "category",
      Cell: ({ value }: any) => {
        const category: any = value?.name;

        return (
          <p className="line-clamp-1" title={removeExtraSpaces(category)}>
            {removeExtraSpaces(category) || "-"}
          </p>
        );
      },
    },

    {
      id: "Price",
      width: "70px",
      Header: () => <div>Price</div>,
      accessor: "base_price",
      Cell: ({ value }: { value: number }) => {
        return <p className="text-right">{formattedPrice(value, 0) || "-"}</p>;
      },
    },
    ...(hideActionColumn
      ? [
          {
            id: "action",
            width: "15%",
            Header: () => <p>Actions</p>,
            accessor: "action",
            Cell: ({ value, row }: { value: any; row: any }) => (
              <div className="flex gap-x-5 px-2 items-center">
                {rowLoading ? (
                  <div className="cursor-not-allowed opacity-50">
                    <EditIcon />
                  </div>
                ) : (
                  <>
                    {hasEditPermission && (
                      <Link
                        to={`/products/${row.original.id}`}
                        className="cursor-pointer opacity-100"
                      >
                        <EditIcon />
                      </Link>
                    )}
                  </>
                )}
                {hasEditPermission && (
                  <img
                    onClick={() => {
                      handleCopy(row.original.id);
                    }}
                    src={CopyIcon}
                    alt="CopyIcon"
                    className={` h-4 w-4 ${
                      rowLoading
                        ? "opacity-50 cursor-not-allowed"
                        : "cursor-pointer opacity-100"
                    }`}
                  />
                )}

                {hasDeletePermission && (
                  <button
                    className={`scale-75 ${
                      rowLoading
                        ? "opacity-50 cursor-not-allowed"
                        : "cursor-pointer opacity-100"
                    }`}
                    onClick={() => {
                      if (rowLoading) return;
                      setDeleteModal(row.original.id);
                    }}
                  >
                    <TrashIcon />
                  </button>
                )}

                {hasEditPermission && (
                  <div
                    onClick={() => {
                      if (rowLoading) return;
                      setSettableData((prevData: any) =>
                        prevData.map((product: any) => {
                          if (product.id === row.original.id) {
                            return {
                              ...product,
                              is_active: !row.original.is_active,
                            };
                          }
                          return product;
                        })
                      );
                      handleStatusToggle(
                        row.original.id,
                        row.original.is_active
                      );
                    }}
                    className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white h-8 w-12"
                  >
                    {row.original.is_active ? (
                      <img
                        src={activeStatus}
                        alt="status-icon"
                        className="w-full object-contain h-full"
                      />
                    ) : (
                      <img
                        src={DeActiveStatus}
                        alt="status-icon"
                        className="w-full object-contain h-full"
                      />
                    )}
                  </div>
                )}
              </div>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    const lastIndex = (page - 1) * pageLength;
    setStartIndex(lastIndex);
  }, [page, pageLength, totalCount]);

  const columns = useMemo(
    () => COLUMN,
    [settableData, isTogglingStatus, rowLoading]
  );

  const data = useMemo(() => settableData || [], [settableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
      useSticky,
    },
    useSortBy,
    usePagination
  );

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };

  if (!hasViewPermission) {
    navigate("/unauthorized");
  }
  return (
    <div className="h-full">
      <div className="flex flex-row justify-between items-center py-2 w-full px-3 h-[3rem] relative">
        <div className="flex gap-4 w-[max-content] ">
          <div className=" w-[235px]">
            <InputField
              onChange={(e: string) => setInputVal(e)}
              placeholder="Search here"
              value={inputVal}
              rightIcon={<SearchIcon />}
              withDebounce
            />
          </div>
          {hasCreatePermission && (
            <div className="w-fit">
              <Link to={"/products/create"}>
                <Button label="Create Product" leftIcon={<PlusIcon />} />
              </Link>
            </div>
          )}
        </div>
        {/* Filter */}
        <div className="absolute bottom-0 right-0 pr-3 pb-1.5">
          <SelectField2
            disabled={loading}
            label="Category"
            placeholder="Select a Category"
            value={filteredCategory}
            onChange={(e) => {
              if (e === filteredCategory) {
                setFilteredCategory("");
              } else {
                setFilteredCategory(e);
              }
            }}
            removeOption={() => {
              setFilteredCategory("");
            }}
            options={categoryList.map((el: any) => ({
              value: el?.id,
              label: el?.name,
            }))}
            leftIcon={<FilterIcon />}
          />
        </div>
      </div>

      <div className="h-[calc(100%-5.5rem)] w-full relative -z-0">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={loading}
        />
      </div>

      {loading ? (
        ""
      ) : (
        <div className="w-full">
          <Pagination
            currentPage={page}
            pageSize={pageLength}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}

      {deleteModal && (
        <Modal
          top
          header="Delete Product"
          footer={
            <div className="flex gap-x-3">
              <Button
                variant="primary"
                label="Delete"
                onClick={() => handleDelete(deleteModal)}
              />
              <Button
                variant="secondary"
                label="Cancel"
                onClick={() => {
                  setDeleteModal("");
                }}
              />
            </div>
          }
          handleCancel={() => setDeleteModal("")}
        >
          <p className="text-left">
            Are you sure you want to delete the product?
          </p>
        </Modal>
      )}
    </div>
  );
};

export default Table;
