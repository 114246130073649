import React from "react";

const LightIcon = ({
  size = "14",
  color = "",
}: {
  size?: string;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52 56H28C27.447 56 27 55.553 27 55V52.176C20.101 47.73 16 40.23 16 32C16 18.767 26.767 8 40 8C53.233 8 64 18.767 64 32C64 40.229 59.899 47.729 53 52.176V55C53 55.553 52.553 56 52 56ZM29 54H51V51.623C51 51.275 51.181 50.952 51.478 50.77C58.066 46.733 62 39.716 62 32C62 19.869 52.131 10 40 10C27.869 10 18 19.869 18 32C18 39.716 21.934 46.732 28.522 50.771C28.819 50.952 29 51.276 29 51.623V54Z"
        fill="#D4A15E"
      />
      <path
        d="M49.999 69H30C29.734 69 29.48 68.895 29.293 68.707L27.293 66.707C27.105 66.52 27 66.266 27 66V55C27 54.447 27.447 54 28 54H52C52.553 54 53 54.447 53 55V66C53 66.266 52.895 66.52 52.707 66.707L50.706 68.707C50.519 68.895 50.264 69 49.999 69ZM30.414 67H49.585L51 65.586V56H29V65.586L30.414 67Z"
        fill="#D4A15E"
      />
      <path
        d="M26.9987 61C26.4647 61 26.0207 60.577 26.0007 60.038C25.9797 59.486 26.4097 59.021 26.9617 59.001L52.9617 58.001C53.4947 57.96 53.9777 58.409 53.9987 58.962C54.0197 59.514 53.5897 59.979 53.0377 59.999L27.0377 60.999C27.0247 61 27.0127 61 26.9987 61Z"
        fill="#D4A15E"
      />
      <path
        d="M26.9987 65C26.4647 65 26.0207 64.577 26.0007 64.038C25.9797 63.486 26.4097 63.021 26.9617 63.001L52.9617 62.001C53.4947 61.971 53.9777 62.409 53.9987 62.962C54.0197 63.514 53.5897 63.979 53.0377 63.999L27.0377 64.999C27.0247 65 27.0127 65 26.9987 65Z"
        fill="#D4A15E"
      />
      <path
        d="M39.9998 72.018C37.4988 72.018 35.1708 70.669 33.9258 68.498C33.6508 68.019 33.8168 67.407 34.2948 67.133C34.7738 66.859 35.3858 67.024 35.6598 67.502C36.5508 69.054 38.2128 70.018 39.9998 70.018C41.7868 70.018 43.4488 69.054 44.3398 67.502C44.6138 67.023 45.2248 66.858 45.7048 67.133C46.1838 67.407 46.3498 68.019 46.0738 68.498C44.8288 70.669 42.5008 72.018 39.9998 72.018Z"
        fill="#D4A15E"
      />
      <path
        d="M40 28.002C37.794 28.002 36 26.208 36 24.002C36 21.796 37.794 20.002 40 20.002C42.206 20.002 44 21.796 44 24.002C44 26.208 42.206 28.002 40 28.002ZM40 22.002C38.897 22.002 38 22.899 38 24.002C38 25.105 38.897 26.002 40 26.002C41.103 26.002 42 25.105 42 24.002C42 22.899 41.103 22.002 40 22.002Z"
        fill="#D4A15E"
      />
      <path
        d="M31.9955 45.001C31.8525 45.001 31.7085 44.971 31.5705 44.905C31.0705 44.671 30.8565 44.075 31.0915 43.575L39.0935 26.577C39.3275 26.078 39.9225 25.86 40.4235 26.098C40.9235 26.332 41.1375 26.928 40.9025 27.428L32.9005 44.427C32.7305 44.789 32.3715 45.001 31.9955 45.001Z"
        fill="#D4A15E"
      />
      <path
        d="M31.9955 38.996C31.8525 38.996 31.7085 38.966 31.5705 38.9C31.0705 38.666 30.8565 38.07 31.0915 37.57L36.7805 25.484C37.0145 24.984 37.6095 24.767 38.1105 25.005C38.6105 25.239 38.8245 25.835 38.5895 26.335L32.9005 38.422C32.7305 38.784 32.3715 38.996 31.9955 38.996Z"
        fill="#D4A15E"
      />
      <path
        d="M31.9961 46C31.4431 46 30.9961 45.553 30.9961 45V37.996C30.9961 37.443 31.4431 36.996 31.9961 36.996C32.5491 36.996 32.9961 37.443 32.9961 37.996V45C32.9961 45.553 32.5491 46 31.9961 46Z"
        fill="#D4A15E"
      />
      <path
        d="M48.0063 45.001C47.6303 45.001 47.2713 44.789 47.1013 44.427L39.0993 27.429C38.8643 26.929 39.0783 26.333 39.5783 26.099C40.0783 25.86 40.6733 26.078 40.9083 26.578L48.9103 43.576C49.1453 44.076 48.9313 44.672 48.4313 44.906C48.2933 44.971 48.1483 45.001 48.0063 45.001Z"
        fill="#D4A15E"
      />
      <path
        d="M48.0064 38.996C47.6304 38.996 47.2714 38.784 47.1014 38.422L41.4094 26.33C41.1754 25.83 41.3884 25.234 41.8884 25C42.3884 24.762 42.9844 24.979 43.2184 25.479L48.9104 37.571C49.1444 38.071 48.9314 38.667 48.4314 38.901C48.2934 38.966 48.1484 38.996 48.0064 38.996Z"
        fill="#D4A15E"
      />
      <path
        d="M48.0039 46C47.4509 46 47.0039 45.553 47.0039 45V37.996C47.0039 37.443 47.4509 36.996 48.0039 36.996C48.5569 36.996 49.0039 37.443 49.0039 37.996V45C49.0039 45.553 48.5569 46 48.0039 46Z"
        fill="#D4A15E"
      />
      <path
        d="M40 22.002C39.447 22.002 39 21.555 39 21.002V18.001C39 17.448 39.447 17.001 40 17.001C40.553 17.001 41 17.448 41 18.001V21.002C41 21.555 40.553 22.002 40 22.002Z"
        fill="#D4A15E"
      />
      <path
        d="M48.0037 36.002H46.5938C46.0408 36.002 45.5938 35.555 45.5938 35.002C45.5938 34.449 46.0408 34.002 46.5938 34.002H48.0037C48.5567 34.002 49.0037 34.449 49.0037 35.002C49.0037 35.555 48.5567 36.002 48.0037 36.002Z"
        fill="#D4A15E"
      />
      <path
        d="M43.7694 36.002H36.2344C35.6814 36.002 35.2344 35.555 35.2344 35.002C35.2344 34.449 35.6814 34.002 36.2344 34.002H43.7694C44.3224 34.002 44.7694 34.449 44.7694 35.002C44.7694 35.555 44.3214 36.002 43.7694 36.002Z"
        fill="#D4A15E"
      />
      <path
        d="M33.4041 36.002H31.9961C31.4431 36.002 30.9961 35.555 30.9961 35.002C30.9961 34.449 31.4431 34.002 31.9961 34.002H33.4041C33.9571 34.002 34.4041 34.449 34.4041 35.002C34.4041 35.555 33.9571 36.002 33.4041 36.002Z"
        fill="#D4A15E"
      />
      <path
        d="M40.0187 25.004C39.4657 25.004 39.0137 24.557 39.0137 24.004C39.0137 23.451 39.4557 23.004 40.0077 23.004H40.0187C40.5707 23.004 41.0187 23.451 41.0187 24.004C41.0187 24.557 40.5697 25.004 40.0187 25.004Z"
        fill="#D4A15E"
      />
    </svg>
  );
};

export default LightIcon;
