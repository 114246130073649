import { use, useEffect, useState } from "react";
import Button from "../../components/Button";
import InputField from "../../components/InputField";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createEditConsultantSchema,
  designConsultantDefaultValues,
} from "../../validators/CreateEditConsultantSchema";
import SelectField2 from "../../components/SelectedField2";
import ProjectFileUpload from "../ProjectManagment/components/ProjectFileUpload";
import TextArea from "../../components/TextArea";
import _debounce from "lodash/debounce";
import { protectedAxiosInstance } from "../../api/axiosManagement";
import {
  getLightingConsultant,
  postLightingConsultant,
} from "../../api/lightingConsultant";
import AddressCard from "./components/AddressComponents/AddressCard";
import { toast } from "react-toastify";
import ConsultantUpload from "../../components/ConsultantUpload";
import ErrorMessage from "../../components/ErrorMessage";
import { checkPermissions } from "../../utils/helper";

function CreateOrder() {
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: designConsultantDefaultValues,
    resolver: yupResolver(createEditConsultantSchema),
  });

  const watchCustomerFiles = watch("customer_files");

  const { id: orderId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  const [customerLoading, setCustomerLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [customerId, setCustomerId] = useState<string>("");

  const [searchText, setSearchText] = useState("");
  const [totalPage, setTotalPage] = useState(0);

  const [consultantLoading, setConsultantLoading] = useState(true);
  const [consultants, setConsultants] = useState<any[]>([]);

  const [consultantSearchText, setConsultantSearchText] = useState("");
  const [consultantPage, setConsultantPage] = useState(1);
  const [consultantTotalPage, setConsultantTotalPage] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);

  // Permissions
  const hasCreatePermission = checkPermissions("create_lc_order", true, toast);

  useEffect(() => {
    if (errors?.address_billing?.message) {
      clearErrors("address_billing");
    }
    if (errors?.customer_files?.message) {
      clearErrors("customer_files");
    }
  }, []);

  // Add this new useEffect to update form value when address is selected
  useEffect(() => {
    if (selectedAddress) {
      setValue("address_billing", selectedAddress.id);
      if (errors?.address_billing?.message) {
        clearErrors("address_billing");
      }
    } else {
      setValue("address_billing", "");
    }
  }, [selectedAddress, setValue, clearErrors]);

  const fetchCustomers = async () => {
    setCustomerLoading(true);
    try {
      const res = await protectedAxiosInstance.get("admin/accounts/customers", {
        params: { length: 50, page, search: searchText },
      });

      const newCustomers = res.data.data.customers.map((customer: any) => ({
        value: customer.id,
        label: customer.name,
      }));

      setCustomerList((prevCustomers) =>
        page === 1 ? newCustomers : [...prevCustomers, ...newCustomers]
      );

      setTotalPage(res.data.data.pagination.total_pages);
      setCustomerLoading(false);
    } catch (error) {
      console.error("Error fetching customers:", error);
      setCustomerLoading(false);
    }
  };

  const [time, setTime] = useState(500);
  const debouncedfetchCustomers = _debounce(fetchCustomers, time);

  useEffect(() => {
    setTime(500);
    setPage(1);
    setCustomerList([]);
    setCustomerLoading(true);
    const fetchData = async () => {
      await debouncedfetchCustomers();
    };
    fetchData();
    return () => {
      debouncedfetchCustomers.cancel();
    };
  }, [searchText]);

  // Update the fetchConsultants function
  const fetchConsultants = async () => {
    try {
      setConsultantLoading(true);
      const response = await getLightingConsultant({
        length: 50,
        page: consultantPage,
        search: consultantSearchText,
      });

      const consultantList = response.data.lighting_consultants
        .filter((user: any) => user.lc_name && user.lc_name.trim() !== "")
        .map((user: any) => ({
          label: user.lc_name,
          value: user.id,
        }));

      setConsultants((prevConsultants) =>
        consultantPage === 1
          ? consultantList
          : [...prevConsultants, ...consultantList]
      );
      setConsultantTotalPage(response.data.pagination.total_pages);
    } catch (error) {
      console.error("Error fetching consultants:", error);
    } finally {
      setConsultantLoading(false);
    }
  };

  const debouncedFetchConsultants = _debounce(fetchConsultants, 500);

  // Add useEffect for consultant search
  useEffect(() => {
    setConsultantPage(1);
    setConsultants([]);
    setConsultantLoading(true);
    const fetchData = async () => {
      await debouncedFetchConsultants();
    };
    fetchData();
    return () => {
      debouncedFetchConsultants.cancel();
    };
  }, [consultantSearchText]);

  const disableCustomerFiles =
    watchCustomerFiles && watchCustomerFiles?.length >= 5;

  const onSubmit = async (data: any) => {
    // Transform customer_files array to remove 'added' property
    const transformedFiles = data.customer_files.map(
      ({ added, ...rest }: any) => rest
    );
    const payload = {
      ordered_for: customerId || null,
      address_billing: selectedAddress?.id || null,
      additional_information: data.additional_information || "N/A",
      consultant_id: data.assign_consultant || null,
      customer_files: transformedFiles || null,
      coupon_code: data.coupon_code || null,
    };

    try {
      setLoading(true);
      await postLightingConsultant(payload);
      toast.success("Consultant Order created successfully");
      navigate("/consultancy-list");
    } catch (error: any) {
      // Handle field-specific validation errors
      if (error?.data?.errors) {
        const backendErrors = error?.data?.errors;
        Object.entries(backendErrors).forEach(([field, messages]) => {
          const message = Array.isArray(messages) ? messages[0] : messages;
          setError(field as any, {
            type: "manual",
            message: message as string,
          });
        });
        toast.error(backendErrors?._schema[0]);
      } else {
        // Handle general API errors
        const errorMessage =
          error?.response?.data?.message ||
          "Failed to process your request. Please try again.";
        toast.error(errorMessage);
      }
      console.error("Consultant Order Error:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!hasCreatePermission) {
    navigate("/consultancy-list");
  }

  return (
    <div className="h-[calc(100vh-2.5rem)] no-scrollbar w-full flex flex-col justify-start items-start">
      <div className="z-50 top-0 bg-white border-b border-[#ECECEC] w-full pt-4 pb-4 px-4">
        <p className="flex font-gilroy-semi-bold font-normal text-[16px] leading-[20px]">
          {orderId ? "Edit Consultant Order" : "Create Consultant Order"}
        </p>
      </div>
      <div className="flex w-full justify-between flex-1 overflow-y-auto custom-scrollbar">
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className="w-full flex"
        >
          {/* Main content area */}
          <div className="xl:w-[50vw] md:w-[70vw] w-full no-scrollbar overflow-y-auto custom-scrollbar pr-[150px] mb-4">
            <div className="mx-4 flex flex-col gap-4">
              {/* Customer Dropdown */}
              <div className="mt-4">
                <Controller
                  name="customer"
                  control={control}
                  render={({ field }) => (
                    <SelectField2
                      label="Enter Customer name:"
                      options={customerList}
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                        setCustomerId(value);
                      }}
                      placeholder="Select Customer"
                      hint={errors?.customer?.message}
                      disabled={loading}
                      required
                      loading={customerLoading}
                      totalPage={totalPage}
                      page={page}
                      setPage={setPage}
                      externalSearch={searchText}
                      externalSetSearch={setSearchText}
                      searchPlaceHolder="Search customer"
                      openListCss="overflow-y-scroll h-[180px]"
                    />
                  )}
                />
              </div>

              <AddressCard
                title="Billing Address"
                label="Billing Address"
                customerId={customerId}
                setSelectedAddress={setSelectedAddress}
                selectedAddress={selectedAddress}
                address={selectedAddress}
                disabled={customerId ? false : true}
                required={true}
              />
              {errors?.address_billing?.message ? (
                <ErrorMessage
                  className="-mt-4"
                  error={errors?.address_billing?.message || ""}
                />
              ) : null}

              {/* Customer Files */}
              <div className="mb-2">
                <Controller
                  name="customer_files"
                  control={control}
                  render={({ field }) => (
                    <ConsultantUpload
                      label="Customer Files"
                      isMulti
                      kind="LC_USER_FILES"
                      setter={(values) => {
                        field.onChange(values);
                      }}
                      imageList={(field.value || []).filter(
                        (item): item is string => !!item
                      )}
                      removeError={() => {
                        clearErrors("customer_files");
                      }}
                      setMediaLoading={setFileLoading}
                      disabled={disableCustomerFiles || loading}
                      required
                      errors={errors?.customer_files?.message}
                    />
                  )}
                />
              </div>

              {/* Additional Information */}
              <div className="mb-2">
                <Controller
                  name="additional_information"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      label="Additional Information"
                      placeholder="Add Comments"
                      rows={3}
                      value={field.value}
                      onChange={field.onChange}
                      hint={errors?.additional_information?.message}
                    />
                  )}
                />
              </div>

              {/* Assign Consultant */}
              <div className="mb-2">
                <Controller
                  name="assign_consultant"
                  control={control}
                  render={({ field }) => (
                    <SelectField2
                      label="Assign consultant"
                      options={consultants}
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      placeholder="Assign Consultant"
                      hint={errors?.assign_consultant?.message}
                      disabled={loading}
                      loading={consultantLoading}
                      totalPage={consultantTotalPage}
                      page={consultantPage}
                      setPage={setConsultantPage}
                      externalSearch={consultantSearchText}
                      externalSetSearch={setConsultantSearchText}
                      searchPlaceHolder="Search consultant"
                      openListCss="overflow-y-scroll h-[140px] z-[999]"
                      required
                    />
                  )}
                />
              </div>

              {/* Coupon Code */}
              <div className="">
                <Controller
                  name="coupon_code"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      label="Coupon Code"
                      placeholder="Enter Coupon Code"
                      value={field.value}
                      onChange={(e) => field.onChange(e.toUpperCase())}
                      hint={errors?.coupon_code?.message}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* Footer */}
      <div className="bg-white bo flex gap-x-4 w-[100%] py-3 border-t border-[#ECECEC] px-4">
        <Button
          label={"Create"}
          variant="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={loading || fileLoading}
          type="submit"
        />
        <Button
          label="Cancel"
          variant="secondary-outline"
          onClick={() => navigate("/consultancy-list")}
        />
      </div>
    </div>
  );
}

export default CreateOrder;
