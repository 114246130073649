import * as yup from "yup";

export const updateTimelineDefaultValues = {
  est_delivery_at: "",
};

export const updateTimelineFormSchema = yup.object().shape({
  est_delivery_at: yup.string().trim().required("Timeline is required"),
});

export type UpdateTimelineFormSchema = yup.InferType<
  typeof updateTimelineFormSchema
>;
