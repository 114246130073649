import React, { useState } from "react";
import { CustomDropdown } from "../ProfessionalDetail/DropDown";
import CalendarIcon from "../../assets/svg-tsx/CalendarIcon";
import OrderStatusTag from "../../components/OrderStatusTag";
import PaymentStatusTag from "../../components/PaymentStatusTag";
import Button from "../../components/Button";
import HistoryClockIcon from "../../assets/svg-tsx/HistoryClockIcon";
import RecordPaymentForm from "./RecordPayment";
import ConsultantAddressPopup from "./ConsultantAddressPopup";
import DownloadInvoicesSection from "./DownloadInvoicesSection";
import PaymentDetails from "../OrderManagment/components/PaymentDetails";
import { cancelOrderLC, closeOrderLC } from "../../api/lightingConsultant";
import { toast } from "react-toastify";

function getDaySuffix(day: any) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

function formatDate(dateString: any, format: string = "default") {
  const date = new Date(dateString);

  // Add IST offset (5 hours and 30 minutes)
  date.setHours(date.getHours() + 5);
  date.setMinutes(date.getMinutes() + 30);

  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "long" });
  const year = date.getFullYear();

  // Extract the day and add 'st', 'nd', or 'rd' as appropriate
  const daySuffix = getDaySuffix(day);

  const hour = date.getHours();
  const minute = date.getMinutes();
  const ampm = hour >= 12 ? "PM" : "AM";
  const formattedHour = hour % 12 === 0 ? "12" : (hour % 12).toString();
  const formattedMinute = minute < 10 ? `0${minute}` : minute.toString();

  const formattedDate = `${day}${daySuffix} ${month} ${year}`;
  const formattedTime = `${formattedHour}:${formattedMinute} ${ampm}`;

  if (format === "date") {
    return formattedDate;
  } else if (format === "time") {
    return formattedTime;
  } else {
    return { date: formattedDate, time: formattedTime };
  }
}

type OrderDetailsTwoProps = {
  orderDetails?: any;
  paymentLogs?: any;
  fetchPaymentLogs?: () => void;
  fetchSingleOrderDetails?: () => void;
  orderId?: string;
};

export default function OrderDetailsTwo({
  orderDetails,
  paymentLogs,
  fetchPaymentLogs = () => {},
  fetchSingleOrderDetails = () => {},
  orderId,
}: OrderDetailsTwoProps) {
  const [paymentModal, setPaymentModal] = useState(false);

  const orderFormattedDate: any = formatDate(orderDetails?.created_at, "date");

  const orderFormattedTime: any = formatDate(orderDetails?.created_at, "time");

  const handlePaymentDetailsModal = () => {
    setPaymentModal(true);
  };

  // API call for cancel order
  const handleCancelOrder = () => {
    cancelOrderLC(orderId)
      .then((response) => {
        toast.success("Order cancelled successfully");
        fetchSingleOrderDetails();
      })
      .catch((error) => {
        console.log(error?.data?.errors);
        toast.error(error?.data?.errors);
      });
  };

  // API call for close order
  const handleCloseOrder = () => {
    closeOrderLC(orderId)
      .then((response) => {
        toast.success("Order closed successfully");
        fetchSingleOrderDetails();
      })
      .catch((error) => {
        console.log(error?.data?.errors);
        toast.error(error?.data?.errors);
      });
  };

  const date = (
    <div className="flex gap-3 items-center">
      <CalendarIcon />
      <div className="flex items-center gap-3">
        <p className="text-[#AEAEAE] font-gilroy-semi-bold text-[12px] tracking-normal">
          {orderFormattedDate},
        </p>

        <p className="text-[#AEAEAE] font-gilroy-semi-bold text-[12px] tracking-normal">
          {orderFormattedTime}
        </p>
      </div>
    </div>
  );
  return (
    <CustomDropdown
      label={`Order id: #${orderDetails?.order_no}`}
      headerClassName="!h-12 border-b-[2px] border-b-[#DEDEDE]"
      date={date}
      // containerClassName="overflow-x-auto custom-scrollbar"
    >
      <div>
        <div className="h-[64px]">
          <div className="flex flex-row justify-between h-full">
            <div className="flex flex-row gap-3">
              <div className="flex flex-col gap-1 ml-5 my-2">
                <p className="text-[#1C1C1C] font-gilroy-semi-bold text-xs">
                  Order Status
                </p>
                {orderDetails?.status && (
                  <p className="text-black font-gilroy-semi-bold text-xs">
                    <OrderStatusTag status={orderDetails?.status} />
                  </p>
                )}
              </div>
              <div className="w-[1px] !h-full bg-[#DEDEDE] mx-5"></div>

              <div className="flex flex-col gap-1 my-2">
                <p className="text-[#1C1C1C] font-gilroy-semi-bold text-xs">
                  Payment Status
                </p>
                <p className="text-black font-gilroy-semi-bold text-xs">
                  <PaymentStatusTag status={orderDetails?.payment_status} />
                </p>
              </div>
            </div>

            <div className="flex items-center mr-5">
              {orderDetails?.allowed_actions?.can_close_order && (
                <Button
                  label="Close Order"
                  variant="secondary-text"
                  className="!text-[#A3A3A3] text-[12px] font-normal font-gilroy-medium"
                  onClick={handleCloseOrder}
                />
              )}
              {orderDetails?.allowed_actions?.can_cancel_order && (
                <Button
                  label="Cancel Order"
                  variant="secondary-text"
                  className="!text-[#FF5B5B] text-[12px] font-normal font-gilroy-medium"
                  onClick={handleCancelOrder}
                />
              )}
              {orderDetails?.status === "CANCELLED" && (
                <p className="text-[#FF5B5B] text-sm font-normal font-gilroy-medium">
                  Order Cancelled
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="w-full h-[240px] flex flex-row justify-between border-t-[2px] border-t-[#DEDEDE]">
          <div className="text-left flex-1 flex flex-col w-[790px] max-w-[790px] h-auto justify-between px-5 py-4 border-r">
            <div className="flex justify-between items-center text-start mb-3">
              <p className="text-black font-gilroy-semi-bold text-xs w-[85px]">
                Payment Info
              </p>

              <div className="mx-2 min-w-[110px]">
                {orderDetails?.requested_offline_payment && (
                  <PaymentStatusTag status="OFFLINE_MODE" />
                )}
              </div>

              <div className="flex flex-row gap-2 ml-2">
                <div>
                  <RecordPaymentForm
                    orderId={orderId}
                    getPaymentLogsApi={fetchPaymentLogs}
                    getSingleOrderApi={fetchSingleOrderDetails}
                    orderDetails={orderDetails}
                  />
                </div>
                <div>
                  <Button
                    label="History"
                    leftIcon={
                      <HistoryClockIcon color="white" width="22" height="22" />
                    }
                    variant="secondary"
                    onClick={handlePaymentDetailsModal}
                    className="h-[30px] !font-gilroy-medium"
                  />
                  {paymentModal && (
                    <PaymentDetails
                      setPaymentModal={setPaymentModal}
                      paymentLogs={paymentLogs}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center text-start">
              <p className="text-[#AEAEAE] w-[40%] text-[12px] leading-[12px] font-gilroy-medium ">
                Base Price
              </p>
              <div className="flex justify-start gap-8 items-center w-[70%]">
                <p className="text-[#1C1C1C] font-gilroy-semi-bold text-center">
                  -
                </p>
                <p className="text-[12px] leading-[12px] font-gilroy-semi-bold">
                  ₹
                  {(orderDetails?.sub_total / 100).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </p>
              </div>
            </div>
            <div className="flex justify-between items-center text-start">
              <p className="text-[#AEAEAE] w-[40%] text-[12px] leading-[12px] font-gilroy-medium ">
                Coupon Discount
              </p>
              <div className="flex justify-start gap-8 items-center w-[70%]">
                <p className="text-[#1C1C1C] font-gilroy-semi-bold text-center">
                  -
                </p>
                <p className="text-[12px] leading-[12px] font-gilroy-semi-bold">
                {orderDetails?.coupon_discount_amount ? 
                  `₹ ${(orderDetails?.coupon_discount_amount / 100).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}`
                : 0}
                </p>
              </div>
            </div>

            <div className="flex  justify-between items-center text-start">
              <p className="text-[#AEAEAE]  text-[12px] leading-[12px] font-gilroy-medium  w-[40%]">
                Total Tax
              </p>
              <div className="flex justify-start gap-8 items-center w-[70%] text-left">
                <p className="text-[#1C1C1C] font-gilroy-semi-bold">-</p>
                <p className="text-[12px] leading-[12px] font-gilroy-semi-bold">
                  ₹
                  {(
                    (orderDetails?.tax_cgst_total +
                      orderDetails?.tax_igst_total +
                      orderDetails?.tax_sgst_total) /
                    100
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </p>
              </div>
            </div>
            <div className="flex justify-between items-center text-start">
              <p className="text-[#AEAEAE]  text-[12px] leading-[12px] font-gilroy-medium  w-[40%]">
                Grand Total
              </p>
              <div className="flex justify-start gap-8 items-center w-[70%]">
                <p className="text-[#1C1C1C] font-gilroy-semi-bold text-center">
                  -
                </p>
                <p className="text-[12px] leading-[12px] font-gilroy-semi-bold">
                  ₹
                  {(orderDetails?.grand_total / 100).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </p>
              </div>
            </div>
            <div className="flex justify-between items-center text-start">
              <p className="text-[#AEAEAE]  text-[12px] leading-[12px] font-gilroy-medium  w-[40%]">
                Total Amount Paid
              </p>

              <div className="flex justify-start gap-8 items-center w-[70%]">
                <p className="text-[#1C1C1C] font-gilroy-semi-bold">-</p>
                <p className="text-[12px] leading-[12px] font-gilroy-semi-bold">
                  ₹
                  {(orderDetails?.amount_paid / 100).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </p>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <ConsultantAddressPopup
              title="Billing Address"
              heading="John Doe"
              content={[
                { line1: orderDetails?.address_billing?.street_1 },
                { line2: orderDetails?.address_billing?.street_2 },
                { city: orderDetails?.address_billing?.city },
                {
                  pincode: orderDetails?.address_billing?.postal_code,
                },
                { state: orderDetails?.address_billing?.state },
                { country: orderDetails?.address_billing?.country },
              ]}
            />
          </div>
          <div className="flex-1 border-l border-l-[#DEDEDE]">
            <DownloadInvoicesSection
              orderId={orderId}
              orderDetails={orderDetails}
              fetchOrderDetails={fetchSingleOrderDetails}
            />
          </div>
        </div>
      </div>
    </CustomDropdown>
  );
}
