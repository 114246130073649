import React from "react";

interface OrderStatusTagProps {
  status: string;
  tagStyles?: string;
  tagCircleStyles?: string;
}

const getStatusColor = (status: string): string => {
  switch (status) {
    case "DRAFT":
      return "bg-[#E2E2E2]"; // Grey
    case "PENDING":
      return "bg-[#ED4C4C]";
    case "AWAITING_TIMELINE":
      return "bg-[#FFF081]"; // Purple
    case "PROCESSING":
      return "bg-[#70B2FF]"; // Yellow
    case "READY_FOR_DISPATCH":
    case "PARTIALLY_READY_FOR_DISPATCH":
      return "bg-[#42C085]"; // Green
    case "DISPATCHED":
    case "PARTIALLY_DISPATCHED":
      return "bg-[#8581FF]"; // Purple
    case "DELIVERED":
    case "PARTIALLY_DELIVERED":
      return "bg-[#0FA94A]"; // Green
    case "READY":
      return "bg-[#ED4C4C]"; // Green
    case "ONGOING":
      return "bg-[#70B2FF]"; // Orange
    case "WAITING_FOR_CONSULTANT":
      return "bg-[#FFA500]"; // Orange
    case "AWAITING_CHANGES":
      return "bg-[#C07D42]"; // Red
    case "COMPLETED":
      return "bg-[#5AEBA2]"; // Green
    case "CANCELLED":
      return "bg-[#EB5A5A]"; // Red
    case "PENDING_APPROVAL":
      return "bg-[#8581FF]"; // Orange
    default:
      return "bg-[#EDEDED]"; // Default grey
  }
};

const formatStatus = (status: string): string => {
  // Replace underscores with spaces and convert to title case
  return status
    ?.split("_")
    ?.map((word) => word.charAt(0) + word.slice(1).toLowerCase())
    ?.join(" ");
};

const OrderStatusTag: React.FC<OrderStatusTagProps> = ({
  status,
  tagStyles,
  tagCircleStyles,
}) => {
  const backgroundColor = getStatusColor(status);
  const formattedStatus = formatStatus(status);

  return (
    <div className={`inline-flex items-center gap-2 py-1`}>
      <div
        className={`w-3 h-3 rounded-full ${backgroundColor} ${tagCircleStyles}`}
      />
      <span
        className={`text-xs font-gilroy-medium capitalize leading-5 ${tagStyles}`}
      >
        {formattedStatus}
      </span>
    </div>
  );
};

export default OrderStatusTag;
