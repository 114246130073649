import { useEffect, useState } from "react";
import WidgetCard from "./components/WidgetCard";
import Filters from "./components/Filters";
import Divider from "./components/Divider";
import ChartWrapper from "./components/ChartWrapper";
import VerticalBarChartComponent from "./components/VerticalBarChartComponent";
import HorizontalBarChartComponent from "./components/HorizontalBarChartComponent";
import DonutChartComponent from "./components/DonutChartComponent";
import CustomTable from "./components/CustomTable";
import LineChartComponent from "./components/LineChartComponent";
import { postAccountsCharts, postCharts } from "../../api/dashboard";
import {
  formatDateTime,
  formatDateToYYYYMMDD,
  getCurrentMonthDateRange,
} from "../../utils/helper";
import { formatNumberIndianRupees } from "../../utils/formatDateTime";

const consultantPerformanceData = {
  name: "Sales",
  values: [
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea971e",
      category_name: "Bikash Rai",
      order_count: 247,
      value: 11226259.61,
    },
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea972e",
      category_name: "Rajesh Rai",
      order_count: 300,
      value: 50000005,
    },
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea973e",
      category_name: "Rittwik De",
      order_count: 100,
      value: 10000000,
    },
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea974e",
      category_name: "Jane Doe",
      order_count: 50,
      value: 10000000,
    },
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea975e",
      category_name: "Akshay Salve",
      order_count: 315,
      value: 10000000,
    },
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea976e",
      category_name: "Vijay",
      order_count: 800,
      value: 50000000,
    },
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea977e",
      category_name: "Tavinder Singh",
      order_count: 700,
      value: 50000000,
    },
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea978e",
      category_name: "Jilani",
      order_count: 600,
      value: 90000000,
    },
  ],
};

const salesByLocationData = {
  name: "# of Sales",
  values: [
    {
      name: "Vadodara, Gujarat",
      value: 25000,
    },
    {
      name: "Surat, Gujarat",
      value: 20000,
    },
    {
      name: "Bhavnagar, Gujarat",
      value: 13000,
    },
    {
      name: "Rajkot, Gujarat",
      value: 1000,
    },
    {
      name: "Amdavad, Gujarat",
      value: 3000,
    },
    {
      name: "Mumbai, Maharashtra",
      value: 5000,
    },
    {
      name: "Delhi, Delhi",
      value: 100000,
    },
    {
      name: "Bengaluru, Karnataka",
      value: 10000,
    },
    {
      name: "Hyderabad, Telangana",
      value: 10000,
    },
    {
      name: "Pune, Maharashtra",
      value: 8000,
    },
  ],
};

const topPerformingCategoriesData = {
  name: "Top Performing Category",
  values: [
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea971e",
      category_name: "Brass magnetic Track - 42V",
      order_count: 247,
      value: 11226259.61,
    },
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea972e",
      category_name: "Ceiling Recessed COB",
      order_count: 300,
      value: 500000000005,
    },
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea973e",
      category_name: "Surface COB",
      order_count: 100,
      value: 100000000000,
    },
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea974e",
      category_name: "Magnetic Track 48V Dali - 4 Wire",
      order_count: 50,
      value: 15000000,
    },
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea975e",
      category_name: "Recessed LED Profile",
      order_count: 315,
      value: 180000000,
    },
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea976e",
      category_name: "Surface LED Profiles",
      order_count: 800,
      value: 1150000000,
    },
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea977e",
      category_name: "Suspended LED Profiles",
      order_count: 700,
      value: 1150000000,
    },
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea978e",
      category_name: "Superslim 48V Magnetic Track",
      order_count: 600,
      value: 1190000000,
    },
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea979e",
      category_name: "Ceiling Recessed SMD",
      order_count: 600,
      value: 11190000000,
    },
    {
      category_id: "f70ca905-0fda-4a84-831b-ba2450ea979e",
      category_name: "Surface SMD",
      order_count: 300,
      value: 1150000000,
    },
  ],
};

const topPerformingProductsData = {
  name: "Top Performing Products",
  values: [
    {
      name: "ARTHUR - LED Recessed Spotlight - IP54",
      value: 25000,
    },
    {
      name: "TITAN - Glare-less Concealed LED Spotlight",
      value: 20000,
    },
    {
      name: "DOOM - Deep recessed LED Antiglare downlight - IP54",
      value: 13000,
    },
    {
      name: "LAURUS - LED Recessed Spotlight",
      value: 1000,
    },
    {
      name: "KRITIOS - Narrow Degree LED spotlight",
      value: 3000,
    },
    {
      name: "VIBE - Trimless Laser Light",
      value: 5000,
    },
    {
      name: "APOLLO - COB Laser antiglare spotlight - IP - 65",
      value: 100000,
    },
    {
      name: "ALEXIS - Antiglare spotlight - IP54",
      value: 10000,
    },
    {
      name: "ELIAN - Smooth antiglare deep recessed spotlight",
      value: 10000,
    },
    {
      name: "RHAPSODY - Golden LED Spotlight",
      value: 8000,
    },
  ],
};

const sampleTableHeaderData = [
  {
    id: "1",
    name: "Category Name",
    className: "w-[100px]",
  },
  {
    id: "2",
    name: "Sales",
    className: "w-[100px]",
  },
];

const sampleTableData = [
  {
    categoryName: "Brass magnetic Track - 42V",
    sales: "1000",
  },

  {
    categoryName: "Ceiling Recessed COB",
    sales: "1000",
  },

  {
    categoryName: "Surface COB",
    sales: "1000",
  },

  {
    categoryName: "Magnetic Track 48V Dali - 4 Wire",
    sales: "1000",
  },

  {
    categoryName: "Recessed LED Profile",
    sales: "1000",
  },

  {
    categoryName: "Surface LED Profiles",
    sales: "1000",
  },

  {
    categoryName: "Suspended LED Profiles",
    sales: "1000",
  },

  {
    categoryName: "Superslim 48V Magnetic Track",
    sales: "1000",
  },

  {
    categoryName: "Ceiling Recessed SMD",
    sales: "1000",
  },

  {
    categoryName: "Surface SMD",
    sales: "1000",
  },
];

const lastLoginTableHeader = [
  {
    id: "1",
    name: "Customer Name",
    className: "min-w-[120px]",
  },
  {
    id: "2",
    name: "Email",
    className: "min-w-[80px]",
  },
  {
    id: "3",
    name: "Phone",
    className: "min-w-[100px]",
  },
  {
    id: "4",
    name: "Last Login",
    className: "min-w-[200px]",
  },
];

const numberOfLocations = {
  name: "# of Users",
  values: [
    {
      name: "Vadodara, Gujarat",
      order_count: 25000,
      value: 25000,
    },
    {
      name: "Surat, Gujarat",
      order_count: 20000,
      value: 20000,
    },
    {
      name: "Bhavnagar, Gujarat",
      order_count: 13000,
      value: 13000,
    },
    {
      name: "Rajkot, Gujarat",
      order_count: 1000,
      value: 1000,
    },
    {
      name: "Amdavad, Gujarat",
      order_count: 3000,
      value: 3000,
    },
    {
      name: "Mumbai, Maharashtra",
      order_count: 5000,
      value: 5000,
    },
    {
      name: "Delhi, Delhi",
      order_count: 10000,
      value: 10000,
    },
    {
      name: "Bengaluru, Karnataka",
      order_count: 10000,
      value: 10000,
    },
    {
      name: "Hyderabad, Telangana",
      order_count: 10000,
      value: 10000,
    },
    {
      name: "Pune, Maharashtra",
      order_count: 8000,
      value: 8000,
    },
  ],
};

const highestSpendingCustomersTableHeader = [
  {
    id: "1",
    name: "Customer Name",
    className: "min-w-[120px]",
  },
  {
    id: "2",
    name: "Total Spending",
    className: "min-w-[120px]",
  },
  {
    id: "3",
    name: "No of Orders",
    className: "min-w-[120px]",
  },
];

const lastTenApprovedProfessionalsHeader = [
  {
    id: "1",
    name: "Name",
    className: "min-w-[120px]",
  },
  {
    id: "2",
    name: "Email",
    className: "min-w-[80px]",
  },
  {
    id: "3",
    name: "Phone",
    className: "min-w-[120px]",
  },
  {
    id: "3",
    name: "Approve Date",
    className: "min-w-[120px]",
  },
  {
    id: "3",
    name: "Type",
    className: "min-w-[120px]",
  },
  {
    id: "3",
    name: "Company",
    className: "min-w-[120px]",
  },
  {
    id: "3",
    name: "Location",
    className: "min-w-[120px]",
  },
];

const numberOfProfessionalsPerLocation = {
  name: "# of Professionals",
  values: [
    {
      name: "Vadodara, Gujarat",
      value: 25000,
    },
    {
      name: "Surat, Gujarat",
      value: 20000,
    },
    {
      name: "Bhavnagar, Gujarat",
      value: 13000,
    },
    {
      name: "Rajkot, Gujarat",
      value: 1000,
    },
    {
      name: "Amdavad, Gujarat",
      value: 3000,
    },
    {
      name: "Mumbai, Maharashtra",
      value: 5000,
    },
    {
      name: "Delhi, Delhi",
      value: 10000,
    },
    {
      name: "Bengaluru, Karnataka",
      value: 10000,
    },
    {
      name: "Hyderabad, Telangana",
      value: 10000,
    },
    {
      name: "Pune, Maharashtra",
      value: 8000,
    },
  ],
};

const highestTimesReferredProfessionalHeader = [
  {
    id: "1",
    name: "Name",
    className: "min-w-[120px]",
  },
  {
    id: "2",
    name: "Times Referred",
    className: "min-w-[120px]",
  },
  {
    id: "3",
    name: "Location",
    className: "min-w-[120px]",
  },
  {
    id: "4",
    name: "Total Order Value",
    className: "min-w-[120px]",
  },
];

const highestTimesReferredProfessionalData = [
  {
    name: "John Doe",
    timesReferred: 10,
    location: "Vadodara, Gujarat",
    totalOrderValue: 1000,
  },
  {
    name: "Asif Mirza",
    timesReferred: 10,
    location: "Surat, Gujarat",
    totalOrderValue: 1000,
  },
  {
    name: "Rajesh Rai",
    timesReferred: 10,
    location: "Bhavnagar, Gujarat",
    totalOrderValue: 1000,
  },
  {
    name: "Rajesh Rai",
    timesReferred: 10,
    location: "Bhavnagar, Gujarat",
    totalOrderValue: 1000,
  },
  {
    name: "Rajesh Rai",
    timesReferred: 10,
    location: "Bhavnagar, Gujarat",
    totalOrderValue: 1000,
  },
  {
    name: "Bikash Rai",
    timesReferred: 10,
    location: "Bhavnagar, Gujarat",
    totalOrderValue: 1000,
  },
  {
    name: "Rajesh Rai",
    timesReferred: 10,
    location: "Bhavnagar, Gujarat",
    totalOrderValue: 1000,
  },
  {
    name: "Shriya",
    timesReferred: 10,
    location: "Bhavnagar, Gujarat",
    totalOrderValue: 1000,
  },
  {
    name: "Akshay",
    timesReferred: 10,
    location: "Bhavnagar, Gujarat",
    totalOrderValue: 1000,
  },
  {
    name: "Pinku Singh",
    timesReferred: 10,
    location: "Bhavnagar, Gujarat",
    totalOrderValue: 1000,
  },
];

const lastTenProjectsPostedHeader = [
  {
    id: "1",
    name: "Project Name",
    className: "min-w-[120px]",
  },
  {
    id: "2",
    name: "Location",
    className: "min-w-[120px]",
  },
];

const lastTenProjectsPostedData = [
  {
    projectName: "Project 1",
    location: "Vadodara, Gujarat",
  },
  {
    projectName: "Project 2",
    location: "Surat, Gujarat",
  },
  {
    projectName: "Project 3",
    location: "Bhavnagar, Gujarat",
  },
  {
    projectName: "Project 4",
    location: "Bhavnagar, Gujarat",
  },
  {
    projectName: "Project 5",
    location: "Bhavnagar, Gujarat",
  },
  {
    projectName: "Project 6",
    location: "Bhavnagar, Gujarat",
  },
  {
    projectName: "Project 7",
    location: "Bhavnagar, Gujarat",
  },
  {
    projectName: "Project 8",
    location: "Bhavnagar, Gujarat",
  },
  {
    projectName: "Project 9",
    location: "Bhavnagar, Gujarat",
  },
  {
    projectName: "Project 10",
    location: "Bhavnagar, Gujarat",
  },
];

export default function GeneralAnalytics() {
  //  State for date range
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [appliedStartDate, setAppliedStartDate] = useState<any>(null);
  const [appliedEndDate, setAppliedEndDate] = useState<any>(null);

  const formattedStartDate = appliedStartDate
    ? formatDateToYYYYMMDD(appliedStartDate)
    : "";
  const formattedEndDate = appliedEndDate
    ? formatDateToYYYYMMDD(appliedEndDate)
    : "";

  // Add handlers for DateRangePicker
  const handleDateApply = (start: Date | null, end: Date | null) => {
    setAppliedStartDate(start);
    setAppliedEndDate(end);
  };

  const handleDateClear = () => {
    setStartDate(null);
    setEndDate(null);
    setAppliedStartDate(null);
    setAppliedEndDate(null);
  };

  //Order Overview states
  const [orderOverviewData, setOrderOverviewData] = useState([]);
  const [orderOverviewLoading, setOrderOverviewLoading] = useState(false);

  // States for total live project
  const [totalLiveProjectData, setTotalLiveProjectData] = useState<any>([]);
  const [totalLiveProjectLoading, setTotalLiveProjectLoading] = useState(false);

  // States for Highest Spending Customers Data
  const [highestSpendingCustomersData, setHighestSpendingCustomersData] =
    useState([]);
  const [highestSpendingCustomersLoading, setHighestSpendingCustomersLoading] =
    useState(false);

  // States for signup customer data
  const [signupCustomersData, setSignupCustomersData] = useState<any>([]);
  const [signupCustomersLoading, setSignupCustomersLoading] = useState(false);

  // States for login customer data
  const [loginCustomersData, setLoginCustomersData] = useState<any>([]);
  const [loginCustomersLoading, setLoginCustomersLoading] = useState(false);

  // States for get Number of Professionals per Location
  const [professionalPerLocationData, setProfessionalPerLocationData] =
    useState<any>([]);
  const [professionalPerLocationLoading, setProfessionalPerLocationLoading] =
    useState(false);

  // States for get Number of Professionals per Location
  const [professionalOverviewwData, setProfessionalOverviewwData] =
    useState<any>([]);
  const [professionalOverviewLoading, setProfessionalOverviewLoading] =
    useState(false);

  // States for Order By Status
  const [orderByStatusData, setOrderByStatusData] = useState<any>([]);
  const [orderByStatusLoading, setOrderByStatusLoading] = useState(false);
  useState(false);

  // States for Sales by Location
  const [salesByLocationData, setSalesByLocationData] = useState<any>([]);
  const [salesByLocationLoading, setSalesByLocationLoading] = useState(false);
  useState(false);

  // States for Consultant Performance
  const [consultantPerformanceData, setConsultantPerformanceData] =
    useState<any>([]);
  const [consultantPerformanceLoading, setConsultantPerformanceLoading] =
    useState(false);

  console.log(consultantPerformanceData, "consultantPerformanceData");

  // States for Approve Professionals data
  const [approveProfessionalsData, setApproveProfessionalsData] = useState<any>(
    []
  );
  const [approveProfessionalsLoading, setApproveProfessionalsLoading] =
    useState(false);

  const heighestSpendingCustomerStrectureDate =
    highestSpendingCustomersData.map((item: any, index) => {
      return {
        id: index,
        name: item?.name,
        value: formatNumberIndianRupees(item?.value),
        order_count: item?.order_count,
      };
    });

  const loginCustomersDataStrectureDate = loginCustomersData.map(
    (item: any, index: any) => {
      return {
        id: index,
        name: item?.name,
        email: item?.email,
        phone: item?.phone,
        last_login: formatDateTime(item?.last_login),
      };
    }
  );

  const approveProfessionalsDataStrectureDate = approveProfessionalsData.map(
    (item: any, index: any) => {
      return {
        id: index,
        name: item?.name,
        email: item?.email,
        phone: item?.phone,
        verification_date: formatDateTime(item?.verification_date),
        professional_type: item?.professional_type,
        company: item?.company,
        location: item?.location,
      };
    }
  );

  // Helper function to get value from orderOverviewData
  const getValueFromOrderOverview = (name: any) => {
    const data: any = orderOverviewData.find((item: any) => item.name === name);
    return data?.values[0]?.value || 0;
  };

  useEffect(() => {
    getOrderByStatusData();
    getLoginCustomersData();
    getLCOrderOverviewData();
    getSalesByLocationData();
    getSignupCustomersData();
    getApproveProfessionalData();
    getConsultantPerformanceData();
    getProfessionalOverviewData();
    getProfessionalPerLocationData();
    getTotalNumberOfLiveProjectData();
    getHighestSpendingCustomersData();
  }, [appliedStartDate, appliedEndDate]);

  const formattedDateRange =
    formattedStartDate && formattedEndDate
      ? `${formattedStartDate} : ${formattedEndDate}`
      : getCurrentMonthDateRange();

  // Function to get LC order overview
  const getLCOrderOverviewData = async () => {
    const payload = {
      chart_name: "lc_order_overview",
      filter: {
        date_range: formattedDateRange,
      },
    };

    try {
      setOrderOverviewLoading(true);
      const res = await postCharts(payload);
      setOrderOverviewData(res?.data?.chart_data);
      setOrderOverviewLoading(false);
    } catch (e: any) {
      console.log(e);
      setOrderOverviewLoading(false);
    } finally {
      setOrderOverviewLoading(false);
    }
  };

  // Function to get total number of live project
  const getTotalNumberOfLiveProjectData = async () => {
    const payload = {
      chart_name: "project_count_public",
      filter: {
        date_range: formattedDateRange,
      },
    };

    try {
      setTotalLiveProjectLoading(true);
      const res = await postCharts(payload);
      setTotalLiveProjectData(res?.data?.chart_data?.[0]?.values);
      setTotalLiveProjectLoading(false);
    } catch (e: any) {
      console.log(e);
      setTotalLiveProjectLoading(false);
    } finally {
      setTotalLiveProjectLoading(false);
    }
  };

  // Function to get Highest Spending Customers
  const getHighestSpendingCustomersData = async () => {
    const payload = {
      chart_name: "customer_highest_spend",
      filter: {
        date_range: formattedDateRange,
      },
    };

    try {
      setHighestSpendingCustomersLoading(true);
      const res = await postCharts(payload);
      setHighestSpendingCustomersData(res?.data?.chart_data?.[0]?.values);
      setHighestSpendingCustomersLoading(false);
    } catch (e: any) {
      console.log(e);
      setHighestSpendingCustomersLoading(false);
    } finally {
      setHighestSpendingCustomersLoading(false);
    }
  };

  // Function to get customer signup over time
  const getSignupCustomersData = async () => {
    const payload = {
      chart_name: "customer_signup_trend",
      filter: {
        date_range: formattedDateRange,
      },
    };

    try {
      setSignupCustomersLoading(true);
      const res = await postAccountsCharts(payload);
      setSignupCustomersData(res?.data?.chart_data);
      setSignupCustomersLoading(false);
    } catch (e: any) {
      console.log(e);
      setSignupCustomersLoading(false);
    } finally {
      setSignupCustomersLoading(false);
    }
  };

  // Function to get customer signup over time
  const getLoginCustomersData = async () => {
    const payload = {
      chart_name: "customer_login_trend",
      filter: {
        date_range: formattedDateRange,
      },
    };

    try {
      setLoginCustomersLoading(true);
      const res = await postAccountsCharts(payload);
      setLoginCustomersData(res?.data?.chart_data?.[0]?.values);
      setLoginCustomersLoading(false);
    } catch (e: any) {
      console.log(e);
      setLoginCustomersLoading(false);
    } finally {
      setLoginCustomersLoading(false);
    }
  };

  // Function to get approve professionals
  const getApproveProfessionalData = async () => {
    const payload = {
      chart_name: "approved_professionals",
      filter: {
        date_range: formattedDateRange,
      },
    };

    try {
      setApproveProfessionalsLoading(true);
      const res = await postAccountsCharts(payload);
      setApproveProfessionalsData(res?.data?.chart_data?.[0]?.values);
      setApproveProfessionalsLoading(false);
    } catch (e: any) {
      console.log(e);
      setApproveProfessionalsLoading(false);
    } finally {
      setApproveProfessionalsLoading(false);
    }
  };

  // Function to get Number of Professionals per Location
  const getProfessionalPerLocationData = async () => {
    const payload = {
      chart_name: "professionals_per_location",
      filter: {
        date_range: formattedDateRange,
      },
    };

    try {
      setProfessionalPerLocationLoading(true);
      const res = await postAccountsCharts(payload);
      setProfessionalPerLocationData(res?.data?.chart_data?.[0]?.values);
      setProfessionalPerLocationLoading(false);
    } catch (e: any) {
      console.log(e);
      setProfessionalPerLocationLoading(false);
    } finally {
      setProfessionalPerLocationLoading(false);
    }
  };

  // Function to get Number of Professionals per Location
  const getProfessionalOverviewData = async () => {
    const payload = {
      chart_name: "professional_overview",
    };

    try {
      setProfessionalOverviewLoading(true);
      const res = await postAccountsCharts(payload);
      setProfessionalOverviewwData(res?.data?.chart_data);
      setProfessionalOverviewLoading(false);
    } catch (e: any) {
      console.log(e);
      setProfessionalOverviewLoading(false);
    } finally {
      setProfessionalOverviewLoading(false);
    }
  };

  // Function to get Order By Status
  const getOrderByStatusData = async () => {
    const payload = {
      chart_name: "lc_order_by_status",
      filter: {
        date_range: formattedDateRange,
      },
    };

    try {
      setOrderByStatusLoading(true);
      const res = await postCharts(payload);
      setOrderByStatusData(res?.data?.chart_data[0]?.values);
      setOrderByStatusLoading(false);
    } catch (e: any) {
      console.log(e);
      setOrderByStatusLoading(false);
    } finally {
      setOrderByStatusLoading(false);
    }
  };

  // Function to get Sales by Location
  const getSalesByLocationData = async () => {
    const payload = {
      chart_name: "lc_order_value_by_location",
      filter: {
        date_range: formattedDateRange,
      },
    };

    try {
      setSalesByLocationLoading(true);
      const res = await postCharts(payload);
      setSalesByLocationData(res?.data?.chart_data[0]?.values);
      setSalesByLocationLoading(false);
    } catch (e: any) {
      console.log(e);
      setSalesByLocationLoading(false);
    } finally {
      setSalesByLocationLoading(false);
    }
  };

  // Function to get Consultant Performance
  const getConsultantPerformanceData = async () => {
    const payload = {
      chart_name: "consultant_order_count",
      filter: {
        date_range: formattedDateRange,
      },
    };

    try {
      setConsultantPerformanceLoading(true);
      const res = await postCharts(payload);
      setConsultantPerformanceData(res?.data?.chart_data[0]?.values);
      setConsultantPerformanceLoading(false);
    } catch (e: any) {
      console.log(e);
      setConsultantPerformanceLoading(false);
    } finally {
      setConsultantPerformanceLoading(false);
    }
  };

  return (
    <div className="w-full">
      <Filters
        onApplyClick={handleDateApply}
        onDateClear={handleDateClear}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <div className="px-4 py-4">
        <div className="grid grid-cols-3 gap-4">
          <WidgetCard
            title="Total Orders"
            value={getValueFromOrderOverview("Total Orders")}
          />
          <WidgetCard
            title="No of Active Orders"
            value={getValueFromOrderOverview("Active Orders")}
          />
          <WidgetCard
            title="Total Orders completed"
            value={getValueFromOrderOverview("Completed Orders")}
          />
          <WidgetCard
            title="Total Sales"
            value={getValueFromOrderOverview("Total Sales")}
            showRupees={true}
          />
          <WidgetCard
            title="AOV"
            value={getValueFromOrderOverview("Average Order Value")}
            showRupees={true}
          />
        </div>
      </div>
      <Divider />
      <div className="px-4 mb-4 grid grid-cols-2 gap-4 w-full">
        <ChartWrapper
          title="Consultant Performance"
          chartData={consultantPerformanceData}
        >
          <VerticalBarChartComponent
            chartData={consultantPerformanceData}
            name={consultantPerformanceData?.name}
            height="450px"
            rotate={true}
            showRupees={false}
            showCount={true} // to show order count
            showLegend={true}
          />
        </ChartWrapper>
        {/* Sales by Location */}
        <ChartWrapper
          title="Sales by Location"
          chartData={salesByLocationData}
          loading={salesByLocationLoading}
        >
          <HorizontalBarChartComponent
            chartData={salesByLocationData}
            name={salesByLocationData?.name}
            showRupees={true}
          />
        </ChartWrapper>
        {/* Order Status */}
        <ChartWrapper
          title="Orders By Status"
          chartData={orderByStatusData}
          loading={orderByStatusLoading}
        >
          <DonutChartComponent
            chartData={orderByStatusData}
            name={orderByStatusData}
            showRupees={false}
            legendsAtBottom={true}
          />
        </ChartWrapper>
      </div>
      <Divider title="Products" header={true} />
      <div className="px-4 mb-4 grid grid-cols-2 gap-4 w-full">
        <ChartWrapper
          title="Top Performing Category"
          chartData={topPerformingCategoriesData?.values}
        >
          <VerticalBarChartComponent
            chartData={topPerformingCategoriesData?.values}
            name={topPerformingCategoriesData?.name}
            height="450px"
            rotate={true}
            showRupees={true}
            showCount={true}
            showLegend={true}
          />
        </ChartWrapper>
        {/* Top Performing Products */}
        {/* Sales by category */}
        <ChartWrapper title="Sales by Category" chartData={sampleTableData}>
          <CustomTable
            tableHeaders={sampleTableHeaderData}
            tableData={sampleTableData}
            customTableClass="!w-[96%] mx-4 mt-2 !h-[430px] overflow-auto custom-scrollbar"
          />
        </ChartWrapper>

        <ChartWrapper
          title="Top Performing Products"
          chartData={topPerformingProductsData?.values}
        >
          <HorizontalBarChartComponent
            chartData={topPerformingProductsData?.values}
            name={salesByLocationData?.name}
          />
        </ChartWrapper>
      </div>
      <Divider title="Customer" header={true} />
      <div className="px-4 mb-4 grid grid-cols-2 gap-4 w-full">
        {/* Signup Over Time */}
        <ChartWrapper
          title="Signup Over Time"
          chartData={signupCustomersData}
          loading={signupCustomersLoading}
        >
          <LineChartComponent
            chartData={signupCustomersData}
            showRupees={true}
            rotate={true}
          />
        </ChartWrapper>
        {/* Last Login Customers */}
        <ChartWrapper
          title="Last Login Customers"
          chartData={loginCustomersDataStrectureDate}
          loading={loginCustomersLoading}
        >
          <CustomTable
            tableHeaders={lastLoginTableHeader}
            tableData={loginCustomersDataStrectureDate}
            customTableClass="!w-[96%] mx-4 mt-2 !h-[420px] overflow-auto custom-scrollbar"
          />
        </ChartWrapper>
        {/* Number of Locations */}
        <ChartWrapper
          title="Number of users per location"
          chartData={numberOfLocations?.values}
        >
          <VerticalBarChartComponent
            chartData={numberOfLocations?.values}
            name={numberOfLocations?.name}
            height="450px"
            rotate={true}
            showCount={true}
            showLegend={true}
          />
        </ChartWrapper>
        {/* Highest Spending Customers */}
        <ChartWrapper
          title="Highest Spending Customers"
          chartData={heighestSpendingCustomerStrectureDate}
          loading={highestSpendingCustomersLoading}
        >
          <CustomTable
            tableHeaders={highestSpendingCustomersTableHeader}
            tableData={heighestSpendingCustomerStrectureDate}
            customTableClass="!w-[96%] mx-4 mt-2 !h-[420px] overflow-auto custom-scrollbar"
          />
        </ChartWrapper>
      </div>

      <Divider title="Professional" header={true} />
      <div className="px-4 pb-4">
        <div className="grid grid-cols-3 gap-4">
          <WidgetCard
            title="Number of Pending Professionals"
            value={professionalOverviewwData[1]?.value}
          />
          <WidgetCard
            title="All Professionals"
            value={professionalOverviewwData[0]?.value}
          />
        </div>
      </div>
      <div className="px-4 mb-4 grid grid-cols-2 gap-4 w-full">
        <ChartWrapper
          title="Last 10 Approved Professionals"
          chartData={approveProfessionalsDataStrectureDate}
          loading={approveProfessionalsLoading}
        >
          <CustomTable
            tableHeaders={lastTenApprovedProfessionalsHeader}
            tableData={approveProfessionalsDataStrectureDate}
            customTableClass="!w-[96%] mx-4 mt-2 !h-[420px] overflow-auto custom-scrollbar"
          />
        </ChartWrapper>
        {/* Number of Professionals per Location */}
        <ChartWrapper
          title="Number of Professionals per Location"
          chartData={professionalPerLocationData}
          loading={professionalPerLocationLoading}
        >
          <VerticalBarChartComponent
            chartData={professionalPerLocationData}
            name={numberOfProfessionalsPerLocation?.name}
            height="450px"
            rotate={true}
            showCount={true}
            showLegend={true}
          />
        </ChartWrapper>
        {/* Highest Times Referred Professional */}
        <ChartWrapper
          title="Highest Times Referred Professional"
          chartData={highestTimesReferredProfessionalData}
        >
          <CustomTable
            tableHeaders={highestTimesReferredProfessionalHeader}
            tableData={highestTimesReferredProfessionalData}
            customTableClass="!w-[96%] mx-4 mt-2 !h-[420px] overflow-auto custom-scrollbar"
          />
        </ChartWrapper>
      </div>

      <Divider title="Projects" header={true} />
      <div className="px-4 pb-4">
        <div className="grid grid-cols-3 gap-4">
          <WidgetCard
            title="Total Number of Live Projects"
            value={totalLiveProjectData[0]?.value}
          />
        </div>
      </div>
      <div className="px-4 mb-4 grid grid-cols-2 gap-4 w-full">
        {/* Last 10 Projects Posted */}
        <ChartWrapper
          title="Last 10 Projects Posted"
          chartData={lastTenProjectsPostedData}
          loading={totalLiveProjectLoading}
        >
          <CustomTable
            tableHeaders={lastTenProjectsPostedHeader}
            tableData={lastTenProjectsPostedData}
            customTableClass="!w-[96%] mx-4 mt-2 !h-[420px] overflow-auto custom-scrollbar"
          />
        </ChartWrapper>
      </div>
    </div>
  );
}
