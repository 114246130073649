import * as yup from "yup";

export const uploadInvoiceDefaultValues = {
  num: "",
  document: "",
};

export const uploadInvoiceFormSchema = yup.object().shape({
  num: yup.string().trim().required("Invoice number is required"),
  document: yup.string().trim().required("Invoice document is required"),
});

export type UploadInvoiceFormSchema = yup.InferType<
  typeof uploadInvoiceFormSchema
>;
