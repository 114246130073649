import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../atom";
import Table from "./components/QuotaionTable";
import { getListForQuotation } from "../../api/quotation";
import { checkPermissions } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useDebounce from "../../hooks/useDebounce";

const QuotaionList = () => {
  const setTitle = useSetRecoilState(titleAtom);
  const navigate = useNavigate();
  const [quotationList, setQuotationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [pageLength, setPageLength] = useState<number>(25);
  const [totalCount, setTotalCount] = useState<number>(100);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);

  // Permissions
  const hasViewPermission = checkPermissions("view_quotation", true, toast);

  async function getQuotationList() {
    const params = {
      page: page,
      length: pageLength,
      search: debouncedSearchValue,
    };
    return getListForQuotation(params)
      .then((res: any) => {
        setQuotationList(res?.data?.quotations);
        setTotalCount(res?.data.pagination.total_count);
      })
      .catch((error) => {
        console.error("Error fetching quotation list:", error);
        if (error?.status === 404) {
          setPage(1);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  }

  useEffect(() => {
    setTableLoading(true);

    getQuotationList();
  }, [page, pageLength, debouncedSearchValue]);

  if (!hasViewPermission) {
    navigate("/unauthorized");
  }

  return (
    <div className="h-full flex flex-col justify-start items-start">
      <div className="h-[calc(100%-2.5rem)] w-full">
        <Table
          quotationList={quotationList}
          isLoading={tableLoading}
          setIsLoading={setTableLoading}
          page={page}
          pageLength={pageLength}
          setPage={setPage}
          setPageLength={setPageLength}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          totalCount={totalCount}
          setTotalCount={setTotalCount}
          getQuotationList={getQuotationList}
        />
      </div>
    </div>
  );
};

export default QuotaionList;
