export const formatIndianRupees = (number: any) => {
  return number?.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatIndianRupeesForDecimals = (value: number) => {
  const price = value / 100;
  const formattedPrice = price?.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedPrice;
};
