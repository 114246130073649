import React from "react";
import { CustomDropdown } from "../ProfessionalDetail/DropDown";
import FileIcon from "../../assets/FileIcon";
import PdfIcon from "../../assets/PdfIcon";
import imag from "../../assets/images/image67.png";
import UploadedFileView from "./UploadedFileView";

type UserFilesProps = {
  orderDetails: any;
};

const UserFiles = ({ orderDetails }: UserFilesProps) => {
  const customerFiles = orderDetails?.customer_files;
  return (
    <CustomDropdown label="User Files">
      <div className="px-4 py-3 max-h-[50vh] overflow-scroll no-scrollbar flex flex-wrap gap-x-5">
        <UploadedFileView files={customerFiles} showDelete={false} />
      </div>
      <div className=" m-4 pt-4 border-[#DEDEDE] border border-r-0 border-l-0 border-b-0 border-t-1">
        <div className="w-[60%]">
          <p className="mb-2 font-gilroy-semi-bold font-normal text-sm leading-6 text-[#1C1C1C]">
            Additional Information
          </p>
          <p className="font-gilroy-regular text-xs leading-6 text-[#1C1C1C]">
            {orderDetails?.additional_information}
          </p>
        </div>
      </div>
    </CustomDropdown>
  );
};

export default UserFiles;
