import React, { Component } from "react";
import * as echarts from "echarts";
import { formatNumberIndianRupees } from "../../../utils/formatDateTime";
import { defaultColors } from "../../../utils/data";
import { stringToColour } from "../../../utils/helper";

class VerticalBarChartComponent extends Component<any, any> {
  private chartRef: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      deviceWidth: window.innerWidth,
    };
  }

  static defaultProps = {
    showCount: false, // Set showCount to false by default
  };

  componentDidMount() {
    this.initializeChart();
  }

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.chartData !== this.props.chartData ||
      prevProps.name !== this.props.name
    ) {
      this.initializeChart();
    }
  }

  initializeChart() {
    const myChart = echarts.init(this.chartRef.current);
    const yAxisName = this.props.name || "";

    const formatedXData =
      this.props.chartData && this.props.chartData?.length > 0
        ? this.props.chartData.map(
            (item: any) => item?.name || item?.category_name
          )
        : [];
    const formatedYData =
      this.props.chartData && this.props.chartData?.length > 0
        ? this.props.chartData.map((item: any, index: any) => {
            return {
              name: item?.name || item?.category_name,
              value: item?.value?.toFixed(2),
              order_count: item?.order_count,
              itemStyle: {
                color: this.props.customColor
                  ? "#FDAC2C"
                  : this.props.staticColors
                  ? index <= defaultColors?.length
                    ? defaultColors[index]
                    : "#FDAC2C"
                  : stringToColour(item?.name || item?.category_name) ||
                    "#FDAC2C",
                // PREVIOUS CODE FOR COLOR SELCETION. KEPT SO THAT WE CAN EASILY REVERT IT BACK
                // color:
                //   index <= defaultColors.length
                //     ? defaultColors[index]
                //     : '#FDAC2C',
              },
            };
          })
        : [];

    const yAxisLabelFormatter = (value: any) => {
      if (this.props.showPercentage) {
        return `${value}%`;
      } else if (this.props.showRupees) {
        return `${formatNumberIndianRupees(value)}`;
      } else {
        return value;
      }
    };
    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        textStyle: {
          fontSize: this.state.deviceWidth > 1024 ? 12 : 10,
        },
        className: "!z-[5]",
        // formatter: `{b0}<br />${yAxisName}: <b>{c0}</b>`,
        formatter: (params: any) => {
          const { showRupees, showPercentage, showCount } = this.props;
          const formattedValue = params[0].value; // Assuming single series, so params[0]
          const formattedOrderCount = params[0]?.data?.order_count;
          const countValue = `<br/>No. of orders : <b>${formattedOrderCount}</b>`;

          if (showRupees) {
            return `${
              params[0].name
            }<br />${yAxisName}: <b>${formatNumberIndianRupees(
              formattedValue
            )}</b>${showCount && formattedOrderCount ? countValue : ""}`;
          } else if (showPercentage) {
            return `${
              params[0].name
            }<br />${yAxisName}: <b>${formattedValue}%</b>${
              showCount && formattedOrderCount ? countValue : ""
            }`;
          } else {
            return `${
              params[0].name
            }<br />${yAxisName}: <b>${formattedValue}</b>${
              showCount && formattedOrderCount ? countValue : ""
            }`;
          }
        },
      },
      grid: {
        left: this.state.deviceWidth > 1024 ? "3%" : "1%",
        right: "4%",
        bottom: "0%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: formatedXData,
          axisTick: {
            show: false,
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: "#000000",
            },
          },
          axisLabel: {
            color: "#000000",
            fontSize: this.state.deviceWidth > 1024 ? "10px" : "9px",
            interval: 0,
            align: "center",
            margin:
              this.state.deviceWidth < 1450 || this.props.rotate
                ? this.props.margin || 42
                : 10,
            rotate: this.state.deviceWidth < 1450 || this.props.rotate ? 35 : 0,
            formatter: function (value: any) {
              // Break the label into multiple lines if it's too long
              const labelArray = value.split(" ");
              if (value && labelArray?.length > 4) {
                return labelArray
                  .map((word: any, index: any) =>
                    index % 4 === 0 && index !== 0 ? "\n" + word : word
                  )
                  .join(" ");
              }
              return value;
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#DDD",
            },
          },
        },
      ],
      yAxis: [
        {
          name: yAxisName,
          nameTextStyle: {
            align: "left",
          },
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#000000",
            },
          },
          axisLabel: {
            color: "#000000",
            fontSize: this.state.deviceWidth > 1024 ? "10px" : "9px",
            formatter: yAxisLabelFormatter,
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#DDD",
            },
          },
        },
      ],
      legend: {
        show: this.props.showLegend || true,
        left: "40px",
        bottom: "0",
      },
      series: [
        {
          name: this.props.seriesName, // Series Name comes here
          type: "bar",
          barWidth: this.state.deviceWidth > 1024 ? "24.5px" : "12px",
          itemStyle: {
            color: "#5766C1",
          },
          data: formatedYData,
        },
      ],
    };
    option && myChart.setOption(option);
  }

  componentWillUnmount() {
    echarts.dispose(this.chartRef.current as HTMLElement);
  }

  render() {
    return (
      <div
        ref={this.chartRef}
        style={{ width: "100%", height: this.props.height || "327px" }}
      />
    );
  }
}

export default VerticalBarChartComponent;
