import React, { useEffect, useState } from "react";
import { stateAtom } from "../atom";
import { useRecoilValue } from "recoil";
import SelectField2 from "./SelectedField2";

type AddrCityStateProps = {
  setSelectedStateCity?: any;
  selectedStateCity?: any;
};

const GetAddrCityState = ({
  selectedStateCity,
  setSelectedStateCity,
}: AddrCityStateProps) => {
  // City state dropdown States
  const [pageLoading, setPageLoading] = useState(false);
  const [locationList, setLocationList] = useState<any>([]);
  const [locationPage, setLocationPage] = useState(1);
  const [locationTotalPage, setLocationTotalPage] = useState(0);
  const [locationSearch, setLocationSearch] = useState("");

  const stateList = useRecoilValue<any>(stateAtom);

  // Effect for handling locationSearch changes
  const itemsPerPage = 10;

  useEffect(() => {
    setLocationPage(1);
  }, [locationSearch]);

  useEffect(() => {
    const allCities =
      stateList
        ?.map((state: any) => {
          return state?.cities?.map((city: any) => ({
            value: `${city}, ${state.name}, ${state.state_code}`,
            label: `${city}, ${state.name}`,
          }));
        })
        .flat(1) || [];

    const filteredCities = allCities.filter((city: any) =>
      `${city.value} ${city.label}`
        .toLowerCase()
        .includes(locationSearch.toLowerCase())
    );

    const totalPage = Math.ceil(filteredCities.length / itemsPerPage);
    setLocationTotalPage(totalPage);

    if (locationPage === 1) {
      // Reset locationList if page is 1
      setLocationList(filteredCities.slice(0, itemsPerPage));
    } else {
      // Append to locationList if page increases
      const startIndex = (locationPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedList = [
        ...(locationList || []), // Preserve existing items
        ...filteredCities.slice(startIndex, endIndex),
      ];
      setLocationList(paginatedList);
    }
  }, [stateList, locationPage, locationSearch]);

  return (
    <div>
      <SelectField2
        searchPlaceHolder="Search city or state here"
        disabled={pageLoading}
        totalPage={locationTotalPage}
        page={locationPage || 1}
        setPage={setLocationPage}
        options={locationList}
        externalSearch={locationSearch}
        externalSetSearch={setLocationSearch}
        value={selectedStateCity}
        label="Select Location"
        onChange={(e: any) => {
          const string = e?.split(", ");
          setSelectedStateCity((prev: any) => ({
            ...prev,
            address_city: string?.[0],
            address_state: string?.[1],
          }));
        }}
        removeOption={() => {
          setSelectedStateCity(null);
        }}
      />
    </div>
  );
};

export default GetAddrCityState;
