import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { toast } from "react-toastify";
import ManagementTableLayout from "../../../components/MangementTable";
import InputField from "../../../components/InputField";
import SearchIcon from "../../../assets/SearchIcon";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { MainAppSideBarExpandAtom, stateAtom } from "../../../atom";
import { getProfessional, updateProfessional } from "../../../api/professional";
import EditIcon from "../../../assets/EditIcon";
import Pagination from "../../../components/Pagination";
import { checkPermissions } from "../../../utils/helper";
import useDebounce from "../../../hooks/useDebounce";
import GetAddrCityState from "../../../components/GetAddrCityState";

function CompanyTable() {
  const stateList = useRecoilValue<any>(stateAtom);

  const [totalCount, setTotalCount] = useState<number>(100);
  const [pageLength, setPageLength] = useState<number>(25);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const sidebarExpand = useRecoilValue(MainAppSideBarExpandAtom);
  const [isTogglingStatus, setIsTogglingStatus] = useState("");
  const [startIndex, setStartIndex] = useState(0);

  const debouncedInputVal = useDebounce(inputVal, 500);

  //Permissions
  const hasEditPermission = checkPermissions("edit_customer");
  const hideActionColumn = !hasEditPermission;

  const [selectedStateCity, setSelectedStateCity] = useState<any>("");

  const city = selectedStateCity && selectedStateCity?.address_city;
  const state = stateList.find(
    (state: any) => state.name === selectedStateCity?.address_state
  )?.state_code;

  useEffect(() => {
    setLoading(true);
    getProfessional({
      status: "VERIFIED",
      page: page,
      length: pageLength,
      search: debouncedInputVal,
      kind: "p_company",
      city: city,
      state: state,
    })
      .then((res) => {
        // console.log(res?.data?.professional);
        const users = res?.data?.professional;
        setUserData(users);
        setTotalCount(res.data.pagination.total_count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [page, pageLength, debouncedInputVal, state, city]);

  const handleStatusToggle = async (
    professionalId: string,
    currentStatus: boolean
  ) => {
    if (isTogglingStatus) return;
    setIsTogglingStatus(professionalId);
    const payload = {
      user: {
        is_active: !currentStatus,
      },
    };
    try {
      await updateProfessional(professionalId, payload); // Assuming you have an updateCustomer function
      toast.success("Status updated successfully");
      // Update local userData state (if needed)
      // You can handle this part based on your application's state management
      setUserData((prevData: any) =>
        prevData.map((professional: any) => {
          if (professional.id === professionalId) {
            return {
              ...professional,
              is_active: !currentStatus,
            };
          }
          return professional;
        })
      );
    } catch (error) {
      console.error(error);
      toast.error("Failed to update status");
    } finally {
      setIsTogglingStatus("");
    }
  };

  const COLUMN = [
    {
      id: "sr",
      Header: () => <div>Sr. No</div>,
      width: "55px",
      accessor: "sr",
      Cell: ({ row }: { row: any }) => <div>{startIndex + row.index + 1}</div>,
    },
    {
      id: "name",

      Header: ({ column }: { column: any }) => (
        <div className=" w-[118px]">
          <div className="flex justify-start items-center">Name</div>
        </div>
      ),

      accessor: "p_company.name",

      Cell: ({ row, value }: any) => (
        <div className="underline underline-offset-2 hover:scale-105 hover:pl-1">
          <Link
            to={`/customers/professionals/company/${row?.original?.customer?.id}`}
          >
            {" "}
            <p>{value}</p>
          </Link>
        </div>
      ),
    },

    {
      id: "email",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Email ID</div>
      ),
      accessor: "p_company.social_email",
      Cell: ({ value }: { value: any }) => <p>{value}</p>,
    },
    {
      id: "phone",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Phone Number</div>
      ),
      accessor: "p_company.social_phone",
      Cell: ({ value }: { value: any }) => <p>{value}</p>,
    },
    {
      id: "location",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Location</div>
      ),
      accessor: "p_company",
      Cell: ({ value }: { value: any }) => (
        <p>
          {[value?.practice_location_city, value?.practice_location_state].join(
            ", "
          )}
        </p>
      ),
    },
    {
      id: "profile_picture",

      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2  ">
          <p>Profile Picture</p>
        </div>
      ),
      accessor: "p_company.profile_picture",
      width: "25%",
      Cell: ({ row, value }: any) => (
        <div className="font-semibold w-10 h-10">
          <img
            src={`${process.env.REACT_APP_BUCKET}/${value}`}
            alt={row.original?.p_company?.name}
            className="w-full h-full py-1 object-contain"
          />
        </div>
      ),
    },
    ...(!hideActionColumn
      ? [
          {
            id: "buttons",
            Header: () => (
              <div className="flex w-full justify-start ">Action</div>
            ),
            width: 200,
            Cell: ({ row }: { row: any }) => (
              <div className="flex justify-start items-center gap-3">
                {hasEditPermission && (
                  <div
                    onClick={() => {}}
                    className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white"
                  >
                    <Link
                      to={`/customers/professionals/${row?.original?.customer?.id}/edit`}
                      className="cursor-pointer"
                    >
                      <EditIcon />
                    </Link>
                  </div>
                )}
              </div>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    const lastIndex = (page - 1) * pageLength;
    setStartIndex(lastIndex);
  }, [page, pageLength, totalCount]);

  const columns = useMemo(() => COLUMN, [userData]);
  const data = useMemo(() => userData || [], [userData]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
    },
    useSortBy,
    usePagination
  );

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };

  return (
    <div className="h-full flex flex-col gap-y-2 pt-2">
      <div className="flex flex-row justify-between items-center w-full px-3 h-[7vh] ">
        <div className="w-full flex gap-4 px-3 py-2 flex-row items-center justify-between">
          <div className="w-[235px] mt-3">
            <InputField
              onChange={(e: string) => setInputVal(e)}
              placeholder="Search here"
              value={inputVal}
              rightIcon={<SearchIcon />}
              withDebounce
            />
          </div>
          <div className="mb-2">
            <GetAddrCityState
              setSelectedStateCity={setSelectedStateCity}
              selectedStateCity={selectedStateCity}
            />
          </div>
        </div>
      </div>

      <div className="h-[calc(100%-13.2vh)] w-full relative -z-0 ">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={loading}
        />
      </div>

      {userData.length <= 0 ? (
        ""
      ) : loading ? (
        ""
      ) : (
        <div className="w-full ">
          <Pagination
            currentPage={page}
            pageSize={pageLength}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}
    </div>
  );
}

export default CompanyTable;
