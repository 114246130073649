import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import HipchatChevronRightIcon2 from "../../assets/HipchatChevronRightIcon2";
import Button from "../../components/Button";
import AuthInputField from "../../components/AuthInputField";
import { useState } from "react";
import { BASE_URL } from "../../api/axiosManagement";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  new_password: yup
    .string()
    .required("Password is required")
    .matches(/\d/, "Password must contain at least one number"),
  confirm_password: yup
    .string()
    .oneOf(
      [yup.ref("new_password")],
      "Passwords do not match. Please ensure both fields are identical."
    )
    .required("Confirm password is required"),
});

const StepThree = ({ token, otpKey }: any) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const newPassword = watch("new_password");
  const confirmPassword = watch("confirm_password");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    if (newPassword === confirmPassword) {
      console.log("password match");
    } else {
      setError("confirm_password", {
        type: "manual",
        message: "Please enter the same in both field",
      });
    }

    const payload = {
      new_password: newPassword,
      otp_key: otpKey,
    };

    try {
      setLoading(true);
      const response = await axios.post(
        `${BASE_URL}/admin/accounts/reset-password`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.status === 200) {
        toast.success("Password Reset successfully");
        navigate("/login");
        reset();
      }
    } catch (error: any) {
      console.error(":", error);
      if (error?.response?.data?.errors?.new_password) {
        setError("new_password", {
          type: "manual",
          message: error.response.data.errors.new_password[0],
        });
      }
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full min-w-[450px]">
      <form onSubmit={handleSubmit(onSubmit)} noValidate className="w-full">
        <>
          <h1 className="text-5xl mx-auto w-max font-mangoGrotesque-black my-4 sm:my-10">
            Set New Password
          </h1>
          <div className="flex flex-col gap-4">
            <Controller
              name="new_password"
              control={control}
              render={({ field }) => (
                <AuthInputField
                  onChange={(e: any) => {
                    setValue("new_password", e);
                  }}
                  value={field?.value || ""}
                  placeholder="Enter password"
                  heading="Enter Password"
                  name="username"
                  type="Password"
                  required
                  hint={errors.new_password?.message}
                />
              )}
            />
            <Controller
              name="confirm_password"
              control={control}
              render={({ field }) => (
                <AuthInputField
                  onChange={(e: any) => {
                    setValue("confirm_password", e);
                  }}
                  value={field?.value || ""}
                  placeholder="Re-enter password"
                  heading="Re-enter Password"
                  name="username"
                  errorMessage={errors?.confirm_password?.message}
                  hint={errors.confirm_password?.message}
                  type="Password"
                  required
                />
              )}
            />
          </div>

          <div className="w-full my-8">
            <Button
              variant="secondary"
              label="Update Password"
              rightIcon={<HipchatChevronRightIcon2 />}
              fullWidth
              loading={loading}
              disabled={loading}
            />
          </div>
        </>
      </form>
    </div>
  );
};

export default StepThree;
