import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import ManagementTableLayout from "../../../components/MangementTable";
import InputField from "../../../components/InputField";
import SearchIcon from "../../../assets/SearchIcon";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { stateAtom } from "../../../atom";
import { getProfessional } from "../../../api/professional";
import EditIcon from "../../../assets/EditIcon";
import Pagination from "../../../components/Pagination";
import { checkPermissions } from "../../../utils/helper";
import useDebounce from "../../../hooks/useDebounce";
import SelectField2 from "../../../components/SelectedField2";
import GetAddrCityState from "../../../components/GetAddrCityState";

function EmployeeTable() {
  const stateList = useRecoilValue<any>(stateAtom);
  const [totalCount, setTotalCount] = useState<number>(100);
  const [pageLength, setPageLength] = useState<number>(25);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const debouncedInputVal = useDebounce(inputVal, 500);

  //Permissions
  const hasEditPermission = checkPermissions("edit_customer");
  const hideActionColumn = !hasEditPermission;

  const [selectedStateCity, setSelectedStateCity] = useState<any>("");

  const city = selectedStateCity && selectedStateCity?.address_city;
  const state = stateList.find(
    (state: any) => state.name === selectedStateCity?.address_state
  )?.state_code;

  useEffect(() => {
    setLoading(true);
    getProfessional({
      status: "VERIFIED",
      page: page,
      length: pageLength,
      search: debouncedInputVal,
      kind: "p_individual",
      city: city,
      state: state,
    })
      .then((res) => {
        const users = res?.data?.professional;
        setUserData(users);
        setTotalCount(res.data.pagination.total_count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [page, pageLength, debouncedInputVal, city, state]);

  const COLUMN = [
    {
      id: "sr",
      Header: () => <div>Sr. No</div>,
      width: "5%",
      accessor: "sr",
      Cell: ({ row }: { row: any }) => <div>{startIndex + row.index + 1}</div>,
    },
    {
      id: "name",
      Header: ({ column }: { column: any }) => (
        <div>
          <div className="flex justify-start items-center">Name</div>
        </div>
      ),
      width: "35%",
      accessor: "p_individual.name",
      Cell: ({ row, value }: any) => {
        return (
          <div className="underline underline-offset-2 hover:scale-105 hover:pl-3">
            <Link
              to={`/customers/professionals/employee/${row?.original?.customer?.id}`}
            >
              <p>{value}</p>
            </Link>
          </div>
        );
      },
    },
    {
      id: "location",
      Header: ({ column }: { column: any }) => (
        <div>
          <div className="flex justify-start items-center">Location</div>
        </div>
      ),
      accessor: "p_individual",
      width: "35%",
      Cell: ({ row, value }: any) => {
        return (
          <p>
            {[
              value?.practice_location_city,
              value?.practice_location_state,
            ].join(", ")}
          </p>
        );
      },
    },
    {
      id: "profile_picture",

      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2  ">
          <p>Profile Picture</p>
        </div>
      ),
      accessor: "p_individual.profile_picture",
      width: "15%",
      Cell: ({ row, value }: any) => (
        <div className="font-semibold w-10 h-10">
          <img
            src={`${process.env.REACT_APP_BUCKET}/${value}`}
            alt={row.original?.p_individual?.name}
            className="w-full h-full py-1 object-contain"
          />
        </div>
      ),
    },
    ...(!hideActionColumn
      ? [
          {
            id: "buttons",
            Header: () => (
              <div className="flex w-full justify-start ">Action</div>
            ),
            width: "10%",
            Cell: ({ row }: { row: any }) => (
              <div className="flex justify-start items-center gap-3">
                {hasEditPermission && (
                  <div
                    onClick={() => {}}
                    className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white"
                  >
                    <Link
                      to={`/customers/professionals/${row?.original?.customer?.id}/edit`}
                      className="cursor-pointer"
                    >
                      <EditIcon />
                    </Link>
                  </div>
                )}
              </div>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    const lastIndex = (page - 1) * pageLength;
    setStartIndex(lastIndex);
  }, [page, pageLength, totalCount]);

  const columns = useMemo(() => COLUMN, [userData]);
  const data = useMemo(() => userData || [], [userData]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
    },
    useSortBy,
    usePagination
  );

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };

  return (
    <div className="h-full flex flex-col gap-y-2">
      <div className="flex flex-row justify-between items-center w-full ">
        <div className="w-full flex gap-4 px-3 py-1 flex-row items-center justify-between">
          <div className="w-[235px] mt-4">
            <InputField
              onChange={(e: string) => setInputVal(e)}
              placeholder="Search here"
              value={inputVal}
              rightIcon={<SearchIcon />}
              withDebounce
            />
          </div>
          <div>
            <GetAddrCityState
              setSelectedStateCity={setSelectedStateCity}
              selectedStateCity={selectedStateCity}
            />
          </div>
        </div>
      </div>

      <div className="h-[calc(100%-13.2vh)] w-full relative -z-0 ">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={loading}
        />
      </div>

      {userData.length <= 0 ? (
        ""
      ) : loading ? (
        ""
      ) : (
        <div className="w-full ">
          <Pagination
            currentPage={page}
            pageSize={pageLength}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}
    </div>
  );
}

export default EmployeeTable;
