import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { stateAtom } from "../../../../atom";
import AddressCardPopup from "./AddressCardPopup";
import ChangeIcon from "../../../../assets/svg-tsx/ChangeIcon";

type TotalProfessionalsType = {
  title?: string;
  heading?: string;
  setOrderDetails?: any;
  editDisabled?: boolean;
  required?: boolean;
  label?: string;
  customerId?: string;
  setSelectedAddress?: any;
  address?: any;
  selectedAddress?: any;
  disabled?: boolean;
};

function AddressCard({
  title,
  address,
  required,
  customerId,
  setSelectedAddress,
  selectedAddress,
  disabled,
}: TotalProfessionalsType) {
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const stateData = useRecoilValue(stateAtom);
  return (
    <>
      <div className="text-start h-full mt-2">
        <div className="w-full flex justify-start">
          <button disabled={disabled} onClick={() => setShowAddressPopup(true)}>
            <div className="mb-2 flex flex-col justify-between items-start">
              <div className="flex justify-between items-center">
                <h4 className="text-[12px] font-semibold tracking-wider font-gilroy-regular mb-2 text-left">
                  {title}
                  {required && (
                    <span className="text-red-500 text-xs ml-1">*</span>
                  )}
                </h4>
                {address && (
                  <span className="cursor-pointer mb-1 ml-2">
                    <ChangeIcon size="16" />
                  </span>
                )}
              </div>
              {!address && (
                <div
                  className={` ${
                    disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                  } flex items-center justify-center bg-[#E4E4E4] rounded-md w-[145px] h-[40px]`}
                >
                  <span className="font-gilroy-semi-bold text-sm">
                    Select Address
                  </span>
                </div>
              )}
            </div>
          </button>
        </div>
        {/* line 1 line 2 city - pincode state */}
        {title === "Billing Address" && address && (
          <div className="w-[24vw] border border-[#DFDFDF] p-4 h-full">
            <div className="flex flex-col space-y-0  text-start  text-[#1C1C1C]">
              <p className="bg-[#EDEDED] mb-4 w-fit py-1 px-2 rounded-lg text-sm capitalize leading-[20px] font-gilroy-bold">
                {address?.tag.toLowerCase()}
              </p>
              <h3 className=" text-[14px] text-[#1C1C1C] leading-[20px] font-gilroy-semi-bold">
                {address?.full_name}
              </h3>

              <p className="text-[12px] leading-[20px] font-gilroy-medium">
                {address?.street_1},
              </p>
              <p className="text-[12px] leading-[20px] font-gilroy-medium">
                {address?.street_2 && <p>{address?.street_2},</p>}
              </p>
              <p className="text-[12px] leading-[20px] font-gilroy-medium">
                {address?.city} - {address?.postal_code},
              </p>
              <p className="text-[12px] leading-[20px] font-gilroy-medium">
                {stateData &&
                  stateData?.find(
                    (state: any) => state?.state_code == address?.state
                  )?.name}
              </p>
              {address?.phone && (
                <p className="text-[12px] leading-[20px] font-gilroy-medium items-center mt-2">
                  <span className="font-gilroy-semi-medium">
                    Phone Number :
                  </span>{" "}
                  {address?.phone}
                </p>
              )}
            </div>
          </div>
        )}

        {showAddressPopup && (
          <AddressCardPopup
            customerId={customerId}
            setShowAddressPopup={setShowAddressPopup}
            setSelectedAddress={setSelectedAddress}
            selectedAddress={selectedAddress}
          />
        )}
      </div>
    </>
  );
}

export default AddressCard;
