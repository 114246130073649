import { protectedAxiosInstance } from "./axiosManagement";

export const postCharts = (payload) => {
  return protectedAxiosInstance
    .post("/admin/orders/charts", payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postAccountsCharts = (payload) => {
  return protectedAxiosInstance
    .post("/admin/accounts/charts", payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
