import React, { useEffect, useState } from "react";
import DeliveryAction from "./DeliveryAction";
import { getDispatchList, markAsDelivered } from "../../../api/orderManagement";
import { toast } from "react-toastify";
import { DELIVERY_OPTIONS } from "../../../utils/data";

type DeliveredProductsTableProps = {
  orderId: string | undefined;
  dispatchList: any;
  getDispatchListApi: any;
  fetchOrderItems: any;
  delivery_opt_timeline: any;
  getSingleOrderApi?: any;
  isOrderCancelled?: boolean;
};

const DeliveredProductsTable = ({
  orderId,
  dispatchList,
  getDispatchListApi,
  fetchOrderItems,
  delivery_opt_timeline,
  getSingleOrderApi,
  isOrderCancelled,
}: DeliveredProductsTableProps) => {
  const [currentDispatch, setCurrentDispatch] = useState<any>(null);

  const onHandleDispatch = (dispatchData: any) => {
    setCurrentDispatch(dispatchData);
  };

  async function handleMarkAsDelivered(dispatchId: string) {
    try {
      await markAsDelivered(orderId, dispatchId);
      getDispatchListApi && getDispatchListApi();
      fetchOrderItems && fetchOrderItems();
      getSingleOrderApi && getSingleOrderApi();
      toast.success("Marked as delivered");
    } catch (error) {
      console.log(error);
      toast.error("Failed to mark as delivered");
    }
  }

  return (
    <div className="px-2">
      <h2 className="text-sm font-normal font-gilroy-medium my-[15px]">
        Delivery{" "}
        <span className="text-[#757575] font-normal font-gilroy-medium text-xs">
          {DELIVERY_OPTIONS[delivery_opt_timeline - 1]}
        </span>
      </h2>

      <div className="w-full overflow-y-auto custom-scrollbar h-[78%]">
        <table className="w-full">
          <thead className="bg-[#ECECEC80] text-[#242424] border-[#ECECEC80] border-l-[0.3px] border-y-0 border-r-0 font-gilroy-semi-bold text-sm leading-4">
            <tr>
              <th className="w-12 py-3 px-4 text-left">#</th>
              <th className="!w-8 py-3 px-4 text-left">Products</th>
              <th className="py-3 px-4 text-left">Courier</th>
              <th className="py-3 px-4 text-left">AWB</th>
              <th className="w-2 py-3 px-4 text-left">Action</th>
            </tr>
          </thead>
          {dispatchList.length !== 0 ? (
            <tbody className="font-gilroy-medium text-[#242424] text-sm leading-4 overflow-x-scroll">
              {dispatchList.map((item: any, index: any) => {
                const showReadyForDispatch = item?.status === "READY";

                return (
                  <tr key={item.id}>
                    <td className="w-12 py-3 px-4 border-[0.3px] border-t-0 border-[#ECECEC]">
                      {index + 1}
                    </td>
                    <td className="!w-8 py-3 px-4 border-[0.3px] border-t-0 border-[#ECECEC]">
                      {item?.item_count}
                    </td>
                    {showReadyForDispatch ? (
                      <td
                        colSpan={2}
                        className="py-3 text-center font-normal italic px-4 border-[0.3px] border-t-0 border-[#ECECEC]  text-[#838383]"
                      >
                        Ready for dispatch
                      </td>
                    ) : (
                      <>
                        <td className="w-[180px] py-3 px-4 border-[0.3px] border-t-0 border-[#ECECEC]">
                          <a
                            href={item?.tracking_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="underline"
                          >
                            {item?.courier_name}
                          </a>
                        </td>
                        <td className="w-[180px] py-3 px-4 border-[0.3px] border-t-0 border-[#ECECEC]">
                          {item?.awb_number}
                        </td>
                      </>
                    )}
                    <td className="w-2 py-3 px-4 border-[0.3px] border-t-0 border-r-[0.1px] border-[#ECECEC]">
                      <DeliveryAction
                        readyForDispatch={showReadyForDispatch}
                        canDispatch={item?.can_dispatch}
                        onHandleDispatch={() => onHandleDispatch(item)}
                        currentDispatch={currentDispatch}
                        orderId={orderId}
                        getDispatchListApi={getDispatchListApi}
                        status={item?.status}
                        resetCurrentDispatch={() => setCurrentDispatch(null)}
                        handleMarkAsDelivered={() =>
                          handleMarkAsDelivered(item?.id)
                        }
                        fetchOrderItems={fetchOrderItems}
                        getSingleOrderApi={getSingleOrderApi}
                        isOrderCancelled={isOrderCancelled}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={5}>
                  <div className="h-[20vh] w-full flex flex-col justify-center items-center gap-1">
                    <p className="font-gilroy-semi-bold font-semibold">
                      No Delivery Records
                    </p>
                    <p className="text-[#AEAEAE] font-gilroy-medium font-normal">
                      No deliveries have been made yet.
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default DeliveredProductsTable;
