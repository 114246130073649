import { useState } from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/common/Modal";
import {
  updateTimelineDefaultValues,
  updateTimelineFormSchema,
  UpdateTimelineFormSchema,
} from "../../../validators/update-timeline-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import ErrorMessage from "../../../components/ErrorMessage";
import {
  InputDisabledStyle,
  primaryInputStyle,
} from "../../../components/InputField";
import {
  getOrderItems,
  updateTimelineBulk,
} from "../../../api/orderManagement";
import { toast } from "react-toastify";

type UpdateTimelineFormProps = {
  orderId: string;
  selectedItems: string[];
  setTableData: (data: any) => void;
  isOrderCancelled: boolean;
};

// Function to format the date into YYYY-MM-DD format to pass as response body
function formatDate(inputDate: any) {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export default function UpdateTimelineForm({
  orderId,
  selectedItems,
  setTableData,
  isOrderCancelled,
}: UpdateTimelineFormProps) {
  const [openUpdateTimeline, setOpenUpdateTimeline] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateTimelineFormSchema>({
    defaultValues: updateTimelineDefaultValues,
    resolver: yupResolver(updateTimelineFormSchema),
  });

  async function onSubmit(data: UpdateTimelineFormSchema) {
    const payload = {
      items: selectedItems,
      est_delivery_at: data.est_delivery_at,
    };
    try {
      setLoading(true);
      await updateTimelineBulk(orderId, payload);
      await getOrderItems(orderId).then((res: any) => {
        setTableData(res.data);
      });
      toast.success("Timeline updated successfully");
      setOpenUpdateTimeline(false);
    } catch (error) {
      toast.error("Failed to update timeline");
    } finally {
      setLoading(false);
    }
  }

  function handleCancel() {
    setOpenUpdateTimeline(false);
    reset();
  }

  return (
    <div>
      <Button
        label="Update Timeline"
        variant="primary"
        onClick={() => setOpenUpdateTimeline(true)}
        disabled={isOrderCancelled}
      />

      {openUpdateTimeline && (
        <Modal
          handleCancel={handleCancel}
          header="Update Timeline"
          clickOutsideToClose={true}
          widthClass="!w-[564px]"
        >
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <p className="text-sm font-gilroy-regular text-[#4B4B4B] w-full">
              {selectedItems?.length}{" "}
              {selectedItems?.length > 1 ? "items" : "item"} selected
            </p>
            <div className="mt-7 w-full gap-4">
              <Controller
                name="est_delivery_at"
                control={control}
                render={({ field }) => (
                  <div className="w-full">
                    <label className="text-sm block font-gilroy-semi-bold">
                      Date
                    </label>
                    <DatePicker
                      selected={field.value ? new Date(field.value) : null}
                      onChange={(date: Date) => {
                        const formattedDate = formatDate(date);
                        field.onChange(formattedDate);
                      }}
                      minDate={new Date()}
                      className={`text-left w-[524px] h-10 px-2 border rounded ${primaryInputStyle}`}
                      dateFormat="dd/MM/yy"
                      placeholderText="DD/MM/YY"
                      dayClassName={(date) =>
                        field.value &&
                        date.toDateString() ===
                          new Date(field.value).toDateString()
                          ? "bg-pot-yellow text-[#000]"
                          : null
                      }
                    />
                    <ErrorMessage error={errors?.est_delivery_at?.message} />
                  </div>
                )}
              />
            </div>

            <div className="mt-7 w-full flex gap-7">
              <Button
                label="Update"
                variant="primary"
                type="submit"
                loading={loading}
                onClick={handleSubmit(onSubmit)}
                className="min-w-[72px]"
              />
              <Button
                label="Cancel"
                variant="secondary-outline-dark"
                onClick={handleCancel}
                disabled={loading}
              />
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
}
