import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../../atom";
import Table from "./Components/ChannelPartnersTable";
import { getListForChannelPartner } from "../../../api/channelPartner";
import { checkPermissions } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useDebounce from "../../../hooks/useDebounce";

const ChannelPartnerList = () => {
  const setTitle = useSetRecoilState(titleAtom);
  const [channelPartnerList, setChannelPartnerList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [pageLength, setPageLength] = useState<number>(25);
  const [totalCount, setTotalCount] = useState<number>(100);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  const debouncedSearchValue = useDebounce(searchValue, 500);
  // Permissions
  const hasViewPermission = checkPermissions(
    "view_channel_partner",
    true,
    toast
  );

  async function geChannelPartnerList() {
    const params = {
      page: page,
      length: pageLength,
      search: debouncedSearchValue,
    };
    return getListForChannelPartner(params)
      .then((res: any) => {
        setChannelPartnerList(res?.data?.channel_partners);
        setTotalCount(res?.data.pagination.total_count);
      })
      .catch((error) => {
        console.error("Error fetching quotation list:", error);
      })
      .finally(() => {
        setTableLoading(false);
      });
  }

  useEffect(() => {
    setTableLoading(true);

    geChannelPartnerList();
  }, [page, pageLength, debouncedSearchValue]);

  if (!hasViewPermission) {
    navigate("/unauthorized");
  }

  return (
    <div className="h-full flex flex-col justify-start items-start">
      <div className="h-[calc(100%-2.5rem)] w-full">
        <Table
          channelPartnerList={channelPartnerList}
          isLoading={tableLoading}
          setIsLoading={setTableLoading}
          page={page}
          pageLength={pageLength}
          setPage={setPage}
          setPageLength={setPageLength}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          totalCount={totalCount}
          setChannelPartnerList={setChannelPartnerList}
          setTotalCount={setTotalCount}
        />
      </div>
    </div>
  );
};

export default ChannelPartnerList;
