import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, targetPathAtom } from "../atom";

const NonPrivateRoute = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [authState, setAuthState] = useRecoilState(authAtom);
  const [pathName, setPathName] = useRecoilState(targetPathAtom);

  useEffect(() => {
    // Check if the user is already authenticated when the component mounts
    if (authState.is_auth && authState.auth_token) {
      // Redirect the authenticated user to the dashboard
      setPathName(location.pathname);
      navigate("/orders");
    }
  }, [authState.is_auth, authState.auth_token, navigate]);

  return <main>{children}</main>;
};

export default NonPrivateRoute;
