import React from "react";
import { formatIndianRupees } from "../../../utils/formatToIndianRupees";

type WidgetCardProps = {
  title: string;
  value?: any;
  showRupees?: boolean;
};

export default function WidgetCard({
  title,
  value,
  showRupees,
}: WidgetCardProps) {
  return (
    <div className="px-3 py-2 border rounded-lg hover:cursor-pointer hover:bg-gray-50 transition-all duration-300 min-w-[300px]">
      <h3 className="text-sm font-gilroy-medium text-black">{title}</h3>
      <p className="text-sm font-gilroy-regular text-black">
        {showRupees ? formatIndianRupees(value) : value}
      </p>
    </div>
  );
}
