import { Tooltip } from "antd";
import PlusIcon from "../../../assets/PlusIcon";
import { checkPermissions } from "../../../utils/helper";

const ProjectCreateDetails = ({ data, setShowInviteModal }: any) => {
  // Permissions
  const hasEditPermission = checkPermissions("edit_project");

  return (
    <div className="w-full border-b">
      <p className="font-gilroy-semi-bold text-[12px] text-pot-yellow p-4">
        Customer Details
      </p>

      <div className="px-4 pb-4 flex flex-col gap-0 text-start text-[12px] leading-5">
        {data?.customer_name ? (
          <div>
            <p className="text-[#AEAEAE]">Name:</p>
            <p>{data?.customer_name ? data?.customer_name : "-"}</p>
          </div>
        ) : null}

        {data?.customer_invite_status === "NO_INVITE" ? (
          <div className="flex flex-col gap-2 text-[12px]">
            <p className="font-gilroy-medium text-[12px] text-[#AEAEAE]">
              Customer status:{" "}
            </p>
            <p className="rounded-md bg-[#EAEAEA] px-1 w-fit font-gilroy-regular">
              No customer is added.
            </p>
            <Tooltip
              title={
                !hasEditPermission &&
                "You don't have permission to perform this action"
              }
            >
              <button
                className={`text-pot-yellow disabled:cursor-not-allowed flex gap-2 items-center font-gilroy-medium  hover:underline ${
                  !hasEditPermission
                    ? " opacity-30 cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                onClick={() => {
                  hasEditPermission && setShowInviteModal(true);
                }}
              >
                <PlusIcon width="16" />
                Add Customer
              </button>
            </Tooltip>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProjectCreateDetails;
