import React, { Component } from "react";
import * as echarts from "echarts";
import { defaultColors } from "../../../utils/data";
import { formatNumberIndianRupees } from "../../../utils/formatDateTime";

class DonutChartComponent extends Component<any, any> {
  private chartRef: React.RefObject<HTMLDivElement>;
  constructor(props: any) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      deviceWidth: window.innerWidth,
    };
  }

  static defaultProps = {
    showCount: false,
  };

  componentDidMount() {
    this.initializeChart();
  }

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.chartData !== this.props.chartData ||
      prevProps.name !== this.props.name
    ) {
      this.initializeChart();
    }
  }

  initializeChart() {
    const myChart = echarts.init(this.chartRef.current);

    const formatedData =
      this.props.chartData && this.props.chartData.length > 0
        ? this.props.chartData.map((item: any, index: any) => {
            return {
              name: (
                item?.name?.toLowerCase()?.replaceAll("_", " ") ||
                item?.category_name
              )
                ?.split(" ")
                ?.map(
                  (word: any) => word.charAt(0).toUpperCase() + word.slice(1)
                )
                ?.join(" "),
              value: item?.value,
              order_count: item?.order_count,
              itemStyle: {
                color:
                  index <= defaultColors.length
                    ? defaultColors[index]
                    : "#FDAC2C",
              },
            };
          })
        : [];

    const option = {
      tooltip: {
        className: "!z-[5]",
        trigger: "item",
        formatter: (params: any) => {
          const { showRupees, showPercentage, showCount } = this.props;
          const formattedSeriesName = params.seriesName;
          const formattedName = params.data.name;
          const formattedValue = params.data.value;
          const formattedOrderCount = params.data.order_count;
          const countValue = `<br/>No. of orders : <b>${formattedOrderCount}</b>`;
          if (showRupees) {
            return `${formattedSeriesName}<br />${formattedName}: <b>${formatNumberIndianRupees(
              formattedValue
            )}</b>${showCount && formattedOrderCount ? countValue : ""}`;
          } else if (showPercentage) {
            return `${formattedSeriesName}<br />${formattedName}: <b>${formattedValue}%</b>${
              showCount && formattedOrderCount ? countValue : ""
            }`;
          }
          return `${formattedSeriesName}<br />${formattedName}: <b>${formattedValue}</b>${
            showCount && formattedOrderCount ? countValue : ""
          }`;
        },
      },
      legend: {
        orient: this.props.horizontalLegends
          ? "horizontal"
          : this.state.deviceWidth > 1024
          ? "vertical"
          : "horizontal",
        type: this.props.horizontalLegends
          ? "scroll"
          : this.state.deviceWidth > 1024
          ? this.props.legendsAtBottom
            ? "plain"
            : null
          : "scroll",
        left: "16px",
        top: this.props.legendsAtBottom ? null : "10px",
        bottom: this.props.legendsAtBottom ? "0px" : null,
        textStyle: {
          fontSize: "10px",
        },
      },
      series: [
        {
          name: this.props.name,
          type: "pie",
          // Key changes for donut chart: specify radius as array for inner and outer radius
          radius:
            this.state.deviceWidth > 1024
              ? ["50%", "70%"] // Desktop: inner radius 50%, outer radius 70%
              : ["30%", "40%"], // Mobile: inner radius 30%, outer radius 40%
          data: formatedData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          label: {
            fontSize: this.state.deviceWidth > 1024 ? 12 : 10,
          },
          // Optional: Add center label or content
          center: ["50%", "50%"],
        },
      ],
    };

    option && myChart.setOption(option);
  }

  componentWillUnmount() {
    echarts.dispose(this.chartRef.current as HTMLElement);
  }

  render() {
    return (
      <div ref={this.chartRef} style={{ width: "100%", height: "400px" }} />
    );
  }
}

export default DonutChartComponent;
