import React, { useState, useEffect } from "react";
import activeStatus from "../../assets/svg/activestatus.svg";
import DeActiveStatus from "../../assets/svg/deactivatestatus.svg";
import { protectedAxiosInstance } from "../../api/axiosManagement";
import loadingSpinner from "../../assets/images/LoadingSpinner.gif";
import { toast } from "react-toastify";
import { checkPermissions } from "../../utils/helper";

interface SingleTrackProps {
  id: string;
  is_enabled: boolean;
  name: string;
  updateIsEnabled: (id: string, is_enabled: boolean) => void;
}

const trackDescriptions = {
  "4-Wire Magnetic Track":
    "The Magnetic Track 48V DALI system provides flexible, automated lighting with dimming and color tuning (2700K-6500K).",
  "2-Wire Magnetic Track":
    "The Magnetic Track system offers flexible, modern lighting with modular setups and various installation options.",
  "SuperSlim Magnetic Track":
    "The 48V Super Slim Magnetic Track offers sleek, energy-efficient lighting for modern interiors.",
  "Brass Magnetic Track":
    "The Brass Magnetic Track - 42V is a premium, customizable system with easy installation.",
  "Stringline Plus System Track":
    "The Stringline Plus Track is a flexible, black lighting system, extendable to 10 meters, with secure wall hook installation.",
};

export const TrackConfigurator = () => {
  const [allTracks, setAllTracks] = useState<any>([]);
  const [trackLoading, setTrackLoading] = useState(true);

  const fetchAllTracks = async () => {
    try {
      const response = await protectedAxiosInstance.get(
        "/admin/products/track"
      );
      setAllTracks(response?.data?.data?.track);
    } catch (error) {
      console.log(error);
    } finally {
      setTrackLoading(false);
    }
  };

  useEffect(() => {
    fetchAllTracks();
  }, []);

  const updateIsEnabled = async (id: string, is_enabled: boolean) => {
    try {
      const response = await protectedAxiosInstance.post(
        `/admin/products/track/${id}/status-change`,
        { is_enabled }
      );

      toast.success("Track status updated successfully");
      fetchAllTracks();
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  return (
    <div className="h-full">
      <div className="py-6 px-[96px] sticky top-0 bg-white">
        <h1 className="text-2xl font-medium font-gilroy-semi-bold">
          Track Configuration
        </h1>
      </div>

      {trackLoading && (
        <div className="h-[70vh] flex justify-center items-center">
          <img src={loadingSpinner} alt="spinner" className="w-[10vh] h-auto" />
        </div>
      )}

      {!trackLoading && allTracks?.length === 0 && (
        <div className="h-[70vh] flex justify-center items-center">
          <p className="font-gilroy-semi-bold text-2xl">No tracks found</p>
        </div>
      )}

      {!trackLoading && allTracks?.length > 0 && (
        <div className="max-w-[800px] ml-[96px] flex flex-col gap-4">
          {allTracks?.map((track: any, index: any) => {
            const { id, is_enabled, name } = track;
            return (
              <SingleTrack
                key={id}
                id={id}
                is_enabled={is_enabled}
                name={name}
                updateIsEnabled={updateIsEnabled}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

function SingleTrack({
  id,
  is_enabled,
  name,
  updateIsEnabled,
}: SingleTrackProps) {
  const [isActive, setIsActive] = useState(is_enabled);  
  // Permissions
  const hasEditPermission = checkPermissions("edit_product");

  return (
    <div className="pb-5 border-b border-[#D1D1D1] flex justify-between">
      <div className="flex flex-col text-xs leading-5">
        <p className="font-gilroy-semi-bold">{name}</p>
        <p className="font-gilroy-medium">
          {trackDescriptions[name as keyof typeof trackDescriptions]}
        </p>
      </div>

      <div className="flex flex-col items-end min-w-[150px]">
        <button
          type="button"
          className="stroke-grey cursor-pointer disabled:cursor-not-allowed disabled:opacity-30 bg-white p-1 rounded-md hover:bg-red hover:stroke-white h-8 w-12                                                                        "
          onClick={() => {
            setIsActive(!isActive);
            updateIsEnabled(id, !isActive);
          }}
          disabled={!hasEditPermission}
        >
          {isActive ? (
            <img
              src={activeStatus}
              alt="status-icon"
              className="w-full object-contain h-full"
            />
          ) : (
            <img
              src={DeActiveStatus}
              alt="status-icon"
              className="w-full object-contain h-full"
            />
          )}
        </button>

        <div>
          <div className="font-gilroy-regular text-xs text-[#989898]">
            {isActive ? "This track is Available" : "Not available to purchase"}
          </div>
        </div>
      </div>
    </div>
  );
}
