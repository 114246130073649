import React from "react";
import ThreeDotsLoader from "./ThreeDotsLoader";

type ChartWrapperProps = {
  title: string;
  children?: React.ReactNode;
  chartData?: any;
  extraStyles?: string;
  filter?: any;
  loading?: boolean;
};

export default function ChartWrapper({
  title,
  children,
  extraStyles,
  chartData,
  filter,
  loading,
}: ChartWrapperProps) {
  return (
    <div
      className={`min-h-[380px] border-[1px] rounded-md py-2 w-full flex flex-col ${extraStyles}`}
    >
      {(title || filter) && (
        <div className="px-4 font-medium flex justify-between gap-1">
          {title && <span className="text-sm font-gilroy-medium">{title}</span>}
          {filter && <span className="w-fit">{filter}</span>}
        </div>
      )}

      <div
        className={`${
          loading ? "flex justify-center items-center min-h-[300px]" : ""
        }`}
      >
        {loading ? (
          <ThreeDotsLoader />
        ) : chartData?.length > 0 ? (
          children
        ) : (
          <div className="w-full h-[300px] text-2xl font-semibold text-gray-300 flex justify-center items-center">
            NO DATA
          </div>
        )}
      </div>
    </div>
  );
}
