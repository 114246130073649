
const SquareEditIcon = ({ color, width }: any) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14203_59666)">
        <path
          d="M6.41675 2.83313H2.33341C2.024 2.83313 1.72725 2.95605 1.50846 3.17484C1.28966 3.39363 1.16675 3.69038 1.16675 3.9998V12.1665C1.16675 12.4759 1.28966 12.7726 1.50846 12.9914C1.72725 13.2102 2.024 13.3331 2.33341 13.3331H10.5001C10.8095 13.3331 11.1062 13.2102 11.325 12.9914C11.5438 12.7726 11.6667 12.4759 11.6667 12.1665V8.08313M10.7917 1.95814C11.0238 1.72608 11.3386 1.5957 11.6667 1.5957C11.9949 1.5957 12.3097 1.72608 12.5417 1.95814C12.7738 2.1902 12.9042 2.50495 12.9042 2.83314C12.9042 3.16133 12.7738 3.47608 12.5417 3.70814L7.00008 9.24981L4.66675 9.83314L5.25008 7.49981L10.7917 1.95814Z"
          stroke="#D4A15E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_14203_59666">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareEditIcon;
