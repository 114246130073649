import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { v4 as uuidV4 } from "uuid";
import close from "../assets/svg/close.svg";
import { findPhoneNumbersInText } from "libphonenumber-js";
import SingleSelection from "./SingleSelection";
import InputField from "./InputField";
import AuthInputField from "./AuthInputField";
import SelectField from "./SelectField";
import Button from "./Button";
import { protectedAxiosInstance } from "../api/axiosManagement";
import { toast } from "react-toastify";
import { stateAtom } from "../atom";
import { useRecoilValue } from "recoil";
import {
  validateNumberHyphen,
  validatePhone,
  validatePrice,
} from "../utils/validations";
import {
  AddressData,
  AddressFormProps,
  addressFormSchema,
} from "../validators/AddressFormSchema";

const AddressForm: React.FC<AddressFormProps> = ({
  addressData,
  setOpenInputAddress,
  setTargetAddress,
  customerId,
  getAddressList,
  showShipping = true,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm<AddressData>({
    resolver: yupResolver(addressFormSchema),
    defaultValues: {
      ...(addressData as AddressData),
      phone: addressData?.phone
        ? findPhoneNumbersInText(addressData.phone)?.[0]?.number
            ?.nationalNumber || ""
        : "",
      country_calling_code: "+91",
    },
  });
  const stateData = useRecoilValue(stateAtom);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: AddressData) => {
    setLoading(true);
    const formattedData = {
      ...data,
      phone: `${data.country_calling_code}${data.phone}`,
      country: "IN",
    };
    delete formattedData.country_calling_code;

    try {
      if (addressData) {
        const patchPayload = { ...formattedData };
        if ("id" in patchPayload) {
          delete (patchPayload as any).id;
        }

        await protectedAxiosInstance.patch(
          `/admin/accounts/customers/${customerId}/address/${addressData?.id}`,
          patchPayload
        );
        toast.success("Address updated successfully.");
      } else {
        await protectedAxiosInstance.post(
          `/admin/accounts/customers/${customerId}/address`,
          formattedData
        );
        toast.success("Address created successfully.");
      }
      getAddressList();
      setOpenInputAddress(false);
      setTargetAddress(undefined);
    } catch (error: any) {
      if (error?.data?.errors) {
        // Backend returns field-specific errors
        const backendErrors = error?.data?.errors;

        // Iterate over the errors and set them in the form
        Object.entries(backendErrors).forEach(([field, messages]) => {
          const message = Array.isArray(messages) ? messages[0] : messages;

          setError(field as keyof AddressData, {
            type: "manual",
            message: message as any, // Ensure it's a string
          });
        });
        console.error("Error handling address:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="bg-white max-w-xl w-[80%] max-h-[80vh] overflow-y-auto no-scrollbar"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-between sticky top-0 z-50 bg-white p-5">
        <p className="text-3xl font-gilroy-bold">
          {addressData ? "Edit Address" : "Create Address"}
        </p>
        <button
          className="scale-150"
          onClick={() => {
            setOpenInputAddress(false);
            setTargetAddress(undefined);
          }}
        >
          <img src={close} alt="close" />
        </button>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} noValidate className="px-5 py-2">
        <div className="text-[0.8rem] flex flex-col mb-3">
          <p className="font-gilroy-bold">
            Select type of address:{" "}
            <span className="text-red-500">&nbsp; *</span>
          </p>
          <div className="flex gap-4">
            {(showShipping ? ["SHIPPING", "BILLING"] : ["BILLING"]).map(
              (el) => (
                <div key={uuidV4()}>
                  <Controller
                    name="kind"
                    control={control}
                    render={({ field }) => (
                      <SingleSelection
                        onClick={() => field.onChange(el)}
                        label={el}
                        rounded
                        isSelected={el === field.value}
                      />
                    )}
                  />
                </div>
              )
            )}
          </div>
          {errors.kind && (
            <p className="text-left font-light text-xs pt-1 text-red-700">
              {errors.kind.message}
            </p>
          )}
        </div>
        <Controller
          name="full_name"
          control={control}
          render={({ field }) => (
            <InputField
              label="Full Name:"
              placeholder="Full Name"
              value={field.value}
              onChange={(e: string) => {
                field.onChange(e);
                setValue("full_name", e);
              }}
              hint={errors?.full_name?.message || ""}
              required
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <AuthInputField
              onChange={(e: any) => {
                field.onChange(e);
                setValue("phone", e);
              }}
              country_code_value={watch("country_calling_code")}
              setCode={(e: string) => setValue("country_calling_code", e)}
              value={field.value}
              placeholder="XXXXXXXXXX"
              heading="Phone number"
              hint={errors?.phone?.message}
              type="telephone"
              required
              validate={validateNumberHyphen}
            />
          )}
        />
        <Controller
          name="street_1"
          control={control}
          render={({ field }) => (
            <InputField
              className="mb-4 mt-3"
              label="Address Line 1:"
              placeholder="Address Line 1"
              value={field.value}
              onChange={(e: string) => {
                field.onChange(e);
                setValue("street_1", e);
              }}
              hint={errors?.street_1?.message || ""}
              required
            />
          )}
        />
        <Controller
          name="street_2"
          control={control}
          render={({ field }) => (
            <InputField
              label="Address Line 2:"
              className="mb-4"
              placeholder="Address Line 2"
              value={field.value}
              onChange={(e: string) => {
                field.onChange(e);
                setValue("street_2", e);
              }}
              hint={errors?.street_2?.message || ""}
            />
          )}
        />
        <div className="flex gap-4 mb-4">
          <div className="flex-1">
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <SelectField
                  placeholder="Select City"
                  hoverText={watch("state") ? "" : "First select state"}
                  label="Select City:"
                  options={(
                    stateData.find(
                      (el: any) => el.state_code === watch("state")
                    ) || { cities: [] }
                  ).cities.map((el: any) => ({
                    value: el,
                    label: el,
                  }))}
                  value={field.value}
                  onChange={(e: string) => {
                    field.onChange(e);
                    setValue("city", e);
                  }}
                  required
                />
              )}
            />
            {errors.city && (
              <p className="text-left font-light text-xs pt-1 text-red-700">
                {errors.city.message}
              </p>
            )}
          </div>

          <div className="flex-1">
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <SelectField
                  label="Select State:"
                  options={stateData.map((el: any) => ({
                    value: el.state_code,
                    label: el.name,
                  }))}
                  value={field.value}
                  onChange={(e: string) => {
                    field.onChange(e);
                    setValue("state", e);
                    setValue("city", "");
                  }}
                  placeholder="Select state"
                  required
                />
              )}
            />
            {errors.state && (
              <p className="text-left font-light text-xs pt-1 text-red-700">
                {errors.state.message}
              </p>
            )}
          </div>
        </div>
        <Controller
          name="postal_code"
          control={control}
          render={({ field }) => (
            <InputField
              label="Pin code:"
              className="mb-4"
              placeholder="Pin code"
              value={field.value}
              onChange={(e: string) => {
                field.onChange(e);
                setValue("postal_code", e);
              }}
              hint={errors?.postal_code?.message || ""}
              required
              maxLength={6}
              validate={validatePrice}
            />
          )}
        />
        <div className="text-[0.8rem] flex flex-col gap-2">
          <p className="font-gilroy-bold">
            Tag Address as: <span className="text-red-500">&nbsp; *</span>
          </p>
          <div className="flex gap-4">
            {["HOME", "WORK", "OTHERS"].map((el: any) => (
              <div key={uuidV4()}>
                <Controller
                  name="tag"
                  control={control}
                  render={({ field }) => (
                    <SingleSelection
                      rounded
                      label={el}
                      isSelected={el === field.value}
                      onClick={() => {
                        field.onChange(el);
                        setValue("tag", el);
                      }}
                    />
                  )}
                />
              </div>
            ))}
          </div>

          {errors.tag && (
            <p className="text-left font-light text-xs pt-1 text-red-700">
              {errors.tag.message}
            </p>
          )}
        </div>

        <div className="flex gap-2 text-[12px] md:text-base sticky bottom-0 z-40 bg-white py-5">
          <Button
            type="button"
            variant="primary"
            label={addressData ? "Update Address" : "Create Address"}
            loading={loading}
            disabled={loading}
            multiClickAllowed={false}
            onClick={(e: any) => {
              e?.preventDefault();
              handleSubmit(onSubmit)();
            }}
          />
          <Button
            label="Cancel"
            variant="secondary-outline"
            onClick={() => {
              setOpenInputAddress(false);
              setTargetAddress(undefined);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default AddressForm;
