import * as yup from "yup";

export const recordPaymentDefaultValues = {
  payment_type: "payment",
  amount: "",
  pay_date: "",
  mode: "",
  comment: "",
  is_reconciled: false,
};

export const recordPaymentFormSchema = yup.object().shape({
  payment_type: yup.string().trim().required("Payment type is required"),
  amount: yup.string().trim().required("Amount received is required"),
  pay_date: yup.string().trim().required("Payment date is required"),
  mode: yup.string().trim().required("Payment method is required"),
  comment: yup.string().trim(),
  is_reconciled: yup.boolean().required("Is reconcile is required"),
});

export type RecordPaymentFormSchema = yup.InferType<
  typeof recordPaymentFormSchema
>;
