import React, { useEffect, useState } from "react";
import VehicleIcon from "../../../assets/svg-tsx/VehicleIcon";
import EditIcon from "../../../assets/EditIcon";
import ReadyForDeliverIcon from "../../../assets/svg-tsx/ReadyForDeliverIcon";
import TickIcon2 from "../../../assets/svg-tsx/TickIcon2";
import Modal from "../../../components/common/Modal";
import { Controller, useForm } from "react-hook-form";
import {
  deliveryDetailsDefaultValues,
  deliveryDetailsFormSchema,
  DeliveryDetailsFormSchema,
} from "../../../validators/delivery-details-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import { Tooltip } from "antd";
import { markAsDelivered, updateDispatch } from "../../../api/orderManagement";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../components/common/ConfirmationModal";

type DeliveryActionProps = {
  readyForDispatch: boolean;
  delivered?: boolean;
  canDispatch?: boolean;
  onHandleDispatch?: () => void;
  currentDispatch?: any;
  orderId?: string;
  getDispatchListApi?: () => void;
  status?: string;
  resetCurrentDispatch?: () => void;
  handleMarkAsDelivered?: () => void;
  fetchOrderItems?: () => void;
  getSingleOrderApi?: () => void;
  isOrderCancelled?: boolean;
};

export default function DeliveryAction({
  readyForDispatch,
  canDispatch = false,
  onHandleDispatch,
  currentDispatch,
  orderId,
  getDispatchListApi,
  status,
  resetCurrentDispatch,
  handleMarkAsDelivered,
  fetchOrderItems,
  getSingleOrderApi,
  isOrderCancelled,
}: DeliveryActionProps) {
  const [deliveryDetailsOpen, setDeliveryDetailsOpen] = useState(false);
  const [dispatchType, setDispatchType] = useState<"dispatch" | "update">(
    "dispatch"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<DeliveryDetailsFormSchema>({
    defaultValues: deliveryDetailsDefaultValues,
    resolver: yupResolver(deliveryDetailsFormSchema),
  });

  async function onSubmit(data: DeliveryDetailsFormSchema) {
    try {
      setIsLoading(true);
      await updateDispatch(orderId, currentDispatch?.id, data);
      getDispatchListApi && getDispatchListApi();
      fetchOrderItems && fetchOrderItems();
      getSingleOrderApi && getSingleOrderApi();
      toast.success("Dispatch updated successfully");
    } catch (error: any) {
      console.log(error);
      toast.error("Failed to update dispatch");
    } finally {
      setIsLoading(false);
      setDeliveryDetailsOpen(false);
      resetCurrentDispatch && resetCurrentDispatch();
    }
  }

  useEffect(() => {
    if (dispatchType === "update") {
      reset({
        courier_name: currentDispatch?.courier_name,
        awb_number: currentDispatch?.awb_number,
        tracking_link: currentDispatch?.tracking_link,
      });
    }
  }, [dispatchType, currentDispatch]);

  function handleCancel() {
    setDeliveryDetailsOpen(false);
    reset();
  }

  return (
    <div>
      {readyForDispatch && (
        <Tooltip
          title={
            <p className="text-black font-gilroy-semi-bold font-normal">
              Dispatch
            </p>
          }
          color="white"
        >
          <button
            type="button"
            onClick={() => {
              setDeliveryDetailsOpen(true);
              onHandleDispatch && onHandleDispatch();
              setDispatchType("dispatch");
            }}
            disabled={!canDispatch || isOrderCancelled}
          >
            <VehicleIcon />
          </button>
        </Tooltip>
      )}
      {(status === "DISPATCHED" || status === "DELIVERED") && (
        <div>
          <div className="flex gap-2.5">
            <button
              type="button"
              onClick={() => {
                setDeliveryDetailsOpen(true);
                onHandleDispatch && onHandleDispatch();
                setDispatchType("update");
              }}
              disabled={isOrderCancelled}
            >
              <EditIcon />
            </button>

            {status === "DELIVERED" && (
              <Tooltip
                title={
                  <p className="text-black font-gilroy-semi-bold font-normal">
                    Delivered
                  </p>
                }
                color="white"
              >
                <button className="cursor-default">
                  <TickIcon2 />
                </button>
              </Tooltip>
            )}

            {status === "DISPATCHED" && (
              <Tooltip
                title={
                  <p className="text-black font-gilroy-semi-bold font-normal">
                    Mark as delivered{" "}
                  </p>
                }
                color="white"
              >
                <button
                  type="button"
                  onClick={() => setOpenConfirmationModal(true)}
                  disabled={isOrderCancelled}
                >
                  <ReadyForDeliverIcon />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      )}

      {deliveryDetailsOpen && (
        <Modal
          header="Delivery Details"
          handleCancel={handleCancel}
          widthClass="w-[564px]"
        >
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="mt-7 w-full flex flex-col gap-4">
              <Controller
                name="courier_name"
                control={control}
                render={({ field }) => (
                  <InputField
                    label="Courier Name"
                    placeholder="Courier Name"
                    value={field.value}
                    onChange={field.onChange}
                    hint={errors?.courier_name?.message}
                    required
                  />
                )}
              />
              <Controller
                name="awb_number"
                control={control}
                render={({ field }) => (
                  <InputField
                    label="AWB Number"
                    placeholder="AWB Number"
                    value={field.value}
                    onChange={field.onChange}
                    hint={errors?.awb_number?.message}
                    required
                  />
                )}
              />

              <Controller
                name="tracking_link"
                control={control}
                render={({ field }) => (
                  <InputField
                    label="Tracking link"
                    placeholder="Tracking link"
                    value={field.value}
                    onChange={field.onChange}
                    hint={errors?.tracking_link?.message}
                    required
                  />
                )}
              />
            </div>

            <div className="mt-7 w-full flex gap-7">
              <Button
                label="Save"
                variant="primary"
                type="submit"
                className="min-w-[56px]"
                onClick={handleSubmit(onSubmit)}
                loading={isLoading}
              />
              <Button
                label="Cancel"
                variant="secondary-outline-dark"
                onClick={handleCancel}
                disabled={isLoading}
              />
            </div>
          </form>
        </Modal>
      )}

      {openConfirmationModal && (
        <ConfirmationModal
          header="Mark as delivered"
          description="Are you sure you want to mark as delivered?"
          onConfirm={() => {
            handleMarkAsDelivered && handleMarkAsDelivered();
            setOpenConfirmationModal(false);
          }}
          onCancel={() => setOpenConfirmationModal(false)}
        />
      )}
    </div>
  );
}
