import React, { useRef, useState, useEffect } from "react";
import InputField, { InputFieldType } from "../../../components/InputField";
import useClickOutside from "../../../hooks/useClickOutside";
import { motion } from "framer-motion";
import { DownwardArrowIcon } from "../../../assets/ArrowIcons";
import EmailIcon from "../../../assets/EmailIcon";
import PhoneIcon from "../../../assets/PhoneIcon";
import ErrorMessage from "../../../components/ErrorMessage";
import CrossIcon from "../../../assets/CrossIcon";

type DropdownPropType = {
  options?: any;
  onSelect?: any;
  hint?: any;
  onChange?: any;
  value?: any;
  selectedCustomer?: any;
  setValue?: any;
};

const CustomerDropdownSearchable = ({
  options,
  onSelect,
  hint,
  onChange,
  value,
  selectedCustomer,
  setValue,
  ...props
}: InputFieldType & DropdownPropType) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const inputRef = useRef<HTMLDivElement>(null);
  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const listRef = useRef<HTMLDivElement>(null);

  useClickOutside(inputRef, () => {
    setOpenDropdown(false);
    setHighlightedIndex(-1);
  });

  const handleClearSelection = (e: React.MouseEvent) => {
    onSelect(null);
    setValue("");
  };

  // Keyboard navigation effect
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!openDropdown) return;

      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setHighlightedIndex((prevIndex) => 
            prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex
          );
          break;
        case 'ArrowUp':
          e.preventDefault();
          setHighlightedIndex((prevIndex) => 
            prevIndex > 0 ? prevIndex - 1 : prevIndex
          );
          break;
        case 'Enter':
          if (highlightedIndex >= 0 && highlightedIndex < options.length) {
            const selectedOption = options[highlightedIndex];
            onSelect(selectedOption);
            setOpenDropdown(false);
            setHighlightedIndex(-1);
          }
          break;
        case 'Escape':
          setOpenDropdown(false);
          setHighlightedIndex(-1);
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [openDropdown, highlightedIndex, options]);

  // Scroll to highlighted option
  useEffect(() => {
    if (highlightedIndex >= 0 && listRef.current) {
      const highlightedElement = optionRefs.current[highlightedIndex];
      
      if (highlightedElement) {
        // Calculate scroll position to center the highlighted element
        const listRect = listRef.current.getBoundingClientRect();
        const elementRect = highlightedElement.getBoundingClientRect();
        
        const scrollTop = 
          highlightedElement.offsetTop - 
          listRef.current.offsetTop - 
          (listRect.height / 2 - elementRect.height / 2);
        
        listRef.current.scrollTop = scrollTop;
      }
    }
  }, [highlightedIndex]);

  const toggleDropdown = () => {
    setOpenDropdown((prev) => !prev);
    setHighlightedIndex(-1);
  };

  return (
    <div ref={inputRef} className="relative mt-3 cursor-pointer">
      <div
        className="flex justify-between"
        onClick={toggleDropdown}
      >
        <InputField
          rightIcon={
            selectedCustomer && !openDropdown ? (
              <div className="flex items-center gap-2">
                <div className="cursor-pointer" onClick={handleClearSelection}>
                  <CrossIcon color="black" />
                </div>
              </div>
            ) : (
              <motion.div
                className="stroke-black"
                animate={{ rotate: openDropdown ? 180 : 0 }}
              >
                <DownwardArrowIcon />
              </motion.div>
            )
          }
          value={openDropdown ? value : selectedCustomer?.name || ""}
          onChange={(e: string) => {
            if (openDropdown) {
              onChange(e);
            }
          }}
          withDebounce={true}
          {...props}
        />
      </div>

      {hint && <ErrorMessage error={hint} />}
      {openDropdown && (
        <div 
          ref={listRef}
          className="absolute cursor-pointer text-sm z-10 top-[4.3rem] w-[100%] max-h-[40vh] overflow-y-auto custom-scrollbar bg-white rounded-md border min-h-[40px]"
        >
          {options?.length === 0 && (
            <div className="flex justify-center items-center h-full py-2">
              <p className="text-sm font-gilroy-medium">No results found</p>
            </div>
          )}
          {options?.map((each: any, index: number) => (
            <div
              ref={(el) => {
                optionRefs.current[index] = el;
              }}
              key={index}
              onClick={() => {
                onSelect(each);
                setOpenDropdown(false);
                setHighlightedIndex(-1);
              }}
              className={`px-3 text-left flex flex-col py-2 hover:bg-[#FCFCFC] ${
                index === highlightedIndex 
                  ? "bg-gray-100 outline outline-1 outline-gray-300" 
                  : ""
              }`}
            >
              <div className="font-gilroy-medium text-[14px]">{each?.name}</div>
              <div className="text-sm text-gray-500 flex gap-6 py-1">
                {each?.phone && (
                  <div className="flex gap-1 items-center w-32">
                    <PhoneIcon width={"12"} />{" "}
                    <p className="text-[#AEAEAE] text-xs">{each?.phone}</p>
                  </div>
                )}
                {each?.email && (
                  <div className="flex gap-1 items-center ">
                    <EmailIcon width={"14"} />{" "}
                    <p className="text-[#AEAEAE] text-xs">{each?.email}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomerDropdownSearchable;