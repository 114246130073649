import React, { useEffect, useMemo, useState } from "react";
import SelectField2 from "../../../components/SelectedField2";
import AuthInputField from "../../../components/AuthInputField";
import { useRecoilState } from "recoil";
import { stateAtom } from "../../../atom";
import {
  validateNumber,
  validateTextSpaceDot,
} from "../../../utils/validations";

interface Props {
  isDesignConsultant?: boolean;
  errorMessage: any;
  setErrorMessage: (value: any) => void;
  consultantData: {
    name: string | null;
    phone: string | null;
    email: string | null;
    city: string | null;
    state: string | null;
  };
  setConsultantData: (value: any) => void;
}

const DesignConsultantFields = ({
  isDesignConsultant,
  errorMessage,
  setErrorMessage,
  consultantData,
  setConsultantData,
}: Props) => {
  const [stateData] = useRecoilState(stateAtom);

  //City State dropdown states
  const [stateCity, setStateCity] = useState<string | null>(null);
  const [locationList, setLocationList] = useState<any>([]);
  const [locationPage, setLocationPage] = useState(1);
  const [locationTotalPage, setLocationTotalPage] = useState(0);
  const [locationSearch, setLocationSearch] = useState("");
  const itemsPerPage = 10;

  useEffect(() => {
    if (consultantData.city && consultantData.state) {
      setStateCity(consultantData.city + "," + consultantData.state);
    }
  }, [consultantData]);

  // Update locationList filtering based on selectedType
  const availableCityOptions = useMemo(() => {
    return locationList;
  }, [locationList]);

  useEffect(() => {
    const allCities =
      stateData
        ?.map((state: any, index: number) => {
          return state?.cities?.map((city: any, index: number) => ({
            key: index,
            value: `${city}, ${state.name}, ${state.state_code}`,
            label: `${city}, ${state.name}`,
          }));
        })
        .flat(1) || [];

    const filteredCities = allCities.filter((city: any) =>
      `${city.value} ${city.label}`
        .toLowerCase()
        .includes(locationSearch.toLowerCase())
    );

    const totalPage = Math.ceil(filteredCities.length / itemsPerPage);
    setLocationTotalPage(totalPage);

    if (locationSearch) {
      // Reset list and page when search term changes
      setLocationPage(1);
      setLocationList(filteredCities.slice(0, itemsPerPage));
    } else if (locationPage === 1) {
      // Reset locationList if page is 1
      setLocationList(filteredCities.slice(0, itemsPerPage));
    } else {
      // Append to locationList if page increases (for infinite scroll)
      const startIndex = 0;
      const endIndex = locationPage * itemsPerPage;
      setLocationList(filteredCities.slice(startIndex, endIndex));
    }
  }, [stateData, locationPage, locationSearch]);

  if (!isDesignConsultant) return null;

  return (
    <div className="mt-4 border-t border-[#DFDFDF] pt-5">
      <div className="flex flex-col gap-3">
        <AuthInputField
          onChange={(e: any) => {
            setConsultantData({ ...consultantData, name: e });
            if (errorMessage.consultantName) {
              setErrorMessage({ ...errorMessage, consultantName: "" });
            }
          }}
          value={consultantData.name || ""}
          placeholder="Enter consultant name"
          heading="Consultant Name"
          hint={errorMessage?.consultantName}
          required
          validate={validateTextSpaceDot}
        />

        <AuthInputField
          onChange={(e: any) => {
            setConsultantData({ ...consultantData, phone: e });
            if (errorMessage.consultantPhone) {
              setErrorMessage({ ...errorMessage, consultantPhone: "" });
            }
          }}
          value={consultantData.phone || ""}
          placeholder="Enter consultant phone"
          heading="Consultant Phone"
          hint={errorMessage?.consultantPhone}
          validate={validateNumber}
          maxLength={10}
          required
        />

        <AuthInputField
          onChange={(e: any) => {
            setConsultantData({ ...consultantData, email: e });
            if (errorMessage.consultantEmail) {
              setErrorMessage({ ...errorMessage, consultantEmail: "" });
            }
          }}
          value={consultantData.email || ""}
          placeholder="Enter consultant email"
          heading="Consultant Email"
          hint={errorMessage?.consultantEmail}
          required
        />

        <SelectField2
          label="Assigned City"
          options={availableCityOptions}
          value={stateCity}
          searchPlaceHolder="Search city"
          totalPage={locationTotalPage}
          page={locationPage || 1}
          setPage={setLocationPage}
          externalSearch={locationSearch}
          externalSetSearch={setLocationSearch}
          onChange={(e: string) => {
            const cityStateArray = e.split(", ");
            const formattedValue = `${cityStateArray[0]},${cityStateArray[2]}`;
            setStateCity(formattedValue);
            setConsultantData({
              ...consultantData,
              city: cityStateArray[0],
              state: cityStateArray[2],
            });
            if (errorMessage.consultantLocation) {
              setErrorMessage({ ...errorMessage, consultantLocation: "" });
            }
          }}
          hint={errorMessage?.consultantLocation}
          removeOption={() => {
            setStateCity("");
            setConsultantData({
              ...consultantData,
              city: null,
              state: null,
            });
          }}
          autoComplete="off"
          required
        />
      </div>
    </div>
  );
};

export default DesignConsultantFields;
