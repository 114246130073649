import classNames from "classnames";
import React from "react";

interface CustomTableProps {
  tableHeaders?: any;
  tableData?: any;
  customTableClass?: string;
  tableHeaderRowClass?: string;
}

export default function CustomTable({
  tableHeaders,
  tableData,
  customTableClass,
  tableHeaderRowClass,
}: CustomTableProps) {
  return (
    <div className={classNames("relative w-full h-full", customTableClass)}>
      <table className="w-full border border-gray-250 h-full">
        {/* Heading */}
        <thead className="sticky -top-[0.5px] bg-[#ECECEC] z-5">
          <tr
            className={classNames(
              "border border-gray-250 [&>th]:px-3 [&>th]:py-2.5 font-gilroy-semi-bold [&>th]:border [&>th]:whitespace-nowrap [&>th]:bg-[#ECECEC] [&>th]:text-left text-sm leading-[14px]",
              tableHeaderRowClass
            )}
          >
            {tableHeaders?.map((item: any) => {
              const { id, name, className } = item;
              return (
                <th key={id} className={classNames("", className)}>
                  {name}
                </th>
              );
            })}
          </tr>
        </thead>

        {/* Table Data */}
        <tbody>
          {tableData.map((row: any, rowIndex: any) => (
            <tr
              key={rowIndex}
              className="border border-gray-250 text-xs leading-[14px] text-black font-gilroy-regular"
            >
              {/* Dynamically render each cell except the 'id' */}
              {Object.keys(row)
                .filter((key) => key !== "id") // Exclude 'id' from rendering
                .map((key, cellIndex) => (
                  <td key={cellIndex} className="px-3 py-2.5 border">
                    {row[key] || "-"}{" "}
                    {/* Render the cell data or '-' if empty */}
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
