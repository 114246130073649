import React, { Component } from "react";
import * as echarts from "echarts";
import {
  formatDateForCharts,
  formatNumberIndianRupees,
} from "../../../utils/formatDateTime";

class LineChartComponent extends Component<any, any> {
  private chartRef: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      deviceWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    this.initializeChart();
  }

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.chartData !== this.props.chartData ||
      prevProps.name !== this.props.name
    ) {
      this.initializeChart();
    }
  }

  initializeChart() {
    const myChart = echarts.init(this.chartRef.current);

    // Function to process year data
    const processYearData = (data: any) => {
      const result: any[] = [];
      const defaultOptions = {
        type: "line",
        smooth: true,
        showSymbol: false,
      };
      data?.forEach((item: any) => {
        const dataArray = item?.values?.map((e: any) => e.value);
        // const dataArray = item?.values.map((e) => {
        //   return { value: e.value, name: convertDateDDMMYYYOnly(e.name) };
        // });
        result.push({
          ...defaultOptions,
          name: item?.name,
          data: dataArray,
          // lineStyle: {
          //   color: stringToColour(item?.name),
          // },
        });
      });
      return result;
    };

    const chartData = this.props.chartData;

    // Calculate data for all months
    const seriesData = processYearData(this.props.chartData);
    const xAxisLabels =
      chartData?.length > 0 && chartData[0].values?.map((e: any) => e.name);
    const precedingDates =
      this.props.precedingDatesExist &&
      chartData?.length > 0 &&
      chartData[1].values.map((e: any) => e.name);

    const yAxisLabelFormatter = (value: any) => {
      if (this.props.showPercentage) {
        return `${value}%`;
      } else if (this.props.showRupees) {
        return `${formatNumberIndianRupees(value)}`;
      } else {
        return value;
      }
    };

    const tooltipValueFormatter = (params: any) => {
      const { showRupees, showPercentage } = this.props;
      let dateDisplayed = false;
      return params
        .map((param: any) => {
          const date = new Date(param.name);
          const day = date.getDate().toString().padStart(2, "0");
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const year = date.getFullYear();
          const formattedDate = `${day}-${month}-${year}`;
          const formattedValue = showRupees
            ? `${formatNumberIndianRupees(param.value)}`
            : showPercentage
            ? `${param.value}%`
            : param.value;
          const dotColorStyle = `style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${param.color}"`;
          const tooltipItem = [];
          if (!dateDisplayed) {
            tooltipItem.push(
              `<div>${formattedDate}
              </div>`
            );
            dateDisplayed = true;
          } else {
            tooltipItem.push(
              `<div>${
                formatDateForCharts(precedingDates[param.dataIndex]) || ""
              }
              </div>`
            );
            dateDisplayed = false;
          }
          tooltipItem.push(
            `<div><span ${dotColorStyle}></span> <span style="margin-right: 5px;">${param.seriesName}</span><b style="font-weight: 700;">${formattedValue}</b></div>`
          );
          return tooltipItem.join("");
        })
        .join("<br>");
    };

    const option = {
      tooltip: {
        trigger: "axis",
        className: "!z-[5]",
        textStyle: {
          fontSize: this.state.deviceWidth > 1024 ? 12 : 10,
        },
        formatter: tooltipValueFormatter,
      },
      legend: {
        data: chartData?.map((e: any) => e.name),
        bottom: 0,
        left: 0,
        padding: [0, 40],
        textStyle: {
          fontSize: "10px",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        // top: "8%",
        bottom: this.state.deviceWidth > 1024 ? "8%" : "12%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: xAxisLabels,
        axisLabel: {
          align: "center",
          margin: this.state.deviceWidth < 1450 || this.props.rotate ? 32 : 10,
          rotate: this.state.deviceWidth < 1450 || this.props.rotate ? 40 : 0,
          formatter: function (value: any) {
            // Convert date format to DD-MM-YYYY
            const date = new Date(value);
            const day = date.getDate().toString().padStart(2, "0");
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
          },
          fontSize: this.state.deviceWidth > 1024 ? "10px" : "9px",
        },
      },
      yAxis: {
        type: "value",
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: yAxisLabelFormatter,
          fontSize: this.state.deviceWidth > 1024 ? "10px" : "9px",
        },
      },
      series: seriesData,
    };
    option && myChart.setOption(option);
  }

  componentWillUnmount() {
    echarts.dispose(this.chartRef.current as HTMLElement);
  }

  render() {
    return (
      <div
        ref={this.chartRef}
        style={{ width: "100%", height: "350px" }}
        className="w-full"
      />
    );
  }
}

export default LineChartComponent;
