import { useRef, useState } from "react";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import useWindowWidth from "../../../hooks/useWindowWidth";
import ErrorMessage from "../../../components/ErrorMessage";
import { truncateFilename } from "../../../utils/helper";
import CrossIcon from "../../../assets/CrossIcon";
import UploadIcon from "../../../assets/UploadIcon";
import LoadingSpinner from "../../../assets/images/Spinner4.gif";

type OrderUploadProps = {
  kind: "ORDER_INVOICE" | "LC_ORDER_INVOICE";
  setter: (value: any) => void;
  file?: {
    file: string;
    filename: string;
    added?: boolean;
    download_url?: string;
  } | null;
  errors?: string[];
  removeError?: () => void;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  targetRef?: any;
  setMediaLoading?: (loading: boolean) => void;
  acceptTypes?: string[];
  maxSize?: number;
};

const OrderUpload = ({
  kind,
  setter,
  file = null,
  errors = [],
  removeError = () => {},
  disabled = false,
  required = false,
  label,
  targetRef,
  setMediaLoading = () => {},
  acceptTypes = [".pdf"],
  maxSize = 20,
}: OrderUploadProps) => {
  const [mouseOnFile, setMouseOnFile] = useState(false);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<any>(null);
  const windowWidth = useWindowWidth();
  const [fileName, setFileName] = useState("");

  const get_signed = async (extension: string) => {
    const payload = {
      extension,
      kind,
    };
    const response = await protectedAxiosInstance
      .post(`/admin/orders/generate-upload-url`, payload)
      .then((res: any) => res?.data?.data)
      .catch((res: any) => {
        return res?.data?.errors;
      });
    return response;
  };

  const signed_to_s3 = async (acceptedFile: File) => {
    const extension = acceptedFile.name.split(".").at(-1)?.toLowerCase();
    const { file_path, upload_url } = await get_signed(extension as string);

    const file = new FormData();
    file.append("file", acceptedFile);

    let contentType: string;

    if (extension === "cdr") {
      contentType = "application/octet-stream";
    } else if (extension === "pdf") {
      contentType = "application/pdf";
    } else if (extension === "dwg") {
      contentType = "image/vnd.dwg";
    } else {
      contentType = "";
    }

    const response = await fetch(upload_url, {
      method: "PUT",
      body: acceptedFile,
      headers: {
        "Content-Type": contentType,
      },
    });

    return { response, file_path };
  };

  const onDrop = async (acceptedFiles: File[], rejectedFiles: any) => {
    removeError();
    if (
      rejectedFiles.some(
        (el: any) =>
          el.errors &&
          el.errors.some((error: any) => error.code === "file-too-large")
      )
    ) {
      toast.error("File size exceeded.");
    }
    if (
      rejectedFiles.some(
        (el: any) =>
          el.errors &&
          el.errors.some((error: any) => error.code === "file-invalid-type")
      )
    ) {
      toast.error(
        `Invalid file format selected. Only ${acceptTypes.join(
          ", "
        )} are accepted.`
      );
    }

    if (acceptedFiles.length === 0) return;

    const acceptedFile = acceptedFiles[0]; // Only take the first file
    setFileName(acceptedFile.name);
    setUploading(true);
    setMediaLoading(true);

    try {
      const { response, file_path } = await signed_to_s3(acceptedFile);

      setter({
        file: file_path,
        filename: acceptedFile.name,
        added: true,
      });
    } catch (error) {
      toast.error("Error uploading file");
    } finally {
      setUploading(false);
      setMediaLoading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    }
  };

  const handleRemove = () => {
    setter(null);
    setFileName("");
  };

  const getAcceptObject = (types: string[]) => {
    return types.reduce((acc: { [key: string]: string[] }, type) => {
      acc[type] = [];
      return acc;
    }, {});
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: getAcceptObject(acceptTypes),
    onDrop,
    maxSize: maxSize * 1024 * 1024,
    multiple: false,
  });

  return (
    <div ref={targetRef} className="min-h-[140px]">
      {label && (
        <div className="flex items-center justify-between text-center pb-1 gap-2">
          <h4 className="text-xs font-bold font-gilroy-regular">
            {label}
            {required && <span className="text-red-500 text-xs ml-1">*</span>}
          </h4>
        </div>
      )}

      {!file && !uploading && (
        <div
          {...getRootProps()}
          className={`flex items-center justify-center w-full mb-2 ${
            disabled || uploading ? "opacity-50" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <label
            className={`flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed bg-white ${
              disabled || uploading
                ? "cursor-not-allowed"
                : "cursor-pointer hover:bg-gray-100"
            }`}
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <UploadIcon color="#D4A15E" />
              <p className="mb-2 text-sm text-gray-500">
                Drag & Drop or{" "}
                <span className="font-gilroy-bold text-pot-yellow">Choose</span>{" "}
                file to upload
              </p>
              <p className="text-xs text-gray-500 text-center">
                Upload {acceptTypes.join(", ")} file (Max file size: {maxSize}{" "}
                MB)
              </p>
            </div>
            <input
              {...getInputProps({ disabled: disabled || uploading })}
              ref={fileInputRef}
            />
          </label>
        </div>
      )}

      <ErrorMessage error={errors} />

      {(file || uploading) && (
        <div className="py-2 my-2">
          <div className="space-y-2">
            {file && (
              <div
                className="flex flex-col relative border-2 border-c-gray-2 px-2 rounded-md"
                onMouseEnter={() => setMouseOnFile(true)}
                onMouseLeave={() => setMouseOnFile(false)}
              >
                <div className="flex space-x-2 items-center py-2 justify-between w-full">
                  {file.download_url ? (
                    <a
                      href={file.download_url}
                      download
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <p
                        className="text-xs text-pot-black font-gilroy-regular hover:underline cursor-pointer"
                        title={file.filename || "-"}
                      >
                        {file.filename
                          ? truncateFilename(
                              file.filename,
                              windowWidth < 200 ? 40 : 50
                            )
                          : "-"}
                      </p>
                    </a>
                  ) : (
                    <p
                      className="text-xs text-pot-black font-gilroy-regular"
                      title={fileName || "-"}
                    >
                      {fileName
                        ? truncateFilename(
                            fileName,
                            windowWidth < 200 ? 40 : 50
                          )
                        : "-"}
                    </p>
                  )}
                  <button onClick={handleRemove}>
                    <CrossIcon color="gray" />
                  </button>
                </div>
              </div>
            )}

            {uploading && (
              <div className="flex justify-center items-center border-2 border-x-c-gray-2 rounded-md">
                <img alt="" width={32} height={32} src={LoadingSpinner} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderUpload;
