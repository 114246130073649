import React, { Component } from "react";
import * as echarts from "echarts";
import { stringToColour } from "../../../utils/helper";

class HorizontalBarChartComponent extends Component<any, any> {
  private chartRef: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    this.initializeChart();
  }

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.chartData !== this.props.chartData ||
      prevProps.name !== this.props.name
    ) {
      this.initializeChart();
    }
  }
  initializeChart() {
    const myChart = echarts.init(this.chartRef.current);
    const yAxisName = this.props.name || "";

    const formatedXData =
      this.props.chartData && this.props.chartData.length > 0
        ? this.props.chartData.map((item: any, index: any) => {
            return {
              name: item?.name,
              value: item?.value,
              itemStyle: {
                color: stringToColour(item?.name) || "#FDAC2C",
                // PREVIOUS CODE FOR COLOR SELCETION. KEPT SO THAT WE CAN EASILY REVERT IT BACK
                // color:
                //   index <= defaultColors.length
                //     ? defaultColors[index]
                //     : '#FDAC2C',
              },
            };
          })
        : [];

    const formatedYData =
      this.props.chartData && this.props.chartData.length > 0
        ? this.props.chartData.map((item: any) => item?.name)
        : [];

    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        className: "!z-[5]",
        formatter: `{b0}<br />${yAxisName}: <b>{c0}</b>`,
      },
      legend: {
        orient: "vertical",
        top: "10px",
        textStyle: {
          fontSize: "10px",
        },
        left: "80px",
        bottom: "10px",
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "5%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        axisTick: {
          show: false,
          alignWithLabel: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#000000",
          },
        },
        axisLabel: {
          color: "#000000",
          fontSize: "10px",
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
            color: "#DDD",
          },
        },
      },
      yAxis: {
        name: yAxisName,
        nameTextStyle: {
          align: "left",
        },
        axisTick: {
          show: false,
          alignWithLabel: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            width: 2,
            color: "#000000",
          },
        },
        axisLabel: {
          color: "#000000",
          fontSize: "10px",
          formatter: function (value: any) {
            // Break the label into multiple lines if it's too long
            const labelArray = value.split(" ");
            if (value && labelArray.length > 5) {
              return labelArray
                .map((word: any, index: any) =>
                  index % 5 === 0 && index !== 0 ? "\n" + word : word
                )
                .join(" ");
            }
            return value;
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
            color: "#DDD",
          },
        },
        data: formatedYData,
      },
      series: [
        {
          name: this.props.seriesName, // Series Name comes here
          type: "bar",
          barWidth: "25px",
          itemStyle: {
            color: "#5766C1",
          },
          data: formatedXData,
        },
      ],
    };
    option && myChart.setOption(option);
  }

  componentWillUnmount() {
    echarts.dispose(this.chartRef.current as HTMLElement);
  }

  render() {
    return (
      <div
        ref={this.chartRef}
        style={{
          // width: "100%",
          height: this.props.height || "427px",
        }}
        className="w-full"
      />
    );
  }
}

export default HorizontalBarChartComponent;
