import { Tooltip } from "antd";
import React, { useState } from "react";
import editPen from "../../assets/svg/pen.svg";
import PlusIcon from "../../assets/PlusIcon";
import { useRecoilValue } from "recoil";
import { stateAtom } from "../../atom";
type ConsultantAddressPopupProps = {
  title: string;
  editDisabled?: boolean;
  content: any;
  heading: string;
};

export default function ConsultantAddressPopup({
  title,
  editDisabled,
  heading,
  content,
}: ConsultantAddressPopupProps) {
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const hasEditPermission = true;
  const stateData = useRecoilValue(stateAtom);

  return (
    <>
      <div className="text-start px-4 pt-4 py-4 h-full w-full">
        <div className="w-full flex justify-between">
          <h1 className="text-[12px] leading-[20px] font-gilroy-semi-bold">
            {title}
          </h1>
          {/* <Tooltip
            title={
              !hasEditPermission &&
              "You don't have permission to perform this action"
            }
          >
            <button
              disabled={!hasEditPermission || editDisabled}
              onClick={() => setShowAddressPopup(true)}
              className={`${!hasEditPermission && " opacity-30 "}`}
            >
              {(title === "Billing Address" || title === "Shipping Address") &&
              content?.length > 0 ? (
                <img src={editPen} alt="edit-pen" />
              ) : (
                <PlusIcon color="black" />
              )}
            </button>
          </Tooltip> */}
        </div>
        {/* line 1 line 2 city - pincode state */}
        {(title === "Billing Address" || title === "Shipping Address") &&
        content?.length > 0 ? (
          <div className="flex flex-col space-y-0 text-start mt-6 text-[#1C1C1C]">
            <h3 className=" text-[12px] leading-[20px] font-gilroy-medium">
              {heading}
            </h3>

            <p className="text-[12px] leading-[20px] font-gilroy-medium">
              {content?.[0]?.line1},
            </p>
            <p className="text-[12px] leading-[20px] font-gilroy-medium">
              {content?.[1]?.line2 && <p>{content?.[1]?.line2},</p>}
            </p>
            <p className="text-[12px] leading-[20px] font-gilroy-medium">
              {content?.[2]?.city} - {content?.[3]?.pincode},
            </p>
            <p className="text-[12px] leading-[20px] font-gilroy-medium">
              {stateData &&
                stateData?.find(
                  (state: any) => state?.state_code == content?.[4]?.state
                )?.name}
            </p>
          </div>
        ) : (
          <div className="py-5  text-[12px] leading-[12px] font-gilroy-medium text-c-white-3">
            <p>No address added</p>
          </div>
        )}
      </div>
    </>
  );
}
