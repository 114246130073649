import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/common/Modal";
import InputField from "../../../components/InputField";
import SelectField2 from "../../../components/SelectedField2";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import { toast } from "react-toastify";
import { validatePrice } from "../../../utils/validations";
import ImageUpload from "../../../components/ImageUpload";

interface ProductFormState {
  product: {
    name: string;
    primary_image: string;
  };
  cart_properties: {
    code: string;
    wattage: string;
    lighting_control: string;
    lighting_control_category: string;
    track_shape: string;
    track_dimension: string;
    track_driver_quantity: string;
    light_color: string | null;
    body_color: string | null;
    reflector_color: string | null;
  };
  quantity: number | null;
  floor_id?: string;
  area_id?: string;
  sequence: number;
  price_unit: number;
  price_total: number;
}

const initialFormState: ProductFormState = {
  product: {
    name: "",
    primary_image: "",
  },
  cart_properties: {
    code: "",
    wattage: "",
    lighting_control: "",
    lighting_control_category: "",
    track_shape: "",
    track_dimension: "",
    track_driver_quantity: "",
    light_color: null,
    body_color: null,
    reflector_color: null,
  },
  quantity: null,
  sequence: 0,
  price_unit: 0,
  price_total: 0,
};

const AddProductModal = ({
  openModal,
  setOpenModal,
  quotationId,
  singleProjectData,
  singleQuotationData,
  getSingleQuotationCartItems,
}: {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  quotationId: string | undefined;
  singleProjectData: any;
  singleQuotationData: any;
  getSingleQuotationCartItems: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<ProductFormState>({ ...initialFormState });
  const [floors, setFloors] = useState<any[]>([]);
  const [areas, setAreas] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>();

  const primaryImageRef = useRef<any>(null);
  const [images, setImages] = useState<string[]>([]);

  const handleSubmit = async (e: any) => {
    e?.preventDefault();
    setLoading(true);
    setErrors({});

    try {
      // Prepare the payload, removing any extra spaces
      const payload = {
        ...form,
        cart_properties: {
          ...form.cart_properties,
          lighting_control_category: form.cart_properties.lighting_control,
        },
      };

      if (singleQuotationData.kind === "PROJECT") {
        payload.floor_id = form.floor_id;
        payload.area_id = form.area_id;
      }

      const res = await protectedAxiosInstance.post(
        `/admin/quotation/${quotationId}/add-custom-item`,
        payload
      );

      if (res.status === 201) {
        getSingleQuotationCartItems(quotationId);
        toast.success("Product added successfully");
        setForm({ ...initialFormState });
        setImages([]);
        setOpenModal(false);
      }
    } catch (e: any) {
      // Handle different types of backend errors
      if (e?.data?.errors) {
        const backendErrors = e?.data?.errors;
        setErrors(backendErrors);
        // Handle schema-level errors
        if (backendErrors?.length) {
          const schemaErrors = backendErrors;
          if (schemaErrors?.length === 1) {
            toast.error(schemaErrors);
          } else {
            toast.error(schemaErrors);
          }
        }
        // Handle field-specific errors
        const fieldErrors: Record<string, string> = {};
        Object.keys(backendErrors).forEach((key) => {
          if (key !== "_schema" && Array.isArray(backendErrors[key])) {
            fieldErrors[key] = backendErrors[key][0];
          }
        });

        setErrors(fieldErrors);
      } else {
        // Fallback error message
        toast.error("Product addition failed");
      }
    } finally {
      setLoading(false);
    }
  };

  // Populate floors when the component mounts or project data changes
  useEffect(() => {
    if (singleProjectData?.project?.floors) {
      const formattedFloors = singleProjectData.project.floors.map(
        (floor: any) => ({
          value: floor.id,
          label: floor.name,
        })
      );
      setFloors(formattedFloors);
    }
  }, [singleProjectData]);

  // Update areas when a floor is selected
  useEffect(() => {
    if (form.floor_id && singleProjectData?.project?.floors) {
      const selectedFloor = singleProjectData.project.floors.find(
        (floor: any) => floor.id === form.floor_id
      );

      if (selectedFloor) {
        const formattedAreas = selectedFloor.areas.map((area: any) => ({
          value: area.id,
          label: area.name,
        }));
        setAreas(formattedAreas);
      } else {
        setAreas([]);
      }

      // Reset area selection when floor changes
      setForm((prev) => ({
        ...prev,
        area_id: undefined,
      }));
    }
  }, [form.floor_id, singleProjectData]);

  useEffect(() => {
    if (form.quantity && form.price_unit) {
      const totalPrice = form.price_unit * form.quantity;
      setForm({ ...form, price_total: totalPrice });
    }
  }, [form.quantity, form.price_unit]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {openModal && (
          <Modal
            clickOutsideToClose
            allowScroll
            header="Add a product"
            footer={
              <Button
                variant="secondary"
                label="Add to list"
                onClick={handleSubmit}
                disabled={loading}
              />
            }
            handleCancel={() => setOpenModal(false)}
            minHeight="!w-[65vh]"
          >
            <div className="w-full flex flex-col gap-4">
              <div className="flex gap-4">
                <div className="w-[40%]">
                  <InputField
                    label="Code"
                    placeholder="Enter Product Code"
                    value={form.cart_properties.code}
                    onChange={(value) => {
                      if (errors?.code) {
                        const newErrors = { ...errors };
                        delete newErrors?.code;
                        setErrors(newErrors);
                      }
                      setForm({
                        ...form,
                        cart_properties: {
                          ...form.cart_properties,
                          code: value?.toUpperCase(),
                        },
                      });
                    }}
                    hint={errors?.code}
                  />
                </div>

                <InputField
                  label="Product Name"
                  placeholder="Name of the product"
                  value={form.product.name}
                  onChange={(value) => {
                    if (errors?.name) {
                      const newErrors = { ...errors };
                      delete newErrors?.name;
                      setErrors(newErrors);
                    }
                    setForm({
                      ...form,
                      product: { ...form.product, name: value },
                    });
                  }}
                  hint={errors?.name}
                  required
                />
              </div>

              <ImageUpload
                targetRef={primaryImageRef}
                label="Upload Product Image:"
                disabled={loading}
                kind="ITEM_PRODUCT"
                setter={(value: string | string[]) => {
                  if (value === "") {
                    // Handle deletion case
                    setImages([]);
                    setForm((prev) => ({
                      ...prev,
                      product: {
                        ...prev.product,
                        primary_image: "",
                      },
                    }));
                    return;
                  }

                  // Handle upload case
                  const newImages = Array.isArray(value) ? value : [value];
                  setImages(newImages);

                  if (newImages.length > 0) {
                    setForm((prev) => ({
                      ...prev,
                      product: {
                        ...prev.product,
                        primary_image: newImages[0],
                      },
                    }));
                  }
                }}
                isMulti={false}
                imageList={images}
                primary={form.product.primary_image}
                primarySetter={(value: string) => {
                  setForm((prev) => ({
                    ...prev,
                    product: {
                      ...prev.product,
                      primary_image: value,
                    },
                  }));
                }}
                errors={[
                  ...(errors?.images || []),
                  ...(errors?.primary_image || []),
                ]}
                removeError={() => {
                  if (errors?.images?.length) delete errors.images;
                  if (errors?.primary_image) delete errors.primary_image;
                  setErrors(structuredClone(errors));
                }}
              />

              <div className="flex gap-4">
                <InputField
                  label="Wattage"
                  placeholder="8W"
                  value={form.cart_properties.wattage}
                  onChange={(value) =>
                    setForm({
                      ...form,
                      cart_properties: {
                        ...form.cart_properties,
                        wattage: value,
                      },
                    })
                  }
                />

                <InputField
                  label="Lighting Control"
                  placeholder="Dali, Switch On/Off, etc."
                  value={form.cart_properties.lighting_control}
                  onChange={(value) =>
                    setForm({
                      ...form,
                      cart_properties: {
                        ...form.cart_properties,
                        lighting_control: value,
                      },
                    })
                  }
                />
              </div>

              <div className="w-full">
                <InputField
                  label="Track Shape"
                  placeholder="L-shape, Line, Rectangle, etc."
                  value={form.cart_properties.track_shape}
                  onChange={(value) =>
                    setForm({
                      ...form,
                      cart_properties: {
                        ...form.cart_properties,
                        track_shape: value,
                      },
                    })
                  }
                />
              </div>

              <div className="flex gap-4">
                <InputField
                  label="Track Dimension"
                  placeholder="8m x 9m x 10m"
                  value={form.cart_properties.track_dimension}
                  onChange={(value) =>
                    setForm({
                      ...form,
                      cart_properties: {
                        ...form.cart_properties,
                        track_dimension: value,
                      },
                    })
                  }
                />

                <InputField
                  label="Track Driver Quantity"
                  placeholder="1"
                  value={form.cart_properties.track_driver_quantity}
                  onChange={(value) =>
                    setForm({
                      ...form,
                      cart_properties: {
                        ...form.cart_properties,
                        track_driver_quantity: value,
                      },
                    })
                  }
                  error={errors?.trackDriverQuantity}
                  validate={validatePrice}
                />
              </div>

              <div className="flex gap-4">
                <InputField
                  label="Light Color"
                  placeholder="3000k, Tunable, etc."
                  value={form.cart_properties.light_color || ""}
                  onChange={(value) =>
                    setForm({
                      ...form,
                      cart_properties: {
                        ...form.cart_properties,
                        light_color: value,
                      },
                    })
                  }
                />

                <InputField
                  label="Body Color"
                  placeholder="Black"
                  value={form.cart_properties.body_color || ""}
                  onChange={(value) =>
                    setForm({
                      ...form,
                      cart_properties: {
                        ...form.cart_properties,
                        body_color: value,
                      },
                    })
                  }
                />

                <InputField
                  label="Reflector Color"
                  placeholder="Black"
                  value={form.cart_properties.reflector_color || ""}
                  onChange={(value) =>
                    setForm({
                      ...form,
                      cart_properties: {
                        ...form.cart_properties,
                        reflector_color: value,
                      },
                    })
                  }
                />
              </div>

              <div className="flex gap-4">
                <InputField
                  label="Item Quantity"
                  placeholder="1"
                  value={form.quantity || ""}
                  onChange={(value) => {
                    if (errors?.quantity) {
                      const newErrors = { ...errors };
                      delete newErrors?.quantity;
                      setErrors(newErrors);
                    }
                    setForm({ ...form, quantity: Number(value) });
                  }}
                  hint={errors?.quantity}
                  required
                  validate={validatePrice}
                />

                <InputField
                  label="Unit Price"
                  placeholder="Price of single item"
                  value={form.price_unit}
                  onChange={(value) => {
                    if (errors?.price_unit) {
                      const newErrors = { ...errors };
                      delete newErrors?.price_unit;
                      setErrors(newErrors);
                    }
                    setForm({ ...form, price_unit: Number(value) });
                  }}
                  hint={errors?.price_unit}
                  required
                  validate={validatePrice}
                />

                <InputField
                  label="Total Price"
                  placeholder="Total Price"
                  value={form.price_total}
                  onChange={(value) =>
                    setForm({ ...form, price_total: Number(value) })
                  }
                  hint={errors?.price_total}
                  required
                  disabled={true}
                />
              </div>

              {singleQuotationData.kind === "PROJECT" && (
                <div className="w-full grid grid-cols-2 gap-4">
                  <SelectField2
                    label="Floor"
                    value={form.floor_id}
                    options={floors}
                    onChange={(value) => {
                      if (errors?.floor_id) {
                        const newErrors = { ...errors };
                        delete newErrors?.floor_id;
                        setErrors(newErrors);
                      }
                      setForm({
                        ...form,
                        floor_id: value,
                        area_id: undefined,
                      });
                    }}
                    hint={errors?.floor_id}
                    searchPlaceHolder="Select floor"
                  />

                  <SelectField2
                    label="Area"
                    value={form.area_id}
                    options={areas}
                    onChange={(value) => {
                      if (errors?.area_id) {
                        const newErrors = { ...errors };
                        delete newErrors?.area_id;
                        setErrors(newErrors);
                      }
                      setForm({
                        ...form,
                        area_id: value,
                      });
                    }}
                    searchPlaceHolder="Select area"
                    hint={errors?.area_id}
                    disabled={!form.floor_id}
                  />
                </div>
              )}
            </div>
          </Modal>
        )}
      </form>
    </div>
  );
};

export default AddProductModal;
