import React, { useEffect, useMemo, useState } from "react";
import calenderIcon from "../../../assets/svg/clenderIcon.svg";
import { AnimatePresence, motion } from "framer-motion";
import { Link, useParams } from "react-router-dom";
import ProgressBar from "../../../components/Progressbar";
import EditIcon from "../../../assets/EditIcon";
import activeStatus from "../../../assets/svg/activestatus.svg";
import DeActiveStatus from "../../../assets/svg/deactivatestatus.svg";
import { formatDate } from "../../../utils/formatDateTime";
import { useRecoilState, useRecoilValue } from "recoil";
import { singleProjectAtom, stateAtom } from "../../../atom";
import { updateProject } from "../../../api/project";
import ScaleLoading from "../../../components/ScaleLoading";
import { states } from "../../../utils/states";

export default function OrderProjectDetails({ projectDetails }: any) {
  const { id } = useParams();
  const stateData = useRecoilValue(stateAtom);
  const [visibilityLoading, setVisibilityLoading] = useState(false);
  const [visibilityError, setVisibilityError] = useState(false);
  const [singleProjectData, setSingleProjectData] =
    useRecoilState(singleProjectAtom);

  // Function to convert Iso state code into full name of state (MH, Maharashtra)
  const stateCodeToName = (stateCode: any) => {
    if (states[stateCode]) {
      return states[stateCode];
    } else {
      return "-";
    }
  };

  const handleVisibility = async () => {
    setVisibilityLoading(true);
    try {
      if (singleProjectData) {
        const { name, address_state, address_city, is_public } =
          singleProjectData;
        const { data } = await updateProject(id, {
          name,
          address_state,
          address_city,
          is_public: !is_public,
        });
        setSingleProjectData(data?.project);
      }
    } catch (err) {
      setVisibilityError(true);
    }
    setVisibilityLoading(false);
  };

  const getTotalAreas = () => {
    let areaCount: number = 0;
    projectDetails?.floors?.map((floor: any) => {
      areaCount += floor?.areas?.length;
    });
    return areaCount;
  };

  return (
    <div className="px-4 py-3">
      <div className={`flex justify-between`}>
        <h1 className="text-pot-yellow font-medium text-sm">
          Project Details:
        </h1>
      </div>
      <div className="flex justify-between w-full">
        <div className={`w-full flex gap-44`}>
          <div className="w-full flex flex-col items-baseline justify-between">
            <div className="w-full py-2">
              {projectDetails?.name ? (
                <>
                  <p className="text-[12px] flex font-gilroy-medium text-[#AEAEAE]">
                    Project Name
                  </p>
                  <p className="text-[12px] flex font-gilroy-medium ">
                    {projectDetails?.name}
                  </p>
                </>
              ) : (
                <p className="text-base flex font-mangoGrotesque-black opacity-30">
                  No Project Added
                </p>
              )}
            </div>
            {projectDetails && (
              <div className="w-full flex flex-col gap-2">
                <div className="full">
                  <div className="flex flex-col gap-2 text-[12px]">
                    <div className="flex items-start flex-col">
                      <p className="text-[12px] flex font-gilroy-medium text-[#AEAEAE]">
                        Date Created
                      </p>
                      <p className="text-[12px] flex text-[#000000]">
                        {projectDetails?.created_at
                          ? formatDate(projectDetails?.created_at)
                          : "-"}
                      </p>
                    </div>
                    <p className="text-[12px] flex flex-col  text-[#000000] ">
                      <p className="text-[12px] flex font-gilroy-medium text-[#AEAEAE]">
                        Location
                      </p>
                      <span>
                        {/* If both city and state are present then display both */}
                        {projectDetails?.address_city &&
                        projectDetails?.address_state
                          ? `${projectDetails.address_city}, ${
                              stateData &&
                              stateData.find(
                                (state: any) =>
                                  state.state_code ===
                                  projectDetails.address_state
                              )?.name
                            }`
                          : projectDetails?.address_city &&
                            !projectDetails?.address_state
                          ? projectDetails.address_city
                          : ""}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="w-[50%]">
                  <div className="text-sm flex flex-col justify-center text-[12px]">
                    <p className="text-[12px] flex font-gilroy-medium text-[#AEAEAE]">
                      Floor/Area
                    </p>
                    <p className="text-[12px] flex font-gilroy-medium  text-[#000000]">
                      {projectDetails?.floors?.length || "-"}/
                      {getTotalAreas() || "-"}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
