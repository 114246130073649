import React, { useState } from "react";
import { checkPermissions } from "../../../utils/helper";
import Button from "../../../components/Button";
import CreateOrderPopup from "./CreateOrderPopup";

type CreateQuotationOrderProps = {
  kind: string;
  quotationId: any;
  projectData?: any;
  disabled?: boolean;
};

export default function CreateQuotationOrder({
  kind,
  quotationId,
  projectData,
  disabled = false,
}: CreateQuotationOrderProps) {
  const [openCreateOrder, setOpenCreateOrder] = useState(false);
  const hasCreateOrderPermission = checkPermissions("create_order");

  return (
    <div>
      <Button
        variant="secondary"
        label="Convert to Order"
        showNoPermissionTooltip={!hasCreateOrderPermission}
        onClick={() => setOpenCreateOrder(true)}
        disabled={disabled}
      />
      {openCreateOrder && (
        <CreateOrderPopup
          quotationId={quotationId}
          kind={kind}
          onClose={() => setOpenCreateOrder(false)}
          projectData={projectData}
        />
      )}
    </div>
  );
}
