import React, { useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { titleAtom, userInfoAtom } from "../../atom";

import { useNavigate } from "react-router-dom";
import InputField from "../../components/InputField";
import Button from "../../components/Button";
import SelectCheckboxOption from "../../components/SelectCheckboxOption";
import { getCustomerQuicklist } from "../../api/customers";
import EmailIcon from "../../assets/EmailIcon";
import PhoneIcon from "../../assets/PhoneIcon";
import { getProjectQuicklist } from "../../api/project";
import crossIcon from "../../assets/svg/close.svg";
import useOnClickOutside from "../../hooks/useClickOutside";
import SearchIcon from "../../assets/SearchIcon";
import { DownwardArrowIcon } from "../../assets/ArrowIcons";
import { motion } from "framer-motion";
import { postOrderDraft } from "../../api/orderManagement";
import ProjectDropdownSearchable from "./components/ProjectDropdownSearchable";
import CustomerDropdownSearchable from "./components/CustomerDropdownSearchable";
import { toast } from "react-toastify";
import ProfessionalDropdown from "../../components/ProfessionalDropdown";
import { getProfessionalList } from "../../api/professional";
import { checkPermissions } from "../../utils/helper";
import SelectField2 from "../../components/SelectedField2";
import { getListForChannelPartner } from "../../api/channelPartner";
import _debounce from "lodash/debounce";
import { protectedAxiosInstance } from "../../api/axiosManagement";

export default function CreateOrder() {
  const setTitle = useSetRecoilState(titleAtom);
  const navigate = useNavigate();
  const buttonRef = useRef(null);
  const containerRef = useRef(null);
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [customerList, setCustomerList] = useState<any>();
  const [customerSearch, setCustomerSearch] = useState("");
  const [searchProject, setSearchProject] = useState("");
  const [seletedtype, setSelectedType] = useState("customer");
  const [selectedProject, setSelectedProject] = useState<any>();
  const [projectList, setProjectList] = useState<any>();
  const [openProfessionalDropdown, setOpenProfessionalDropdown] =
    useState(false);
  useOnClickOutside(buttonRef, () => setOpenProfessionalDropdown(false));
  const debounceTimeoutRef = useRef<any>(null);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLength, setPageLength] = useState(10);
  const [professionalList, setProfessionalList] = useState<any>([]);
  const [professionalId, setProfessionalId] = useState("");
  const [professionalKind, setProfessionalKind] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [totalPages, setTotalPages] = useState(200); // Initial total number of pages

  const [channelPartnerList, setChannelPartnerList] = useState([]);
  const [channelPartnerLoading, setChannelPartnerLoading] = useState(false);
  const [channelPartner, setChannelPartner] = useState<any>(null);
  const [totalPage, setTotalPage] = useState(1);
  const [channlePartnerPage, setChannlePartnerPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [externalChannelPartner, setExternalChannelPartner] =
    useState<any>(null);
  const [externalProject, setExternalProject] = useState<any>();

  const [time, setTime] = useState(500);
  const debouncedFetchChannelPartners = _debounce(geChannelPartnerList, time);

  const [deliveryOption, setDeliveryOption] = useState("all_products");

  const userInfo = useRecoilValue(userInfoAtom);

  // Project dropdown states
  const [projectsPageLoading, setProjectsPageLoading] = useState(true);
  const [projectTotalPage, setProjectTotalPage] = useState(1);
  const [page, setPage] = useState<number>(1);
  const [searchText, setSearchText] = useState("");

  // Permissions
  const hasCreatePermission = checkPermissions("create_order", true, toast);

  async function geChannelPartnerList() {
    setChannelPartnerLoading(true);
    const params = {
      page: channlePartnerPage,
      length: 10,
      search: searchValue,
    };
    try {
      const res = await getListForChannelPartner(params);
      const newChannelPartnerList = res?.data?.channel_partners.map(
        (channelPartner: any) => ({
          value: channelPartner.id,
          label: channelPartner.name,
        })
      );

      // Update list based on page number
      setChannelPartnerList((prev) =>
        channlePartnerPage === 1
          ? newChannelPartnerList
          : [...prev, ...newChannelPartnerList]
      );

      setTotalPage(res?.data?.pagination?.total_pages);
    } catch (error) {
      console.error("Error fetching channel partner list:", error);
    } finally {
      setChannelPartnerLoading(false);
    }
  }

  // Handle pagination/infinite scroll
  useEffect(() => {
    if (channlePartnerPage <= totalPage) {
      const fetchData = async () => {
        await debouncedFetchChannelPartners();
      };
      fetchData();
    }
    return () => {
      debouncedFetchChannelPartners.cancel();
    };
  }, [channlePartnerPage]);

  // Handle search
  useEffect(() => {
    setTime(500);
    setChannlePartnerPage(1);
    setChannelPartnerList([]);
    setChannelPartnerLoading(true);
    const fetchData = async () => {
      await debouncedFetchChannelPartners();
    };
    fetchData();
    return () => {
      debouncedFetchChannelPartners.cancel();
    };
  }, [searchValue]);

  useEffect(() => {
    if (selectedProject) {
      const matchedProject = projectList.find(
        (project: any) => project.value === selectedProject
      );
      setExternalProject(
        matchedProject ? { value: matchedProject.label } : null
      );
    }
  }, [selectedProject]);

  const handleOrderType = (type: string) => {
    setSelectedType(type);
  };

  useEffect(() => {
    const params = {
      page: 1,
      length: 100,
      search: customerSearch,
    };
    getCustomerQuicklist(params)
      .then((res) => {
        setCustomerList(res?.data?.customers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [customerSearch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProfessionalList({
          search: debouncedSearchTerm || "",
          page: currentPage || "",
          length: totalPages || "",
        });
        setProfessionalList(response?.data?.professional);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData();
  }, [debouncedSearchTerm, currentPage, totalPages]);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedSearchTerm(searchQuery);
    }, 400);
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [searchQuery]);

  const handleClear = () => {
    setProfessionalId("");
    delete errors?.p_individual_id;
  };

  useEffect(() => {
    delete errors?.p_individual_id;
  }, [searchQuery]);

  const fetchProjects = async () => {
    setProjectsPageLoading(true);
    try {
      const res = await protectedAxiosInstance.get(
        "/admin/projects/quick-list",
        {
          params: { length: pageLength, page, search: searchText },
        }
      );

      const newProjects = res.data.data.project.map((project: any) => ({
        value: project.id,
        label: project.name,
      }));

      setProjectList((prevProjects: any) =>
        page === 1 ? newProjects : [...prevProjects, ...newProjects]
      );

      setProjectTotalPage(res.data.data.pagination.total_pages);
      setProjectsPageLoading(false);
    } catch (error) {
      console.error("Error fetching projects:", error);
      setProjectsPageLoading(false);
    }
  };

  const debouncedFetchProjects = _debounce(fetchProjects, time);

  useEffect(() => {
    if (seletedtype === "project") {
      setTime(10);
      const fetchData = async () => {
        await debouncedFetchProjects();
      };
      fetchData();
      return () => {
        debouncedFetchProjects.cancel();
      };
    }
  }, [page]);

  useEffect(() => {
    setTime(500);
    setPage(1);
    setProjectList([]);
    setProjectsPageLoading(true);
    const fetchData = async () => {
      await debouncedFetchProjects();
    };
    fetchData();
    return () => {
      debouncedFetchProjects.cancel();
    };
  }, [searchText]);

  const handleSubmitOrderDraft = () => {
    const payload = {
      professional_id: professionalId || null, // Include if selectedProfessional is defined
      professional_kind: professionalKind || null,
      owner: seletedtype === "customer" ? "USER" : "PROJECT",
      owner_id:
        seletedtype === "customer" ? selectedCustomer?.id : selectedProject,
      delivery_opt_timeline: deliveryOption === "all_products" ? 1 : 2,
      assigned_channel_partner: channelPartner,
    };

    // Only send professional_id if it's defined
    if (professionalId) {
      payload.professional_id = professionalId;
    }

    postOrderDraft(payload)
      .then((res) => {
        if (res) {
          const id = res?.data?.order?.id;
          toast.success("Order draft successfully");
          navigate(`/orders/${id}`);
        }
      })
      .catch((err) => {
        console.log(err?.data?.errors);
        setErrors(err?.data?.errors);
      });
  };

  if (!hasCreatePermission) {
    navigate("/orders");
  }

  useEffect(() => {
    if (userInfo?.channel_partner) {
      setChannelPartner(userInfo?.channel_partner?.id);
      setExternalChannelPartner({
        value: userInfo?.channel_partner?.name,
      });
    }
  }, []);

  const disableChannelPartnerDropdown = userInfo?.channel_partner
    ? true
    : false;

  return (
    <div className="h-full overflow-y-auto  custom-scrollbar">
      <div className="bg-white border-b border-[#ECECEC] w-full pt-4 pb-4  pl-4">
        <h2 className="font-semibold">Create new Order</h2>
      </div>
      <div className="overflow-y-auto custom-scrollbar h-[calc(100%-150px)] pl-4">
        <div className="flex flex-col gap-4   pr-5 md:pr-14 lg:pr-0 lg:w-[70%] lg:max-w-[50vw] ">
          <div>
            <p className="font-gilroy-semi-bold py-4">Order for:</p>
            <div className="flex justify-start gap-4 ">
              <SelectCheckboxOption
                kind="customer"
                isSelected={seletedtype === "customer"}
                handleSelection={handleOrderType}
              />
              <SelectCheckboxOption
                kind="project"
                isSelected={seletedtype === "project"}
                handleSelection={handleOrderType}
              />
            </div>

            {seletedtype === "customer" && (
              <div>
                <CustomerDropdownSearchable
                  options={customerList}
                  onSelect={(value: any) => {
                    setSelectedCustomer(value);
                    setSearchProject("");
                    setSelectedProject(null);
                    delete errors?.owner_id;
                  }}
                  onChange={(value: any) => {
                    setCustomerSearch(value);
                  }}
                  value={customerSearch}
                  selectedCustomer={selectedCustomer}
                  setValue={setCustomerSearch}
                  label="Select Customer:"
                  placeholder="Search customer here..."
                  hint={errors?.owner_id}
                  required
                />
              </div>
            )}

            {selectedCustomer && seletedtype === "customer" && (
              <div className="mt-2 flex gap-8">
                {selectedCustomer?.email && (
                  <div className="flex gap-2 items-center">
                    <EmailIcon width={"18"} />
                    <p>{selectedCustomer?.email} </p>
                  </div>
                )}

                {selectedCustomer?.phone && (
                  <div className="flex gap-2 items-center">
                    {" "}
                    <PhoneIcon />
                    <p>{selectedCustomer?.phone} </p>
                  </div>
                )}
              </div>
            )}

            {seletedtype === "project" && (
              <div className="cursor-pointer mt-3">
                <SelectField2
                  label="Select Project:"
                  loading={projectsPageLoading}
                  totalPage={projectTotalPage}
                  page={page}
                  setPage={setPage}
                  options={projectList}
                  value={selectedProject}
                  onChange={(value: any) => {
                    setSelectedProject(value);
                    setErrors((prev: any) => {
                      const newErrors = { ...prev };
                      delete newErrors.owner_id;
                      return newErrors;
                    });
                  }}
                  placeholder="Select a project"
                  externalSearch={searchText}
                  externalSetSearch={setSearchText}
                  searchPlaceHolder="Search project"
                  required
                  hint={errors?.owner_id}
                  externalValue={externalProject}
                  setExternalValue={setExternalProject}
                />
              </div>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <div className="">
              <ProfessionalDropdown
                smallText
                width="100%"
                label="Refer Professional:"
                Xsearch={searchQuery}
                setXSearch={setSearchQuery}
                options={professionalList.map((el: any) => ({
                  value: el.p_individual_id,
                  label: el,
                }))}
                onChange={(e: any) => {
                  setProfessionalId(e);
                  delete errors?.professional_id;
                }}
                hint={errors?.professional_id}
                value={professionalId}
                searchPlaceHolder="Search Professional"
                // placeholder="Choose employee"
                onClear={handleClear}
                // disabled={}
                setProfessionalKind={setProfessionalKind}
              />
            </div>
          </div>

          {/* channel partner */}
          <SelectField2
            options={channelPartnerList}
            value={channelPartnerLoading ? null : channelPartner}
            disabled={disableChannelPartnerDropdown}
            onChange={(value: any) => {
              setChannelPartner(value);
            }}
            loading={channelPartnerLoading}
            totalPage={totalPage}
            page={channlePartnerPage}
            setPage={setChannlePartnerPage}
            externalSearch={searchValue}
            externalSetSearch={setSearchValue}
            placeholder="Select Channel Partner"
            searchPlaceHolder="Select Channel Partner"
            label="Assign to Channel Partner"
            openListCss="h-[180px]"
            removeOption={() => {
              setChannelPartner(null);
            }}
            externalValue={externalChannelPartner}
            setExternalValue={setExternalChannelPartner}
          />

          <p className="font-gilroy-semi-bold text-sm font-normal leading-5">
            Delivery Options
          </p>
          <SelectCheckboxOption
            kind="Get the Products delivered together whenever available"
            isSelected={deliveryOption === "all_products"}
            handleSelection={() => setDeliveryOption("all_products")}
          />
          <SelectCheckboxOption
            kind="Deliver the items separately according to the timeline"
            isSelected={deliveryOption === "separate_delivery"}
            handleSelection={() => setDeliveryOption("separate_delivery")}
          />
        </div>
      </div>

      <div className="flex gap-x-4 pt-4 pl-4 border-t  border-[#ECECEC]">
        <Button
          label="Create"
          variant="primary"
          onClick={handleSubmitOrderDraft}
        />
        <Button
          label="Cancel"
          variant="secondary-outline"
          onClick={() => navigate("/orders")}
        />
      </div>
    </div>
  );
}
