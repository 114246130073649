import React, { useEffect, useMemo, useState } from "react";
import OrderTimelineTable from "./OrderTimelineTable";
import Button from "../../../components/Button";
import PrintIcon from "../../../assets/svg-tsx/PrintIcon";
import { useParams } from "react-router-dom";
import {
  getShareableToken,
  submitTimeline,
} from "../../../api/orderManagement";
import { toast } from "react-toastify";
import { getStatusBgColor } from "../SingleOrderDetails";
import AddProductPopup from "./AddProductPopup";
import { checkPermissions } from "../../../utils/helper";
import UpdateTimelineForm from "./UpdateTimelineForm";
import PaymentStatusTag from "../../../components/PaymentStatusTag";

const checkColumnVisibility = (allData: any[]) => {
  // Default return object for empty array or undefined data
  const defaultVisibility = {
    showTrackShape: false,
    showTrackDimension: false,
    showControllerQuantity: false,
  };

  // Return default values if data is empty or undefined
  if (!allData || allData.length === 0) {
    return defaultVisibility;
  }

  let showTrackShape = false;
  let showTrackDimension = false;
  let showControllerQuantity = false;

  // Flatten the nested data structure to check all items
  allData?.forEach((section: any) => {
    section.items?.forEach((item: any) => {
      if (item?.item_properties?.track_shape) {
        showTrackShape = true;
      }
      if (item?.item_properties?.track_dimension) {
        showTrackDimension = true;
      }
      if (item?.item_properties?.track_driver_quantity) {
        showControllerQuantity = true;
      }
    });
  });

  return {
    showTrackShape,
    showTrackDimension,
    showControllerQuantity,
  };
};

function OrderTimelineRequested({
  data,
  orderId,
  setTimelineData,
  handleDelete,
  handleQuantity,
  setTableData,
  orderDetails,
  projectDetails,
  setOrderDetails,
  handleCreateDispatch,
  isOrderCancelled,
}: any) {
  const { id } = useParams();
  const [openAddProductModal, setOpenAddProductModal] =
    useState<boolean>(false);
  const [tableWidth, SetTableWidth] = useState(0); // State to track bottom bar height
  const [checkedRows, setCheckedRows] = useState<{ [key: string]: boolean }>(
    {}
  );

  // Permission
  const hasEditPermission = checkPermissions("edit_order");

  const isOwnerProject = orderDetails?.owner === "PROJECT";

  const updateTimeline = () => {
    let itemsWithEstDates: any = [];
    let totalItems: any = [];
    data?.map((singleData: any) => {
      singleData.items?.map((item: any) => {
        totalItems.push(item);
        if (item.est_delivery_at) {
          itemsWithEstDates.push(item);
        }
      });
    });
    submitTimeline(id)
      .then((res: any) => toast.success("Timeline Submitted"))
      .catch((error: any) => {
        const errorMessage = error?.data?.errors?.general[0];
        toast.error(errorMessage);
      });
  };

  function AddProductHandler() {
    getShareableToken(orderId)
      .then((res: any) => {
        const token = res?.data?.shareable_token;
        const fullUrl = `${
          process.env.REACT_APP_DOMAIN
        }/shop/categories?orderNum=${orderDetails?.order_no}&orderId=${
          orderDetails?.id
        }&shareableToken=${token}${
          isOwnerProject ? `&isProjectOrder=true` : ""
        }`;
        // Ensure URL is defined and valid
        if (fullUrl) {
          window.location.href = fullUrl;
        }
      })
      .catch((error: any) => {
        toast.error(error?.data?.errors);
      });
  }

  // Initialize checked rows when data changes
  useEffect(() => {
    const initialCheckedRows: { [key: string]: boolean } = {};
    data?.forEach((singleData: any) => {
      singleData.items?.forEach((item: any) => {
        initialCheckedRows[item.id] = false;
      });
    });
    setCheckedRows(initialCheckedRows);
  }, [data]);

  // Handle checkbox changes
  const handleCheckboxChange = (itemId: string, isChecked: boolean) => {
    setCheckedRows((prev) => ({
      ...prev,
      [itemId]: isChecked,
    }));
  };

  // Add this helper function to check if any items are selected
  const hasSelectedItems = () => {
    return Object.values(checkedRows).some((value) => value === true);
  };

  const selectedItems = Object.entries(checkedRows)
    .filter(([_, value]) => value === true)
    .map(([id]) => id);

  const columnVisibility = useMemo(() => checkColumnVisibility(data), [data]);

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex justify-between">
        <h1 className="text-xl font-gilroy-bold">
          Order Items
          {orderDetails?.status === "AWAITING_TIMELINE" && (
            <PaymentStatusTag status={orderDetails?.status} className="mx-2" />
          )}
          <span className="text-sm text-[#757575] font-gilroy-medium mx-2">
            {orderDetails?.line_item_count.toLocaleString()} Items
          </span>
        </h1>
        <div className="flex gap-2">
          {hasSelectedItems() && (
            <>
              <UpdateTimelineForm
                orderId={orderId}
                selectedItems={selectedItems}
                setTableData={setTableData}
                isOrderCancelled={isOrderCancelled}
              />
              <Button
                label="Create Dispatch"
                variant="primary"
                onClick={() => {
                  handleCreateDispatch(selectedItems);
                }}
                disabled={!orderDetails?.allowed_actions?.can_create_dispatch}
              />
            </>
          )}

          {orderDetails?.allowed_actions?.can_add_item &&
            hasSelectedItems() && (
              <div className="h-[30px] mt-1 mx-3 w-[1px] bg-[#CFCFCF]"></div>
            )}

          {orderDetails?.allowed_actions?.can_add_item && (
            <div
              onClick={() => {
                hasEditPermission && setOpenAddProductModal(true);
              }}
            >
              <Button
                label="Add Item"
                variant="secondary"
                disabled={!hasEditPermission}
                showNoPermissionTooltip={!hasEditPermission}
                onClick={AddProductHandler}
              />
            </div>
          )}
        </div>
      </div>
      <div className="overflow-auto custom-scrollbar">
        <div className="flex flex-col">
          {data?.map((singleData: any) => {
            if (singleData?.items.length === 0) {
              return null;
            }
            return (
              <div key={singleData?.floor?.id}>
                <h1
                  className={`${
                    orderDetails?.owner === "USER"
                      ? "hidden"
                      : singleData?.floor?.id === "no_floor" && "italic"
                  }   bg-black text-white text-lg py-1.5 font-gilroy-bold text-center sticky top-0 z-10`}
                  style={{ width: `${tableWidth}px` }}
                >
                  {singleData?.floor?.name}
                </h1>
                <OrderTimelineTable
                  data={singleData.items}
                  orderId={orderId}
                  columnVisibility={columnVisibility}
                  setTimelineData={setTimelineData}
                  handleDelete={handleDelete}
                  handleQuantity={handleQuantity}
                  setTableData={setTableData}
                  setOrderDetails={setOrderDetails}
                  orderDetails={orderDetails}
                  projectDetails={projectDetails}
                  SetTableWidth={SetTableWidth}
                  checkedRows={checkedRows}
                  onCheckboxChange={handleCheckboxChange}
                  isOrderCancelled={isOrderCancelled}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default OrderTimelineRequested;
