import { useEffect, useState } from "react";
import Table from "./components/OrderTable";
import { getOrderList } from "../../api/orderManagement";
import { checkPermissions } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useDebounce from "../../hooks/useDebounce";

function OrderList() {
  const navigate = useNavigate();
  const [orderList, setOrderList] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [pageLength, setPageLength] = useState<number>(25);
  const [totalCount, setTotalCount] = useState<number>(100);
  const [searchValue, setSearchValue] = useState("");
  const [orderStatus, setOrderStatus] = useState();

  const debouncedSearchValue = useDebounce(searchValue, 500);

  // Filter states
  const [filteredOrderStatus, setFilteredOrderStatus] = useState("");
  const [filteredPaymentStatus, setFilteredPaymentStatus] = useState("");

  //Permissions
  const hasViewPermission = checkPermissions("view_order", true, toast);

  const [sortBy, setSortBy] = useState("");

  useEffect(() => {
    setTableLoading(true);

    const params = {
      page: page,
      length: pageLength,
      search: debouncedSearchValue,
      order_status: filteredOrderStatus,
      payment_status: filteredPaymentStatus,
      exclude_draft: true,
      sort_by: sortBy,
    };
    if (orderStatus === "DRAFT") {
      params.exclude_draft = false;
    }
    getOrderList(params)
      .then((res: any) => {
        // console.log(res);
        setOrderList(res?.data?.order);
        setTotalCount(res?.data.pagination.total_count);
      })
      .catch((error) => {
        console.error("Error fetching order list:", error);
      })
      .finally(() => {
        setTableLoading(false);
      });
  }, [
    page,
    pageLength,
    debouncedSearchValue,
    orderStatus,
    filteredOrderStatus,
    filteredPaymentStatus,
    sortBy,
  ]);

  if (!hasViewPermission) {
    navigate("/unauthorized");
  }

  return (
    <div className="h-full flex flex-col justify-start items-start">
      <div className="h-[calc(100%-13vh)] w-full">
        <Table
          orderList={orderList}
          isLoading={tableLoading}
          page={page}
          pageLength={pageLength}
          setPage={setPage}
          setPageLength={setPageLength}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          totalCount={totalCount}
          setTotalCount={setTotalCount}
          setOrderStatus={setOrderStatus}
          filteredOrderStatus={filteredOrderStatus}
          setFilteredOrderStatus={setFilteredOrderStatus}
          filteredPaymentStatus={filteredPaymentStatus}
          setFilteredPaymentStatus={setFilteredPaymentStatus}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      </div>
    </div>
  );
}

export default OrderList;
