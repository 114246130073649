import React from "react";

interface ChangeIconProps {
  size?: string;
  color?: string;
}

const ChangeIcon = ({ color = "black", size = "12" }: ChangeIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M14 3.00008V6.00008C14 6.13269 13.9473 6.25987 13.8536 6.35364C13.7598 6.44741 13.6326 6.50008 13.5 6.50008H10.5C10.4011 6.50016 10.3043 6.47088 10.222 6.41595C10.1397 6.36101 10.0756 6.28289 10.0377 6.19148C9.99981 6.10007 9.98991 5.99947 10.0092 5.90243C10.0286 5.80539 10.0762 5.71626 10.1462 5.64633L11.2906 4.50008C10.37 3.61805 9.14556 3.12373 7.87063 3.11946H7.8425C6.53609 3.1169 5.28121 3.62882 4.34938 4.54446C4.25389 4.6336 4.12731 4.68183 3.99672 4.67883C3.86612 4.67584 3.74189 4.62185 3.65059 4.52843C3.55929 4.43501 3.50817 4.30957 3.50818 4.17894C3.50819 4.04831 3.55932 3.92287 3.65063 3.82946C4.76524 2.74045 6.25981 2.1278 7.8181 2.12115C9.37639 2.1145 10.8761 2.71437 12 3.79384L13.1475 2.64633C13.2175 2.57676 13.3065 2.52945 13.4033 2.51037C13.5002 2.49129 13.6005 2.50129 13.6916 2.53912C13.7828 2.57694 13.8607 2.6409 13.9156 2.72293C13.9705 2.80496 13.9998 2.90139 14 3.00008ZM11.6506 11.4557C10.7245 12.3603 9.48355 12.8704 8.18896 12.8787C6.89436 12.8869 5.64702 12.3928 4.70938 11.5001L5.85375 10.3557C5.9244 10.2859 5.97266 10.1966 5.99236 10.0992C6.01205 10.0019 6.00231 9.90084 5.96435 9.80904C5.9264 9.71724 5.86196 9.63883 5.77926 9.5838C5.69655 9.52878 5.59934 9.49963 5.5 9.50008H2.5C2.36739 9.50008 2.24022 9.55276 2.14645 9.64653C2.05268 9.7403 2 9.86748 2 10.0001V13.0001C1.99992 13.099 2.0292 13.1958 2.08414 13.2781C2.13908 13.3604 2.21719 13.4245 2.30861 13.4624C2.40002 13.5003 2.50061 13.5102 2.59765 13.4908C2.6947 13.4715 2.78382 13.4238 2.85375 13.3538L4 12.2063C5.10762 13.2753 6.58568 13.8746 8.125 13.8788H8.15812C9.72568 13.8829 11.2317 13.2692 12.35 12.1707C12.4413 12.0773 12.4924 11.9519 12.4924 11.8212C12.4925 11.6906 12.4413 11.5652 12.35 11.4717C12.2587 11.3783 12.1345 11.3243 12.0039 11.3213C11.8733 11.3183 11.7467 11.3666 11.6512 11.4557H11.6506Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default ChangeIcon;
