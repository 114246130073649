import React, { useState } from "react";
import editPen from "../../../assets/svg/pen.svg";

import OrderAddressPopup from "./OrderAddressPopup";
import PlusIcon from "../../../assets/PlusIcon";
import { useRecoilValue } from "recoil";
import { stateAtom } from "../../../atom";
import { checkPermissions } from "../../../utils/helper";
import { Tooltip } from "antd";

type TotalProfessionalsType = {
  title?: string;
  heading?: string;
  content?: any;
  orderDetails?: any;
  setOrderDetails?: any;
  editDisabled?: boolean;
};

function OrderAddressCard({
  title,
  heading,
  content,
  orderDetails,
  setOrderDetails,
  editDisabled,
}: TotalProfessionalsType) {
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const stateData = useRecoilValue(stateAtom);
  console.log(content);
  // Permission
  const hasEditPermission = checkPermissions("edit_order");
  return (
    <>
      <div className="text-start px-4 pt-4 py-4 h-full">
        <div className="w-full flex justify-between">
          <h1 className="text-[12px] leading-[20px] font-gilroy-semi-bold">
            {title}
          </h1>
          <Tooltip
            title={
              !hasEditPermission &&
              "You don't have permission to perform this action"
            }
          >
            <button
              disabled={!hasEditPermission || editDisabled}
              onClick={() => setShowAddressPopup(true)}
              className={`${!hasEditPermission && " opacity-30 "}`}
            >
              {(title === "Billing Address" && orderDetails?.address_billing) ||
              (title === "Shipping Address" &&
                orderDetails?.address_delivery) ? (
                <img src={editPen} alt="edit-pen" />
              ) : (
                <PlusIcon color="black" />
              )}
            </button>
          </Tooltip>
        </div>
        {/* line 1 line 2 city - pincode state */}
        {(title === "Billing Address" && orderDetails?.address_billing) ||
        (title === "Shipping Address" && orderDetails?.address_delivery) ? (
          <div className="flex flex-col space-y-0 text-start mt-6 text-[#1C1C1C]">
            <h3 className=" text-[12px] leading-[20px] font-gilroy-medium">
              {heading}
            </h3>

            <p className="text-[12px] leading-[20px] font-gilroy-medium">
              {content[0].line1},
            </p>
            <p className="text-[12px] leading-[20px] font-gilroy-medium">
              {content[1].line2 && <p>{content[1].line2},</p>}
            </p>
            <p className="text-[12px] leading-[20px] font-gilroy-medium">
              {content[2].city} - {content[3].pincode},
            </p>
            <p className="text-[12px] leading-[20px] font-gilroy-medium">
              {stateData &&
                stateData?.find(
                  (state: any) => state?.state_code == content[4].state
                )?.name}
            </p>
          </div>
        ) : (
          <div className="py-5  text-[12px] leading-[12px] font-gilroy-medium text-c-white-3">
            <p>No address added</p>
          </div>
        )}
        {showAddressPopup && (
          <OrderAddressPopup
            orderDetails={orderDetails}
            setOrderDetails={setOrderDetails}
            currentAddress={
              title === "Shipping Address"
                ? orderDetails?.address_delivery
                : orderDetails.address_billing
            }
            title={title}
            setShowAddressPopup={setShowAddressPopup}
          />
        )}
      </div>
    </>
  );
}

export default OrderAddressCard;
