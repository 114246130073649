import { useState } from "react";
import Button from "../../../components/Button";
import RecordIcon from "../../../assets/svg-tsx/RecordIcon";
import Modal from "../../../components/common/Modal";
import {
  recordPaymentDefaultValues,
  recordPaymentFormSchema,
  RecordPaymentFormSchema,
} from "../../../validators/record-payment-form";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectCheckboxOption from "../../../components/SelectCheckboxOption";
import InputField, { primaryInputStyle } from "../../../components/InputField";
import DatePicker from "react-datepicker";
import ErrorMessage from "../../../components/ErrorMessage";
import SelectField from "../../../components/SelectField";
import SelectField2 from "../../../components/SelectedField2";
import activeStatus from "../../../assets/svg/activestatus.svg";
import DeActiveStatus from "../../../assets/svg/deactivatestatus.svg";
import { PAYMENT_MODES } from "../../../utils/data";
import { recordPayment } from "../../../api/orderManagement";
import { toast } from "react-toastify";

// Function to format the date into YYYY-MM-DD format to pass as response body
function formatDate(inputDate: any) {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

type RecordPaymentFormProps = {
  orderId: string | undefined;
  getPaymentLogsApi: () => void;
  getSingleOrderApi: () => void;
  getDispatchListApi: () => void;
  orderDetails?: any;
};

export default function RecordPaymentForm({
  orderId,
  getPaymentLogsApi,
  getSingleOrderApi,
  getDispatchListApi,
  orderDetails,
}: RecordPaymentFormProps) {
  const [loading, setLoading] = useState(false);
  const [recordPaymentOpen, setRecordPaymentOpen] = useState(false);

  const {
    control,
    handleSubmit,
    setError,
    reset,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<RecordPaymentFormSchema>({
    defaultValues: recordPaymentDefaultValues,
    resolver: yupResolver(recordPaymentFormSchema),
  });

  function handleCancel() {
    setRecordPaymentOpen(false);
    reset();
  }

  async function onSubmit(data: RecordPaymentFormSchema) {
    const formattedAmount =
      data?.payment_type === "payment"
        ? parseFloat(data?.amount) * 100
        : -parseFloat(data?.amount) * 100;
    const payload = {
      amount: formattedAmount,
      mode: data?.mode,
      pay_date: data?.pay_date,
      comment: data?.comment,
      is_reconciled: data?.is_reconciled,
    };
    try {
      setLoading(true);
      await recordPayment(orderId, payload);
      await getPaymentLogsApi();
      await getSingleOrderApi();
      await getDispatchListApi();
      toast.success("Payment recorded successfully");
      setRecordPaymentOpen(false);
    } catch (error: any) {
      if (error?.data?.errors?.general?.length > 0) {
        error?.data?.errors?.general?.forEach((errorItem: any) => {
          Object.entries(errorItem).forEach(([field, message]) => {
            // Set field-level errors using setError
            if (field === "amount") {
              setError("amount", {
                type: "manual",
                message: message as string,
              });
            } else {
              setError(field as keyof RecordPaymentFormSchema, {
                type: "manual",
                message: message as string,
              });
            }
          });
        });
      } else {
        toast.error("Failed to record payment");
      }
    } finally {
      setLoading(false);
    }
  }

  const payment = watch("payment_type") === "payment" ? true : false;

  const paymentMethodOptions = payment
    ? PAYMENT_MODES.filter((mode) => !mode?.value?.startsWith("PG_"))
    : PAYMENT_MODES;

  return (
    <div>
      <Button
        variant="secondary"
        label="Record"
        leftIcon={<RecordIcon className="w-5 h-5" />}
        onClick={() => setRecordPaymentOpen(true)}
        className="h-[30px] !font-gilroy-medium"
        disabled={!orderDetails?.allowed_actions?.can_record_payment}
      />

      {recordPaymentOpen && (
        <Modal
          handleCancel={handleCancel}
          header="Record Payment"
          clickOutsideToClose={true}
          widthClass="!w-[564px]"
        >
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="mt-7 w-full flex flex-col gap-4">
              <div className="flex justify-start gap-4">
                <Controller
                  name="payment_type"
                  control={control}
                  render={({ field }) => {
                    return (
                      <SelectCheckboxOption
                        kind="Payment Received (Offline only) "
                        isSelected={field?.value === "payment"}
                        handleSelection={() => field.onChange("payment")}
                      />
                    );
                  }}
                />
                <Controller
                  name="payment_type"
                  control={control}
                  render={({ field }) => {
                    return (
                      <SelectCheckboxOption
                        kind="Issue Refund"
                        isSelected={field?.value === "refund"}
                        handleSelection={() => field.onChange("refund")}
                      />
                    );
                  }}
                />
              </div>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      label={payment ? "Amount received " : "Amount to refund"}
                      placeholder={
                        payment ? "Amount received" : "Amount to refund"
                      }
                      value={field?.value}
                      onChange={(e) => {
                        // Allow numbers and up to 2 decimal places
                        const value = e.replace(/[^0-9.]/g, "");
                        // Ensure only one decimal point
                        const parts = value.split(".");
                        if (parts.length > 2) return;
                        // Limit decimal places to 2
                        if (parts[1] && parts[1].length > 2) return;
                        field.onChange(value);
                      }}
                      hint={errors?.amount?.message}
                      required
                      className="w-full"
                    />
                  );
                }}
              />
              <div className="flex justify-start items-start gap-4                                                                                                      ">
                <Controller
                  name="pay_date"
                  control={control}
                  render={({ field }) => (
                    <div className="w-full">
                      <label className="text-xs block pb-1 font-gilroy-semi-bold">
                        {payment ? "Payment Date" : "Refund Date"}{" "}
                        <span className="text-red-500 text-xs ml-1">*</span>
                      </label>
                      <DatePicker
                        selected={field.value ? new Date(field.value) : null}
                        onChange={(date: Date) => {
                          const formattedDate = formatDate(date);
                          field.onChange(formattedDate);
                        }}
                        maxDate={new Date()}
                        className={`text-left w-[250px] h-[35.5px] px-2 border rounded-[6px] ${primaryInputStyle}`}
                        dateFormat="dd/MM/yy"
                        placeholderText="DD/MM/YY"
                        dayClassName={(date) =>
                          field.value &&
                          date.toDateString() ===
                            new Date(field.value).toDateString()
                            ? "bg-pot-yellow text-[#000]"
                            : null
                        }
                      />
                      <ErrorMessage error={errors?.pay_date?.message} />
                    </div>
                  )}
                />

                <Controller
                  name="mode"
                  control={control}
                  render={({ field }) => {
                    return (
                      <SelectField2
                        label={payment ? "Mode of Payment" : "Mode of Refund"}
                        options={paymentMethodOptions}
                        value={field?.value}
                        onChange={field.onChange}
                        placeholder={
                          payment
                            ? "Select Mode of Payment"
                            : "Select Refund Method"
                        }
                        hint={errors?.mode?.message}
                        selectCSS="w-[250px]"
                        required
                      />
                    );
                  }}
                />
              </div>
              <Controller
                name="comment"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      label="Comment"
                      placeholder="Comment"
                      value={field?.value}
                      onChange={field.onChange}
                      hint={errors?.comment?.message}
                      className="w-full"
                    />
                  );
                }}
              />

              <div className="flex flex-col">
                <p className="text-sm text-[#1C1C1c] font-gilroy-semi-bold">
                  Mark as reconcile
                </p>
                <Controller
                  name="is_reconciled"
                  control={control}
                  render={({ field }) => {
                    return (
                      <div
                        onClick={() => {
                          field.onChange(!field?.value);
                        }}
                        className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white h-8 w-12"
                      >
                        {field.value ? (
                          <img
                            src={activeStatus}
                            alt="status-icon"
                            draggable={false}
                          />
                        ) : (
                          <img
                            src={DeActiveStatus}
                            alt="status-icon"
                            draggable={false}
                          />
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            </div>

            <div className="flex w-full gap-4 mt-7">
              <Button
                variant="primary"
                label="Record Payment"
                type="submit"
                onClick={handleSubmit(onSubmit)}
                loading={loading}
                className="!min-w-[130px]"
              />
              <Button
                variant="secondary-outline-dark"
                label="Cancel"
                onClick={handleCancel}
                disabled={loading}
              />
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
}
