import React, { useState } from "react";
import Modal from "../../../components/common/Modal";
import Button from "../../../components/Button";
import InputField from "../../../components/InputField";
import { updateQuotationItemUnitPrice } from "../../../api/quotation";
import { toast } from "react-toastify";

type ChangeItemUnitPriceModalProps = {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  itemInfo: any;
  handleClose?: () => void;
  quotationId: any;
  getSingleQuotationCartItems: (quotationId: any) => void;
  floorInfo?: any;
  isPartList?: boolean;
  partListData?: any;
};

export default function ChangeItemUnitPriceModal({
  openModal,
  setOpenModal,
  itemInfo,
  handleClose,
  quotationId,
  getSingleQuotationCartItems,
  floorInfo,
  isPartList = false,
  partListData,
}: ChangeItemUnitPriceModalProps) {
  const [unitPrice, setUnitPrice] = useState(
    isPartList ? partListData?.price_unit : itemInfo?.price_unit
  );
  const [loading, setLoading] = useState(false);

  function handleUpdateUnitPrice() {
    setLoading(true);
    let payload: any = {};

    if (!isPartList) {
      payload.custom_unit_price = unitPrice ? parseFloat(unitPrice) : null;
    }

    if (isPartList) {
      payload.track_part_updates = [
        {
          part_id: partListData?.id,
          custom_unit_price: unitPrice ? parseFloat(unitPrice) : null,
        },
      ];
    }

    if (floorInfo) {
      payload.floor_id = floorInfo.floor_id;
      payload.area_id = floorInfo.area_id;
    }

    updateQuotationItemUnitPrice(quotationId, itemInfo?.id, payload)
      .then(() => {
        getSingleQuotationCartItems(quotationId);
        toast.success("Unit price updated successfully");
        handleClose?.();
      })
      .catch((error) => {
        if (error?.data?.errors?.custom_unit_price?.[0]) {
          toast.error(error?.data?.errors?.custom_unit_price?.[0]);
        } else {
          toast.error("Error in updating unit price");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Modal
      clickOutsideToClose
      allowScroll
      header="Change Item Unit Price"
      footer={
        <div className="flex items-center gap-4">
          <Button
            variant="secondary"
            label="Update Price"
            onClick={handleUpdateUnitPrice}
            disabled={loading}
          />
          <Button
            variant="primary"
            label="Cancel"
            onClick={handleClose}
            disabled={loading}
          />
        </div>
      }
      handleCancel={handleClose}
    >
      <div className="flex flex-col gap-2 w-full">
        <InputField
          label="Unit Price"
          placeholder="Default Original Price"
          value={unitPrice}
          onChange={(value) => {
            const regex = /^(\d+)?(\.\d{0,2})?$/;
            if (value === "" || regex.test(value)) {
              setUnitPrice(value);
            }
          }}
          disabled={loading}
        />
        <div className="flex justify-end">
          <Button
            label="Revert to Original Price"
            onClick={() => {
              setUnitPrice("");
            }}
            disabled={loading}
          />
        </div>
      </div>
    </Modal>
  );
}
