import { protectedAxiosInstance } from "./axiosManagement";

export const getLightingConsultant = (params) => {
  return protectedAxiosInstance
    .get("/admin/accounts/admin-users/lc-quick-list", { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postLightingConsultant = (payload) => {
  return protectedAxiosInstance
    .post("/admin/lc/order/create", payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getLightingConsultantDetails = (id) => {
  return protectedAxiosInstance
    .get(`/admin/lc/order/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getPaymentLogsLightingConsultant = (id) => {
  return protectedAxiosInstance
    .get(`/admin/lc/order/${id}/payment-history`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const recordPaymentLightingConsultant = (id, payload) => {
  return protectedAxiosInstance
    .post(`/admin/lc/order/${id}/record-payment`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const uploadInvoiceLightingConsultant = (id, payload) => {
  return protectedAxiosInstance
    .post(`/admin/lc/order/${id}/invoice`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteInvoiceLightingConsultant = (id) => {
  return protectedAxiosInstance
    .delete(`/admin/lc/order/${id}/invoice`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const submitConsultantFiles = (id, payload) => {
  return protectedAxiosInstance
    .post(`/admin/lc/order/${id}/consultant-files`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};


export const getListForLightingConsultant = (params) => {
  return protectedAxiosInstance
    .get("/admin/lc/order", { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const assignedConsultant = (orderId, payload) => {
  return protectedAxiosInstance
    .post(`/admin/lc/order/${orderId}/assign-consultant`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};


export const cancelOrderLC = (orderId) => {
  return protectedAxiosInstance
    .post(`/admin/lc/order/${orderId}/cancel-order`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const closeOrderLC = (orderId) => {
  return protectedAxiosInstance
    .post(`/admin/lc/order/${orderId}/close-order`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};