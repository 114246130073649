import { useMemo } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import InputField from "../../../components/InputField";
import SearchIcon from "../../../assets/SearchIcon";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import ManagementTableLayout from "../../../components/MangementTable";
import PlusIcon from "../../../assets/PlusIcon";
import { formatIndianRupeesForDecimals } from "../../../utils/formatToIndianRupees";
import Pagination from "../../../components/Pagination";
import { useRecoilValue } from "recoil";
import { ordeAppConfigAtom, stateAtom } from "../../../atom";
import { checkPermissions } from "../../../utils/helper";
import OrderStatusTag from "../../../components/OrderStatusTag";
import PaymentStatusTag from "../../../components/PaymentStatusTag";
import SelectField2 from "../../../components/SelectedField2";
import FilterIcon from "../../../assets/svg-tsx/FilterIcon";
import UpandDownArrow from "../../../assets/svg/dateIcon.svg";
import UpArrowIcon from "../../../assets/svg-tsx/UpArrowIcon";
import { UpwardArrowIcon } from "../../../assets/ArrowIcons";
import UpArrow2 from "../../../assets/svg-tsx/UpArrow2";

const Table = ({
  page,
  setPage,
  pageLength,
  setPageLength,
  searchValue,
  setSearchValue,
  orderList,
  isLoading,
  totalCount,
  setTotalCount,
  setOrderStatus,
  orderStatusSummary,
  filteredOrderStatus,
  setFilteredOrderStatus,
  filteredPaymentStatus,
  setFilteredPaymentStatus,
  loading,
  sortBy,
  setSortBy,
}: any) => {
  const stateList = useRecoilValue(stateAtom);
  const orderAppConfig = useRecoilValue(ordeAppConfigAtom);

  const ORDER_STATUS = orderAppConfig?.order_status?.map((status: any) => ({
    label: status,
    value: status,
  }));
  const PAYMENT_STATUS = orderAppConfig?.payment_status?.map((status: any) => ({
    label: status,
    value: status,
  }));

  // Permissions
  const hasCreatePermission = checkPermissions("create_order");
  const hasEditPermission = checkPermissions("edit_order");

  const handleSort = (field: string) => {
    if (sortBy === field) {
      // If already sorting by this field, toggle to descending
      setSortBy(`-${field}`);
    } else if (sortBy === `-${field}`) {
      // If already sorting by this field descending, clear sort
      setSortBy("");
    } else {
      // Otherwise, sort by this field ascending
      setSortBy(field);
    }
  };

  // Helper function to determine sort direction for a field
  const getSortDirection = (field: string) => {
    if (sortBy === field) return "asc";
    if (sortBy === `-${field}`) return "desc";
    return "none";
  };

  const COLUMN = [
    {
      id: "order_no",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center gap-1 min-w-max cursor-pointer">
          Order ID
          {/* <img src={UpandDownArrow} alt="UpandDownArrow" className="ml-2" /> */}
          <div
            className="w-4 h-4 flex items-center justify-center"
            onClick={() => handleSort("order_no")}
          >
            <UpArrow2
              className={`h-4 w-4 transition-transform ${
                getSortDirection("order_no") === "desc"
                  ? "rotate-180"
                  : getSortDirection("order_no") === "none"
                  ? "opacity-50"
                  : ""
              }`}
            />
          </div>
        </div>
      ),
      accessor: "order_no",
      width: "10px",
      Cell: ({ value, row }: any) => (
        <Link to={`/orders/${row.original.id}`}>
          <div>{value || "-"}</div>
        </Link> // id needs to be checked, showing undefined
      ),
    },
    {
      id: "order_date",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 px-2 cursor-pointer">
          <p>Date</p>
          {/* <img src={UpandDownArrow} alt="UpandDownArrow" className="ml-2" /> */}
          <div
            className="w-4 h-4 flex items-center justify-center"
            onClick={() => handleSort("order_date")}
          >
            <UpArrow2
              className={`h-4 w-4 transition-transform ${
                getSortDirection("order_date") === "desc"
                  ? "rotate-180"
                  : getSortDirection("order_date") === "none"
                  ? "opacity-50"
                  : ""
              }`}
            />
          </div>
        </div>
      ),
      accessor: "order_date",
      Cell: ({ value, row }: any) => {
        // Parse the incoming date string
        const date = new Date(value);
        // Create a formatted date string in the "DD/MM/YYYY" format
        const formattedDate = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`;

        return <div>{value ? formattedDate : "-"}</div>;
      },
    },
    {
      id: "owner_name",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center ">Name</div>
      ),
      accessor: "owner_name",
      Cell: ({ value, row }: any) => (
        <>
          {hasEditPermission ? (
            <Link to={`/orders/${row?.original?.id}`}>
              <div className="underline underline-offset-2 hover:scale-105">
                {row.original.owner.name}
              </div>
            </Link>
          ) : (
            <div>{row.original.owner.name}</div>
          )}
        </>
      ),
    },
    {
      id: "address_delivery",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center ">Location</div>
      ),
      accessor: "address_delivery",
      width: "13vw",
      Cell: ({ row }: any) => {
        const { address_delivery } = row.original;
        const city = address_delivery?.city;
        const state = address_delivery?.state;

        const location =
          city && state
            ? `${city}, ${
                stateList?.find((el: any) => el?.state_code === state)?.name
              }`
            : "-";

        return <div className="w-[200px] break-words">{location}</div>;
      },
    },
    {
      id: "owner_type",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center ">Type</div>
      ),
      accessor: "owner_type",
      Cell: ({ value, row }: any) => <div>{row.original.owner.kind}</div>,
    },
    {
      id: "partner",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center ">Partner</div>
      ),
      accessor: "partner",
      Cell: ({ value, row }: any) => (
        <div>{row?.original?.assigned_channel_partner?.name || "-"}</div>
      ),
    },
    {
      id: "line_item_count",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-end items-center w-full">Total Item</div>
      ),
      accessor: "line_item_count",
      width: "7vw",
      Cell: ({ value }: any) => (
        <div className="w-full text-right">{value || "-"}</div>
      ),
    },
    {
      id: "grand_total",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-end gap-1 items-center w-full cursor-pointer">
          Total Amount
          {/* <img src={UpandDownArrow} alt="UpandDownArrow" className="ml-2" /> */}
          <div
            className="w-4 h-4 flex items-center justify-center"
            onClick={() => handleSort("total_amount")}
          >
            <UpArrow2
              className={`h-4 w-4 transition-transform ${
                getSortDirection("total_amount") === "desc"
                  ? "rotate-180"
                  : getSortDirection("total_amount") === "none"
                  ? "opacity-50"
                  : ""
              }`}
            />
          </div>
        </div>
      ),
      accessor: "grand_total",
      Cell: ({ value }: any) => (
        <div className="w-full text-right">
          {formatIndianRupeesForDecimals(parseFloat(value)) || "-"}
        </div>
      ),
    },
    {
      id: "payment_status",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Payment Status</div>
      ),
      accessor: "payment_status",
      width: "11vw",
      Cell: ({ value, row }: { value: any; row: any }) => (
        <div>
          <PaymentStatusTag
            status={row.original.payment_status}
            className="!py-0 px-3"
          />
        </div>
      ),
    },

    {
      id: "status",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center ">Order Status</div>
      ),
      accessor: "status",
      width: "12vw",
      Cell: ({ value, row }: { value: any; row: any }) => (
        <div>
          <OrderStatusTag status={value} />
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMN, [orderList]);
  const data = useMemo(() => orderList || [], [orderList]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
    },
    useSortBy,
    usePagination
  );

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };

  return (
    <div className="h-full">
      <div className="flex flex-row justify-between items-center pl-3 pt-12 pb-8 w-full h-[3rem] ">
        <div className="flex gap-4 w-[max-content]">
          <div className="w-[235px]">
            <InputField
              onChange={(e: string) => setSearchValue(e)}
              placeholder="Search Order ID"
              value={searchValue}
              inputType={"number"}
              className="arrows-removed-from-input"
              rightIcon={<SearchIcon />}
            />
          </div>

          {hasCreatePermission && (
            <div className="w-fit">
              <Link to={"/orders/create"}>
                <Button label="Create Order" leftIcon={<PlusIcon />} />
              </Link>
            </div>
          )}
        </div>
        <div className="w-full flex flex-row justify-end">
          <div className="mx-4 mb-4">
            <SelectField2
              disabled={isLoading}
              label="Payment Status"
              placeholder="Select payment status"
              value={filteredPaymentStatus}
              onChange={(e) => {
                if (e === filteredPaymentStatus) {
                  setFilteredPaymentStatus("");
                } else {
                  setFilteredPaymentStatus(e);
                }
              }}
              removeOption={() => {
                setFilteredPaymentStatus("");
              }}
              options={PAYMENT_STATUS?.map((el: any) => ({
                value: el.value,
                label: el.label.split("_").join(" "),
              }))}
              leftIcon={<FilterIcon />}
            />
          </div>
          <div className="mx-4 mb-4">
            <SelectField2
              disabled={isLoading}
              label="Order Status"
              placeholder="Select order status"
              value={filteredOrderStatus}
              onChange={(e) => {
                if (e === filteredOrderStatus) {
                  setFilteredOrderStatus("");
                } else {
                  setFilteredOrderStatus(e);
                }
              }}
              removeOption={() => {
                setFilteredOrderStatus("");
              }}
              options={ORDER_STATUS?.map((el: any) => ({
                value: el.value,
                label: el.label.split("_").join(" "),
              }))}
              leftIcon={<FilterIcon />}
            />
          </div>
        </div>
        <div className="flex flex-row justify-end items-center gap-2 w-[10%] mr-4 mt-2">
          <div className="inline-flex gap-3 items-center">
            <div
              className="flex items-center gap-2 border border-black rounded-md px-2  py-1 text-sm cursor-pointer hover:bg-black hover:text-white"
              onClick={() => setOrderStatus("DRAFT")}
            >
              <p className="font-medium">Drafts</p>
              {orderStatusSummary?.DRAFT?.count !== undefined && (
                <p className="bg-black px-1 py-0.5 rounded-full text-white text-xs text-center">
                  {orderStatusSummary.DRAFT.count}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="h-[calc(100%-2rem)] w-full relative -z-0 mt-2">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={isLoading}
        />
      </div>
      {isLoading ? (
        ""
      ) : (
        <div className="w-full">
          <Pagination
            currentPage={page}
            pageSize={pageLength}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}
    </div>
  );
};

export default Table;
