import { CustomDropdown } from "../../DropDown";
import tickIcon from "../../../../assets/svg/tick.svg";
import { formatDateTime } from "../../../../utils/helper";

export default function Timeline({ professionalStatusHistory }: any) {
  return (
    <>
      <CustomDropdown label="Timeline">
        <div className="px-4 py-3 h-[50vh] overflow-scroll no-scrollbar">
          {professionalStatusHistory?.map((el: any, i: number) => {
            return (
              <div
                key={i}
                className="flex  justify-start items-start gap-4 w-[80%] text-start"
              >
                <div>
                  <img src={tickIcon} alt="tick-icon" />
                  <div
                    className={`${
                      i !== professionalStatusHistory.length - 1
                        ? `h-[50px] border-2 w-0 ml-2`
                        : ""
                    } `}
                  ></div>
                </div>

                <div>
                  <div className="flex space-x-4 text-[12px] leading-5 ">
                    <p className="font-medium ">
                      Profile Status changed to{" "}
                      {el.new_status.replace("_", " ")}
                    </p>

                    <p className="text-[#AEAEAE]">
                      {formatDateTime(el.created_at)}
                    </p>
                  </div>

                  <p className="text-[12px] leading-5 ">{el.comment}</p>
                </div>
              </div>
            );
          })}
        </div>
      </CustomDropdown>
    </>
  );
}

// const currentDate = new Date();
// const time = currentDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
// console.log(time);

// const currentDate = new Date();
// const day = currentDate.getDate();
// const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(currentDate);
// const year = currentDate.getFullYear().toString().substr(-2);

// const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} '${year}`;
// console.log(formattedDate); // output: "8th May '23"

// function getOrdinalSuffix(day:any) {
//     if (day > 3 && day < 21) return 'th';
//     switch (day % 10) {
//         case 1: return "st";
//         case 2: return "nd";
//         case 3: return "rd";
//         default: return "th";
//     }
// }
